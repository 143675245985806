import {Button} from "@mui/material";
import {button, fonts, mainColors, semiBoldText} from "../../../../../app.style";
import {useContext} from "react";
import {AppContext} from "../../appWrapper/AppWrapper";


const YesButton = ({onClick} : {onClick : Function}) => {
  const {translation} = useContext(AppContext);

  return (
    <Button variant="contained"
            disableElevation fullWidth
            sx={[fonts.spartan14, semiBoldText, button, {background: mainColors.primary.color}]}
            onClick={() => onClick()}>
      {translation.modalPrompt.yes}
    </Button>
  )
}

export default YesButton;