export enum GuiColors {
  INHERIT = "inherit",
  PRIMARY = "primary",
  SECONDARY = "secondary",
  DISABLED = "disabled",
  SECONDARY_BRIGHT = "secondaryBright",
  CONTRAST = "contrast",
  SUCCESS = "success",
  ERROR = "error",
  INFO = "info",
  WARNING = "warning",
  RED = "red",
  ORANGE = "orange",
  GREEN = "green",
  BLUE = "blue",
  VIOLET = "violet",
}