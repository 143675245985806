import {PropsWithChildren} from "react";
import {Box, Container, Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography} from "@mui/material";
import {Close} from '@mui/icons-material';
import {
  centeredBox,
  closeIcon,
  dialogHeaderTypographyStyle,
  mainColors,
  responsiveDialogStyle
} from "../../../../app.style";
import NoButton from "./confirmModal/NoButton";
import YesButton from "./confirmModal/YesButton";

interface AppModalProps extends PropsWithChildren {
  isOpen: boolean;
  setIsOpen: Function;
  headerText: string;
  confirmHandler?: Function;
  setChildren: Function;
}

const ConfirmModal = ({isOpen, setIsOpen, headerText, confirmHandler, children, setChildren} : AppModalProps) => {

  const handleConfirm = () => {
    if (confirmHandler) confirmHandler();
    close();
  }

  const close = () => {
    setIsOpen(false);
    setChildren(<></>)
  }

  return (
    <Dialog open={isOpen} sx={responsiveDialogStyle}>
      <DialogTitle>
        <Box sx={centeredBox}>
          <Typography sx={dialogHeaderTypographyStyle}>
            {headerText}
          </Typography>
          <IconButton onClick={close}
            sx={[closeIcon, mainColors.contrast]}>
            <Close/>
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Container>
          {children}
          <Grid container spacing={2} sx={{mt: 1}}>
            <Grid item xs={12} md={6}>
              <NoButton onClick={close}/>
            </Grid>
            <Grid item xs={12} md={6}>
              <YesButton onClick={() => handleConfirm()}/>
            </Grid>
          </Grid>
        </Container>
      </DialogContent>
    </Dialog>
  )
}

export default ConfirmModal;