import {Box, Button} from "@mui/material";
import {useContext} from "react";
import {AppContext, AppFsDrawerContext} from "../../../../../common/appWrapper/AppWrapper";
import {button, centeredBox, fonts, mainColors, semiBoldText} from "../../../../../../../app.style";
import CruiseForm from "../../../../../common/appForm/cruiseForm/CruiseForm";


const CreteCruiseBtn = () => {
  const {translation} = useContext(AppContext);
  const {setIsFsDrawerOpen, setFsDrawerChildren} = useContext(AppFsDrawerContext);

  const openCreateCruiseDrawer = () => {
    setFsDrawerChildren(<CruiseForm formTitle={translation.page.admin.cruises.cruiseForm.createCruise}/>);
    setIsFsDrawerOpen(true);
  }

  return (
    <Box sx={centeredBox}>
      <Button
        variant="contained"
        color="secondary"
        disableElevation
        fullWidth
        onClick={() => openCreateCruiseDrawer()}
        sx={[fonts.spartan14, semiBoldText, button, mainColors.white, {maxWidth: "330px"}]}>
        {translation.create}
      </Button>
    </Box>
  )
}

export default CreteCruiseBtn;