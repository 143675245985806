import {Translation} from "./translations";

export const translationPl: Translation = {
  desktopLangLabel: "Język / Waluta",
  mobileLangLabel: "Język",
  undefined: "Nie określono",
  choose: "Wybierz",
  save: "Zapisz",
  cancel: "Anuluj",
  login: "Zaloguj się",
  close: "Zamknij",
  edit: "Edytuj",
  delete: "Usuń",
  create: "Stwórz",
  navMenu: {
    menu: "Menu",
    home: "Start",
    cruises: "Rejsy",
    bookings: "Rezerwacje",
    faq: "FAQ",
    admin: "Admin",
    login: "Zaloguj",
    settings: "Ustawienia"
  },
  footer: {
    sections: "Sekcje",
    contact: "Kontakt",
    disclaimer: "Prezentowane na stronie internetowej chacrunasails.eu ogłoszenia, reklamy, cenniki i informacje nie stanowią oferty w rozumieniu przepisów Kodeksu Cywilnego.",
    copyright: "Wszelkie prawa zastrzeżone © Chacruna Sails ",
  },
  form: {
    common: {
      dintReceiveEmail: "Nie otrzymałeś maila?",
      sendAgain: "Wyślij ponownie",
      sendAgainSuccess: "Sprawdź ponownie skrzynkę",
      sendAgainFailure: "Coś poszło nie tak :("
    },
    loginForm: {
      emailAddress: "Adres e-mail",
      password: "Hasło",
      loginBtn: "Zaloguj",
      forgotPass: '"Nie pamiętam hasła..."',
      noAccount: "Nie masz konta?",
      createAccount: "Stwórz konto",
    },
    registerForm: {
      gender: {
        title: "Płeć",
        options: {
          MALE: "Mężczyzna",
          FEMALE: "Kobieta",
        }
      },
      name: "Imię",
      surname: "Nazwisko",
      nickname: "Pseudonim",
      email: "Adres email",
      phone: "Nr. telefonu",
      password: "Hasło",
      repeatPassword: "Powtórz hasło",
      born: "Data urodzenia",
      registerBtn: "Zarejestruj",
      alreadyHaveAccount: "Masz już konto?",
      backToLogin: "Zaloguj się",
      passReq: {
        header: "Twoje hasło musi zawierać:",
        leastSigns: "co najmniej 8 znaków",
        capitalLetter: "wielką literę",
        smallLetter: "małą literę"
      },
      regSuccessInfo1: "Wysłano email weryfikacyjny na adres: ",
      regSuccessInfo2: "Sprawdź skrzynkę pocztową (również folder \"Spam\") i potwierdź adres aby móc się zalogować.",
    },
    resetPassword: {
      title: "Podaj adres email na który zarejestrowano konto",
      reset: "Resetuj",
      mailInfo: "Jeśli konto z podanym adresem email istnieje, wysłano email z instrukcją odnośnie dalszych kroków w celu zmiany hasła.",
      iRemember: "\"Pamiętam hasło...\"",
    },
    setPassword: {
      title: "Podaj nowe hasło",
      change: "Zmień hasło",
      changeSuccess: "Hasło zostało pomyślnie zmienione!",
      changeFailure: "Coś poszło nie tak :(",
    },
    profileSettings: {
      update: "Aktualizuj",
      updated: "Zapisano zmiany",
      logout: "Wyloguj",
    },
    newBooking: {
      steps: {
        data: "Dane",
        bunks: "Koje",
        payment: "Płatność",
      },
      subStep: "Krok",
      bookingDataStep: {
        bookedCruiseInfo: {
          title: "Informacje o rezerwacji:",
          from: "Rejs z:",
          to: "Rejs do:",
          embarkDate: "Zaokrętowanie:",
          disembarkDate: "Wyokrętowanie:",
          duration: "Czas trwania:",
          days: "dni",
        },
        bookedByDetails: {
          title: "Dane osoby rezerwującej:",
          iAmAlsoPassenger: "Jestem jednocześnie uczestnikiem rejsu.",

        },
        passengerDetails: {
          title: "Dane uczestników rejsu:",
          passenger: "Dane",
          details: "uczestnika",
          addAnotherPassenger: "Dodaj uczestnika",
          noMoreSpaces: "Nie ma więcej miejsc",
          removePassenger: "Usuń uczestnika",
        },
        requiredAgreements: {
          title: "Wymagane zgody:",
          reservationPolicyConfirm1: "Zapoznałem się i akceptuję",
          policy: "politykę",
          reservationPolicyConfirm2: "rezerwacji i anulowania rezerwacji rejsów na stronie \"Chacruna Sails\".",
          reservationProcessingConfirm: "Wyrażam zgodę na przetwarzanie moich danych osobowych w celu prawidłowego przeprowadzenia procesu rezerwacji.",
        },
        nextPreviousBtns: {
          next: "Wybierz Koje",
          previous: "",
        }
      },
      bunksSelectionStep: {
        title: "swoje koje:",
        available: "Dostępne",
        taken: "Zajęte",
        selected: "Wybrane",
        nextPreviousBtns: {
          next: "Do Płatności",
          previous: "Zmień Dane",
        },
        bunkName: {
          bowPort: "1: Dziób - bakburta",
          bowStarboard: "2: Dziób - sterburta",
          mesaPort: "3: Mesa - bakburta",
          mesaStarboard: "4: Mesa - sterburta",
          sternPort: "5: Rufa - bakburta",
          sternStarboard: "6: Rufa - sterburta",
        }
      },
      paymentStep: {
        discountCode: {
          title: "Kod zniżki:",
          label: "Kod",
          description: "Jeśli posiadasz kod zniżki, możesz go użyć aby zobaczyć nową cenę.",
          valid: "Kod poprawny",
          invalid: "Kod niepoprawny",
          useCode: "Zastosuj"
        },
        bookingOptions: {
          title: "Opcje rezerwacji:",
          fullPaymentReq: "Z uwagi na bliski termin rejsu, możliwa jest tylko opcja pełnej płatności.",
          remainingPaymentDueInfo: "Pełna płatność będzie wymagana do 14 dnia przed rozpoczęciem rejsu.",
          remainingPaymentDueDate: "Zapłać pozostałą część rezerwacji napóźniej do końca: ",
          bookingType: "Typ rezerwacji",
          options: {
            ONE_STAGE: "Pełna płatność (100%)",
            TWO_STAGE: "Tylko zaliczka (50%)"
          },

        },
        paymentOptions: {
          title: "Forma płatności",
          currency: "Waluta",
        },
        summary: {
          title: "Podsumowanie:",
          yourBunks: "Numery twoich koi:",
          bookedQty: "Ilość rezerwowanych miejsc:",
          pricePerPerson: "Cena za osobę:",
          discountPricePerPerson: "Cena za osobę po zniżce:",
          totalPrice: "Cena całkowita:",
          amountToPay: "Do zapłaty:"
        },
        nextPreviousBtns: {
          next: "Płacę",
          previous: "Zmień Koje",
        },
        bankTransferInfo1: "Wysłano email z danymi przelewu na adres: ",
        bankTransferInfo2: "Sprawdź skrzynkę pocztową (również folder \"Spam\") i postępuj zgodnie z instrukcją zawartą " +
          "w wiadomości email.",
      }
    }
  },
  modalHeader: {
    menuLogin: "Logowanie w serwisie",
    bookingLogin: "Zaloguj się aby dokonać rezerwacji",
    register: "Rejestracja w serwisie",
    profileSettings: "Ustawienia profilu",
    passReset: "Resetowanie hasła",
    regSuccess: "Rejestracja pomyślna!",
    writeReview: "Nowa recenzja",
    editReview: "Edytuj recenzję",
    editDiscount: "Edytuj zniżkę",
    createDiscount: "Stwórz zniżkę",
    closeBookingDetailsConfirm: "Czy napewno chcesz zamknąć szczegóły rezerwacji?",
    cancelBooking: "Czy napewno chcesz anulować rezerwację?",
    deleteCruise: "Czy napewno chcesz usunąć rejs?",
    deleteDiscount: "Czy napewno chcesz usunąć zniżkę?",
    deleteReview: "Czy napewno chcesz usunąć recenzję?",
    discardChanges: "Czy napewno chcesz odrzucić zmiany?",
    manageBooking: "Zarządzanie rezerwacją",
    changeBookingStatus: "Zmień rezerwację",
    advanceBooking: "Zmienić na następny status?",
    bookingParticipants: "Uczestnicy rezerwacji",
    crewList: "Lista załogi",
    paymentInfoSent: "Wysłano dane do przelewu",
    policy: "Polityka rezerwacji",
    bookingCollision: "Kolizja rezerwacji :(",
  },
  bookingCollisionModal: {
    text: "Ups! Wygląda na to, że Twoja rezerwacja wygasła lub ktoś inny zdążył zarezerwować Twoje koje przed Tobą. Proszę, spróbuj złożyć nową rezerwację..."
  },
  modalPrompt: {
    yes: "Tak",
    no: "Nie",
    allUnsavedDataWillBeLost: "Wszystkie niezapisane zmiany zostaną utracone",
  },
  policyModal: {
    text1: "Użytkownik składając rezerwację w serwisie \"Chacruna Sails\" oświadcza, że jest osobą pełnoletnią a jego (lub uczestników dla których składa rezerwację) stan zdrowia fizycznego i psychicznego nie stanowi poważnych przeciwwskazań do odbycia rejsu na jachcie morskim Bavaria 36 \"Chacruna\".",
    text2: "Użytkownik składając rezerwację w serwisie \"Chacruna Sails\" najpierw tworzy wstępną rezerwację która zostanie potwierdzona drogą email, dopiero po ujszczeniu opłaty rezerwacyjnej. Opłatę rezerwacyjną należy wnieść jak najszybciej (po otrzymaniu wiadomości email z instrukcją) w celu zabezpieczenia rezerwowanych koi i miejsca na rejsie. Organizator zastrzega sobie prawo (w rzadkich przypadkach) do unieważnienia rezerwacji w przypadku gdyby opłata rezerwacyjna została wniesiona przez innego użytkownika wcześniej niż opłata rezerwacyjna użytkownika pierwotnego i byłaby rezerwacją na te same koje co w rezerwacji użytkownika pierwotnego. W takim przypadku, użytkownik pierwotny otrzyma pełny zwrot wpłaconej kwoty.",
    text3: "Użytkownik po złożeniu wstępnej rezerwacji otrzyma drogą mailową od Organizatora \"Umowę świadczenia usługi turystycznej\" oraz dane do przelewu bankowego po ujszczeniu którego, jego rezerwacja zostanie potwierdzona i zabezpieczona.",
    text4: "Użytkownik, w związku z rezerwowanym rejsem, nie powinien organizować logistyki (kupować biletów transportu masowego, rezerwować hoteli) przed otrzymaniem oficjalnego potwierdzenia ważności rezerwacji drogą email z serwisu \"Chacruna Sails\". Organizator nie ponosi odpowiedzialności za ewentualne straty finansowe użytkownika który podjąłby takie działania przed otrzymaniem oficjalnego potwierdzenia rezerwacji. Oficjalnym potwierdzeniem rezerwacji jest status rezerwacji: ZALICZKA WPŁACONA lub OPŁACONO.",
    text5: "Użytkownikowi przysługuje nieograniczone prawo do anulowania wcześniej złożonej rezerwacji i w zależności od tego ile dni pozostało w momencie anulowania rezerwacji do dnia planowanego rozpoczęcia rejsu, może być naliczona \"opłata za późne anulowanie rezerwacji\" według następującego algorytmu:",
    text5a: "Anulowanie rezerwacji wcześniej niż 28 dni przed rozpoczęciem rejsu jest darmowe i użytkownik otrzyma pełny zwrot poniesionych kosztów.",
    text5b: "Anulowanie rezerwacji w przedziale pomiędzy 28 dni a 14 dni przed rozpoczęciem rejsu wiąże się z utratą częsci lub całości wpłaconej zaliczki (w przypadku opcji rezerwacji z wpłaconą tylko zaliczką 50%), utratą częsci do maksymalnie 50% wpłaconej pełnej kwoty (w przypadku opcji rezerwacji z wpłaceniem pełnej kwoty 100%). Utracona kwota jest obliczana proporcjonalnie do ilości dni które upłynęły w przedziale od daty 28 dni przed rozpoczęciem rejsu do daty 14 dni przed rozpoczęciem rejsu zgodnie z zasadą - im bliżej do daty 14 dni przed rozpoczęciem rejsu tym większa utrata zaliczki.",
    text5c: "Anulowanie rezerwacji w przedziale pomiędzy 14 dni a 7 dni przed rozpoczęciem rejsu wiąże się z utratą częsci lub całości wniesionej opłaty. Część \"zaliczkowa\" nie podlega w tym przedziale czasu zwrotowi. Kwota zwrotu pozostałej opłaty rezerwacyjnej liczona jest analogicznie jak w punkcie b) (proporcja jest liczona dla dni które upłynęły w przedziale od 14 do 7 dni przed datą rozpoczęcia rejsu).",
    text5d: "Anulowanie rezerwacji w czasie poniżej 7 dni przed rozpoczęciem rejsu, wiąże się z utratą całej opłaty rezerwacyjnej.",
    text5ex: "Wyjaśnienie: Polityka anulowania rezerwacji wynika z zabezpieczenia Organizatora przed istotnymi stratami materialnymi które mogłyby powstać w sytuacji gdyby bez powyższej polityki, użytkownik anulował rejs, pozostawiająć zbyt mało czasu Organizatorowi na znalezienie zastępstwa (innego członka załogi). Zastępstwo znaleźć jest tym trudniej im bliższy termin rejsu. Należy też wziąć pod uwagę, że uczestnicy rejsu biorą udział w składce na opłaty portowe co w przypadku mniejszej liczby załogi jest bardziej odczuwalne przez uczestników (koszty dzielą się na mniej osób). W celu lepszego zrozumienia polityki anulowania rezerwacji prosimy zapoznać się w sekcji \"Często zadawane pytania\" (FAQ) z pytaniem: \"Czy mogę anulować rezerwację?\".",
    text6: "Użytkownik, po złożeniu rezerwacji, może w każdym momencie zmienić dane uczestników rejsu co pozwala mu zastąpić jednego uczestnika, innym. Jest to przydatne w przypadku gdyby jakikolwiek uczestnik dla którego użytkownik złożył rezerwację, nie mógł z jakiegoś powodu wziąć udziału w rejsie. Pozwala to użytkownikowi na znalezienie zastępstwa na co Organizator w pełni zezwala lecz nie ponosi odpowiedzialności za ustalenia czynione pomiędzy użytkownikiem a zastępcą.",
    text7: "W przypadku składania rezerwacji z opcją wpłaty tylko zaliczki 50%, użytkownik powinien ujścić pozostałe 50% opłaty rezerwacyjnej, najpóźniej do 14 dnia przed datą rozpoczęcia rejsu o czym będzie poinformowany drogą mailową wraz z wiadomościami przypominającymi. Brak ujszczenia pozostałych 50% opłaty rezerwacyjnej wiąże się z ryzykiem utraty (ANULOWANIA) rezerwacji a poniesione przez użytkownika koszty nie podlegają zwrotowi. Rezerwacja która nie została w pełni opłacona do 14 dnia przed rozpoczęciem rejsu wchodzi w stan: ANULOWANIE co oznacza, że jeszcze nie została anulowana ale pierwotnie zarezerwowane przez użytkownika koje są dostępne w serwisie i ktoś inny może je zarezerwować. Jeśli tak się stanie (inny użytkownik dokona rezerwacji na koje które były w rezerwacji o statusie: ANULOWANIE), pierwotna rezerwacja przechodzi w stan ANULOWANA, a wpłacona zaliczka 50% - przepada.",
    text8: "Organizator nie ponosi odpowiedzialności za ewentualne straty finansowe związane z niemożnościa odbycia części lub całości rejsu w wyznaczonym terminie wynikające z takich sytuacji jak: stan klęski żywiołowej, konflikt zbrojny, pandemia, skażenia obszaru, kataklizmy, blokada granic ustanowiona przez organy państwowe i inne podobne zdażenia będące całkowicie poza kontrolą Organizatora.",
    text9: "Organizator bierze odpowiedzialność za stan techniczny jachtu i ponosi odpowiedzialność za sytuacje w których rejs mógłby się nie odbyć częściowo lub w całości w wyznaczonym terminie z powodu swojego zaniedbania lub zaniechania włączając w to sytuacje które skutkowałoby poważną usterką techniczną, czyniącą jacht niezdolnym do żeglugi z jego zatonięciem włącznie. W takim przypadku Organizator dołoży wszelkich starań aby zrekompensować uczestnikom rejsu, ewentualne straty finansowe i/lub moralne.",
    text10: "Organizator będzie informował uczestników rejsu o wszelkich możliwych ustaleniach dotyczących rezerwowanego rejsu drogą mailową lub telefoniczną, mającymi na celu dbałość o satysfakcję i bezpieczeństwo uczestników (z zastrzeżeniem, że bezpieczeństwo jest wyższym priorytetem), takich jak:",
    text10a: "pomoc doradcza w sprawie transportu \"do\" miejsca zaokrętowania oraz \"z\" miejsca wyokrętowania,",
    text10b: "ustalenia dotyczące preferencji żywieniowych uczestników oraz tworzenie listy wacht kambuzowych (grafik gotowania),",
    text10c: "tworzenie ogólnego jadłospisu i listy prowiantu w porozumieniu z uczestnikami,",
    text10d: "inne ustalenia mogące wpływać na komfort i/lub bezpieczeństwo odbywanego rejsu.",
    text11: "Te i wszelkie pozostałe ustalenia oraz warunki świadczenia usługi, zawarte będą w \"Umowie świadczenia usługi turystycznej\" z którą użytkownik zobowiązuje się zapoznać przed ujszczeniem opłaty rezerwacyjnej.",
  },
  manageBooking: {
    or: "LUB",
    payRemaining: "Zapłać resztę",
    changeBookingDetails: "Zmień rezerwację",
    cancelBooking: "Anuluj rezerwację",
  },
  changeBookingStatus: {
    advanceBooking: "Następny status",
    cancelBooking: "Anuluj rezerwację",
  },
  cancelBookingInfo: {
    cruiseWillBeginIn: "Rejs rozpoczyna się za:",
    days: "dni",
    lateCancelFeeApplies: "Naliczona zostanie opłata za późne anulowanie rezerwacji zgodnie z",
    lateCancelFeeDoesNotApply: "Możesz anulować rezerwację beż żadnych opłat.",
    policy: "polityką",
    bookingAndCancellation: "rezerwacji i anulowania rezerwacji.",
    returnAmt: "Zwracana Tobie kwota:",
    finalWarning: "Uwaga: Anulowanie rezerwacji jest natychmiastowe i ostateczne.",
  },
  page: {
    home: {
      welcomeText: "Witaj na stronie",
      offerSectionTitle: "Dołącz do plemienia Chacruny!",
      offerSectionSubtitle: "\"Pływamy nie po to by uciec od życia, ale po to by życie nam nie uciekło.\"",
      offerSectionDescription1: "Witaj na stronie poświęconej tworzeniu społeczności skupionej wokół tematyki żeglarskiej na jachcie żaglowym o nazwie \"Chacruna\". Strona ta, jest podzielona na sekcje w których możesz zapoznać się z zaplanowanymi rejsami, dokonać rezerwacji, śledzić nasze przygody oraz nabyć podstawową wiedzę z zakresu żeglugi jachtem morskim w sekcji FAQ (często zadawane pytania), co będzie świetnym przygotowaniem przed Twoim rejsem z nami na \"Chacrunie\" na który Cię serdecznie zapraszam!",
      offerSectionDescription2: "Dzięki tej stronie możesz stać się członkiem naszego \"plemienia\" poprzez proste stworzenie konta. Założenie konta na tej stronie umożliwi Ci rezerwowanie rejsów, komentowanie i ocenianie rejsów w których brałeś/brałaś udział oraz pozwoli nam utrzymywać ze sobą kontakt mailowy w celu procesowania twojej rezerwacji i przygotowania Cię do przeżycia z nami, niesamowitej i niezapomnianej przygody! Sprawdź w zakładce rejsy co mamy w planach, lub zerknij na przeszłe rejsy by dowiedzieć się jak było!",
      socialMediaTitle: "Wspólnie budujemy treści w social media!",
      socialMediaSubtitle: "\"Pokaż to, co bez Ciebie, mogłoby nigdy nie zostać pokazane.\"",
      socialMediaDescription1: "Nasze przygody nie kończą się wraz z końcem rejsu, a pamiątki to nie tylko magnesy na lodówkę. W dobie internetu, niemal wszyscy korzystamy z mediów społecznościowych i chcemy się podzielić najlepszymi momentami naszego życia z resztą świata. Dlatego też powstał kanał na YouTube dedykowany załogom \"Chacruny\" gdzie uwieczniamy najlepsze momenty naszych rejsów. Jeśli chcesz pokazać znajomym gdzie byłeś/byłaś, to całkowicie bezpłatnie dostaniesz świętną pamiątkę z rejsu w postaci filmu na kanale @ChacrunaSails w serwisie YouTube!",
      socialMediaDescription2: "Tworzenie treści zarówno na YT jak i Facebooku czy Instagramie nie byłoby możliwe bez Ciebie. Zdjęcia czy filmy kręcone Twoim telefonem możesz po rejsie podesłać do wykorzystania w social mediach czy filmie pamiątkowym do czego serdecznie zachęcamy! Dzięki temu każdy może zobaczyć Twoją przygodę na \"Chacrunie\" również Twoimi oczami! Żadne materiały nie zostaną opublikowane oczywiście bez Twojej zgody - nic o Tobie bez Ciebie.",
      socialMediaTitle2: "Zobacz nasze treści:",
      aboutSailboatTitle: "O jachcie żaglowym \"Chacruna\"...",
      aboutSailboatSubtitle: "\"Łodzie stojące w porcie są bezpieczne ale nie do tego służą.\"",
      aboutSailboatDescription1: "\"Chacruna\" jest jachtem żaglowym kategorii \"A\" - oceanicznym. Kategoria ta oznacza, że jacht ten może pływać przy sile wiatru powyżej 8 stopni w skali Beauforta oraz fali o wysokości znaczącej ponad 4m. Jachty morskie różnią się istotnie od śródlądowych. Na \"Chacrunie\" mamy balast stały w postaci kilu (zamiast miecza występującego u większości jachtów \"Mazurskich\"), stały silnik diesla, a maszt jest na stałe zespolony z konstrukcją kadłuba (nie składa się). Ożaglowanie jest typowe dla tego typu jednostek i składa się z żagla głównego (grot) oraz przedniego (genua).",
      aboutSailboatDescription2: "Kadłub jachtu o konstrukcji laminatowej został zbudowany w 2004r. a zwodowany w 2005r. przez stocznie Bavaria Yachtbau GmbH. Długość jachtu wynosi 36 stóp (11m) co jest wystarczającą wielkością by zapewnić komfortowe warunki bytowe dla sześciu osób (certyfikowany jest na 8 osób). Jacht posiada dwie zamykane kajuty - dziobową i rufową (z dużymi kojami) oraz dwa dodatkowe miejsca do spania w mesie (na śródokręciu). Na jachcie znajdziemy wszystkie niezbędne środki bezpieczeństwa, pełny osprzęt żeglarski, instalacje sanitarne, kuchnię oraz dodatkowe urządzenia użytku codziennego.",
      aboutSailboatTitle2: "Wyposażenie",
      equipment: {
        propulsion: {
          title: "Napęd",
          description: "Stały silnik \"Volvo Penta D1-30\" o mocy 30KM. Ekonomiczne spalanie na poziomie 1.2L/h (prędkość przelotowa 5 węzłów)."
        },
        sails: {
          title: "Żagle",
          description: "Zarówno grot jak i genua na rolerze - żagle można obsługiwać z kokpitu. Prędkość na pełnych żaglach do 7.5 węzła."
        },
        hull: {
          title: "Kadłub",
          description: "Konstukcja laminatowa. Pokład pokryty dekoracyjnym drewnem tekowym w kokpicie, na burtach i dziobie."
        },
        tanks: {
          title: "Zbiorniki",
          description: "Zbiornik paliwa 80L oraz dwa zbiorniki na wodę słodką: 120L i 80L wystarczają średnio na ok. 2 dni użytkowania."
        },
        battery: {
          title: "Akumulatory",
          description: "Akumulatory ładowane z alternatora i paneli słonecznych zapewnianią prąd na jachcie nawet poza portami."
        },
        storage: {
          title: "Schowki",
          description: "Dwie bakisty rufowe, dużo szafek i przestrzeni magazynowych zapewnia miejsce na przechowywanie jedzenia i rzeczy osobistych."
        },
        gps: {
          title: "GPS",
          description: "Wraz z ploterem stanowi nieocenioną pomoc nawigacyjną i ułatwia lokalizację nawet w środku nocy."
        },
        autopilot: {
          title: "Autopilot",
          description: "Włączając autopilota będzie on trzymał zadany kurs, dzięki czemu możemy puścić koło sterowe i robić inne rzeczy."
        },
        vhf: {
          title: "Radio VHF",
          description: "Pozwala utrzymywać łączność radiową z innymi radiostacjami (np. porty, inne jachty). Służy też do wzywania pomocy."
        },
        internet: {
          title: "Internet",
          description: "System \"Starlink\" zapewnia nam dostęp do szybkiego internetu przez cały czas trwania rejsu."
        },
        soundSystem: {
          title: "System Muzyczny",
          description: "Standardowe radio wraz z przystawkami i nagłośnieniem, pozwala nam cieszyć się dowoli ulubioną muzyką."
        },
        sockets: {
          title: "Gniazdka",
          description: "Gniazdka 230V dostępne do użytku w portach oraz 12V w każdej chwili (do ładowania np. telefonów czy tabletów)."
        },
        bimini: {
          title: "Bimini",
          description: "Zapewnia schronienie przed ostrym słońcem dzięki czemu możemy się zrelaksować w zacienionym kokpicie."
        },
        sprayHood: {
          title: "Szprycbuda",
          description: "Przydaje się w przelotach w wietrznej lub deszczowej pogodzie osłaniając załogę w kokpicie przed elementami."
        },
        solar: {
          title: "Solary",
          description: "Mocne panele słoneczne zapewniają ładowanie akumulatorów w ciągu dnia nawet przy pochmurnej pogodzie."
        },
        tables: {
          title: "Stoliki",
          description: "Jeden duży stolik znajduje się w mesie a drugi - mniejszy i składany znajduje się w kokpicie."
        },
        snorkeling: {
          title: "Nurkowanie",
          description: "Mamy sprzęt do snorkowania który pozwala podziwiać morską faunę i florę. Są też okulary do pływania."
        },
        dinghy: {
          title: "Ponton",
          description: "Ponton mieści kilka osób i wraz z własnym napędem pozwala na szybki transport pomiędzy jachtem a lądem."
        },
        oven: {
          title: "Kuchenka",
          description: "Kuchenka dwupalnikowa, gazowa wraz z piekarnikiem umożliwia przygotowywanie dowolnych, ciepłych potraw."
        },
        fridge: {
          title: "Lodówka",
          description: "Dzięki lodówce możemy długo przechowywać żywność oraz chłodzić nasze ulubione trunki i napoje."
        },
        kitchen: {
          title: "Kuchnia",
          description: "W pełni wyposażona kuchnia zawiera wszelkie niezbędne naczynia, sztućce, garnki, patelnie i inne utensylia."
        },
        toilet: {
          title: "Toaleta",
          description: "Wyposażona w manulną pompę, pozwala załatwiać swoje potrzeby fizjologiczne bez konieczności chodzenia do portu."
        },
        shower: {
          title: "Prysznic",
          description: "Prysznic łazienkowy oraz rufowy pozwala się umyć lub po prostu spłukać z soli po kąpieli w morskiej wodzie."
        },
        sink: {
          title: "Zlew",
          description: "Można umyć zęby, ręce lub pooglądać się w lustrze. Dostępna jest zarówno zimna jak i ciepła woda."
        },
        anchor: {
          title: "Kotwica",
          description: "Główna kotwica z elektryczną windą i 70m łańcuchem oraz zapasowa z krótkim łańuchem pozwala stawać na kotwicowiskach."
        },
        fenders: {
          title: "Odbijacze",
          description: "Komplet odbijaczy przydaje się w portach podczas manewrów cumowania chroniąc kadłub przed uderzeniem czy zadrapaniem."
        },
        moorings: {
          title: "Cumy",
          description: "Zestaw cum jest oczywiście niezbędny do bezpiecznego stawania w portach. Dostępne są cumy różnej długości."
        },
        lifeRaft: {
          title: "Tratwa",
          description: "Tratwa ratunkowa nigdy nie była potrzebna jednak warto ją mieć. W przypadku katastrofy ratuje życie."
        },
        epirb: {
          title: "EPIRB",
          description: "Jest to boja ratunkowa która automatycznie nadaje sygnał podając swoją lokalizację, wzywając służby SAR."
        },
        lifeVests: {
          title: "Kamizelki",
          description: "Mamy 6 sztuk kamizelek ratunkowych które przydają się w szczególności osobom nie umiejącym pływać. "
        },
        heater: {
          title: "Ogrzewanie",
          description: "Farelka elektryczna na 230V, przydaje się tylko zimnych okresach lub na chłodniejszych akwenach."
        },
        vacuumCleaner: {
          title: "Odkurzacz",
          description: "Gdy się coś rozsypie lub nabrudzimy, bezprzewodowy odkurzacz pomoże nam zachować porządek na łodzi."
        },
        oilLamp: {
          title: "Lampa naftowa",
          description: "Awaryjne źródło światła. Często jej używamy wieczorami bo uważamy, że robi świetny klimat."
        },
        lights: {
          title: "Oświetlenie",
          description: "Niskoprądowe oświetlenie LED pobiera mało prądu. Oświetlenie znajduje się we wszystkich pomieszczeniach jachtu."
        },
        linen: {
          title: "Pościel",
          description: "Każdy członek załogi dostaje swój komplet czystej i pachnącej pościeli (prześcieradła, kołdry, poduszki)."
        },
        towels: {
          title: "Ręczniki",
          description: "Każdy członek załogi dostaje swój, świeży ręcznik kąpielowy, a w miarę potrzeby nawet dwa ręczniki!"
        }
      },
      aboutMeTitle: "O mnie...",
      aboutMeSubtitle: "\"Witam wszystkich! Mam na imię Michał, znany również jako Kapitan Klar z powodu mojej łysej glacy...\"",
      aboutMeDescription1: "Zawsze chciałem mieć córkę i syna ale jako, że na drugie dziecko się nie zanosi to adoptowałem \"Chacrunę\" (która bywa kapryśna jak córeczka). \"Chacruna\" jest moim prywatnym jachtem który pochłania spore zasoby finansowe, jednak moim celem nigdy nie było kupno jachtu dla wielkich zysków. Poszedłem tą drogą ponieważ, zawsze chciałem zwiedzać świat i uznałem, że najwięcej swobody i wolności by to robić zapewni mi posiadanie własnego jachtu żaglowego. Eksploracja globusa jest tym bardziej ciekawa jeśli towarzyszy nam grono fajnych i pozytywnych ludzi. Można powiedzieć, że bardziej zależy mi na dobrym towarzystwie podczas rejsów niż finansowych zyskach, dlatego też ceny rejsów na \"Chacrunie\" są nieco niższe niż średnia rynkowa ale powinny być wystarczające aby pokrywać bieżące koszty utrzymania i obsługi jachtu.",
      aboutMeDescription2: "Z wykształcenia jestem konstruktorem i pilotem, a od pewnego czasu również programistą. Nie wiem skąd wziął się u mnie zapał do żeglarstwa ale żeglowanie jest w pewnym stopniu podobne do prowadzenia statku powietrznego. Na wodzie podobnie jak w powietrzu - są określone procedury, podobny sposób prowadzenia rozmów przez radio oraz wymagana jest odpowiednia doza ostrożności i przede wszystkim - wyobraźni. Jacht prowadzę samodzielnie i nie wymagam doświadczenia załogi, jednak jeśli ktoś ma ochotę pomagać podczas manewrów, posterować w przelotach lub po prostu dowiedzieć się jak coś działa to z przyjemnością podzielę się moją skromną wiedzą. Z racji technicznego wykształcenia, większość napraw i ulepszeń przeprowadzam sam (co pozwala mi też oszczędzić na robociźnie). Jacht znam bardzo dobrze, można powiedzieć: \"jak własną kieszeń\". Bez skrzynki bosmańskiej i narzędzi byłbym jak bez ręki. Z całą pewnością nie jestem pozbawiony wad charakteru ale staram się przekuwać je w zalety, nadrabiać poczuciem humoru i dystansem do siebie. Prowadzenie jachtu nie należy do łatwych zadań ponieważ jako skipper muszę zadbać nie tylko o sam jacht ale przede wszystkim o bezpieczeństwo i dobre samopoczucie mojej załogi. Zależy mi na luźniej atmosferze podczas rejsu, tolerancji innych poglądów i oczywiście wyśmienitej zabawie! Ahoj! :)",
    },
    cruises: {
      notPlannedYet1: "Jeszcze nie zaplanowano rejsów na ten rok.",
      notPlannedYet2: "Sprawdź ponownie za jakiś czas...",
      cruiseDetails: {
        myBooking: "MOJA REZERWACJA",
        bookNow: "REZERWUJĘ TEN REJS!",
        bookAgain: "REZERWUJĘ PONOWNIE!",
        cruiseStatus: {
          upcoming: "NADCHODZĄCY",
          ongoing: "W TRAKCIE",
          ended: "ZAKOŃCZONY"
        },
        difficulty: "Poziom trudności:",
        difficulties: {
          TRIVIAL: "TRYWIALNY",
          EASY: "ŁATWY",
          MEDIUM: "SREDNI",
          HARD: "TRUDNY",
          EXTREME: "EKSTREMALNY",
        },
        freeSpaces: "Wolne miejsca:",
        noFreeSpaces: "Brak wolnych miejsc",
        notRatedYet: "Brak recenzji",
        currency: "zł",
        perPerson: "/os.",
        reviews: {
          sectionTitle: "Recenzje uczestników rejsu:",
          noReviews: "Jeszcze nie ma recenzji...",
          title: "Tytuł",
          description: "Recenzja",
          rating: "Ocena",
          createdAt: "Utworzono:",
          modifiedAt: "Zmodyfikowano:",
          loginToReview: "aby napisać recenzję",
          writeReview: "Napisz Recenzję",
        },
        splashUploadPrimary: "Aktualizuj zdjęcie główne",
        splashUploadDrop: "Upuść by zaktualizować",
        crusePicsUploadPrimary: "Dodaj zdjęcie z rejsu",
        crusePicsUploadDrop: "Upuść by dodać",
        backToCruises: "Powrót do rejsów"
      }
    },
    bookings: {
      loginToSeeBookings: "aby zobaczyć swoje rezerwacje...",
      noUpcomingBookingsTitle: "Brak nadchodzących rezerwacji.",
      noUpcomingBookingsDescription: "Aby dokonać rezerwacji przejdź do sekcji \"Rejsy\" i wybierz interesującą Cię wycieczkę.",
      bookingInterval: {
        HISTORICAL: "Historyczne",
        UPCOMING: "Kolejne"
      },
      bookingDetails: {
        yourSpaces: "Twoje miejsca:",
        cruiseDetails: "Szczegóły Rejsu",
        manageBooking: "Zarządzaj Rezerwacją",
        finalizeBooking: "Dokończ Rezerwację",
        bookingStatusTitle: "Status Rezerwacji",
        bookingStatus: {
          PENDING: "BRAK PŁATNOŚCI",
          PROCESSING: "PROCESOWANIE PŁATNOŚCI",
          ADVANCE_PAID: "ZALICZKA WPŁACONA",
          FULLY_PAID: "OPŁACONA",
          CANCELLING: "ANULOWANIE",
          CANCELED: "ANULOWANA",
        }
      },
      imgBadge: {
        in: "ZA",
        days: "DNI",
        write: "NAPISZ",
        review: "RECENZJĘ",
        reviewed: "ZRECENZOWANO",
      }
    },
    faq: {
      title: "Często zadawane pytania",
      questions: {
        question1: {
          title: "Co obejmuje rezerwacja?",
          content1: "Twoja rezerwacja obejmuje miejsce dla Ciebie na jachcie (zarezerwowane koje), miejsca w schowkach " +
            "i szafkach w których możesz przechowywać swoje rzeczy osobiste, dostęp do wszystkich elementów wyposażenia " +
            "jachtu oraz opiekę skippera podczas całego trwania rejsu.",
          content2: "Rezerwacja nie obejmuje opłat portowych pobieranych w marinach, składki na paliwo i prowiant, " +
            "oraz kosztów transportu do miejsca zaokrętowania i z miejsca wyokrętowania.",
          content3: "Koszty opłat portowych za cały jacht wynoszą między 25€-125€ za dobę postoju w marinie. W " +
            "przypadku 6-cio osobowej załogi te koszty dzielą się naturalnie na wszystkich więc jeśli podczas rejsu " +
            "stawalibyśmy w 6-ciu portach to musimy się liczyć z wydatkiem 25€-125€ na osobę za cały rejs. Paliwo jest " +
            "kosztem znacznie niższym ponieważ nawet gdybyśmy zużyli cały bak 80L to mówimy o koszcie ok 20€ na osobę " +
            "za cały rejs. Ceny żywności w sklepach są nieco wyższe niż w polsce (15-30%), jednak opłaca się gotować " +
            "na jachcie i ograniczyć jedzenie w drogich restauracjach czy portach do kilku wspólnych kolacji.",
        },
        question2: {
          title: "Czy mogę anulować rezerwację?",
          content1: "Rezerwację można anulować w każdym momencie, jednak w zależności od ilości dni pozostałych do " +
            "rozpoczęcia rejsu może być naliczona opłata za późne anulowanie rezerwacji. W przypadku anulowania " +
            "rezerwacji do 28 dni przed rozpoczęciem rejsu, zwracana jest pełna kwota. Poniżej 7 dni przed rozpoczęciem " +
            "rejsu, zwrot niestety nie przysługuje.",
          content2: "Anulowanie rezerwacji w przedziale od 28 do 14 dni przed rozpoczęciem rejsu:\n" +
            "• Jeśli wpłacono tylko zaliczkę (50%), zwracana jest kwota według formuły: ((ilość dni do rejsu - 14) / 14) x Zaliczka.\n" +
            "• Jeśli wpłacono pełną kwotę zwracane jest 50% pełnej kwoty plus część zaliczki (jak wyżej).\n \n" +
            "Przykład 1: Rezerwujący chce anulować rezerwację 21 dni przed rozpoczęciem rejsu i wpłacił pełną kwotę. " +
            "Rezerwujący otrzyma zwrot 75% całej wpłaconej kwoty.\n  \n" +
            "Przykład 2: Rezerwujący chce anulować rezerwację 21 dni przed rozpoczęciem rejsu i wpłacił tylko zaliczkę. " +
            "Rezerwujący otrzyma zwrot 50% wpłaconej zaliczki.",
          content3: "Anulowanie rezerwacji w przedziale od 14 do 7 dni przed rozpoczęciem rejsu:\n" +
            "• Zaliczka, czyli 50% wartości pełnej opłaty nie podlega w tym przedziale zwrotowi.\n" +
            "• Zwracana kwota jest obliczana wg. formuły: ((ilość dni do rejsu - 7) / 7) x 50% kwoty rezerwacji.\n \n" +
            "Przykład 3: Rezerwujący chce anulować rezerwację 14 dni przed rozpoczęciem rejsu i wpłacił pełną kwotę." +
            "Zostanie mu zwrócone 50% poniesionych kosztów rezerwacji.\n \n" +
            "Przykład 4: Rezerwujący chce anulować rezerwację 10 dni przed rozpoczęciem rejsu i wpłacił pełną kwotę. " +
            "Zostanie mu zwrócone 21.43% poniesionych kosztów rezerwacji.\n  \n" +
            "We wszystkich przykładach, tak naprawdę nie ma znaczenia czy wpłacono pełną kwotę rezerwacji czy tylko " +
            "zaliczkę 50% ponieważ opłata za późne anulowanie rezerwacji jest niezależna od typu rezerwacji.",
        },
        question3: {
          title: "Czy muszę mieć patent żeglarski aby zarezerwować rejs?",
          content1: "Mówiąc w skrócie - patent żeglarski nie jest wymagany na kazdym rejsie poza ekstremalnym. " +
            "Oferta jest adresowana zarówno do osób z patentem jak i bez.",
          content2: "Jeśli nie masz patentu - nie przejmuj się. Możesz się zrelaksować i nie robić nic związanego " +
            "z obsługą jachtu jeśli nie masz na to ochoty. Jeśli jednak interesuje Cię zdobycie nieco doświadczenia " +
            "lub po prostu chcesz pomóc, to zwyczajnie o tym powiedz skipperowi, a będziesz mieć przydzielone takie " +
            "zadania z jakimi czujesz się komfortowo. I nie przejmuj się jeśli coś Ci się nie uda - skipper bierze " +
            "to pod uwagę i nie będzie krzyczał. :)",
          content3: "Jeśli masz patent i chcesz zdobywać doświadczenie to również powiedz o tym skipperowi. Jeśli chcesz, " +
            "po rejsie, otrzymasz też tzw. \"opinię z rejsu\" która może Ci się przydać do stażu morskiego aby dostać " +
            "w przyszłości wyższy stopień patentu."
        },
        question4: {
          title: "Jak przebiega proces rezerwacji?",
          content1: "Proces rezerwacji składa się z kilku prostych kroków. W pierwszym kroku, rezerwujący podaje dane " +
            "osób dla których chce dokonać rezerwacji. W drugim wybiera wolne koje z puli dostępnych miejsc. Następnie " +
            "można dokonać wyboru opcji rezerwacji (pełna płatność lub tylko zaliczka 50%) i podać kod zniżki jeśli " +
            "się takowy posiada.",
          content2: "Po potwierdzeniu trzeciego kroku w formularzu rezerwacji, zostanie wysłana wiadomość na podany przez " +
            "Ciebie adres email na który zarejestrowano Twoje konto. Wiadomość zawiera dane do przelewu który należy " +
            "wykonać jak najszybciej w celu ostateczego potwierdzenia rezerwacji. W załączniku wiadomości, znajdziesz " +
            "również umowę z którą należy się zapoznać, wypełnić, podpisać na ostatniej stronie i odesłać na nasz adres " +
            "email: chacrunasails@gmail.com przed datą rozpoczęcia rejsu. Podpisaną umowę możesz również przekazać " +
            "osobiście do rąk skippera w dniu zaokrętowania.",
          content3: "Po zleceniu przelewu w twoim banku, w celu przyspieszenia procesu potwierdzenia rezerwacji, " +
            "warto odesłać na nasz adres email potwierdzenie przelewu aby zarezerwowane przez Ciebie koje stały się " +
            "niedostępne jak najszybciej dla innych rezerwujących. W razie pytań, napisz do nas lub zadzwoń: +48 669 477 375"
          // title: "Moja płatność została odrzucona. Co z moją rezerwacją?",
          // content1: "Jeśli płatność została odrzucona przez bramkę płatności to znaczy, że błąd wystąpił po stronie " +
          //   "dostawcy metody płatności i twoje środki nie zostały pobrane z konta. Twoja rezerwacja najprawdopodobniej " +
          //   "będzie w stanie \"PROCESOWANIE PŁATNOŚCI\".",
          // content2: "Jeśli twoja rezerwacja (w zakładce REZERWACJE) jest w stanie \"PROCESOWANIE PŁATNOŚCI\", sprawdź " +
          //   "swoją skrzynkę email i upewnij się, że transakcja napewno nie przeszła. Jeśli problem z płatnością się powtarza " +
          //   "skontaktuj się na nasz adres email: chacrunasails@gmail.com lub zadzwoń: +48 669 477 375.",
          // content3: "Jeśli twoja rezerwacja (w zakładce REZERWACJE) jest w stanie \"BRAK PŁATNOŚCI\", oznacza to, że" +
          //   "rezerwujący nawet nie został przeniesiony na stronę procesora płatności i w dalszym ciągu może dokończyć" +
          //   "rezerwację. Wystarczy na rezerwacji kliknąc przycisk \"Dokończ Rezerwację\""
        },
        question5: {
          title: "Gdzie mogę zmienić szczegóły mojej rezerwacji?",
          content1: "Jeśli wpłacono zaliczkę lub pełną kwotę, w zakładce rezerwacje możesz kliknąć przycisk " +
            "\"Zarządzaj Rezerwacją\". W zależności od stanu rezerwacji możesz dokonać trzech rodzajów zmian:" +
            "dopłacić pozostałą część rezerwacji (w przypadku wcześniej wpłaconej zaliczki), zmienić rezerwację " +
            "(dane uczestników lub wybrane koje), anulować rezerwację.",
          content2: "W każdym momencie możesz zmienić zarezerwowane przez siebie koje na inne które nie są zajęte. " +
            "Mozesz też zmienić dane uczestników rejsu co jest przydatne w przypadku gdyby inna osoba która miała z tobą " +
            "płynąć nie mogłą tego zrobić z jakiegoś powodu ale ktoś miałby ją zastąpić. Warto podawać prawdziwe dane " +
            "uczestników ponieważ ułatwia to później skipperowi, ustalenie listy załogi.",
          content3: "Anulowanie rezerwacji otwiera okno w którym będziesz powiadomiony/a o obliczonej ilości środków " +
            "które zostaną Ci zwrócone na konto po kliknięciu przycisku potwierdzającego. Anulowanie rezerwacji jest " +
            "ostateczne i środki wrócą na twoje konto w przeciągu maksymalnie 7 dni."
        },
        question6: {
          title: "Jak rozumieć \"poziom trudności\" rejsu?",
          content1: "Rejsy podzielono na pięć stopni trudności: trywialny, łatwy, średni, trudny i ekstremalny. " +
            "Stopień trudności jest oceną dość subiektywną i rejs \"średni\" dla jednej osoby, może być odczuwalny " +
            "jako trywialny, a dla innej np. jako \"trudny\", jednak skala bazuje na obiektywnych czynnikach takich jak: " +
            "średnia ilość godzin dziennie w trasie, akceptowalna granica warunków pogodowych przy których decydujemy się płynąć, " +
            "nocne przeloty, itp.",
          content2: "Generalnie im mniej godzin dziennie pływania tym rejs jest łatwiejszy. Jeśli przewidywane są nocne " +
            "przeprawy to rejs raczej będzie trudniejszy. Jeśli pływamy przy mniejszym zafalowaniu i wietrze to tym łatwiejszy rejs, " +
            "a jeśli zgadzamy się pływać przy większych falach i silniejszym wietrze to tym trudniejszy.",
          content3: "• Rejs Trywialny: Do 2.5h dziennie pływania za dnia, tylko przy słabym zafalowaniu i sile wiatru " +
            "do 4B, bez nocnych postojów na kotwicy w zatokach.\n \n" +
            "• Rejs Łatwy: Do 4.5h dziennie pływania za dnia, tylko przy niedużym zafalowaniu i sile wiatru " +
            "do 5B, z możliwością nocnych postojów na kotwicy w osłoniętych zakotach.\n \n" +
            "• Rejs Średni: Do 7h dziennie pływania za dnia, również przy umiarkowanym zafalowaniu i sile wiatru " +
            "do 6B, z możliwością nocnych postojów na kotwicy w osłoniętych zakotach i wachtami kotwicowymi.\n \n" +
            "• Rejs Trudny: Do 10h pływania na dobę, również przy znaczącym zafalowaniu i sile wiatru " +
            "do 7B z koniecznością pełnienia wacht za sterem, z możliwością nocnych postojów na kotwicy w osłoniętych " +
            "zakotach i wachtami kotwicowymi.\n \n" +
            "• Rejs Ekstremalny: Powyżej 10h pływania na dobę, również przy sztormowym zafalowaniu i sile wiatru ponad 8B " +
            "z koniecznością pełnienia wacht za sterem, z możliwością nocnych postojów na kotwicy i wachtami kotwicowymi."
        },
        question7: {
          title: "Co zabrać na rejs?",
          content1: "Na tygodniowy rejs najlepiej spakować się wyłącznie w bagaż podręczny. Wiele lini lotniczych oferuje " +
            "za dodatkową dopłatą, przewóz dodatkowego bagażu kabinowego jednak często jest to zbędne. Nie zaleca się " +
            "zabierania głównego bagażu rejstrowanego z uwagi na koszty i ograniczoną przestrzeń magazynową na jachcie. " +
            "Żeli kąpielowych, szamponów, kremów z filtrem UV czy pasty do zębów nie trzeba zabierać, (chyba, że ktoś " +
            "musi) ponieważ kupujemy takie rzeczy przed wypłynięciem i są one wspólne.",
          content2: "Wybierając się na rejs najważniejsze jest zabranie dokumentów (ważny dowód osobisty na terenie UE), " +
            "pieniędzy (warto też mieć gotówkę) i oczywiście telefonu. Warto też zabrać ładowarkę do telefonu lub " +
            "kabel USB z wtyczką do twojego smartfona, słuchawki do słuchania muzyki lub audiobooków, okulary " +
            "przeciwsłoneczne, nakrycie głowy, przybory higieny osobistej, powerbank i koniecznie - stopery do uszu " +
            "(w nocy ktoś może chrapać, a i też sam jacht wydaje swoje odgłosy - coś puka, coś stuka albo lina " +
            "trzeszczy i wtedy warto mieć stopery).",
          content3: "Oczywiście nie możemy zapomnieć o bieliźnie na zmiane na każdy dzień rejsu, T-shirtach, stroju " +
            "kąpielowym, klapkach, krótkich spodenkach, a na chłodniejsze wieczory czy gorszą pogodę, jednych długich " +
            "spodniach, co najmniej jednej bluzie z kapturem i opcjonalnie - nieprzemakalnej kurtce. Ważne też aby mieć " +
            "wygodne buty z miękką podeszwą. Jeśli ktoś lubi, może też zabrać np. książkę czy niewielką grę planszową.\n \n" +
            "Nie warto zabierać suszarek do włosów, laptopów (chyba, że ktoś bardzo potrzebuje), tosterów, żelazka, " +
            "nadmiarowych ciuchów czy jedzenia (oprócz kanapek na czas lotu)."
        },
        question8: {
          title: "Czego spodziewać się podczas rejsu?",
          content1: "W skrócie - dobrej zabawy! Egzystencja na jachcie żaglowym jest podobna do kempingu w wersji " +
            "premium z uwagi na większą przestrzeń i ilość udogodnień. Mimo wszystko, życie na jachcie jest inne niż " +
            "w hotelu i ma swoje lepsze i słabsze strony. Chociaż \"Chacruna\" ma 11m długości i przestrzeni jest więcej " +
            "niż np. w kamperze to jednak nadal jest to ograniczona przestrzeń. Zbiorniki z wodą słodką też mają ograniczoną " +
            "pojemność o czym warto pamiętać biorąc prysznic i nie zapomnieć o współzałogantach.",
          content2: "Generalnie poruszanie się po jachcie wymaga wypracowania pewnych nawyków i wyobraźni. W trasie " +
            "obowiązuje zasada, \"jedna ręka dla jachtu, druga dla mnie\" co oznacza tyle, że jedna ręka zawsze " +
            "powinna się czegoś trzymać co jest istotne przy zafalowaniu. Jeśli jacht płynie, wszystkie przedmioty " +
            "powinny być zabiezpieczone w taki sposób aby przy przechyle pozostawały na swoim miejscu i trzeba o tym " +
            "pamiętać odkładając swoje rzeczy, które mogłyby się uszkodzić przy uderzeniu o szafkę czy podłogę. " +
            "Na początku może się pojawić pierwsze wrażenie, że jest mało miejsca na jachcie. Tak się dzieje " +
            "kiedy wszyscy wypakowują swoje rzeczy podczas zaokrętowania i panuje ogólne zamieszanie. Z czasem uczymy " +
            "się poruszać w taki sposób żeby nie wchodzić sobie w drogę lub odkrywamy, że żeby komuś coś podać na " +
            "pokładzie, nie musimy chodzić przez mesę, potem zejściówką do kokpitu i na pokład ale wystarczy jeden " +
            "ruch ręką, żeby podać coś przez właz dziobowy.",
          content3: "W zamian za nasz trud nagradzani jesteśmy niesamowitymi widokami w postaci pięknych zatok, marin, " +
            "lagun, miast, zachodów słońca i gwiazd na niebie. Po kilku dniach sama atmosfera na jachcie nabiera " +
            "niepowtarzalnego flow gdzie wszystko zaczyna działać jak w zegarku i załoga funkcjonuje niemal jak jeden " +
            "organizm. Silne poczucie odrębności od innych zostaje zatarte i stajemy się sobie bliźsi niż starzy " +
            "znajomi. Jest to moment w którym każdy wie kto robi śniadanie czy obiad, kto zmywa, co się kończy w zapasach, " +
            "co kto pije, gdzie płyniemy i o której tam będizemy."
        },
        question9: {
          title: "Czy podczas rejsu mogę pić alkohol lub palić?",
          content1: "Nie jest to zabronione, a co nie jest zabronione jest dozwolone w rosądnych granicach. Skipper " +
            "wychodzi z założenia, że ma do czynienia z ludźmi którzy chcą się dobrze bawić i są co najmniej " +
            "trochę rozsądni. Skipper nie wie ile, kto i czego może spożyć więc nie wchodzi w rolę kontrolera stanu " +
            "(nie)trzeźwości. Jednak warto pamiętać idąc rano do sklepu po pieczywo, żeby wracając na jacht, nie było " +
            "sytuacji, w której ktoś wrócił pijany z trzema flaszkami wódki, bez chleba. Po prostu nie zapominajmy " +
            "o chlebie...",
          content2: "Istnieje wiele różnych sytuacji w których nadmierne spożycie alkoholu nie ma większego znazenia " +
            "oraz sytuacji w których ma ogromne znaczenie. Przykładowo - podczas nocnych przelotów w gorszych warunkach " +
            "na trudnym rejsie, lepiej nie być pijanym. Każdy mniej więcej wie ile może wypić. Generalna zasada jest taka, " +
            "że na wodzie starajmy się pozostać raczej po tej trzeźwiejszej stronie w zależności od warunków - im gorsze " +
            "warunki pogodowe tym potrzeba większej trzeźwości umysłu.",
          content3: "Najniebezpieczniejsze sytuacje wynikające z nietrzeźwości załogantów zdarzają się przy utracie " +
            "równowagi co w najlepszym przypadku skończy się siniakiem, a w najgorszym wypadnięciem za burtę. Nic nie " +
            "stoi na przeszkodzie natomiast, będąc w porcie, iść do tawerny i tam walczyć w pełnym składzie załogi " +
            "do białego rana jeśli jest taka wola uczestników."
        },
        question10: {
          title: "Co będzie w przypadku sztormu?",
          content1: "Mitem jest twierdzenie, że na wodzie może nagle zdarzyć się sztorm. Sztormy nie zdarzają się nagle, " +
            "a zapowiedzi złych warunków docierają do nas nawet kilka dni wcześniej. Co innego lokalne burze które mogą " +
            "pojawiać się dość szybko (w przeciągu kilku godzin) i zniknąć jeszcze szybciej (w przeciągu dosłownie 15min).",
          content2: "Oprócz rejsów ekstremalnych, generalnie podczas sztormu nie pływamy i stoimy bezpiecznie, w " +
            "dobrze osłoniętym porcie. Jest to czas w którym możemy iść do tawerny przeganiać chmury, zwiedzać miasto " +
            "portowe, grać w karty czy planszówki. W rzadkich przypadkach może się zdarzyć, że nie sposób będzie " +
            "dotrzeć do miejsca docelowego ze względu na trwający wielodniowy i silny sztorm. W takiej sytuacji lepiej nie " +
            "ryzykować i na lotnisko powrotu, po prostu dostać się drogą lądową. Jak do tej pory taka sytuacja się " +
            "nie zdarzyła.",
          content3: "Jeśli chodzi o lokalne burze to wbrew powszechnym przekonaniom nie jest to sztorm. Po pierwsze " +
            "lokalna burza odziałuje na małym obszarze akwenu przez co nie tworzy dużego zafalowania. Innymi słowy - " +
            "wieje i grzmi ale nie ma fal. W znacznej większości przypadków burza przechodzi bokiem, powieje pół godziny, " +
            "pogrzmi i pójdzie sobie. Czasami jednak burza może przetoczyć się centralnie nad nami. W takim przypadku " +
            "można się spodziewać silnych porywów wiatru i gwałtownej ulewy która potrwa ok. 30min. Najbezpieczniej wtedy " +
            "zmniejszyć lub całkowicie zwinąć żagle z uwagi na porywy wiatru, odpalić silnik i płynąć sobie spokojnie " +
            "na dieslu. Burze groźnie wyglądają ale tak naprawdę groźne nie są bo nie tworzą fal i trwają krótko."
        },
        question11: {
          title: "Kiedy będzie film z rejsu?",
          content1: "Filmiki z rejsu są na tą chwilę montowane samodzielnie przez Kapitana co oczywiście jest " +
            "procesem który wymaga czasu. Dobre złożenie jednego filmu trwa nawet do 3 dni roboczych " +
            "czyli Kapitan musi mieć co najmniej jeden weekend wolny aby go zmontować.",
          content2: "Ponieważ rejsy są organizowane w dłuższych turach, filmików można się spodziewać po zakończeniu " +
            "całej tury - np. ciągu czterech rejsów. Filmiki są robione wg. chronologii więc dopóki nie powstanie film " +
            "z rejsu przed Twoim rejsem to film z Twojego rejsu też nie będzie gotowy.",
          content3: "Innymi słowy wysyp filmów z rejsów będzie albo pomiędzy turami (jeśli czas na to pozwala) lub " +
            "jeśli tury są gęsto upakowane w sezonie, to na koniec całego sezonu żeglarskiego. Oczywiście w interesie wszystkich " +
            "jest to aby filmiki były gotowe jak najszybciej jednak prosimy o wyrozumiałość i cierpliwość - film na " +
            "pewno powstanie i będziesz pierwszą osobą która się o tym dowie. :)"
        },
      }
    },
    admin: {
      cruises: {
        noCruises: "Brak rejsów na ten rok",
        bookingsOrDiscounts: "Rezerwacje / Zniżki",
        cruiseForm: {
          createCruise: "Dodawanie nowego rejsu",
          editCruise: "Edycja rejsu",
          price: "Cena",
          titlePl: "Tytuł PL",
          titleEn: "Tytuł EN",
          descriptionPl: "Opis PL",
          descriptionEn: "Opis EN",
          planPl: "Plan PL",
          planEn: "Plan EN",
          cruiseDifficulty: "Poziom Trudności",
          startLocation: "Miejsce startu",
          finishLocation: "Miejsce zakończenia",
          startDay: "Data startu",
          finishDay: "Data zakończenia",
          splashImg: "Zdjęcie główne",
          ytLink: "Link Youtube",
          images: "Zdjęcia",
        }
      },
      cruiseDetails: {
        tabs: {
          BOOKINGS: "REZERWACJE",
          DISCOUNTS: "KODY"
        },
        bookedSpaces: "Zarezerwowane miejsca:",
        noBookings: "Brak rezerwacji na ten rejs...",
        noDiscounts: "Brak zniżek na ten rejs...",
        code: "Kod:",
        bookedBy: "Rezerwujący:",
        timeCreate: "Zarezerwowano:",
        lastModified: "Ostatnia zmiana:",
        changeStatus: "Zmień Rezerwację",
        showParticipants: "Uczestnicy",
        showCrewList: "Lista Załogi",
        discount: {
          code: "Kod",
          amount: "Zniżka",
        }
      }
    },
    emailVerify: {
      emailVerifyTitle: "Witamy na pokładzie!",
      emailVerifyDescription1: "Cieszymy się, że dołączyłeś do naszego \"plemienia\". Życzymy wielu udanych rejsów i wspaniałych wrażeń!",
      emailVerifyDescription2: "Możesz się teraz ",
      emailVerifyLogin: "ZALOGOWAĆ"
    },
    expiredVerificationToken: "Token weryfikacyjny adresu email wygasł lub jest niepoprawny!",
    expiredResetToken: "Token resetu hasła wygasł lub jest niepoprawny!",
  },
  formErrors: {
    fieldRequired: "To pole jest wymagane!",
    agreementRequired: "Ta zgoda jest wymagana!",
    incorrectFormat: "Niepoprawny format!",
    onlyLetters: "Dozwolone są tylko litery!",
    onlyLettersAndDash: "Dozwolone są tylko litery oraz '-'!",
    badEmail: "Niepoprawny adres e-mail!",
    emailTaken: "Ten email jest już zajęty!",
    maxAllowed: "Dozwolone jest maksymalnie ",
    maxChars: " znaków!",
    maxLetters: " liter!",
    invalidLogin: "Niepoprawne dane logowania!",
    phoneFormat: "Numer telefonu tylko w formacie +YYXXXXXXXXX!",
    inadequatePassword: "Hasło nie spełnia wymagań bezpieczeństwa!",
    noWhitespaces: "Nie może zawierać spacji!",
    mismatchedPassword: "Wprowadzone hasła nie są takie same!",
    youMustChoose: "Musisz wybrać",
    rangeBetween: "Musi być w przedziale",
    bunks: "koje!",
  }
}