import {centeredBox, fonts} from "../../../../../../app.style";
import {Container, Grid, Typography} from "@mui/material";
import {useContext} from "react";
import {AppContext} from "../../../../common/appWrapper/AppWrapper";
import Image from "mui-image";
import EquipmentItem from "./equipmentItem/EquipmentItem";
import {ReactComponent as PropellerIcon} from "../../../../../../assets/icons/equipment/propeller.svg";
import {ReactComponent as SailsIcon} from "../../../../../../assets/icons/equipment/sails.svg";
import {ReactComponent as HullIcon} from "../../../../../../assets/icons/equipment/hull.svg";
import {ReactComponent as TanksIcon} from "../../../../../../assets/icons/equipment/tanks.svg";
import {ReactComponent as BatteryIcon} from "../../../../../../assets/icons/equipment/battery.svg";
import {ReactComponent as StorageIcon} from "../../../../../../assets/icons/equipment/storage.svg";
import {ReactComponent as GpsIcon} from "../../../../../../assets/icons/equipment/gps.svg";
import {ReactComponent as AutopilotIcon} from "../../../../../../assets/icons/equipment/autopilot.svg";
import {ReactComponent as VhfIcon} from "../../../../../../assets/icons/equipment/vhf.svg";
import {ReactComponent as InternetIcon} from "../../../../../../assets/icons/equipment/internet.svg";
import {ReactComponent as MusicIcon} from "../../../../../../assets/icons/equipment/music.svg";
import {ReactComponent as SocketIcon} from "../../../../../../assets/icons/equipment/sockets.svg";
import {ReactComponent as BiminiIcon} from "../../../../../../assets/icons/equipment/bimini.svg";
import {ReactComponent as SprayhoodIcon} from "../../../../../../assets/icons/equipment/sprayhood.svg";
import {ReactComponent as SolarIcon} from "../../../../../../assets/icons/equipment/solar.svg";
import {ReactComponent as TableIcon} from "../../../../../../assets/icons/equipment/table.svg";
import {ReactComponent as DivingIcon} from "../../../../../../assets/icons/equipment/diving.svg";
import {ReactComponent as DinghyIcon} from "../../../../../../assets/icons/equipment/dinghy.svg";
import {ReactComponent as OvenIcon} from "../../../../../../assets/icons/equipment/oven.svg";
import {ReactComponent as FridgeIcon} from "../../../../../../assets/icons/equipment/fridge.svg";
import {ReactComponent as KitchenIcon} from "../../../../../../assets/icons/equipment/kitchen.svg";
import {ReactComponent as ToiletIcon} from "../../../../../../assets/icons/equipment/toilet.svg";
import {ReactComponent as ShowerIcon} from "../../../../../../assets/icons/equipment/shower.svg";
import {ReactComponent as SinkIcon} from "../../../../../../assets/icons/equipment/sink.svg";
import {ReactComponent as AnchorIcon} from "../../../../../../assets/icons/equipment/anchor.svg";
import {ReactComponent as FenderIcon} from "../../../../../../assets/icons/equipment/fenders.svg";
import {ReactComponent as RopeIcon} from "../../../../../../assets/icons/equipment/rope.svg";
import {ReactComponent as RaftIcon} from "../../../../../../assets/icons/equipment/raft.svg";
import {ReactComponent as BuoyIcon} from "../../../../../../assets/icons/equipment/epirb.svg";
import {ReactComponent as VestIcon} from "../../../../../../assets/icons/equipment/life_vest.svg";
import {ReactComponent as HeatingIcon} from "../../../../../../assets/icons/equipment/heating.svg";
import {ReactComponent as VacuumIcon} from "../../../../../../assets/icons/equipment/vacuum.svg";
import {ReactComponent as OilLampIcon} from "../../../../../../assets/icons/equipment/oil_lamp.svg";
import {ReactComponent as LightsIcon} from "../../../../../../assets/icons/equipment/light.svg";
import {ReactComponent as LinenIcon} from "../../../../../../assets/icons/equipment/linen.svg";
import {ReactComponent as TowelIcon} from "../../../../../../assets/icons/equipment/towel.svg";


const AboutChacrunaSection = () => {
  const {translation} = useContext(AppContext);

  return (
    <Container>
      <Grid container spacing={2} sx={{my: 5}}>
        <Grid item xs={12} sx={[centeredBox]}>
          <Typography sx={[fonts.mainRunic30, {textAlign: "center"}]}>
            {translation.page.home.aboutSailboatTitle}
          </Typography>
        </Grid>
        <Grid item xs={12} sx={[centeredBox]}>
          <Typography sx={[fonts.spartan14, {textAlign: "center", fontStyle: 'italic'}]}>
            {translation.page.home.aboutSailboatSubtitle}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} sx={{mt: 3}}>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <Typography sx={[fonts.spartan14, {textAlign: "justify"}]}>
                &emsp;&emsp;{translation.page.home.aboutSailboatDescription1}
              </Typography>
            </Grid>
            <Grid item xs={12} sx={centeredBox}>
              <Image src={"/images/logo_dark.png"} duration={1000} width={64} />
            </Grid>
            <Grid item xs={12}>
              <Typography sx={[fonts.spartan14, {textAlign: "justify"}]}>
                &emsp;&emsp;{translation.page.home.aboutSailboatDescription2}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} sx={{mt: 3}}>
          <Image src={"/images/boat.jpg"} duration={1000} width={"100%"} />
        </Grid>
        <Grid item xs={12} sx={[centeredBox, {mt: 5}]}>
          <Typography sx={[fonts.mainRunic30, {textAlign: "center"}]}>
            {translation.page.home.aboutSailboatTitle2}
          </Typography>
        </Grid>
        <Grid item xs={12} sx={[centeredBox, {ml: 7, mt: 2}]}>
          <Image src={"/images/layout/layout.png"} duration={1000} style={{maxWidth: "600px"}} />
        </Grid>
        <Grid container spacing={4} sx={{mt: 3}}>
          <EquipmentItem icon={<PropellerIcon/>}
                         label={translation.page.home.equipment.propulsion.title}
                         description={translation.page.home.equipment.propulsion.description}/>
          <EquipmentItem icon={<SailsIcon/>}
                         label={translation.page.home.equipment.sails.title}
                         description={translation.page.home.equipment.sails.description}/>
          <EquipmentItem icon={<HullIcon/>}
                         label={translation.page.home.equipment.hull.title}
                         description={translation.page.home.equipment.hull.description}/>
          <EquipmentItem icon={<TanksIcon/>}
                         label={translation.page.home.equipment.tanks.title}
                         description={translation.page.home.equipment.tanks.description}/>
          <EquipmentItem icon={<BatteryIcon/>}
                         label={translation.page.home.equipment.battery.title}
                         description={translation.page.home.equipment.battery.description}/>
          <EquipmentItem icon={<StorageIcon/>}
                         label={translation.page.home.equipment.storage.title}
                         description={translation.page.home.equipment.storage.description}/>
          <EquipmentItem icon={<GpsIcon/>}
                         label={translation.page.home.equipment.gps.title}
                         description={translation.page.home.equipment.gps.description}/>
          <EquipmentItem icon={<AutopilotIcon/>}
                         label={translation.page.home.equipment.autopilot.title}
                         description={translation.page.home.equipment.autopilot.description}/>
          <EquipmentItem icon={<VhfIcon/>}
                         label={translation.page.home.equipment.vhf.title}
                         description={translation.page.home.equipment.vhf.description}/>
          <EquipmentItem icon={<InternetIcon/>}
                         label={translation.page.home.equipment.internet.title}
                         description={translation.page.home.equipment.internet.description}/>
          <EquipmentItem icon={<MusicIcon/>}
                         label={translation.page.home.equipment.soundSystem.title}
                         description={translation.page.home.equipment.soundSystem.description}/>
          <EquipmentItem icon={<SocketIcon/>}
                         label={translation.page.home.equipment.sockets.title}
                         description={translation.page.home.equipment.sockets.description}/>
          <EquipmentItem icon={<BiminiIcon/>}
                         label={translation.page.home.equipment.bimini.title}
                         description={translation.page.home.equipment.bimini.description}/>
          <EquipmentItem icon={<SprayhoodIcon/>}
                         label={translation.page.home.equipment.sprayHood.title}
                         description={translation.page.home.equipment.sprayHood.description}/>
          <EquipmentItem icon={<SolarIcon/>}
                         label={translation.page.home.equipment.solar.title}
                         description={translation.page.home.equipment.solar.description}/>
          <EquipmentItem icon={<TableIcon/>}
                         label={translation.page.home.equipment.tables.title}
                         description={translation.page.home.equipment.tables.description}/>
          <EquipmentItem icon={<DivingIcon/>}
                         label={translation.page.home.equipment.snorkeling.title}
                         description={translation.page.home.equipment.snorkeling.description}/>
          <EquipmentItem icon={<DinghyIcon/>}
                         label={translation.page.home.equipment.dinghy.title}
                         description={translation.page.home.equipment.dinghy.description}/>
          <EquipmentItem icon={<OvenIcon/>}
                         label={translation.page.home.equipment.oven.title}
                         description={translation.page.home.equipment.oven.description}/>
          <EquipmentItem icon={<FridgeIcon/>}
                         label={translation.page.home.equipment.fridge.title}
                         description={translation.page.home.equipment.fridge.description}/>
          <EquipmentItem icon={<KitchenIcon/>}
                         label={translation.page.home.equipment.kitchen.title}
                         description={translation.page.home.equipment.kitchen.description}/>
          <EquipmentItem icon={<ToiletIcon/>}
                         label={translation.page.home.equipment.toilet.title}
                         description={translation.page.home.equipment.toilet.description}/>
          <EquipmentItem icon={<ShowerIcon/>}
                         label={translation.page.home.equipment.shower.title}
                         description={translation.page.home.equipment.shower.description}/>
          <EquipmentItem icon={<SinkIcon/>}
                         label={translation.page.home.equipment.sink.title}
                         description={translation.page.home.equipment.sink.description}/>
          <EquipmentItem icon={<AnchorIcon/>}
                         label={translation.page.home.equipment.anchor.title}
                         description={translation.page.home.equipment.anchor.description}/>
          <EquipmentItem icon={<FenderIcon/>}
                         label={translation.page.home.equipment.fenders.title}
                         description={translation.page.home.equipment.fenders.description}/>
          <EquipmentItem icon={<RopeIcon/>}
                         label={translation.page.home.equipment.moorings.title}
                         description={translation.page.home.equipment.moorings.description}/>
          <EquipmentItem icon={<RaftIcon/>}
                         label={translation.page.home.equipment.lifeRaft.title}
                         description={translation.page.home.equipment.lifeRaft.description}/>
          <EquipmentItem icon={<BuoyIcon/>}
                         label={translation.page.home.equipment.epirb.title}
                         description={translation.page.home.equipment.epirb.description}/>
          <EquipmentItem icon={<VestIcon/>}
                         label={translation.page.home.equipment.lifeVests.title}
                         description={translation.page.home.equipment.lifeVests.description}/>
          <EquipmentItem icon={<HeatingIcon/>}
                         label={translation.page.home.equipment.heater.title}
                         description={translation.page.home.equipment.heater.description}/>
          <EquipmentItem icon={<VacuumIcon/>}
                         label={translation.page.home.equipment.vacuumCleaner.title}
                         description={translation.page.home.equipment.vacuumCleaner.description}/>
          <EquipmentItem icon={<OilLampIcon/>}
                         label={translation.page.home.equipment.oilLamp.title}
                         description={translation.page.home.equipment.oilLamp.description}/>
          <EquipmentItem icon={<LightsIcon/>}
                         label={translation.page.home.equipment.lights.title}
                         description={translation.page.home.equipment.lights.description}/>
          <EquipmentItem icon={<LinenIcon/>}
                         label={translation.page.home.equipment.linen.title}
                         description={translation.page.home.equipment.linen.description}/>
          <EquipmentItem icon={<TowelIcon/>}
                         label={translation.page.home.equipment.towels.title}
                         description={translation.page.home.equipment.towels.description}/>
        </Grid>
      </Grid>
    </Container>
  )
}

export default AboutChacrunaSection;