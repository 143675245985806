import {Button, Typography} from "@mui/material";
import {useContext, useEffect, useState} from "react";
import {AppContext} from "../../../appWrapper/AppWrapper";
import SmallSpinner from "../../../appSpinner/SmallSpinner";
import {button, fonts, mainColors, rainbowColors, semiBoldText} from "../../../../../../app.style";
import AppUserRequest from "../../../../../service/appUser/AppUserRequest";
import ProfileSettingsFormValidator from "../ProfileSettingsFormValidator";
import {AuthContext} from "../../../../../../App";


interface UpdateButtonProps {
  formData: AppUserRequest;
  profileSettingsFormValidator: ProfileSettingsFormValidator;
  showErrorText: boolean;
  setShowErrorText: Function;
}

const UpdateButton = (props: UpdateButtonProps) => {
  const {jwt} = useContext(AuthContext);
  const {translation, appCacheApi, appLoadersCache} = useContext(AppContext);
  const [disabled, setDisabled] = useState(false);
  const [saved, setSaved] = useState(false);

  const tryUpdate = () => {
    setDisabled(true);
    if (props.profileSettingsFormValidator.validateForm()){
      appCacheApi.updateSelf(props.formData, jwt)
    } else {
      props.setShowErrorText(true);
      console.error("Form is invalid");
    }
  }

  useEffect(() => {
    if (appLoadersCache.updateSelfLoading) {
      props.setShowErrorText(false);
    }
  }, [appLoadersCache.updateSelfLoading, props]);

  useEffect(() => {
    if (disabled && !appLoadersCache.updateSelfLoading) {
      setDisabled(false);
      if (!props.showErrorText) setSaved(true);
    }
  }, [appLoadersCache.updateSelfLoading, disabled, props.showErrorText]);

  return (
    <>
      <Button variant="contained"
              disabled={disabled}
              disableElevation fullWidth
              sx={[fonts.spartan14, semiBoldText, button, {mt: 3, background: mainColors.primary.color}]}
              onClick={() => tryUpdate()}>
        {disabled ?
          <SmallSpinner/> :
          translation.form.profileSettings.update
        }
      </Button>
      {saved &&
      <Typography align="center" sx={[fonts.spartan14, semiBoldText, rainbowColors.green, {mt: 2}]}>
        {translation.form.profileSettings.updated}
      </Typography>}
    </>
  )
}

export default UpdateButton;