export const mainColors = {
  // primary: {color: "#1E81F7"},
  // primaryBright: {color: "#1C9AF7"},
  primary: {color: "#1E81F7"},
  primaryBright: {color: "#1D9FFF"},
  // secondary: {color: "#41107A"},
  secondary: {color: "#5db20d"},
  secondaryBright: {color: "#6cd00b"},
  backgroundDark: {color: "#e8e8e8"},
  backgroundLighter: {color: "#fafafa"},
  backgroundDataGrid: {color: "#e1e1e1"},
  menu: {color: "#FFFFFF"},
  white: {color: "#FFFFFF"},
  grey: {color: "#7f7f7f"},
  contrast: {color: "#000000"},
  footer: {color: "#000000CC"},
  disabled: {color: "#666666"},
}

export const rainbowColors = {
  red: {color: "#BA3C05"},
  orange: {color: "#F07D00"},
  green: {color: "#35C951"},
  blue: {color: "#1E81F7"},
  violet: {color: "#3E18B3"},
}

export const rainbowColorsDark = {
  red: {color: "#963104"},
  orange: {color: "#C96900"},
  green: {color: "#28993E"},
  blue: {color: "#1764BF"},
  violet: {color: "#2D1182"},
}

export const rainbowColorsLight = {
  red: {color: "#C74005"},
  orange: {color: "#FF8500"},
  green: {color: "#3ADB58"},
  blue: {color: "#1F85FF"},
  violet: {color: "#441AC4"},
}

export const fonts = {
  mainRunic30: {
    fontFamily: "Runic2, sans-serif",
    fontSize: "30px",
  },
  navRunic30: {
    fontFamily: "Runic3, sans-serif",
    fontSize: "30px",
  },
  navRunic36: {
    fontFamily: "Runic3, sans-serif",
    fontSize: "36px",
  },
  navRunic45: {
    fontFamily: "Runic3, sans-serif",
    fontSize: "45px",
  },
  brandRunic36: {
    fontFamily: "Runic, sans-serif",
    fontSize: "36px",
  },
  brandRunic60: {
    fontFamily: "Runic, sans-serif",
    fontSize: "60px",
  },
  spartan9: {
    fontFamily: "Spartan, sans-serif",
    fontSize: "9px",
  },
  spartan10: {
    fontFamily: "Spartan, sans-serif",
    fontSize: "10px",
  },
  spartan11: {
    fontFamily: "Spartan, sans-serif",
    fontSize: "11px",
  },
  spartan12: {
    fontFamily: "Spartan, sans-serif",
    fontSize: "12px",
  },
  spartan14: {
    fontFamily: "Spartan, sans-serif",
    fontSize: "14px",
  },
  spartan16: {
    fontFamily: "Spartan, sans-serif",
    fontSize: "16px",
  },
  spartan18: {
    fontFamily: "Spartan, sans-serif",
    fontSize: "18px",
  },
  spartan20: {
    fontFamily: "Spartan, sans-serif",
    fontSize: "20px",
  },
  spartan30: {
    fontFamily: "Spartan, sans-serif",
    fontSize: "30px",
  },
  spartan42: {
    fontFamily: "Spartan, sans-serif",
    fontSize: "42px",
  }
}

export const closeIcon = {
  position: 'absolute',
  right: 8,
  top: 8,
}

export const normalIconSize = {
  width: "30px",
  height: "30px",
}

export const modalCornerIconStyle = {
  ...normalIconSize,
  mb: "5px"
}

export const boldText = {fontWeight: 700}

export const semiBoldText = {fontWeight: 600}

export const mediumText = {fontWeight: 500}

export const paperStyle = {
  borderRadius: "28px",
  boxShadow: "0 10px 35px #00000019"
}

export const paperButtonStyle = {
  py: 0,
  px: 0,
  borderRadius: "28px",
  textTransform: 'none',
  width: "100%",
  ...mainColors.contrast,
}

export const centeredBox = {
  display: "flex",
  flexGrow: 1,
  justifyContent: "center"
}

export const verticalBox = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center"
}

export const centeredContainer = {
  height: 1,
  alignItems: "center"
}

export const betweenBox = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}

export const formContainerStyle = {
  display: "flex",
  flexDirection: "column",
  gap: "16px"
}

export const maxSizeBox = {
  height: 1,
  width: 1
}

export const halfScaledTransform = {transform: "scale(0.5)"}

export const button = {
  height: "50px",
  borderRadius: "25px",
  textTransform: "none",
}

export const menuButton = {
  height: "32px",
  minWidth: "32px",
  borderRadius: "16px",
  px: 1,
  textTransform: "none",
}

export const appMenuIcon = {
  width: "24px",
  height: "24px",
  mx: 0
}

export const priorityHighCirle = {
  filter: "invert(22%) sepia(99%) saturate(3398%) hue-rotate(335deg) brightness(88%) contrast(95%)",
}

export const priorityMediumCirle = {
  filter: "invert(51%) sepia(99%) saturate(2347%) hue-rotate(6deg) brightness(98%) contrast(105%)",
}

export const priorityLowCirle = {
  filter: "invert(30%) sepia(93%) saturate(1302%) hue-rotate(138deg) brightness(106%) contrast(101%)",
}

export const calendarPriorityCircleAdjust = {
  transform: "scale(0.25) translate(0px, 16px)",
  marginTop: "-12px",
}

export const activityListCircleAdjust = {
  transform: "scale(0.25)",
}

export const whiteMask = {
  filter: "invert(90%) sepia(80%) saturate(0%) hue-rotate(51deg) brightness(112%) contrast(101%)"
}

export const dialogHeaderTypographyStyle = {
  ...fonts.spartan18,
  ...mediumText,
  mx: 5,
  textAlign: "center",
  color: mainColors.contrast,
}

export const formTypographyColorless = {
  ...mediumText,
  ...fonts.spartan14
}

export const formTypographyLargeColorless = {
  ...fonts.spartan18,
  ...semiBoldText
}
export const formTypography = {
  color: mainColors.disabled,
  activityFormTypographyColorless: formTypographyColorless
}

export const objectsSelectorListTypography = {
  ...fonts.spartan14,
  ...semiBoldText,
  ...mainColors.contrast
}

export const selectedFieldBox = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}

export const footerButtons = {
  alignSelf: "flex-end",
  display: "flex",
  justifyContent: "flex-end",
  mt: 2
}

export const dialogActions = {
  display: "block",
  mx: 3,
  px: 0,
  pb: 2,
  pt: 0
}

export const responsiveDialogStyle = {
  "& .MuiDialog-paper": {
    borderRadius: "28px",
    boxShadow: "0 10px 35px #00000019",
  }
}

export const splashImageSm = {
  borderRadius: "28px 28px 0 0"
}

export const splashImageMd = {
  borderRadius: "28px 0 0 28px"
}

export const clampedTextStyle = {
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: "6",
  WebkitBoxOrient: "vertical"
}