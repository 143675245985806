import {Translation} from "../../../../../language/translation/translations";
import StringValidator from "../../../../utils/validators/StringValidator";
import {validatorSettings} from "../../../../utils/validators/validatorSettings";
import NumberValidator from "../../../../utils/validators/NumberValidator";
import CruiseRequest from "../../../../service/cruise/CruiseRequest";
import DateTimeValidator from "../../../../utils/validators/DateTimeValidator";


export default class CruiseFormValidator {
  translation: Translation;
  titlePlValidator: StringValidator;
  titleEnValidator: StringValidator;
  descrPlValidator: StringValidator;
  descrEnValidator: StringValidator;
  planPlValidator: StringValidator;
  planEnValidator: StringValidator;
  startLocationValidator: StringValidator;
  finishLocationValidator: StringValidator;
  startDayValidator: DateTimeValidator;
  finishDayValidator: DateTimeValidator;
  youtubeLinkValidator: StringValidator;
  priceValidator: NumberValidator;

  constructor(translation: Translation) {
    this.translation = translation;
    this.titlePlValidator = new StringValidator(
      translation, validatorSettings.cruiseTitle, false, true, true);
    this.titleEnValidator = new StringValidator(
      translation, validatorSettings.cruiseTitle, false, true, true);
    this.descrPlValidator = new StringValidator(
      translation, validatorSettings.cruiseDescription, false, true, true);
    this.descrEnValidator = new StringValidator(
      translation, validatorSettings.cruiseDescription, false, true, true);
    this.planPlValidator = new StringValidator(
      translation, validatorSettings.cruisePlan, false, true, true);
    this.planEnValidator = new StringValidator(
      translation, validatorSettings.cruisePlan, false, true, true);
    this.startLocationValidator = new StringValidator(
      translation, validatorSettings.location, false, true, true);
    this.finishLocationValidator = new StringValidator(
      translation, validatorSettings.location, false, true, true);
    this.startDayValidator = new DateTimeValidator(translation);
    this.finishDayValidator = new DateTimeValidator(translation);
    this.youtubeLinkValidator = new StringValidator(
      translation, validatorSettings.youtubeLink, false, true, true, false);
    this.priceValidator = new NumberValidator(
      translation, 1, 1000000);
  }

  validateForm = (cruiseForm: CruiseRequest) => {
    this.titlePlValidator.validate(cruiseForm.titlePl);
    this.titleEnValidator.validate(cruiseForm.titleEn);
    this.descrPlValidator.validate(cruiseForm.descriptionPl);
    this.descrEnValidator.validate(cruiseForm.descriptionEn);
    this.planPlValidator.validate(cruiseForm.planPl);
    this.planEnValidator.validate(cruiseForm.planEn);
    this.startLocationValidator.validate(cruiseForm.startLocation);
    this.finishLocationValidator.validate(cruiseForm.finishLocation);
    this.startDayValidator.validate(cruiseForm.startDay);
    this.finishDayValidator.validate(cruiseForm.finishDay);
    this.youtubeLinkValidator.validate(cruiseForm.youtubeLink);
    this.priceValidator.validate(cruiseForm.price);

    return this.titlePlValidator.isValid && this.titleEnValidator.isValid &&
      this.descrPlValidator.isValid && this.descrEnValidator.isValid &&
      this.planPlValidator.isValid && this.planEnValidator.isValid &&
      this.startLocationValidator.isValid && this.finishLocationValidator.isValid &&
      this.startDayValidator.isValid && this.finishDayValidator.isValid &&
      this.youtubeLinkValidator.isValid && this.priceValidator.isValid;
  }
}