import {translationPl} from "./pl";
import {translationEn} from "./en";

export const translations = {
  PL: translationPl,
  EN: translationEn
}

export interface Translation {
  desktopLangLabel: string,
  mobileLangLabel: string,
  undefined: string,
  choose: string,
  save: string,
  cancel: string,
  login: string,
  close: string,
  edit: string,
  delete: string,
  create: string,
  navMenu: {
    menu: string,
    home: string,
    cruises: string,
    bookings: string,
    faq: string,
    admin: string,
    login: string,
    settings: string
  },
  footer: {
    sections: string,
    contact: string,
    disclaimer: string,
    copyright: string,
  },
  form: {
    common: {
      dintReceiveEmail: string,
      sendAgain: string,
      sendAgainSuccess: string,
      sendAgainFailure: string
    },
    loginForm: {
      emailAddress: string,
      password: string,
      loginBtn: string,
      forgotPass: string,
      noAccount: string,
      createAccount: string,
    },
    registerForm: {
      gender: {
        title: string,
        options: {
          MALE: string,
          FEMALE: string,
        },
      },
      name: string,
      surname: string,
      nickname: string,
      email: string,
      phone: string,
      password: string,
      repeatPassword: string,
      born: string,
      registerBtn: string
      alreadyHaveAccount: string,
      backToLogin: string,
      passReq: {
        header: string,
        leastSigns: string,
        capitalLetter: string,
        smallLetter: string
      },
      regSuccessInfo1: string,
      regSuccessInfo2: string,

    },
    resetPassword: {
      title: string,
      reset: string,
      mailInfo: string,
      iRemember: string,
    },
    setPassword: {
      title: string,
      change: string,
      changeSuccess: string,
      changeFailure: string,
    },
    profileSettings: {
      update: string,
      updated: string,
      logout: string,
    },
    newBooking: {
      steps: {
        data: string,
        bunks: string,
        payment: string,
      },
      subStep: string,
      bookingDataStep: {
        bookedCruiseInfo: {
          title: string,
          from: string,
          to: string,
          embarkDate: string,
          disembarkDate: string,
          duration: string,
          days: string,
        },
        bookedByDetails: {
          title: string,
          iAmAlsoPassenger: string,
        },
        passengerDetails: {
          title: string,
          passenger: string,
          details: string,
          addAnotherPassenger: string,
          noMoreSpaces: string,
          removePassenger: string,
        },
        requiredAgreements: {
          title: string,
          reservationPolicyConfirm1: string,
          policy: string,
          reservationPolicyConfirm2: string,
          reservationProcessingConfirm: string,
        },
        nextPreviousBtns: {
          next: string,
          previous: string,
        }
      },
      bunksSelectionStep: {
        title: string,
        available: string,
        taken: string,
        selected: string,
        nextPreviousBtns: {
          next: string,
          previous: string,
        },
        bunkName: {
          bowPort: string,
          bowStarboard: string,
          mesaPort: string,
          mesaStarboard: string,
          sternPort: string,
          sternStarboard: string,
        }
      },
      paymentStep: {
        discountCode: {
          title: string,
          label: string,
          description: string,
          valid: string,
          invalid: string,
          useCode: string,
        },
        bookingOptions: {
          title: string,
          fullPaymentReq: string,
          remainingPaymentDueInfo: string,
          remainingPaymentDueDate: string,
          bookingType: string,
          options: {
            ONE_STAGE: string,
            TWO_STAGE: string,
          }
        },
        paymentOptions: {
          title: string,
          currency: string,
        },
        summary: {
          title: string,
          yourBunks: string,
          bookedQty: string,
          pricePerPerson: string,
          discountPricePerPerson: string,
          totalPrice: string,
          amountToPay: string
        },
        nextPreviousBtns: {
          next: string,
          previous: string,
        },
        bankTransferInfo1: string,
        bankTransferInfo2: string,
      }
    }
  },
  modalHeader: {
    menuLogin: string,
    bookingLogin: string,
    register: string,
    profileSettings: string,
    passReset: string,
    regSuccess: string,
    writeReview: string,
    editReview: string,
    editDiscount: string,
    createDiscount: string,
    closeBookingDetailsConfirm: string,
    cancelBooking: string,
    deleteCruise: string,
    deleteDiscount: string,
    deleteReview: string,
    discardChanges: string,
    manageBooking: string,
    changeBookingStatus: string,
    advanceBooking: string,
    bookingParticipants: string,
    crewList: string,
    paymentInfoSent: string,
    policy: string,
    bookingCollision: string,
  },
  bookingCollisionModal: {
    text: string,
  },
  modalPrompt: {
    yes: string,
    no: string,
    allUnsavedDataWillBeLost: string,
  },
  policyModal: {
    text1: string,
    text2: string,
    text3: string,
    text4: string,
    text5: string,
    text5a: string,
    text5b: string,
    text5c: string,
    text5d: string,
    text5ex: string,
    text6: string,
    text7: string,
    text8: string,
    text9: string,
    text10: string,
    text10a: string,
    text10b: string,
    text10c: string,
    text10d: string,
    text11: string,
  },
  manageBooking: {
    or: string,
    changeBookingDetails: string,
    cancelBooking: string,
    payRemaining: string,
  },
  changeBookingStatus: {
    advanceBooking: string,
    cancelBooking: string,
  },
  cancelBookingInfo: {
    cruiseWillBeginIn: string,
    days: string,
    lateCancelFeeApplies: string,
    lateCancelFeeDoesNotApply: string,
    policy: string,
    bookingAndCancellation: string,
    returnAmt: string,
    finalWarning: string,
  },
  page: {
    home: {
      welcomeText: string,
      offerSectionTitle: string,
      offerSectionSubtitle: string,
      offerSectionDescription1: string,
      offerSectionDescription2: string,
      socialMediaTitle: string,
      socialMediaSubtitle: string,
      socialMediaDescription1: string,
      socialMediaDescription2: string,
      socialMediaTitle2: string,
      aboutSailboatTitle: string,
      aboutSailboatSubtitle: string,
      aboutSailboatDescription1: string,
      aboutSailboatDescription2: string,
      aboutSailboatTitle2: string,
      equipment: {
        propulsion: {
          title: string,
          description: string
        },
        sails: {
          title: string,
          description: string
        },
        hull: {
          title: string,
          description: string
        },
        tanks: {
          title: string,
          description: string
        },
        battery: {
          title: string,
          description: string
        },
        storage: {
          title: string,
          description: string
        },
        gps: {
          title: string,
          description: string
        },
        vhf: {
          title: string,
          description: string
        },
        autopilot: {
          title: string,
          description: string
        },
        internet: {
          title: string,
          description: string
        },
        soundSystem: {
          title: string,
          description: string
        },
        sockets: {
          title: string,
          description: string
        }
        bimini: {
          title: string,
          description: string
        },
        sprayHood: {
          title: string,
          description: string
        },
        solar: {
          title: string,
          description: string
        },
        tables: {
          title: string,
          description: string
        },
        snorkeling: {
          title: string,
          description: string
        },
        dinghy: {
          title: string,
          description: string
        },
        oven: {
          title: string,
          description: string
        },
        fridge: {
          title: string,
          description: string
        },
        kitchen: {
          title: string,
          description: string
        },
        toilet: {
          title: string,
          description: string
        },
        shower: {
          title: string,
          description: string
        },
        sink: {
          title: string,
          description: string
        },
        anchor: {
          title: string,
          description: string
        },
        fenders: {
          title: string,
          description: string
        },
        moorings: {
          title: string,
          description: string
        },
        lifeRaft: {
          title: string,
          description: string
        },
        epirb: {
          title: string,
          description: string
        },
        lifeVests: {
          title: string,
          description: string
        },
        heater: {
          title: string,
          description: string
        },
        vacuumCleaner: {
          title: string,
          description: string
        },
        oilLamp: {
          title: string,
          description: string
        },
        lights: {
          title: string,
          description: string
        },
        linen: {
          title: string,
          description: string
        },
        towels: {
          title: string,
          description: string
        }
      }
      aboutMeTitle: string,
      aboutMeSubtitle: string,
      aboutMeDescription1: string,
      aboutMeDescription2: string,
    },
    cruises: {
      notPlannedYet1: string,
      notPlannedYet2: string,
      cruiseDetails: {
        myBooking: string,
        bookNow: string,
        bookAgain: string,
        cruiseStatus: {
          upcoming: string,
          ongoing: string,
          ended: string
        },
        difficulty: string,
        difficulties: {
          TRIVIAL: string,
          EASY: string,
          MEDIUM: string,
          HARD: string,
          EXTREME: string,
        },
        freeSpaces: string,
        noFreeSpaces: string,
        notRatedYet: string,
        currency: string,
        perPerson: string,
        reviews: {
          sectionTitle: string,
          noReviews: string,
          title: string,
          description: string,
          rating: string,
          createdAt: string,
          modifiedAt: string,
          loginToReview: string,
          writeReview: string,
        },
        splashUploadPrimary: string,
        splashUploadDrop: string,
        crusePicsUploadPrimary: string,
        crusePicsUploadDrop: string,
        backToCruises: string,
      }
    },
    bookings: {
      loginToSeeBookings: string,
      noUpcomingBookingsTitle: string,
      noUpcomingBookingsDescription: string,
      bookingInterval: {
        HISTORICAL: string,
        UPCOMING: string
      },
      bookingDetails: {
        yourSpaces: string,
        cruiseDetails: string,
        manageBooking: string,
        finalizeBooking: string,
        bookingStatusTitle: string,
        bookingStatus: {
          PENDING: string,
          PROCESSING: string,
          ADVANCE_PAID: string,
          FULLY_PAID: string,
          CANCELLING: string,
          CANCELED: string,
        }
      },
      imgBadge: {
        in: string,
        days: string,
        write: string,
        review: string,
        reviewed: string,
      }
    },
    faq: {
      title: string,
      questions: {
        question1: {
          title: string,
          content1: string,
          content2: string,
          content3: string
        },
        question2: {
          title: string,
          content1: string,
          content2: string,
          content3: string
        },
        question3: {
          title: string,
          content1: string,
          content2: string,
          content3: string
        },
        question4: {
          title: string,
          content1: string,
          content2: string,
          content3: string
        },
        question5: {
          title: string,
          content1: string,
          content2: string,
          content3: string
        },
        question6: {
          title: string,
          content1: string,
          content2: string,
          content3: string
        },
        question7: {
          title: string,
          content1: string,
          content2: string,
          content3: string
        },
        question8: {
          title: string,
          content1: string,
          content2: string,
          content3: string
        },
        question9: {
          title: string,
          content1: string,
          content2: string,
          content3: string
        },
        question10: {
          title: string,
          content1: string,
          content2: string,
          content3: string
        },
        question11: {
          title: string,
          content1: string,
          content2: string,
          content3: string
        },
      }
    },
    admin: {
      cruises: {
        noCruises: string,
        bookingsOrDiscounts: string,
        cruiseForm: {
          createCruise: string,
          editCruise: string,
          price: string,
          titlePl: string,
          titleEn: string,
          descriptionPl: string,
          descriptionEn: string,
          planPl: string,
          planEn: string,
          cruiseDifficulty: string,
          startLocation: string,
          finishLocation: string,
          startDay: string,
          finishDay: string,
          splashImg: string,
          ytLink: string,
          images: string,
        }
      },
      cruiseDetails: {
        tabs: {
          BOOKINGS: string,
          DISCOUNTS: string
        },
        bookedSpaces: string,
        noBookings: string,
        noDiscounts: string,
        code: string,
        bookedBy: string,
        timeCreate: string,
        lastModified: string,
        changeStatus: string,
        showParticipants: string,
        showCrewList: string,
        discount: {
          code: string,
          amount: string,
        }
      }
    }
    emailVerify: {
      emailVerifyTitle: string,
      emailVerifyDescription1: string,
      emailVerifyDescription2: string,
      emailVerifyLogin: string
    },
    expiredVerificationToken: string,
    expiredResetToken: string,
  },
  formErrors: {
    fieldRequired: string,
    agreementRequired: string,
    incorrectFormat: string,
    onlyLetters: string,
    onlyLettersAndDash: string,
    badEmail: string,
    emailTaken: string,
    maxAllowed: string,
    maxChars: string,
    maxLetters: string,
    invalidLogin: string,
    phoneFormat: string,
    inadequatePassword: string,
    noWhitespaces: string,
    mismatchedPassword: string,
    youMustChoose: string,
    rangeBetween: string,
    bunks: string,
  }
}