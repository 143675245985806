import {Box, Typography} from "@mui/material";
import {boldText, centeredBox, fonts, mainColors} from "../../../../app.style";
import YouCanNowLogin from "../../common/YouCanNowLogin";
import {useContext} from "react";
import {AppContext} from "../../common/appWrapper/AppWrapper";

const PassChangeSuccess = () => {
  const {translation} = useContext(AppContext);

  return (
    <Box>
      <Box sx={[centeredBox, {mt: 5}]}>
        <Typography sx={[fonts.spartan20, mainColors.contrast, boldText, {textAlign: "center"}]}>
          {translation.form.setPassword.changeSuccess}
        </Typography>
      </Box>
      <YouCanNowLogin/>
    </Box>
  )
}

export default PassChangeSuccess;