import {Translation} from "../../../../../language/translation/translations";
import StringValidator from "../../../../utils/validators/StringValidator";
import {validatorSettings} from "../../../../utils/validators/validatorSettings";
import ReviewRequest from "../../../../service/review/ReviewRequest";


export default class ReviewFormValidator {
  translation: Translation;
  titleValidator: StringValidator;
  descriptionValidator: StringValidator;

  constructor(translation: Translation) {
    this.translation = translation;
    this.titleValidator = new StringValidator(
      translation, validatorSettings.reviewTitleMaxChars, false, true, true);
    this.descriptionValidator = new StringValidator(
      translation, validatorSettings.reviewDescMaxChars,false, true, true);
  }

  validateForm = (reviewForm: ReviewRequest) => {
    this.titleValidator.validate(reviewForm.title);
    this.descriptionValidator.validate(reviewForm.description);

    return this.titleValidator.isValid && this.descriptionValidator.isValid;
  }
}