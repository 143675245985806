import {Cruise} from "../../../../../model/Cruise";
import BookingRequest from "../../../../../service/booking/BookingRequest";
import NextPrevStepBtns from "../nextPrevStepBtns/NextPrevStepBtns";
import {Box, Grid} from "@mui/material";
import {useContext, useEffect, useRef, useState} from "react";
import {AppContext} from "../../../appWrapper/AppWrapper";
import Image from "mui-image";
import BunkIndicator from "./bunkIndicator/BunkIndicator";
import {BunkStatus} from "../../../../../utils/appEnums/BunkStatus";
import BunksLegend from "./bunksLegend/BunksLegend";
import BunksSelector from "./bunksSelector/BunksSelector";
import {ALL_BUNKS} from "../../../../../model/modelConstants/constants";
import {AuthContext} from "../../../../../../App";
import {Booking} from "../../../../../model/Booking";


interface BunksSelectionStepProps {
  cruise: Cruise;
  setActiveStep: Function;
  bookingFormData: BookingRequest;
  setBookingFormData: Function;
  bookingCollisionHandler: Function;
}

const BunksSelectionStep = ({cruise, setActiveStep, bookingFormData, setBookingFormData, bookingCollisionHandler} : BunksSelectionStepProps) => {
  const {jwt} = useContext(AuthContext);
  const {translation, appCacheApi} = useContext(AppContext);
  const [selectedBunks, setSelectedBunks] = useState(bookingFormData.bunks);
  const [containerWidth, setContainerWidth] = useState(0);
  const [showError, setShowError] = useState(false);
  const [nextDisabled, setNextDisabled] = useState(false);
  const layout = useRef<HTMLDivElement>();

  const getBunkStatus = (bunkNo: number) => {
    if (selectedBunks.includes(bunkNo)) {
      return BunkStatus.SELECTED;
    } else {
      if (cruise.availBunks.includes(bunkNo) || bookingFormData.initialBunks.includes(bunkNo)) {
        return BunkStatus.FREE;
      } else {
        return BunkStatus.TAKEN;
      }
    }
  }



  const nextStepHandler = () => {
    if (selectedBunks.length === bookingFormData.participants.length){
      setShowError(false);
      setNextDisabled(true);
      appCacheApi.postBooking(bookingFormData, jwt, "", (b : Booking) => {
          setBookingFormData(BookingRequest.fromExisting(b));
          setActiveStep(2);
        },
        (status: number) =>  {
          if (status === 409) bookingCollisionHandler();
        });
    } else {
      setShowError(true);
    }
  }

  useEffect(() => {
    bookingFormData.setBunks(selectedBunks);
    //eslint --disable-next-line
  }, [bookingFormData, selectedBunks]);

  useEffect(() => {
    if (layout.current) setContainerWidth(layout.current.offsetWidth);
  }, [layout.current?.offsetWidth]);

  return (
    <Grid container spacing={2} justifyContent={"center"}>
      <Grid item xs={12} sm={6}>
        <BunksLegend/>
        <Box sx={{display: "flex", justifyContent: "center", mb: 2}}>
          <Box ref={layout} sx={{position: "relative", width: "170px"}}>
            <Image
              src={"/images/layout/vertical/layout_numbers_v.png"}
              duration={0} style={{maxWidth: "170px"}} />
            {ALL_BUNKS.map((bunkNo, index) =>
              <BunkIndicator key={index} img={`layout_${bunkNo}`}
                             status={getBunkStatus(bunkNo)} width={containerWidth}/>
            )}
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} sx={{mt: 1, mb: 2}}>
        <BunksSelector
          passengerQty={bookingFormData.participants.length}
          selectedBunks={selectedBunks}
          getBunkStatus={getBunkStatus}
          setSelectedBunks={setSelectedBunks}
          showError={showError}/>
      </Grid>
      <Grid item xs={12} sx={{mb: 5}}>
        <NextPrevStepBtns
          nextLabel={translation.form.newBooking.bunksSelectionStep.nextPreviousBtns.next}
          nextStepHandler={nextStepHandler}
          prevLabel={translation.form.newBooking.bunksSelectionStep.nextPreviousBtns.previous}
          prevStepHandler={() => setActiveStep(0)}
          nextDisabled={nextDisabled}/>
      </Grid>
    </Grid>
  )
}

export default BunksSelectionStep;