import {Cruise} from "../../../../../model/Cruise";
import {button, centeredBox, fonts, mainColors, semiBoldText} from "../../../../../../app.style";
import SmallSpinner from "../../../../common/appSpinner/SmallSpinner";
import {Box, Button} from "@mui/material";
import {useCallback, useContext, useEffect, useState} from "react";
import {AppContext, AppFsDrawerContext, AppModalContext} from "../../../../common/appWrapper/AppWrapper";
import LoginForm from "../../../../common/appForm/loginForm/LoginForm";
import BookingForm from "../../../../common/appForm/bookingForm/BookingForm";
import {isCruiseBookedByUser} from "../../../../../utils/misc/helpers";

const MakeBookingBtn = ({cruise} : {cruise: Cruise}) => {
  const [disabled, setDisabled] = useState(false);
  const [isBtnClicked, setIsBtnClicked] = useState(false);
  const {translation, appUser, myBookings} = useContext(AppContext);
  const {setModalHeaderText, setModalChildren, setIsModalOpen} = useContext(AppModalContext);
  const {setIsFsDrawerOpen, setFsDrawerChildren} = useContext(AppFsDrawerContext);

  const whenDrawerIsClosed = () => {
    setIsBtnClicked(false);
    setDisabled(false);
  }

  const showForm = useCallback(() => {
    setFsDrawerChildren(<BookingForm cruise={cruise} onDrawerClose={whenDrawerIsClosed}/>)
    setIsFsDrawerOpen(true);
    setDisabled(true);
  }, [setFsDrawerChildren, cruise, setIsFsDrawerOpen]);
  
  const handleClick = () => {
    setIsBtnClicked(true);
    if (appUser) {
      showForm();
    } else {
      setModalHeaderText(translation.modalHeader.bookingLogin);
      setModalChildren(<LoginForm/>);
      setIsModalOpen(true);
    }
  }

  const getButtonDescription = () => {
    return isCruiseBookedByUser(myBookings, cruise) ?
      translation.page.cruises.cruiseDetails.bookAgain :
      translation.page.cruises.cruiseDetails.bookNow;
  }

  useEffect(() => {
    if (!disabled && appUser && isBtnClicked){
      showForm();
    }
  }, [appUser, disabled, isBtnClicked, showForm]);

  return (
    <Box sx={centeredBox}>
      <Button variant="contained"
              disabled={disabled}
              disableElevation
              sx={[fonts.spartan14, semiBoldText, button, {display: "flex", flexGrow: 1, maxWidth: "400px", mt: 3, background: mainColors.primary.color}]}
              onClick={() => handleClick()}>
        {disabled ? <SmallSpinner/> : getButtonDescription()}
      </Button>
    </Box>
  )
}

export default MakeBookingBtn;