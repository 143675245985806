import {MenuOption} from "./NavMenu";
import {AppRole} from "../../../../model/modelEnums/AppRole";
import {useCallback, useEffect, useState} from "react";

const useMenuOptions = (translation: any, role: AppRole | undefined) => {
  const defaultOpts : MenuOption[] = [
    {
      title: translation.navMenu.home,
      url: "/home"
    },
    {
      title: translation.navMenu.cruises,
      url: "/cruises"
    },
    {
      title: translation.navMenu.bookings,
      url: "/bookings"
    },
    {
      title: translation.navMenu.faq,
      url: "/faq"
    }
  ]

  const adminOpt : MenuOption = {title: translation.navMenu.admin, url: "/admin"}

  const getMenuOptions = useCallback(() => {
    return (role === AppRole.ADMIN) ? defaultOpts.concat(adminOpt) : defaultOpts;
    // eslint-disable-next-line
  }, [role, translation])

  const [menuOptions, setMenuOptions] = useState(getMenuOptions());

  useEffect(() => {
    setMenuOptions(getMenuOptions);
  }, [getMenuOptions]);

  return {menuOptions}
}

export default useMenuOptions;