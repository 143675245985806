import {createContext, useContext, useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {AppContext, AppModalContext} from "../AppWrapper";
import {langOptions} from "../../../../../language/common";
import useSimpleOpts from "../../appSelectField/appSelectField/simpleOption/useSimpleOpts";
import {SimpleOptionProps} from "../../appSelectField/appSelectField/simpleOption/SimpleOption";
import AppUserRequest from "../../../../service/appUser/AppUserRequest";
import DesktopMenu from "./desktopMenu/DesktopMenu";
import {MD_BREAKPOINT} from "../../guiConstants";
import {AuthContext} from "../../../../../App";
import useMenuOptions from "./useMenuOptions";
import MobileMenu from "./mobileMenu/MobileMenu";
import AppFooter from "../appFooter/AppFooter";
import ProfileSettingsForm from "../../appForm/profileSettingsForm/ProfileSettingsForm";
import LoginForm from "../../appForm/loginForm/LoginForm";

export interface MenuOption {
  title: string;
  url: string;
}

interface NavMenuContextType {
  tab: number;
  changeTab: (value: any) => void;
  menuOptions: MenuOption[];
  langOpts: any;
}

export const NavMenuContext = createContext<NavMenuContextType>({} as NavMenuContextType)

const NavMenu = () => {
  const {role, jwt} = useContext(AuthContext)
  const {appUser, appCacheApi, windowWidth, translation, setSelectedLanguage} = useContext(AppContext);
  const {setIsModalOpen, setModalHeaderText, setModalChildren} = useContext(AppModalContext);
  const {simpleOpts: langOpts} = useSimpleOpts(langOptions, false);
  const {menuOptions} = useMenuOptions(translation, role);
  const [tab, setTab] = useState(0);
  let navigate = useNavigate();
  let location = useLocation();

  const handlePersonBtnClick = () => {
    if (appUser) {
      setModalHeaderText(translation.modalHeader.profileSettings);
      setModalChildren(<ProfileSettingsForm/>)
    } else {
      setModalHeaderText(translation.modalHeader.menuLogin);
      setModalChildren(<LoginForm/>)
    }
    setIsModalOpen(true);
  }

  const handleChange = (newValue: number) => {
    setTab(newValue);
    navigate(menuOptions[newValue].url)
  };

  const setLang = (input: { props: SimpleOptionProps }) => {
    if (appUser) {
      const request = AppUserRequest.fromExisting(appUser);
      request.setLang(input);
      appCacheApi.updateSelf(request, jwt);
    } else {
      setSelectedLanguage(input.props.value);
    }
  }

  useEffect(() => {
    const currentOption = menuOptions.find(opt => location.pathname.includes(opt.url));
    if (currentOption)
      setTab(menuOptions.indexOf(currentOption));
  }, [menuOptions, location]);

  return (
    <NavMenuContext.Provider value={{tab, changeTab: handleChange, menuOptions, langOpts}}>
      {windowWidth >= MD_BREAKPOINT ?
        <DesktopMenu setLang={setLang} handlePersonBtnClick={handlePersonBtnClick}/> :
        <MobileMenu setLang={setLang} handlePersonBtnClick={handlePersonBtnClick}/>}
      <AppFooter/>
    </NavMenuContext.Provider>
  );
}

export default NavMenu