import CruiseDetails from "../../../common/cruiseDetails/CruiseDetails";
import {Cruise} from "../../../../model/Cruise";
import {Box, Container} from "@mui/material";
import YoutubeVideo from "../../../common/youtubeVideo/YoutubeVideo";
import Image from "mui-image";
import CruiseReviews from "../cruiseReviews/CruiseReviews";
import CruiseImages from "../cruiseImages/CruiseImages";
import {getCruiseStatus, isCruiseBookedByUser} from "../../../../utils/misc/helpers";
import {CruiseStatus} from "../../../../utils/appEnums/CruiseStatus";
import MakeBookingBtn from "./makeBookingBtn/MakeBookingBtn";
import {KeyboardBackspace} from "@mui/icons-material";
import IconAndTextButton from "../../../common/appButton/iconAndTextButton/IconAndTextButton";
import {useContext} from "react";
import {AppContext} from "../../../common/appWrapper/AppWrapper";
import {useNavigate} from "react-router-dom";
import MyBookingBtn from "./myBookingBtn/MyBookingBtn";
import {AuthContext} from "../../../../../App";
import {AppRole} from "../../../../model/modelEnums/AppRole";
import {apiUrl} from "../../../../service/urls";
import AppDropZone from "../../../common/appDropZone/AppDropZone";
import {centeredBox} from "../../../../../app.style";

const CruiseFullDetails = ({cruise} : {cruise: Cruise}) => {
  const {role, jwt} = useContext(AuthContext);
  const {translation, myBookings, appCacheApi} = useContext(AppContext);
  const navigate = useNavigate();

  const uploadSplashImg = (payload: any) => {
    appCacheApi.postCruiseImg(payload, jwt, `/${cruise.id}/splash-img`)
  }

  const uploadCruiseImg = (payload: any) => {
    appCacheApi.postCruiseImg(payload, jwt, `/${cruise.id}/cruise-img`)
  }

  return (
    <>
      <Container sx={{mb: 5}}>
        <Box sx={{my: 3}}>
        {cruise.youtubeLink ? <YoutubeVideo vidLink={cruise.youtubeLink}/> :
          <Image
            src={cruise.splashImg ? apiUrl.cruises + "/" + cruise.splashImg : "/images/mock/default_splash.jpg"}
            duration={1000}/>}
        </Box>
        {role === AppRole.ADMIN &&
          <Box sx={[centeredBox, {my: 3}]}>
            <AppDropZone
                handleUpload={uploadSplashImg}
                primaryText={translation.page.cruises.cruiseDetails.splashUploadPrimary}
                dropText={translation.page.cruises.cruiseDetails.splashUploadDrop}/>
          </Box>
        }
        <CruiseDetails cruise={cruise} isFullDetails={true}/>
        <IconAndTextButton
          text={translation.page.cruises.cruiseDetails.backToCruises}
          icon={<KeyboardBackspace/>}
          variant={"text"}
          onClick={() => navigate("/cruises")}
          sx={{mt: 2}}/>
        {getCruiseStatus(cruise) === CruiseStatus.UPCOMING ?
          <>
            {isCruiseBookedByUser(myBookings, cruise) &&
            <MyBookingBtn/>}
            {cruise.availBunks.length !== 0 &&
            <MakeBookingBtn cruise={cruise}/>}
          </> :
          <>
            <CruiseReviews cruise={cruise}/>
            <CruiseImages cruise={cruise}/>
          </>}
        {role === AppRole.ADMIN &&
            <Box sx={[centeredBox, {my: 3}]}>
                <AppDropZone
                    handleUpload={uploadCruiseImg}
                    primaryText={translation.page.cruises.cruiseDetails.crusePicsUploadPrimary}
                    dropText={translation.page.cruises.cruiseDetails.crusePicsUploadDrop}/>
            </Box>
        }
      </Container>
    </>
  )
}

export default CruiseFullDetails;