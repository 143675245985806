const env = process.env

const hostBe = env.REACT_APP_BE_HOST as string;
const hostFe = env.REACT_APP_FE_HOST as string;

export const apiUrl = {
  auth: hostBe + env.REACT_APP_AUTH_API,
  users: hostBe + env.REACT_APP_USERS_API,
  bookings: hostBe + env.REACT_APP_BOOKINGS_API,
  cruises: hostBe + env.REACT_APP_CRUISES_API,
  discounts: hostBe + env.REACT_APP_DISCOUNTS_API,
  reviews: hostBe + env.REACT_APP_REVIEWS_API,
  verify: hostBe + env.REACT_APP_AUTH_API + env.REACT_APP_VERIFY_API,
  reset: hostBe + env.REACT_APP_AUTH_API + env.REACT_APP_RESET_API,
  resendVerify: hostBe + env.REACT_APP_AUTH_API + env.REACT_APP_RESEND_VERIFY_API,
  passChange: hostBe + env.REACT_APP_AUTH_API + env.REACT_APP_PASS_CHANGE_API,
  getReturnAmt: hostBe + env.REACT_APP_BOOKINGS_API + env.REACT_APP_GET_RETURN_API,
  sendInitialPaymentInstructions: hostBe + env.REACT_APP_BOOKINGS_API + env.REACT_APP_INITIAL_BOOKING_EMAIL_API,
  sendFinalPaymentInstructions: hostBe + env.REACT_APP_BOOKINGS_API + env.REACT_APP_FINAL_BOOKING_EMAIL_API
}

export const frontendUrl = {
  home: hostFe,
}