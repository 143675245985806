import {Box, Typography} from "@mui/material";
import {fonts, mainColors, semiBoldText} from "../../../../../app.style";

const SubSectionElement = ({letter, text} : {letter: string, text: string}) => {

  return (
      <Box sx={{display: "flex", mb: 1}}>
        <Box sx={{minWidth: "28px"}}/>
        <Typography sx={[fonts.spartan12, mainColors.grey, semiBoldText, {minWidth: "16px"}]}>
          {letter}
        </Typography>
        <Typography sx={[fonts.spartan12, mainColors.grey, semiBoldText]}>
          {text}
        </Typography>
      </Box>
  )
}

export default SubSectionElement;