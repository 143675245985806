import {Box, Button, Typography} from "@mui/material";
import {button, centeredBox, fonts, mainColors, semiBoldText} from "../../../../../app.style";
import {useContext} from "react";
import {AppContext, AppFsDrawerContext, AppModalContext} from "../../appWrapper/AppWrapper";
import {AuthContext} from "../../../../../App";

const BookingCollisionContent = ({onDrawerClose} : {onDrawerClose: Function | undefined}) => {
  const {jwt} = useContext(AuthContext);
  const {translation, appCacheApi} = useContext(AppContext);
  const {setIsFsDrawerOpen} = useContext(AppFsDrawerContext);
  const {setIsModalOpen} = useContext(AppModalContext);

  const closeHandler = () => {
    setIsFsDrawerOpen(false);
    setIsModalOpen(false);
    if (onDrawerClose) onDrawerClose();
    appCacheApi.getCruises();
    appCacheApi.getMyBookings("", jwt);
  }

  return (
    <>
      <Box sx={{my: 2}}>
        <Typography sx={[fonts.spartan14, mainColors.contrast]}>
          {translation.bookingCollisionModal.text}
        </Typography>
      </Box>
      <Box sx={centeredBox}>
        <Button
          variant="contained"
          disableElevation
          fullWidth
          onClick={() => closeHandler()}
          sx={[fonts.spartan14, semiBoldText, button, mainColors.white, {maxWidth: "330px"}]}>
          {translation.close}
        </Button>
      </Box>
    </>
  )
}

export default BookingCollisionContent;