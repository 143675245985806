import AbstractValidator from "./AbstractValidator";
import {Translation} from "../../../language/translation/translations";


export default class PasswordValidator implements AbstractValidator {
  private _translation: Translation;
  private _isValid= false;
  private _errorText = ".";
  private _containsSmall = false;
  private _containsCapital = false;
  private _noWhitespace = false;
  private _hasMinRequiredChars = false;
  private _wasInputTouched = false;
  private readonly _minChars: number;
  private readonly _maxChars: number;

  constructor(translation: Translation, minChars: number, maxChars: number) {
    this._translation = translation;
    this._maxChars = maxChars;
    this._minChars = minChars;
  }

  validate(input: string) {
    this._isValid = false;
    this._wasInputTouched = true;
    this._hasMinRequiredChars = (input.length >= this._minChars);
    this._containsSmall = /^(?=.*[a-z])/.test(input);
    this._containsCapital = /^(?=.*[A-Z])/.test(input);
    this._noWhitespace = /^\S*$/.test(input);

    if (input.length === 0) {
      this._errorText = this._translation.formErrors.fieldRequired;
    } else if (!this._hasMinRequiredChars || !this._containsSmall || !this._containsCapital) {
      this._errorText = this._translation.formErrors.inadequatePassword;
    } else if (!this._noWhitespace){
      this._errorText = this._translation.formErrors.noWhitespaces;
    } else if (input.length > this._maxChars) {
      this._errorText = this._translation.formErrors.maxAllowed
        + this._maxChars
        + this._translation.formErrors.maxChars;
    } else {
      this._errorText = ".";
      this._isValid = true;
    }
  }

  public set translation(translation: Translation) {
    this._translation = translation;
  }

  public get isValid(){
    return this._isValid;
  }

  public get errorText(){
    return this._errorText;
  }

  public get containsSmall(){
    return this._containsSmall;
  }

  public get containsCapital(){
    return this._containsCapital;
  }

  public get hasMinRequiredChars(){
    return this._hasMinRequiredChars;
  }
}