import {centeredBox, fonts, mainColors} from "../../../../../../app.style";
import {Container, Grid, IconButton, Paper, Typography} from "@mui/material";
import {useContext} from "react";
import {AppContext} from "../../../../common/appWrapper/AppWrapper";
import YoutubeVideo from "../../../../common/youtubeVideo/YoutubeVideo";
import {ReactComponent as YTIcon} from "../../../../../../assets/icons/socialMedia/color/youtube.svg";
import {ReactComponent as FbIcon} from "../../../../../../assets/icons/socialMedia/color/facebook.svg";
import {ReactComponent as InstaIcon} from "../../../../../../assets/icons/socialMedia/color/instagram.svg";
import {openLinkInNewWindow} from "../../../../../utils/misc/helpers";


const SocialMediaSection = () => {
  const {translation} = useContext(AppContext);

  return (
    <Paper elevation={5} sx={{backgroundColor: mainColors.white.color}}>
      <Container sx={{py: 7}}>
        <Grid container spacing={2}>
          <Grid item xs={12} sx={[centeredBox]}>
            <Typography sx={[fonts.mainRunic30, {textAlign: "center"}]}>
              {translation.page.home.socialMediaTitle}
            </Typography>
          </Grid>
          <Grid item xs={12} sx={[centeredBox]}>
            <Typography sx={[fonts.spartan14, {textAlign: "center", fontStyle: 'italic'}]}>
              {translation.page.home.socialMediaSubtitle}
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{mt: 3}}>
            <Typography sx={[fonts.spartan14, {textAlign: "justify"}]}>
              &emsp;&emsp;{translation.page.home.socialMediaDescription1}
            </Typography>
          </Grid>
          <Grid item xs={12} >
            <YoutubeVideo vidLink={"OBqdv-WHwsQ"}/>
          </Grid>
          <Grid item xs={12}>
            <Typography sx={[fonts.spartan14, {textAlign: "justify"}]}>
              &emsp;&emsp;{translation.page.home.socialMediaDescription2}
            </Typography>
          </Grid>
          <Grid item xs={12} sx={[centeredBox]}>
            <Typography sx={[fonts.spartan20, {textAlign: "center"}]}>
              {translation.page.home.socialMediaTitle2}
            </Typography>
          </Grid>
          <Grid item xs={4} sx={[centeredBox]}>
            <IconButton onClick={() => openLinkInNewWindow("https://www.youtube.com/@ChacrunaSails")}>
              <YTIcon/>
            </IconButton>
          </Grid>
          <Grid item xs={4} sx={[centeredBox]}>
            <IconButton onClick={() => openLinkInNewWindow("https://www.facebook.com/chacrunasails")}>
              <FbIcon/>
            </IconButton>
          </Grid>
          <Grid item xs={4} sx={[centeredBox]}>
            <IconButton onClick={() => openLinkInNewWindow("https://www.instagram.com/chacruna_sails")}>
              <InstaIcon/>
            </IconButton>
          </Grid>
        </Grid>
      </Container>
    </Paper>
  )
}

export default SocialMediaSection;