import SubStep from "../../subStep/SubStep";
import {Box, Typography} from "@mui/material";
import {fonts, mainColors} from "../../../../../../../app.style";
import {useContext} from "react";
import {AppContext} from "../../../../appWrapper/AppWrapper";
import {MD_BREAKPOINT} from "../../../../guiConstants";
import useSimpleOpts from "../../../../appSelectField/appSelectField/simpleOption/useSimpleOpts";
import {currencyOptions} from "../../../../../../../language/common";
import AppSelectField from "../../../../appSelectField/AppSelectField";
import BookingRequest from "../../../../../../service/booking/BookingRequest";
import {Booking} from "../../../../../../model/Booking";
import {AuthContext} from "../../../../../../../App";
import {BookingType} from "../../../../../../model/modelEnums/BookingType";
import {getDaysLeft, getDuePaymentDate} from "../../../../../../utils/misc/helpers";


interface BookingOptionsProps {
  cruiseStartDay: string;
  bookingFormData: BookingRequest;
  setBookingFormData: Function;
  bookingCollisionHandler: Function;
}

const BookingOptions = ({cruiseStartDay, bookingFormData, setBookingFormData, bookingCollisionHandler} : BookingOptionsProps) => {
  const {jwt} = useContext(AuthContext);
  const {translation, windowWidth, appCacheApi} = useContext(AppContext);
  const {simpleOpts: currencyOpts} = useSimpleOpts(currencyOptions, false);
  const {simpleOpts: bookingOpts} = useSimpleOpts(getDaysLeft(cruiseStartDay) >= 14 ?
    translation.form.newBooking.paymentStep.bookingOptions.options :
    {ONE_STAGE : translation.form.newBooking.paymentStep.bookingOptions.options.ONE_STAGE}, false);

  const changeCurrency = (input: any) => {
    bookingFormData.setCurrency(input);
    appCacheApi.postBooking(bookingFormData, jwt, "",
      (b: Booking) => setBookingFormData(BookingRequest.fromExisting(b)),
      (status: number) => {if (status === 409) bookingCollisionHandler()});
  }

  const changeBookingType = (input: any) => {
    bookingFormData.setBookingType(input);
    appCacheApi.postBooking(bookingFormData, jwt, "",
      (b: Booking) => setBookingFormData(BookingRequest.fromExisting(b)),
      (status: number) => {if (status === 409) bookingCollisionHandler()});
  }

  return(
    <>
      <Box sx={{display: "flex"}}>
        <SubStep subStepNo={2} totalSteps={2}/>
        <Typography sx={[fonts.spartan16, mainColors.contrast, {mb: 2}]}>
          {translation.form.newBooking.paymentStep.bookingOptions.title}
        </Typography>
      </Box>
      <Box sx={{display: "flex", justifyContent: windowWidth < MD_BREAKPOINT ? "center" : undefined}}>
        <Box sx={{maxWidth: "500px", display: "flex", flexDirection: "column"}}>
          <Box sx={{width: "200px", mb: 2}}>
            <AppSelectField
              label={translation.form.newBooking.paymentStep.paymentOptions.currency}
              options={currencyOpts}
              selectedValue={bookingFormData.currency}
              required={true}
              setFormData={changeCurrency}/>
          </Box>
          <Box sx={{maxWidth: "300px"}}>
            <AppSelectField
              label={translation.form.newBooking.paymentStep.bookingOptions.bookingType}
              options={bookingOpts}
              selectedValue={bookingFormData.bookingType}
              required={true}
              setFormData={changeBookingType}/>
          </Box>
          {bookingOpts.length === 2 ?
            bookingFormData.bookingType === BookingType.TWO_STAGE ?
            <>
              <Typography sx={[fonts.spartan12, mainColors.contrast, {mt: 2}]}>
                {translation.form.newBooking.paymentStep.bookingOptions.remainingPaymentDueInfo}
              </Typography>
              <Typography sx={[fonts.spartan12, mainColors.contrast, {mt: 1}]}>
                {translation.form.newBooking.paymentStep.bookingOptions.remainingPaymentDueDate}
                {getDuePaymentDate(cruiseStartDay)}
              </Typography>
            </> :
            <Box sx={{my: 2}}/> :
            <Typography sx={[fonts.spartan12, mainColors.contrast, {mt: 2}]}>
              {translation.form.newBooking.paymentStep.bookingOptions.fullPaymentReq}
            </Typography>
            }
        </Box>
      </Box>
    </>
  )
}

export default BookingOptions;