import {useContext, useEffect, useState} from "react";
import {AppContext} from "../../appWrapper/AppWrapper";
import BackToLoginButton from "../../appForm/registerForm/backToLoginButton/BackToLoginButton";
import {centeredBox, fonts, mainColors, semiBoldText} from "../../../../../app.style";
import {Box, Link, Typography} from "@mui/material";
import {ForwardToInbox} from '@mui/icons-material';
import usePost from "../../../../service/axios/usePost";
import {apiUrl} from "../../../../service/urls";
import ResendInfo from "../resendInfo/ResendInfo";


const RegisterSuccess = ({email} : {email: string}) => {
  const {translation} = useContext(AppContext);
  const [disabled, setDisabled] = useState(false);
  const {setData, loading, status, postData: resend} = usePost(apiUrl.resendVerify + email);

  const sendAgain = () => {
    setDisabled(true);
    resend({});
  }

  useEffect(() => {
    if (!loading && disabled) {
      setDisabled(!disabled);
      setData(null);
    }
  }, [loading, disabled, setDisabled, setData]);

  return (
    <>
      <Box sx={[centeredBox, {mb: 2}]}>
        <ForwardToInbox fontSize={"large"} color={"primary"}/>
      </Box>
      <Typography align="center" sx={[fonts.spartan14, semiBoldText, mainColors.contrast, {mb: 2}]}>
        {translation.form.registerForm.regSuccessInfo1}{email}
      </Typography>
      <Typography align="center" sx={[fonts.spartan14, mainColors.contrast]}>
        {translation.form.registerForm.regSuccessInfo2}
      </Typography>
      <Typography align="center" sx={[fonts.spartan14, semiBoldText, mainColors.contrast, {mt: 3}]}>
        {translation.form.common.dintReceiveEmail}
      </Typography>
      <Box sx={[centeredBox, {my: 1}]}>
        <Link
          component="button"
          disabled={disabled}
          sx={[fonts.spartan14, semiBoldText, mainColors.contrast]}
          onClick={() => sendAgain()}>
          {translation.form.common.sendAgain}
        </Link>
      </Box>
      <ResendInfo status={status}/>
      <Box sx={[centeredBox, {mt: 3}]}>
        <BackToLoginButton/>
      </Box>
    </>
  );
}

export default RegisterSuccess;