import {button, fonts, mainColors, mediumText, semiBoldText} from "../../../../../app.style";
import {Button, Typography} from "@mui/material";
import {BookingStatus} from "../../../../model/modelEnums/BookingStatus";
import {Booking} from "../../../../model/Booking";
import {Cruise} from "../../../../model/Cruise";
import {useContext} from "react";
import {AppContext, AppFsDrawerContext, AppModalContext} from "../../appWrapper/AppWrapper";
import ManageBookingForm from "../../appForm/manageBookingForm/ManageBookingForm";
import {AuthContext} from "../../../../../App";
import FinalPaymentForm from "../../appForm/finalPaymentForm/FinalPaymentForm";
import CancelBookingInfo from "../cancelBookingInfo/CancelBookingInfo";


interface ManageBookingModalContentProps {
  cruise: Cruise;
  booking: Booking
}

const ManageBookingModalContent = ({cruise, booking} : ManageBookingModalContentProps) => {
  const {jwt} = useContext(AuthContext);
  const {translation, appCacheApi} = useContext(AppContext);
  const {setIsFsDrawerOpen, setFsDrawerChildren, setOnFsDrawerClose} = useContext(AppFsDrawerContext);
  const {setIsModalOpen, setIsConfirmModalOpen, setConfirmModalHandler, setConfirmModalHeaderText, setConfirmModalChildren} = useContext(AppModalContext);

  const showBookingDetails = () => {
    setFsDrawerChildren(<ManageBookingForm cruise={cruise} booking={booking}/>)
    setIsFsDrawerOpen(true);
    setIsModalOpen(false);
  }

  const showPayRemaining = () => {
    setFsDrawerChildren(<FinalPaymentForm cruise={cruise} booking={booking}/>)
    setIsFsDrawerOpen(true);
    setOnFsDrawerClose(() => () => setIsFsDrawerOpen(false));
    setIsModalOpen(false);
  }

  const cancelBookingPrompt = () => {
    setConfirmModalHeaderText(translation.modalHeader.cancelBooking);
    setConfirmModalChildren(<CancelBookingInfo booking={booking} cruise={cruise}/>);
    setConfirmModalHandler(() => () => {
      setIsModalOpen(false);
      appCacheApi.cancelBooking({}, jwt, booking.id, () => {
        appCacheApi.getCruises("/" + booking.cruiseId);
      })
    });
    setIsConfirmModalOpen(true);
  }

  return (
    <>
      {[BookingStatus.ADVANCE_PAID, BookingStatus.CANCELLING].includes(booking.bookingStatus) &&
          <>
            <Button
                variant="contained"
                color="secondary"
                disableElevation
                fullWidth
                onClick={() => showPayRemaining()}
                sx={[fonts.spartan14, semiBoldText, button, mainColors.white, {maxWidth: "330px"}]}>
              {translation.manageBooking.payRemaining}
            </Button>
            <Typography sx={[fonts.spartan14, mediumText, {my: 2, textAlign: "center"}]}>
              {translation.manageBooking.or}
            </Typography>
          </>}
      <Button
        variant="contained"
        disableElevation
        fullWidth
        onClick={() => showBookingDetails()}
        sx={[fonts.spartan14, semiBoldText, button, {maxWidth: "330px"}]}>
        {translation.manageBooking.changeBookingDetails}
      </Button>
      <Typography sx={[fonts.spartan14, mediumText, {my: 2, textAlign: "center"}]}>
        {translation.manageBooking.or}
      </Typography>
      <Button
        variant="outlined"
        color="contrast"
        disableElevation
        fullWidth
        onClick={() => cancelBookingPrompt()}
        sx={[fonts.spartan14, semiBoldText, button, {maxWidth: "330px"}]}>
        {translation.manageBooking.cancelBooking}
      </Button>
    </>
  )
}

export default ManageBookingModalContent;