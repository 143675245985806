import AbstractValidator from "./AbstractValidator";
import {Translation} from "../../../language/translation/translations";


export default class StringValidator implements AbstractValidator {
  private _translation: Translation;
  private _isValid= false;
  private _errorText = ".";
  private _noWhitespace = false;
  private readonly _maxChars: number;
  private readonly _allowOnlyLetters: boolean;
  private readonly _allowWhitespace: boolean;
  private readonly _allowDash?: boolean;
  private readonly _mandatory: boolean;

  constructor(translation: Translation, maxChars: number, allowOnlyLetters: boolean, allowDash?: boolean, allowWhitespace?: boolean, mandatory = true) {
    this._translation = translation;
    this._maxChars = maxChars;
    this._allowOnlyLetters = allowOnlyLetters;
    this._allowDash = allowDash;
    this._allowWhitespace = allowWhitespace ?? false;
    this._mandatory = mandatory;
  }

  validate(input: string) {
    this._isValid = false;
    this._noWhitespace = /^\S*$/.test(input);
    if (!this._allowWhitespace && !this._noWhitespace){
      this._errorText = this._translation.formErrors.noWhitespaces;
    } else if (this._allowOnlyLetters && this._allowDash && !/^[A-Za-zĄąĆćĘęŁłŃńÓóŚśŹźŻż-]*$/.test(input)){
      this._errorText = this._translation.formErrors.onlyLettersAndDash;
    } else if (this._allowOnlyLetters && !this._allowDash && !/^[A-Za-zĄąĆćĘęŁłŃńÓóŚśŹźŻż]*$/.test(input)) {
      this._errorText = this._translation.formErrors.onlyLetters;
    } else if (input.length > this._maxChars) {
      this._errorText = this._translation.formErrors.maxAllowed
        + this._maxChars
        + this._translation.formErrors.maxLetters;
    } else if (input.length === 0 && this._mandatory){
      this._errorText = this._translation.formErrors.fieldRequired;
    } else {
      this._errorText = "."
      this._isValid = true;
    }
  }

  public set translation(translation: Translation) {
    this._translation = translation;
  }

  public get isValid(){
    return this._isValid;
  }

  public set isValid(isValid: boolean){
    this._isValid = isValid;
  }

  public get errorText(){
    return this._errorText;
  }

  public set errorText(text: string){
    this._errorText = text;
  }
}