import {objectsSelectorListTypography} from "../../../../../../app.style";
import {Typography} from "@mui/material";


export interface SimpleOptionProps {
  value: string,
  label: string
}

const SimpleOption = (props: SimpleOptionProps) => {

  return (
    <Typography sx={objectsSelectorListTypography}>
      {props.label}
    </Typography>
  )
}

export default SimpleOption;