import useGet from "../axios/useGet";
import {apiUrl} from "../urls";
import {useEffect, useState} from "react";
import {Discount} from "../../model/Discount";

const useGetDiscounts = () => {
  const [discounts, setDiscounts] = useState([] as Discount[]);
  const {data, setData, loading: getDiscountsLoading, getData: getDiscounts} = useGet(apiUrl.discounts);

  useEffect(() => {
    if (data) {
      setDiscounts(data as unknown as Discount[]);
      setData(null);
    }
  }, [data, setData])

  return {discounts, setDiscounts, getDiscounts, getDiscountsLoading}
}

export default useGetDiscounts;