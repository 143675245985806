import {
  centeredBox,
  closeIcon,
  dialogHeaderTypographyStyle,
  mainColors,
  responsiveDialogStyle
} from "../../../../app.style";
import {Box, Container, Dialog, DialogContent, DialogTitle, IconButton, Typography} from "@mui/material";
import {Close} from "@mui/icons-material";
import {useContext} from "react";
import PolicyModalContent from "./policyModal/PolicyModalContent";
import {AppContext} from "../appWrapper/AppWrapper";

const PolicyModal = ({isOpen, setIsOpen} : {isOpen: boolean, setIsOpen: Function}) => {
  const {translation} = useContext(AppContext);

  return (
    <Dialog open={isOpen} sx={responsiveDialogStyle}>
      <DialogTitle>
        <Box sx={centeredBox}>
          <Typography sx={dialogHeaderTypographyStyle}>
            {translation.modalHeader.policy}
          </Typography>
          <IconButton onClick={() => setIsOpen(false)} sx={[closeIcon, mainColors.contrast]}>
            <Close/>
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Container sx={{py: 1}}>
          <PolicyModalContent setIsOpen={setIsOpen}/>
        </Container>
      </DialogContent>
    </Dialog>
  )
}

export default PolicyModal;