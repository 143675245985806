import {useCallback, useContext, useEffect, useState} from "react";
import {AppContext} from "../appWrapper/AppWrapper";

const useSeasonCruises = (selectedYear: number) => {
  const {cruises} = useContext(AppContext);

  const getCruisesInYear = useCallback(() => {
    return cruises.filter(c => parseInt(c.startDay.split("-")[0]) === selectedYear)
  }, [selectedYear, cruises]);

  const [seasonCruises, setSeasonCruises] = useState(getCruisesInYear())

  useEffect(() => {
    setSeasonCruises(getCruisesInYear());
  }, [getCruisesInYear]);

  return {seasonCruises}
}

export default useSeasonCruises;