import {mainColors, semiBoldText} from "../../../../../../app.style";

export const welcomeText = {
  ...semiBoldText,
  ...mainColors.white,
  position: "absolute",
  top: "25%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  textShadow: "0 0 10px #000000"
}

export const websiteNameText = {
  ...semiBoldText,
  ...mainColors.white,
  position: "absolute",
  top: "45%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  textShadow: "0 0 10px #000000"
}