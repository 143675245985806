import {Box, Container, Drawer} from '@mui/material'
import {PropsWithChildren, useContext, useEffect, useState} from "react";
import {ANIMATION_SPEED} from "../../guiConstants";
import {Close} from '@mui/icons-material';
import IconAndTextButton from "../../appButton/iconAndTextButton/IconAndTextButton";
import {AppContext} from "../AppWrapper";

interface FsDrawerProps extends PropsWithChildren {
  isOpen: boolean;
  onFsDrawerClose: Function;
}

const FsDrawer = (props: FsDrawerProps) => {
  const {translation} = useContext(AppContext);
  const [width, setWidth] = useState(document.documentElement.clientWidth);

  useEffect(() => {
    window.addEventListener('resize', () => setWidth(document.documentElement.clientWidth));
    return () => {window.removeEventListener('resize', () => setWidth(document.documentElement.clientWidth));}
  }, []);

  return (
    <Drawer
      transitionDuration={ANIMATION_SPEED}
      anchor={"right"}
      open={props.isOpen}>
      <Box sx={{width: width, height: "100vh"}}>
        <Container sx={{mt: 2}}>
          <IconAndTextButton
            text={translation.close}
            icon={<Close/>}
            variant={"text"}
            onClick={() => props.onFsDrawerClose()}/>
          {props.children}
        </Container>
      </Box>
    </Drawer>
  )
}
export default FsDrawer;