import {Container} from "@mui/material";
import useIntervalBookings from "./useIntervalBookings";
import {BookingInterval} from "../../../../utils/appEnums/BookingInterval";
import NoBookings from "./noBookings/NoBookings";
import BookingCard from "./bookingCard/BookingCard";
import {findInCache} from "../../../../service/helpers/cacheUtils";
import {useContext} from "react";
import {AppContext} from "../../../common/appWrapper/AppWrapper";
import {Cruise} from "../../../../model/Cruise";


const Bookings = ({selectedBookingInterval}: {selectedBookingInterval: BookingInterval}) => {
  const {intervalBookings} = useIntervalBookings(selectedBookingInterval);
  const {cruises} = useContext(AppContext);

  return (
    <Container sx={{mt: 3}}>
      {intervalBookings.length === 0 ?
        <NoBookings/> :
        intervalBookings.map((booking, index) =>
          <BookingCard booking={booking} cruise={findInCache(booking.cruiseId, cruises) as Cruise} key={index}/>)}
    </Container>
  )
}

export default Bookings;