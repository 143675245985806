import {Box, Divider, Grid, Typography} from "@mui/material";
import {Cruise} from "../../../../../model/Cruise";
import BookedCruiseInfo from "./bookedCruiseInfo/BookedCruiseInfo";
import BookingPersonData from "./bookingPersonData/BookingPersonData";
import {useContext, useState} from "react";
import {FormMode} from "../../../../../utils/appEnums/FormMode";
import {AppContext} from "../../../appWrapper/AppWrapper";
import {MD_BREAKPOINT} from "../../../guiConstants";
import BookingRequest from "../../../../../service/booking/BookingRequest";
import ParticipantsData from "./participantsData/ParticipantsData";
import RequiredAgreements from "./requiredAgreements/RequiredAgreements";
import AppCheckbox from "../../../appCheckbox/AppCheckbox";
import BookingDataStepValidator from "./BookingDataStepValidator";
import {fonts, mediumText} from "../../../../../../app.style";
import NextPrevStepBtns from "../nextPrevStepBtns/NextPrevStepBtns";


const BookingDataStep = ({cruise, setActiveStep, bookingFormData} : {cruise: Cruise, setActiveStep: Function, bookingFormData: BookingRequest}) => {
  const {windowWidth, translation} = useContext(AppContext);
  const [bookingPersonFormMode, setBookingPersonFormMode] = useState(FormMode.VIEW);
  const [isParticipant, setIsParticipant] = useState(bookingFormData.userIsParticipant);
  const [bookingDataStepValidator] = useState(new BookingDataStepValidator(translation, bookingFormData));
  const [showErrorText, setShowErrorText] = useState(false);
  const [forceBlur, setForceBlur] = useState(false);

  const nextStepHandler = () => {
    setShowErrorText(true);
    setForceBlur(!forceBlur);
    if (bookingDataStepValidator.validateForm()) {
      setActiveStep(1);
      setShowErrorText(false);
    }
  }

  const handleIsParticipantChange = (isParticipant: boolean) => {
    setIsParticipant(isParticipant);
    bookingFormData.setUserIsParticipant(isParticipant);
  }

  return (
    <Grid container spacing={3} justifyContent={"center"}>
      <Grid item xs={12}>
        <BookedCruiseInfo cruise={cruise}/>
      </Grid>
      <Grid item xs={12}>
        <Divider/>
      </Grid>
      <Grid item xs={12} md={4} lg={4}>
        <BookingPersonData formMode={bookingPersonFormMode} setFormMode={setBookingPersonFormMode}/>
        <Box sx={{mt: "17px", display: "flex", justifyContent: "center"}}>
          <AppCheckbox
            checked={isParticipant}
            label={
              <Typography sx={[fonts.spartan14, mediumText]}>
                {translation.form.newBooking.bookingDataStep.bookedByDetails.iAmAlsoPassenger}
              </Typography>}
            setFormData={handleIsParticipantChange}/>
        </Box>
      </Grid>
      {windowWidth < MD_BREAKPOINT &&
      <Grid item xs={12}>
        <Divider variant={"middle"}/>
      </Grid>}
      <Grid item xs={12} sm={12} md={8} lg={8} sx={{pl: windowWidth >= MD_BREAKPOINT ? 3: 0}}>
        <ParticipantsData
          availBunks={cruise.availBunks.length}
          bookingFormData={bookingFormData}
          participantsValidator={bookingDataStepValidator.participantsValidator}
          bookingIsParticipant={isParticipant}
          showErrorText={showErrorText}
          forceBlur={forceBlur}/>
      </Grid>
      <Grid item xs={12}>
        <Divider/>
      </Grid>
      <Grid item xs={12}>
        <RequiredAgreements
          bookingDataStepValidator={bookingDataStepValidator}
          bookingFormData={bookingFormData}
          showErrorText={true}/>
      </Grid>
      <Grid item xs={12} sx={{mb: 5}}>
        <NextPrevStepBtns
         nextLabel={translation.form.newBooking.bookingDataStep.nextPreviousBtns.next}
         nextStepHandler={nextStepHandler}/>
      </Grid>
    </Grid>
  )
}

export default BookingDataStep;