import {Box, Container, Tab, Tabs, Typography} from "@mui/material";
import {centeredBox, fonts, mainColors, semiBoldText} from "../../../../app.style";
import useYearOptions from "./useYearOptions";
import {useEffect, useState} from "react";

interface YearTabsProps {
  setSelectedYear: Function;
}

const YearTabs = ({setSelectedYear}: YearTabsProps) => {
  const [tab, setTab] = useState(0);
  const {yearOptions} = useYearOptions();

  const handleChange = (newValue: number) => {
    setTab(newValue);
    setSelectedYear(yearOptions[newValue]);
  };

  useEffect(() => {
    const currYearIndex = yearOptions.indexOf(new Date().getFullYear());
    setTab(currYearIndex);
  }, [yearOptions, setTab]);

  return (
    <Container>
      <Box sx={centeredBox}>
        <Tabs value={tab} variant={"scrollable"} sx={{borderBottom: 1, borderColor: 'divider'}}>
          {
            yearOptions.map((option, index) => (
              <Tab
                onClick={() => handleChange(index)}
                sx={mainColors.contrast} key={index} label={
                <Typography sx={[fonts.navRunic30, semiBoldText, {my: -2}]}>{option}</Typography>
              }/>
            ))
          }
        </Tabs>
      </Box>
    </Container>
  )
}

export default YearTabs;