import {Button} from "@mui/material";
import LoginFormValidator from "../LoginFormValidator";
import {useContext, useEffect, useState} from "react";
import {AppContext, AppModalContext} from "../../../appWrapper/AppWrapper";
import usePost from "../../../../../service/axios/usePost";
import {apiUrl} from "../../../../../service/urls";
import LoginRequest from "../../../../../service/auth/LoginRequest";
import {AuthContext} from "../../../../../../App";
import SmallSpinner from "../../../appSpinner/SmallSpinner";
import {button, fonts, mainColors, semiBoldText} from "../../../../../../app.style";


interface LoginButtonProps {
  formData: LoginRequest;
  loginFormValidator: LoginFormValidator;
  setShowErrorText: Function;
}

const LoginButton = (props: LoginButtonProps) => {
  const {setJwt} = useContext(AuthContext)
  const {translation} = useContext(AppContext);
  const {setIsModalOpen} = useContext(AppModalContext);
  const [disabled, setDisabled] = useState(false);
  const {data, loading, status, postData: login} = usePost(apiUrl.auth + "/login");

  const tryLogin = () => {
    setDisabled(true);

    if (props.loginFormValidator.validateForm(props.formData)){
      login(props.formData)
      return;
    } else {
      props.setShowErrorText(true);
      console.error("Form is invalid");
    }
  }

  useEffect(() => {
    if (loading) {
      props.setShowErrorText(false);
    } else {
      if (status === 403){
        props.loginFormValidator.setInvalidEmailOrPass();
        props.setShowErrorText(true);
      } else if (status === 200){
        const respData = data as unknown as {token: string};
        setJwt(respData.token);
        setIsModalOpen(false);
      }
    }
  }, [status, loading, data, setJwt, setIsModalOpen, props]);

  useEffect(() => {
    if (disabled && !loading) setDisabled(false);
  }, [loading, disabled]);

  return (
    <Button variant="contained"
            disabled={disabled}
            disableElevation fullWidth
            sx={[fonts.spartan14, semiBoldText, button, {mt: 3, background: mainColors.primary.color}]}
            onClick={() => tryLogin()}>
      {disabled ?
        <SmallSpinner/> :
        translation.form.loginForm.loginBtn
      }
    </Button>
  )
}

export default LoginButton;