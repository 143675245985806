import {useEffect, useState} from "react";
import useGetCruises from "./cruise/useGetCruises";
import useGetSelf from "./appUser/useGetSelf";
import useUpdateSelf from "./appUser/useUpdateSelf";
import usePostCruise from "./cruise/usePostCruise";
import useDeleteCruises from "./cruise/useDeleteCruises";
import usePostDiscount from "./discount/usePostDiscount";
import useDeleteDiscounts from "./discount/useDeleteDiscounts";
import useGetDiscounts from "./discount/useGetDiscounts";
import usePostReview from "./review/usePostReview";
import useDeleteReviews from "./review/useDeleteReviews";
import useGetCruiseBookings from "./booking/useGetCruiseBookings";
import usePostBooking from "./booking/usePostBooking";
import useCancelBooking from "./booking/useCancelBooking";
import useGetMyBookings from "./booking/useGetMyBookings";
import useAdvanceBooking from "./booking/useAdvanceBooking";
import usePostCruiseImg from "./cruise/usePostCruiseImg";


export interface AppLoadersCache {
  getSelfLoading: boolean,
  updateSelfLoading: boolean,
  getMyBookingsLoading: boolean,
  getCruiseBookingsLoading: boolean,
  postBookingLoading: boolean,
  advanceBookingLoading: boolean,
  cancelBookingLoading: boolean,
  getCruisesLoading: boolean,
  postCruiseLoading: boolean,
  postCruiseImgLoading: boolean,
  deleteCruisesLoading: boolean,
  getDiscountsLoading: boolean,
  postDiscountLoading: boolean,
  deleteDiscountsLoading: boolean,
  postReviewLoading: boolean,
  deleteReviewsLoading: boolean,
}

export interface AppCacheApi {
  getSelf: Function,
  updateSelf: Function,
  getMyBookings: Function,
  getCruiseBookings: Function,
  postBooking: Function,
  advanceBooking: Function,
  cancelBooking: Function,
  getCruises: Function,
  postCruise: Function,
  postCruiseImg: Function,
  deleteCruises: Function,
  getDiscounts: Function,
  postDiscount: Function,
  deleteDiscounts: Function,
  postReview: Function,
  deleteReviews: Function,
}

const useAppCacheService = () => {
  const {appUser, setAppUser, getSelf, getSelfLoading} = useGetSelf();
  const {myBookings, setMyBookings, getMyBookings, getMyBookingsLoading} = useGetMyBookings();
  const {cruiseBookings, setCruiseBookings, getCruiseBookings, getCruiseBookingsLoading} = useGetCruiseBookings(myBookings, setMyBookings);
  const {cruises, setCruises, getCruises, getCruisesLoading} = useGetCruises();
  const {discounts, setDiscounts, getDiscounts, getDiscountsLoading} = useGetDiscounts();

  const {updateSelf, updateSelfLoading} = useUpdateSelf(appUser, setAppUser);
  const {postBooking, postBookingLoading} = usePostBooking(myBookings, setMyBookings, cruiseBookings, setCruiseBookings);
  const {advanceBooking, advanceBookingLoading} = useAdvanceBooking(myBookings, cruiseBookings, setMyBookings, setCruiseBookings)
  const {cancelBooking, cancelBookingLoading} = useCancelBooking(myBookings, cruiseBookings, setMyBookings, setCruiseBookings);
  const {postCruise, postCruiseLoading} = usePostCruise(cruises, setCruises);
  const {postCruiseImg, postCruiseImgLoading} = usePostCruiseImg(cruises, setCruises);
  const {deleteCruises, deleteCruisesLoading} = useDeleteCruises(cruises, setCruises);
  const {postDiscount, postDiscountLoading} = usePostDiscount(discounts, setDiscounts);
  const {deleteDiscounts, deleteDiscountsLoading} = useDeleteDiscounts(discounts, setDiscounts);
  const {postReview, postReviewLoading} = usePostReview(cruises, setCruises);
  const {deleteReviews, deleteReviewsLoading} = useDeleteReviews(cruises, setCruises);

  const [appCacheApi] = useState<AppCacheApi>({
    getSelf, getMyBookings, getCruiseBookings, getCruises, getDiscounts,
    updateSelf, postBooking, advanceBooking, postCruise, postCruiseImg, postDiscount, postReview,
    cancelBooking, deleteCruises, deleteDiscounts, deleteReviews
  });

  const [appLoadersCache, setAppLoadersCache] = useState<AppLoadersCache>({
    getSelfLoading, getMyBookingsLoading, getCruiseBookingsLoading,
    getCruisesLoading, getDiscountsLoading, updateSelfLoading,
    postBookingLoading, advanceBookingLoading, cancelBookingLoading, postCruiseLoading, postCruiseImgLoading,
    deleteCruisesLoading, postDiscountLoading, deleteDiscountsLoading,
    postReviewLoading, deleteReviewsLoading
  });

  useEffect(() => {
    setAppLoadersCache({
      getSelfLoading, getMyBookingsLoading, getCruiseBookingsLoading,
      getCruisesLoading, getDiscountsLoading, updateSelfLoading,
      postBookingLoading, advanceBookingLoading, cancelBookingLoading, postCruiseLoading, postCruiseImgLoading,
      deleteCruisesLoading, postDiscountLoading, deleteDiscountsLoading,
      postReviewLoading, deleteReviewsLoading
    })
  }, [
    getSelfLoading, getMyBookingsLoading, getCruiseBookingsLoading,
    getCruisesLoading, getDiscountsLoading, updateSelfLoading,
    postBookingLoading, advanceBookingLoading, cancelBookingLoading, postCruiseLoading, postCruiseImgLoading,
    deleteCruisesLoading, postDiscountLoading, deleteDiscountsLoading,
    postReviewLoading, deleteReviewsLoading
  ]);

  return {
    appUser, setAppUser,
    myBookings,setMyBookings,
    cruiseBookings,setCruiseBookings,
    cruises, setCruises,
    discounts, setDiscounts,
    appLoadersCache, appCacheApi}
}

export default useAppCacheService;