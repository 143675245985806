import {Cruise} from "../../../../../../model/Cruise";
import {Box, Grid} from "@mui/material";
import {SM_BREAKPOINT} from "../../../../../common/guiConstants";
import {useContext} from "react";
import {AppContext} from "../../../../../common/appWrapper/AppWrapper";
import {centeredBox} from "../../../../../../../app.style";
import {
  getCruiseStatus,
  isPriceDisplaySm,
  isPriceDisplayXs,
  isRatingDisplaySm,
  isRatingDisplayXs
} from "../../../../../../utils/misc/helpers";
import {CruiseStatus} from "../../../../../../utils/appEnums/CruiseStatus";
import FromToDateLocation from "../../../../../common/cruiseDetails/fromToDateLocation/FromToDateLocation";
import CruiseRating from "../../../../../common/cruiseDetails/cruiseRating/CruiseRating";
import CruiseTitle from "../../../../../common/cruiseDetails/cruiseTitle/CruiseTitle";
import CruiseDifficultyLabel from "../../../../../common/cruiseDetails/cruiseDifficultyLabel/CruiseDifficultyLabel";
import {Booking} from "../../../../../../model/Booking";
import CruiseDetailsBtn from "./cruiseDetailsBtn/CruiseDetailsBtn";
import BookingPrice from "./bookingPrice/BookingPrice";
import useReviewsLeft from "../../../../../../utils/hooks/useReviewsLeft";
import WriteReviewBtn from "../../../../../common/appButton/writeReviewBtn/WriteReviewBtn";
import ManageBookingBtn from "./manageBookingBtn/ManageBookingBtn";
import BookingStatusInfo from "./bookingStatusInfo/BookingStatusInfo";
import CruiseDescription from "../../../../../common/cruiseDetails/cruiseDescription/CruiseDescription";


const BookingDetails = ({booking, cruise}: {booking: Booking, cruise: Cruise}) => {
  const {windowWidth} = useContext(AppContext);
  const {reviewsLeft} = useReviewsLeft(cruise);

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Box sx={[{display: "flex", justifyContent: "center"}, windowWidth >= SM_BREAKPOINT && {justifyContent: "space-between"}]}>
            <Box>
              <FromToDateLocation
                startLocation={cruise.startLocation}
                finishLocation={cruise.finishLocation}
                startDay={cruise.startDay}
                finishDay={cruise.finishDay}/>
              {getCruiseStatus(cruise) === CruiseStatus.UPCOMING &&
              <BookingStatusInfo booking={booking}/>}
            </Box>
            {isRatingDisplaySm(windowWidth, cruise) && <CruiseRating reviews={cruise.reviews}/>}
            {isPriceDisplaySm(windowWidth, cruise) && <BookingPrice
                booking={booking}
                justify={"flex-end"}/>}
          </Box>
        </Grid>
        {isPriceDisplayXs(windowWidth, cruise) &&
        <Grid item xs={12}>
          <BookingPrice
                booking={booking}
                justify={"center"}/>
        </Grid>}
        <Grid item xs={12} sx={{pt: 1}}>
          <CruiseTitle titlePl={cruise.titlePl} titleEn={cruise.titleEn}/>
        </Grid>
        <Grid item xs={12}>
          <CruiseDifficultyLabel difficulty={cruise.cruiseDifficulty}/>
        </Grid>
      </Grid>
      {getCruiseStatus(cruise) !== CruiseStatus.UPCOMING &&
      <Box sx={{display: "flex", flexDirection: "column", flexGrow: 1, justifyContent: "flex-end", pt: 2, overflow: "hidden"}}>
        <CruiseDescription textPl={cruise.descriptionPl} textEn={cruise.descriptionEn} isClamped={true}/>
      </Box>}
      <Box sx={{display: "flex", flexDirection: "column", justifyContent: "flex-end", flexGrow: 1}}>
        <Grid container
              direction="row"
              justifyContent="center"
              alignItems="flex-end" spacing={1}>
          <Grid item xs={12} sm={6} sx={{mt: 2}}>
            <CruiseDetailsBtn cruiseId={cruise.id}/>
          </Grid>
          {getCruiseStatus(cruise) === CruiseStatus.UPCOMING &&
            <Grid item xs={12} sm={6} sx={{mt: 2}}>
              <ManageBookingBtn cruise={cruise} booking={booking}/>
            </Grid>}
          {reviewsLeft !== 0 && getCruiseStatus(cruise) !== CruiseStatus.UPCOMING &&
            <Grid item xs={12} sm={6} sx={{mt: 2}}>
                <WriteReviewBtn cruiseId={cruise.id}/>
            </Grid>}
        </Grid>
      </Box>
      {isRatingDisplayXs(windowWidth, cruise) &&
      <Box sx={[centeredBox, {mt: 2, justifyContent: "center"}]}>
        <CruiseRating reviews={cruise.reviews}/>
      </Box>}
    </>
  )
}

export default BookingDetails;