import {button, centeredBox, fonts, semiBoldText} from "../../../../../../app.style";
import {Box, Button} from "@mui/material";
import {useContext} from "react";
import {AppContext} from "../../../../common/appWrapper/AppWrapper";
import {useNavigate} from "react-router-dom";

const MyBookingBtn = () => {
  const {translation} = useContext(AppContext);
  const navigate = useNavigate();
  
  const handleClick = () => {
    navigate("/bookings");
  }

  return (
    <Box sx={centeredBox}>
      <Button variant="outlined"
              color={"contrast"}
              disableElevation
              sx={[fonts.spartan14, semiBoldText, button, {display: "flex", flexGrow: 1, maxWidth: "400px", mt: 3}]}
              onClick={() => handleClick()}>
        {translation.page.cruises.cruiseDetails.myBooking}
      </Button>
    </Box>
  )
}

export default MyBookingBtn;