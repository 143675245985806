import {Box, Grid, Typography} from "@mui/material";
import {mainColors, rainbowColors, rainbowColorsDark} from "../../../../../../../app.style";
import {useContext} from "react";
import {AppContext} from "../../../../appWrapper/AppWrapper";
import {badgeTextStyle, colorBadgeStyle} from "./bunkgLegend.style";

const BunksLegend = () => {
  const {translation} = useContext(AppContext);

  return (
    <Grid container spacing={2} justifyContent={"center"} sx={{my:1}}>
      <Grid item xs={4} sm={3} sx={{display: "flex", justifyContent: "flex-end"}}>
        <Box sx={{display: "flex"}}>
          <Box sx={[colorBadgeStyle, {backgroundColor: rainbowColors.red.color}]}>
            <Typography sx={badgeTextStyle}>
              {translation.form.newBooking.bunksSelectionStep.taken}
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={4} sm={3} sx={{display: "flex", justifyContent: "center"}}>
        <Box sx={[colorBadgeStyle, {backgroundColor: mainColors.primary.color}]}>
          <Typography sx={badgeTextStyle}>
            {translation.form.newBooking.bunksSelectionStep.available}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={4} sm={3} sx={{display: "flex", justifyContent: "flex-start"}}>
        <Box sx={{display: "flex"}}>
          <Box sx={[colorBadgeStyle, {backgroundColor: rainbowColorsDark.green.color}]}>
            <Typography sx={badgeTextStyle}>
              {translation.form.newBooking.bunksSelectionStep.selected}
            </Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}

export default BunksLegend;