import {apiUrl} from "../urls";
import {useEffect} from "react";
import usePost from "../axios/usePost";
import {findInCache, updateCache} from "../helpers/cacheUtils";
import {clone} from "../../utils/misc/helpers";
import {Booking} from "../../model/Booking";


const usePostBooking = (myBookings: Booking[], setMyBookings: Function, cruiseBookings: Booking[], setCruiseBookings: Function) => {
  const {
    data,
    setData,
    loading: postBookingLoading,
    postData: postBooking,
  } = usePost(apiUrl.bookings);

  useEffect(() => {
    if (data) {
      const postResponse = data as Booking;
      if (findInCache(postResponse.id, cruiseBookings)){
        const cacheClone = clone(cruiseBookings);
        updateCache([postResponse], cacheClone);
        setCruiseBookings(cacheClone);
        if (findInCache(postResponse.id, myBookings)) {
          const myBookingsClone = clone(myBookings);
          updateCache([postResponse], myBookingsClone);
          setMyBookings(myBookingsClone);
        }
      } else {
        const cacheClone = clone(myBookings);
        updateCache([postResponse], cacheClone);
        setMyBookings(cacheClone);
      }
      setData(null);
    }
  }, [data, myBookings, setMyBookings, setData, cruiseBookings, setCruiseBookings])

  return {postBookingLoading, postBooking}
}

export default usePostBooking;