import {centeredBox, fonts} from "../../../../../../app.style";
import {Container, Grid, Typography} from "@mui/material";
import {useContext} from "react";
import {AppContext} from "../../../../common/appWrapper/AppWrapper";
import Image from "mui-image";


const OfferSection = () => {
  const {translation} = useContext(AppContext);

  return (
    <Container sx={{py: 7}}>
      <Grid container spacing={2} >
        <Grid item xs={12} sx={[centeredBox]}>
          <Typography sx={[fonts.mainRunic30, {textAlign: "center"}]}>
            {translation.page.home.offerSectionTitle}
          </Typography>
        </Grid>
        <Grid item xs={12} sx={[centeredBox]}>
          <Typography sx={[fonts.spartan14, {textAlign: "center", fontStyle: 'italic'}]}>
            {translation.page.home.offerSectionSubtitle}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} sx={{mt: 3}}>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <Typography sx={[fonts.spartan14, {textAlign: "justify"}]}>
                &emsp;&emsp;{translation.page.home.offerSectionDescription1}
              </Typography>
            </Grid>
            <Grid item xs={12} sx={centeredBox}>
              <Image src={"/images/logo_dark.png"} duration={1000} width={64} />
            </Grid>
            <Grid item xs={12}>
              <Typography sx={[fonts.spartan14, {textAlign: "justify"}]}>
                &emsp;&emsp;{translation.page.home.offerSectionDescription2}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} sx={{mt: 3}}>
          <Image src={"/images/crew1.jpg"} duration={1000} width={"100%"} />
        </Grid>
        <Grid item xs={12} md={6} >
          <Image src={"/images/crew2.jpg"} duration={1000} width={"100%"} />
        </Grid>
        <Grid item xs={12} md={6} >
          <Image src={"/images/crew3.jpg"} duration={1000} width={"100%"} />
        </Grid>
      </Grid>
    </Container>
  )
}

export default OfferSection;