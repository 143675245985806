import {button, fonts, semiBoldText} from "../../../../../../../app.style";
import {Button} from "@mui/material";
import {KeyboardBackspace} from '@mui/icons-material';
import SmallSpinner from "../../../../appSpinner/SmallSpinner";

const NextStepBtn = ({label, nextStepHandler, nextDisabled} : {label: string, nextStepHandler: Function, nextDisabled?: boolean}) => {

  return(
    <Button
      variant="contained"
      endIcon={<KeyboardBackspace sx={{transform: "rotate(180deg)"}}/>}
      disabled={nextDisabled}
      disableElevation fullWidth
      onClick={() => nextStepHandler()}
      sx={[fonts.spartan14, semiBoldText, button, {maxWidth: "330px"}]}>
      {nextDisabled ? <SmallSpinner/> : label}
    </Button>
  )
}

export default NextStepBtn;