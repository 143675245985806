import {Box} from "@mui/material";

const YoutubeVideo = ({vidLink}: {vidLink: string}) => {
  return (
    <Box sx={{position: "relative", paddingBottom: "56.25%", height: 0}}>
      <iframe
        style={{position: "absolute", top: 0, left: 0, width: "100%", height: "100%", border: 0}}
        src={`https://www.youtube.com/embed/${vidLink}`}
        title="YouTube video player"
        allowFullScreen></iframe>
    </Box>
  )
}

export default YoutubeVideo;