import {Grid, Typography} from "@mui/material";
import {ReactElement} from "react";
import {fonts, mainColors, semiBoldText} from "../../../../../../../app.style";

interface EquipmentItemProps {
  icon: ReactElement<any, any>;
  label: string;
  description: string;
}

const EquipmentItem = (props: EquipmentItemProps) => {
  return(
    <Grid item xs={12} sm={6} md={4} sx={[{mt: 0}]}>
      <Grid container spacing={0}>
        <Grid item xs={4} sx={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
          {props.icon}
          <Typography sx={[fonts.spartan16, semiBoldText, mainColors.primary, {textAlign: "center", mb: 1}]}>
            {props.label}
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography sx={[fonts.spartan14, {textAlign: "center"}]}>
            {props.description}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default EquipmentItem;