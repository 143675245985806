import {Booking} from "../../../../model/Booking";
import {Box, Divider, Grid} from "@mui/material";
import Summary from "../bookingForm/paymentStep/summary/Summary";
import BookedCruiseInfo from "../bookingForm/bookingDataStep/bookedCruiseInfo/BookedCruiseInfo";
import {Cruise} from "../../../../model/Cruise";
import {useContext, useState} from "react";
import {AppContext, AppFsDrawerContext, AppModalContext} from "../../appWrapper/AppWrapper";
import {AuthContext} from "../../../../../App";
import {AppRole} from "../../../../model/modelEnums/AppRole";
import BookingRequest from "../../../../service/booking/BookingRequest";
import NextPrevStepBtns from "../bookingForm/nextPrevStepBtns/NextPrevStepBtns";
import PaymentEmailSent from "../../appModal/paymentEmailSent/PaymentEmailSent";
import usePost from "../../../../service/axios/usePost";
import {apiUrl} from "../../../../service/urls";


const PaymentStep = ({cruise, booking} : {cruise: Cruise, booking: Booking}) => {
  const {jwt, role} = useContext(AuthContext);
  const {translation, appCacheApi} = useContext(AppContext);
  const [bookingFormData] = useState(BookingRequest.fromExisting(booking));
  const {setIsFsDrawerOpen} = useContext(AppFsDrawerContext);
  const {setModalHeaderText, setModalChildren, setIsModalOpen} = useContext(AppModalContext);
  const {postData: sendEmail} = usePost(apiUrl.sendFinalPaymentInstructions + bookingFormData.id);
  const [nextDisabled, setNextDisabled] = useState(false);

  const successHandler = (data: Booking) => {
    if (role === AppRole.ADMIN) {
      appCacheApi.advanceBooking({}, jwt, bookingFormData.id);
    } else {
      sendEmail({}, jwt);
      setModalHeaderText(translation.modalHeader.paymentInfoSent);
      setModalChildren(<PaymentEmailSent bookingId={bookingFormData.id}/>);
      setIsModalOpen(true);
    }
    appCacheApi.getCruises("/" + bookingFormData.cruiseId);
    setIsFsDrawerOpen(false);
  }

  const errorHandler = (status: number) => {
    console.log(status)
  }

  const nextStepHandler = () => {
    setNextDisabled(true);
    appCacheApi.advanceBooking({}, jwt, bookingFormData.id, successHandler, errorHandler)
  }

  return (
    <Box sx={{ width: '100%', mt: 2 }}>
      <Box sx={{mt: 2}}>
        <Grid container spacing={3} justifyContent={"center"}>
          <Grid item xs={12}>
            <BookedCruiseInfo cruise={cruise}/>
          </Grid>
          <Grid item xs={12}>
            <Divider variant={"middle"}/>
          </Grid>
          {/*<Grid item xs={12}>*/}
          {/*  <PaymentOptions subStepNo={1} totalSteps={1}/>*/}
          {/*</Grid>*/}
          {/*<Grid item xs={12}>*/}
          {/*  <Divider variant={"middle"}/>*/}
          {/*</Grid>*/}
          <Grid item xs={12}>
            <Box sx={{display: "flex", justifyContent: "center"}}>
              <Summary bookingFormData={bookingFormData} isFinal={true}/>
            </Box>
          </Grid>
          <Grid item xs={12} sx={{mb: 5}}>
            <NextPrevStepBtns
              nextLabel={translation.form.newBooking.paymentStep.nextPreviousBtns.next}
              nextStepHandler={nextStepHandler}
              nextDisabled={nextDisabled}/>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default PaymentStep;