import {createTheme} from "@mui/material/styles";
import {
  mainColors,
  objectsSelectorListTypography,
  rainbowColors,
  rainbowColorsDark,
  rainbowColorsLight
} from "./app.style";
import {plPL as pickersPlPL} from "@mui/x-date-pickers/locales";
import {plPL as corePlPL} from "@mui/material/locale";
import {plPL} from "@mui/x-data-grid";

declare module '@mui/material/styles' {
  interface Palette {
    secondaryBright: Palette['primary'];
    contrast: Palette['primary'];
    disabled: Palette['primary'];
    red: Palette['primary'];
    orange: Palette['primary'];
    green: Palette['primary'];
    blue: Palette['primary'];
    violet: Palette['primary'];
  }

  interface PaletteOptions {
    secondaryBright?: PaletteOptions['primary'];
    contrast?: PaletteOptions['primary'];
    disabled?: PaletteOptions['primary'];
    red?: PaletteOptions['primary'];
    orange?: PaletteOptions['primary'];
    green?: PaletteOptions['primary'];
    blue?: PaletteOptions['primary'];
    violet?: PaletteOptions['primary'];
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    secondaryBright: true
    contrast: true;
    red: true;
    orange: true;
    green: true;
    blue: true;
    violet: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    secondaryBright: true
    contrast: true;
    disabled: true;
    red: true;
    orange: true;
    green: true;
    blue: true;
    violet: true;
  }
}

declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    secondaryBright: true
    contrast: true;
    disabled: true;
    red: true;
    orange: true;
    green: true;
    blue: true;
    violet: true;
  }
}

declare module '@mui/material/SvgIcon' {
  interface SvgIconPropsColorOverrides {
    secondaryBright: true
    contrast: true;
    disabled: true;
    red: true;
    orange: true;
    green: true;
    blue: true;
    violet: true;
  }
}

export const theme = createTheme({
    palette: {
      primary: {main: mainColors.primary.color},
      secondary: {main: mainColors.secondary.color},
      secondaryBright: {main: mainColors.secondaryBright.color},
      contrast: {main: mainColors.contrast.color},
      disabled: {main: mainColors.disabled.color},
      red: {main: rainbowColors.red.color, dark: rainbowColorsDark.red.color, light: rainbowColorsLight.red.color},
      orange: {main: rainbowColors.orange.color, dark: rainbowColorsDark.orange.color, light: rainbowColorsLight.orange.color},
      green: {main: rainbowColors.green.color, dark: rainbowColorsDark.green.color, light: rainbowColorsLight.green.color},
      blue: {main: rainbowColors.blue.color, dark: rainbowColorsDark.blue.color, light: rainbowColorsLight.blue.color},
      violet: {main: rainbowColors.violet.color, dark: rainbowColorsDark.violet.color, light: rainbowColorsLight.violet.color},
    },
    components: {
      MuiSelect: {
        styleOverrides: {
          select: {
            minHeight: "0px",
            height: "20px"
          }
        }
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: mainColors.contrast.color,
            "&.Mui-checked": mainColors.primary,
            "&.Mui-disabled": mainColors.disabled
          }
        }
      },
      MuiInput: {
        styleOverrides: {
          input: {
            paddingTop: "4px",
            paddingBottom: "5px",
            minHeight: "0px",
            height: "20px",
            "&.Mui-disabled": {
              WebkitTextFillColor: mainColors.disabled.color
            }
          }
        }
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            "&::before": {
              borderBottom: "1px solid " + mainColors.contrast.color + " !important"
            },
            "&.Mui-disabled::before": {
              borderBottom: "1px solid " + mainColors.disabled.color + " !important",
              WebkitTextFillColor: mainColors.disabled.color + " !important"
            },
          },
          input: {
            "&.Mui-disabled": {
              WebkitTextFillColor: mainColors.disabled.color + " !important"
            },
          }
        }
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor:"inherit !important"
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: mainColors.primary.color + "!important"
            },
            "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
              borderColor: mainColors.disabled.color + " !important"
            },
          },
          notchedOutline: {
            borderColor: mainColors.contrast.color,
          }
        }
      },
      MuiAutocomplete: {
        styleOverrides: {
          inputRoot: objectsSelectorListTypography,
          option: objectsSelectorListTypography
        }
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            fontWeight: 500,
            fontFamily: "Spartan, sans-serif",
            fontSize: "14px",
            lineSpacing: "18px",
            letterSpacing: "-0.42px",
            color: mainColors.contrast.color,
            "&.Mui-disabled": mainColors.disabled,
            "&.Mui-focused": mainColors.primaryBright,
            "&.Mui-error": rainbowColors.red
          }
        }
      },
      MuiBackdrop: {
        styleOverrides: {
          root: {
            backgroundColor: "rgba(0,0,0,0.19)"
          }
        }
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            borderColor: mainColors.grey.color
          }
        }
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            color: mainColors.contrast.color,
            backgroundColor: mainColors.backgroundLighter.color
          }
        }
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: mainColors.contrast
        }
      },
      MuiTablePagination: {
        styleOverrides: {
          actions: mainColors.contrast
        }
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            "&.Mui-disabled": mainColors.disabled
          }
        }
      }
    }
  },
  plPL,
  corePlPL,
  pickersPlPL
);