import {Box, Container, Tab, Tabs, Typography} from "@mui/material";
import {useContext, useState} from "react";
import {AppContext} from "../../../../common/appWrapper/AppWrapper";
import {CruiseDetailOption} from "../../../../../utils/appEnums/CruiseDetailOption";
import {centeredBox, fonts, mainColors, semiBoldText} from "../../../../../../app.style";

interface BookingIntervalTabsProps {
  setSelectedTab: Function;
}

const CruiseDetailsTabs = ({setSelectedTab}: BookingIntervalTabsProps) => {
  const [tab, setTab] = useState(0);
  const {translation} = useContext(AppContext);
  const cruiseDetailsOptions = [CruiseDetailOption.BOOKINGS, CruiseDetailOption.DISCOUNTS]

  const handleChange = (newValue: number) => {
    setTab(newValue);
    setSelectedTab(cruiseDetailsOptions[newValue]);
  };

  return (
    <Container>
      <Box sx={centeredBox}>
        <Tabs value={tab} variant={"scrollable"} sx={{borderBottom: 1, borderColor: 'divider'}}>
          {
            cruiseDetailsOptions.map((option, index) => (
              <Tab
                onClick={() => handleChange(index)}
                sx={[mainColors.contrast]} key={index} label={
                <Typography sx={[fonts.navRunic30, semiBoldText, {my: -2}]}>
                  {translation.page.admin.cruiseDetails.tabs[option]}
                </Typography>
              }/>
            ))
          }
        </Tabs>
      </Box>
    </Container>
  )
}

export default CruiseDetailsTabs;