import {ExpandMore} from "@mui/icons-material";
import {MenuItem, TextField} from "@mui/material";
import {useEffect, useState} from "react";
import {getSimpleOptionByValue} from "../../../utils/misc/helpers";


interface SelectFieldProps {
  selectedValue: any,
  setFormData: Function,
  label: string,
  options: any[],
  disabled?: boolean,
  className?: string,
  required?: boolean
}

const AppSelectField = (props: SelectFieldProps) => {
  const [input, setInput] = useState(getSimpleOptionByValue(props.options, props.selectedValue));

  const getIcon = () => {
    return <ExpandMore color={props.disabled ? "disabled" : "primary"}/>
  }

  useEffect(() => {
    setInput(getSimpleOptionByValue(props.options, props.selectedValue));
  }, [props.disabled, props.options, props.selectedValue])

  return (
    <TextField
      required={props.required}
      className={props.className}
      select
      SelectProps={{IconComponent: getIcon}}
      fullWidth
      disabled={props.disabled}
      label={props.label}
      variant="standard"
      value={input}
      onChange={(e) => {
        setInput(e.target.value)
        props.setFormData(e.target.value)
      }}
    >
      {props.options.map((option, index) => (
        <MenuItem key={index} value={option}>
          {option}
        </MenuItem>
      ))}
    </TextField>
  );
}

export default AppSelectField;