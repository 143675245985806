import {Box, Step, StepLabel, Stepper} from "@mui/material";
import {Cruise} from "../../../../model/Cruise";
import {useContext, useEffect, useState} from "react";
import {AppContext, AppFsDrawerContext, AppModalContext} from "../../appWrapper/AppWrapper";
import BookingDataStep from "./bookingDataStep/BookingDataStep";
import BunksSelectionStep from "./bunksSelectionStep/BunksSelectionStep";
import PaymentStep from "./paymentStep/PaymentStep";
import BookingRequest from "../../../../service/booking/BookingRequest";
import {Booking} from "../../../../model/Booking";
import {clone, strEquals} from "../../../../utils/misc/helpers";
import SimpleTextChild from "../../appModal/confirmModal/simpleTextChild/SimpleTextChild";
import BookingCollisionContent from "../../appModal/bookingCollisionContent/BookingCollisionContent";
import {AuthContext} from "../../../../../App";


const BookingForm = ({cruise, step, booking, onDrawerClose}: {cruise: Cruise, step?: number, booking?: Booking, onDrawerClose?: Function}) => {
  const {jwt} = useContext(AuthContext);
  const {selectedLanguage, translation, appUser, appCacheApi} = useContext(AppContext);
  const {setOnFsDrawerClose, setIsFsDrawerOpen} = useContext(AppFsDrawerContext);
  const {setIsModalOpen, setModalHeaderText, setModalChildren, setModalCloseHandler} = useContext(AppModalContext);
  const {setConfirmModalHeaderText, setConfirmModalChildren, setConfirmModalHandler, setIsConfirmModalOpen} = useContext(AppModalContext);
  const [activeStep, setActiveStep] = useState(step ?? 0);
  const [bookingFormData, setBookingFormData] = useState(
    booking ? BookingRequest.fromExisting(booking) :
    BookingRequest.fromEmpty(cruise, appUser!, selectedLanguage));
  const [initialFormData] = useState(clone(bookingFormData));

  const handleBookingCollision = () => {
    setIsModalOpen(true);
    setModalHeaderText(translation.modalHeader.bookingCollision);
    setModalChildren(<BookingCollisionContent onDrawerClose={onDrawerClose} />)
    setModalCloseHandler(() => () => {
      setIsFsDrawerOpen(false);
      setIsModalOpen(false);
      if (onDrawerClose) onDrawerClose();
      appCacheApi.getCruises();
      appCacheApi.getMyBookings("", jwt);
    })
  }

  useEffect(() => {
    setOnFsDrawerClose(() => () => {
      if (strEquals(bookingFormData, initialFormData)){
        setIsFsDrawerOpen(false);
      } else {
        setConfirmModalHeaderText(translation.modalHeader.closeBookingDetailsConfirm);
        setConfirmModalChildren(<SimpleTextChild text={translation.modalPrompt.allUnsavedDataWillBeLost}/>);
        setConfirmModalHandler(() => () => {
          setIsFsDrawerOpen(false);
        });
        setIsConfirmModalOpen(true);
      }
      if (onDrawerClose) onDrawerClose();
    })
  }, [bookingFormData, initialFormData, onDrawerClose, setConfirmModalChildren, setConfirmModalHandler,
    setConfirmModalHeaderText, setIsConfirmModalOpen, setIsFsDrawerOpen, setOnFsDrawerClose, translation]);

  return (
    <Box sx={{ width: '100%', mt: 2 }}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {Object.values(translation.form.newBooking.steps).map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box sx={{mt: 2}}>
        {activeStep === 0 && <BookingDataStep
            cruise={cruise} setActiveStep={setActiveStep} bookingFormData={bookingFormData}/>}
        {activeStep === 1 && <BunksSelectionStep
            cruise={cruise} setActiveStep={setActiveStep} bookingFormData={bookingFormData}
            setBookingFormData={setBookingFormData} bookingCollisionHandler={handleBookingCollision}/>}
        {activeStep === 2 && <PaymentStep
            cruise={cruise} setActiveStep={setActiveStep} bookingFormData={bookingFormData}
            setBookingFormData={setBookingFormData} bookingCollisionHandler={handleBookingCollision}/>}
      </Box>
    </Box>
  )
}

export default BookingForm;