import {IconButton} from "@mui/material";
import {EditNote} from '@mui/icons-material';

const EditBtn = ({clickHandler} : {clickHandler : Function}) => {
  return (
    <IconButton onClick={() => clickHandler()}>
      <EditNote/>
    </IconButton>
  )
}

export default EditBtn;