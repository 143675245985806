import {AppUser} from "../../model/AppUser";
import {Gender} from "../../model/modelEnums/Gender";
import {SimpleOptionProps} from "../../components/common/appSelectField/appSelectField/simpleOption/SimpleOption";
import {Participant} from "../../model/Participant";

export default class ParticipantRequest {
  id = "";
  name = "";
  surname = "";
  gender = Gender.FEMALE;
  born = "1990-01-01"

  public static fromEmpty() {
    return new ParticipantRequest();
  }

  public static fromUser(appUser: AppUser) {
    const participantFormData = new ParticipantRequest();
    participantFormData.name = appUser.name;
    participantFormData.surname = appUser.surname;
    participantFormData.gender = appUser.gender;
    participantFormData.born = appUser.born;
    return participantFormData;
  }

  public static fromExisting(participant: Participant) {
    const participantFormData = new ParticipantRequest();
    participantFormData.id = participant.id;
    participantFormData.name = participant.name;
    participantFormData.surname = participant.surname;
    participantFormData.gender = participant.gender;
    participantFormData.born = participant.born;
    return participantFormData;
  }

  setName = (input: string) => {
    this.name = input;
  }

  setSurname = (input: string) => {
    this.surname = input;
  }

  setGender = (input: { props: SimpleOptionProps }) => {
    this.gender = input.props.value as Gender;
  }

  setBorn = (input: string) => {
    this.born = input;
  }
}