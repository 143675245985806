import useAppCacheService, {AppCacheApi, AppLoadersCache} from "../../../service/useAppCacheService";
import React, {createContext, PropsWithChildren, ReactNode, useContext, useEffect, useState} from "react";
import {AuthContext} from "../../../../App";
import NavMenu from "./navMenu/NavMenu";
import useLocalStorage from "../../../utils/hooks/useLocalStorage";
import {Language} from "../../../../language/Language";
import {translationPl} from "../../../../language/translation/pl";
import {Translation, translations} from "../../../../language/translation/translations";
import {Box} from "@mui/material";
import AppModal from "../appModal/AppModal";
import {AppUser} from "../../../model/AppUser";
import {Booking} from "../../../model/Booking";
import {Cruise} from "../../../model/Cruise";
import {Discount} from "../../../model/Discount";
import ConfirmModal from "../appModal/ConfirmModal";
import FsDrawer from "./fsDrawer/FsDrawer";


interface AppContextType {
  appUser: AppUser | undefined;
  setAppUser: Function;
  myBookings: Booking[];
  setMyBookings: Function;
  cruiseBookings: Booking[];
  setCruiseBookings: Function;
  cruises: Cruise[];
  setCruises: Function;
  discounts: Discount[];
  setDiscounts: Function;
  appLoadersCache: AppLoadersCache;
  appCacheApi: AppCacheApi;
  windowWidth: number;
  footerHeight: number;
  setFooterHeight: Function;
  setMenuHeight: Function;
  selectedLanguage: Language;
  setSelectedLanguage: Function;
  translation: Translation;
}

interface AppModalContextType {
  setIsModalOpen: Function;
  setModalHeaderText: Function;
  setModalChildren: Function;
  setModalCloseHandler: Function;
  setIsConfirmModalOpen: Function;
  setConfirmModalHeaderText: Function;
  setConfirmModalChildren: Function;
  setConfirmModalHandler: Function;
}

interface AppFsDrawerContextType {
  setIsFsDrawerOpen: Function;
  setFsDrawerChildren: Function;
  setOnFsDrawerClose: Function;
}

export const AppContext = createContext<AppContextType>(
  {} as AppContextType
)

export const AppModalContext = createContext<AppModalContextType>(
  {} as AppModalContextType
)

export const AppFsDrawerContext = createContext<AppFsDrawerContextType>(
  {} as AppFsDrawerContextType
)

const AppWrapper = (props: PropsWithChildren) => {
  const {jwt, role} = useContext(AuthContext);
  const [selectedLanguage, setSelectedLanguage] = useLocalStorage("PL", "language");
  const [translation, setTranslation] = useState(translationPl);
  const {
    appUser, setAppUser,
    myBookings, setMyBookings, cruiseBookings, setCruiseBookings,
    cruises, setCruises, discounts, setDiscounts,
    appLoadersCache, appCacheApi} = useAppCacheService();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [footerHeight, setFooterHeight] = useState(0);
  const [menuHeight, setMenuHeight] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalHeaderText, setModalHeaderText] = useState("");
  const [modalChildren, setModalChildren] = useState(<></>);
  const [modalCloseHandler, setModalCloseHandler] = useState<Function>();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [confirmModalHeaderText, setConfirmModalHeaderText] = useState("");
  const [confirmModalChildren, setConfirmModalChildren] = useState(<></>)
  const [confirmModalHandler, setConfirmModalHandler] = useState<Function>();
  const [isFsDrawerOpen, setIsFsDrawerOpen] = useState(false);
  const [fsDrawerChildren, setFsDrawerChildren] = useState<ReactNode>();
  const [onFsDrawerClose, setOnFsDrawerClose] = useState<Function>(() => () => {});


  useEffect(() => {
    const handleResizeWindow = () => {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  useEffect(() => {
    if (role !== undefined && jwt !== "") {
      appCacheApi.getSelf("", jwt);
    }
  }, [appCacheApi, role, jwt]);

  useEffect(() => {
    if (appUser) setSelectedLanguage(appUser.lang);
  }, [appUser, setSelectedLanguage]);

  useEffect(() => {
    if (appUser && myBookings.length === 0){
      appCacheApi.getMyBookings("", jwt);
    }
  }, [jwt, appUser, appCacheApi, myBookings.length]);

  useEffect(() => {
    if (cruises.length === 0){
      appCacheApi.getCruises();
    }
  }, [appCacheApi, cruises]);

  useEffect(() => {
    setTranslation(translations[selectedLanguage as keyof typeof translations])
  }, [selectedLanguage]);

  return (
    <AppContext.Provider value={{
      appUser, setAppUser, myBookings, setMyBookings, cruiseBookings, setCruiseBookings,
      cruises, setCruises, discounts, setDiscounts, appLoadersCache, appCacheApi,
      windowWidth, footerHeight, setFooterHeight, setMenuHeight,
      selectedLanguage, setSelectedLanguage, translation
    }}>
      <AppModalContext.Provider value={{
        setIsModalOpen, setModalHeaderText, setModalChildren, setModalCloseHandler,
        setIsConfirmModalOpen, setConfirmModalHeaderText, setConfirmModalChildren, setConfirmModalHandler
      }}>
        <AppFsDrawerContext.Provider value={{setIsFsDrawerOpen, setFsDrawerChildren, setOnFsDrawerClose}}>
          <Box sx={{height: menuHeight}}/>
          {props.children}
          <Box sx={{height: footerHeight}}/>
          <NavMenu/>
          <AppModal
            isOpen={isModalOpen}
            headerText={modalHeaderText}
            setIsOpen={setIsModalOpen}
            closeHandler={modalCloseHandler}>
            {modalChildren}
          </AppModal>
          <ConfirmModal
            confirmHandler={confirmModalHandler}
            headerText={confirmModalHeaderText}
            isOpen={isConfirmModalOpen}
            setIsOpen={setIsConfirmModalOpen}
            setChildren={setConfirmModalChildren}>
            {confirmModalChildren}
          </ConfirmModal>
          <FsDrawer isOpen={isFsDrawerOpen} onFsDrawerClose={onFsDrawerClose}>
            {fsDrawerChildren}
          </FsDrawer>
        </AppFsDrawerContext.Provider>
      </AppModalContext.Provider>
    </AppContext.Provider>
  )
}

export default AppWrapper;