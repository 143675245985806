import AbstractValidator from "./AbstractValidator";
import {Translation} from "../../../language/translation/translations";


export default class EmailValidator implements AbstractValidator {
  private _translation: Translation;
  private _isValid= false;
  private _errorText = ".";
  private readonly _maxChars: number;


  constructor(translation: Translation, maxChars: number) {
    this._translation = translation;
    this._maxChars = maxChars;
  }

  validate(input: string) {
    input = input.toLowerCase().trim();
    this._isValid = false;
    if (input.length === 0) {
      this._errorText = this._translation.formErrors.fieldRequired;
    } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(input)) {
      this._errorText = this._translation.formErrors.badEmail;
    } else if (input.length > this._maxChars) {
      this._errorText = this._translation.formErrors.maxAllowed
        + this._maxChars
        + this._translation.formErrors.maxChars;
    } else {
      this._errorText = ".";
      this._isValid = true;
    }
  }

  public set translation(translation: Translation) {
    this._translation = translation;
  }

  public get isValid(){
    return this._isValid;
  }

  public set isValid(isValid: boolean){
    this._isValid = isValid;
  }

  public get errorText(){
    return this._errorText;
  }

  public set errorText(text: string){
    this._errorText = text;
  }
}