import {Translation} from "../../../../../language/translation/translations";
import EmailValidator from "../../../../utils/validators/EmailValidator";
import {validatorSettings} from "../../../../utils/validators/validatorSettings";
import PassResetRequest from "../../../../service/auth/PassResetRequest";


export default class PassResetFormValidator {
  translation: Translation;
  emailValidator: EmailValidator;

  constructor(translation: Translation) {
    this.translation = translation;
    this.emailValidator = new EmailValidator(translation, validatorSettings.emailMaxChars);
  }

  validateForm = (passResetRequest: PassResetRequest) => {
    this.emailValidator.validate(passResetRequest.email);

    return this.emailValidator.isValid;
  }
}