import {mainColors} from "../../../../../../app.style";

export const desktopMenuAppBarStyle = {
  backgroundColor: mainColors.menu.color,
  boxShadow: "0px 10px 20px #00000018"
}

export const desktopMenuMainBoxStyle = {
  display: "flex",
  flexGrow: 1,
  alignItems: "center",
  justifyContent: "space-between",
  py: 1,
  mx: 3
}