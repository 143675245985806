import {centeredBox, fonts, mainColors} from "../../../../../../app.style";
import {Container, Grid, Paper, Typography} from "@mui/material";
import {useContext} from "react";
import {AppContext} from "../../../../common/appWrapper/AppWrapper";
import Image from "mui-image";


const AboutMeSection = () => {
  const {translation} = useContext(AppContext);

  return (
    <Paper elevation={5} sx={{backgroundColor: mainColors.white.color}}>
      <Container sx={{py: 5}}>
        <Grid container spacing={2}>
          <Grid item xs={12} sx={[centeredBox]}>
            <Typography sx={[fonts.mainRunic30, {textAlign: "center"}]}>
              {translation.page.home.aboutMeTitle}
            </Typography>
          </Grid>
          <Grid item xs={12} sx={[centeredBox]}>
            <Typography sx={[fonts.spartan14, {textAlign: "center", fontStyle: 'italic'}]}>
              {translation.page.home.aboutMeSubtitle}
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{mt: 3}}>
            <Image src={"/images/me_primosten.jpg"} duration={1000} width={"100%"} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Image src={"/images/me_boat.jpg"} duration={1000} width={"100%"} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={5}>
              <Grid item xs={12}>
                <Typography sx={[fonts.spartan14, {textAlign: "justify"}]}>
                  &emsp;&emsp;{translation.page.home.aboutMeDescription1}
                </Typography>
              </Grid>
              <Grid item xs={12} sx={centeredBox}>
                <Image src={"/images/logo_dark.png"} duration={1000} width={64} />
              </Grid>
              <Grid item xs={12}>
                <Typography sx={[fonts.spartan14, {textAlign: "justify"}]}>
                  &emsp;&emsp;{translation.page.home.aboutMeDescription2}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Image src={"/images/me_zadar.jpg"} duration={1000} width={"100%"} />
          </Grid>
        </Grid>
      </Container>
    </Paper>
  )
}

export default AboutMeSection;