import {Discount} from "../../model/Discount";

export default class DiscountRequest {
  id: string = "";
  cruiseId: string = "";
  code: string = "";
  discountAmt: number = 0;

  public static fromEmpty(cruiseId: string) {
    const discountFormData = new DiscountRequest();
    discountFormData.cruiseId = cruiseId;
    return discountFormData;
  }

  public static fromExisting(discount: Discount) {
    const discountFormData = new DiscountRequest();
    discountFormData.id = discount.id;
    discountFormData.cruiseId = discount.cruiseId;
    discountFormData.code = discount.code;
    discountFormData.discountAmt = discount.discountAmt;
    return discountFormData;
  }

  setCruiseId = (input: string) => {
    this.cruiseId = input;
  }

  setCode = (input: string) => {
    this.code = input;
  }

  setDiscountAmt = (input: number) => {
    this.discountAmt = parseFloat((input/100).toFixed(2));
  }
}