import {Box, Typography} from "@mui/material";
import {centeredBox, fonts} from "../../../../../app.style";
import AppTextField from "../../appTextField/AppTextField";
import {TextFieldType} from "../../../../utils/appEnums/TextFieldType";
import PasswordAdornment from "../../appTextField/passwordAdornment/PasswordAdornment";
import PasswordRequirements from "../registerForm/passwordRequirements/PasswordRequirements";
import {useContext, useState} from "react";
import {AppContext} from "../../appWrapper/AppWrapper";
import ChangePassButton from "./changePassButton/ChangePassButton";
import usePassSetupFormValidator from "./usePassSetupFormValidator";
import PassSetupRequest from "../../../../service/auth/PassSetupRequest";
import {TokenReaderContext} from "../../TokenReader";


interface PassSetupFormProps {
  setPassChangeStatus: Function;
}

const PassSetupForm = ({setPassChangeStatus}: PassSetupFormProps) => {
  const {translation} = useContext(AppContext);
  const {token} = useContext(TokenReaderContext);
  const [showPassword, setShowPassword] = useState(false);
  const [passSetupFormData] = useState(new PassSetupRequest(token as string));
  const [showErrorText, setShowErrorText] = useState(false);
  const {passSetupFormValidator, validatePassSetupForm,
    passContainsSmall, passContainsCapital, passHasMinRequiredChars
  } = usePassSetupFormValidator(translation, passSetupFormData);

  return (
    <Box sx={{maxWidth: "368px"}}>
      <Box sx={[centeredBox, {mt: 5, mb: 2}]}>
        <Typography sx={[fonts.spartan20, {textAlign: "center"}]}>
          {translation.form.setPassword.title}
        </Typography>
      </Box>
      <AppTextField
        formData={""}
        setFormData={passSetupFormData.setPassword}
        required={true}
        type={showPassword ? TextFieldType.TEXT : TextFieldType.PASSWORD}
        label={translation.form.registerForm.password}
        showErrorText={showErrorText}
        passwordAdornment={<PasswordAdornment setShowPassword={setShowPassword} showPassword={showPassword}/>}
        validator={passSetupFormValidator.passwordValidator}
        formValidatorCallback={validatePassSetupForm}/>
      <AppTextField
        formData={""}
        setFormData={passSetupFormData.setRepeatPassword}
        required={true}
        type={showPassword ? TextFieldType.TEXT : TextFieldType.PASSWORD}
        label={translation.form.registerForm.repeatPassword}
        showErrorText={showErrorText}
        validator={passSetupFormValidator.passwordRepeatValidator}
        formValidatorCallback={validatePassSetupForm}/>
      <PasswordRequirements
        passContainsSmall={passContainsSmall}
        passContainsCapital={passContainsCapital}
        passHasMinRequiredChars={passHasMinRequiredChars}/>
      <ChangePassButton
        formData={passSetupFormData}
        passSetupFormValidator={passSetupFormValidator}
        setShowErrorText={setShowErrorText}
        token={token as string}
        setPassChangeStatus={setPassChangeStatus}/>
    </Box>
  )
}

export default PassSetupForm;