import {apiUrl} from "../urls";
import {useEffect} from "react";
import useDelete from "../axios/useDelete";
import {deleteFromCache} from "../helpers/cacheUtils";
import {clone} from "../../utils/misc/helpers";
import {Discount} from "../../model/Discount";


const useDeleteDiscounts = (discounts: Discount[], setDiscounts: Function) => {
  const {
    data,
    setData,
    loading: deleteDiscountsLoading,
    deleteData: deleteDiscounts
  } = useDelete(apiUrl.discounts);

  useEffect(() => {
    if (data) {
      const deleteResponse = data as Discount;
      const cacheClone = clone(discounts);
      deleteFromCache(deleteResponse, cacheClone);
      setDiscounts(cacheClone);
      setData(null);
    }
  }, [data, discounts, setDiscounts, setData])

  return {deleteDiscountsLoading, deleteDiscounts}
}

export default useDeleteDiscounts;