import {Box, Container, Typography} from "@mui/material";
import {boldText, centeredBox, fonts, rainbowColors} from "../../../app.style";

const ExpiredToken = ({text}: {text: string}) => {

  return (
    <Container sx={{mb: 3}}>
      <Box sx={[centeredBox, {mt: 5}]}>
        <Typography sx={[fonts.spartan20, boldText, rainbowColors.red, {textAlign: "center"}]}>
          {text}
        </Typography>
      </Box>
    </Container>
  )
}

export default ExpiredToken;