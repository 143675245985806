import {Box, Grid} from "@mui/material";
import NextStepBtn from "./nextStepBtn/NextStepBtn";
import PrevStepBtn from "./prevStepBtn/PrevStepBtn";
import {useContext} from "react";
import {AppContext} from "../../../appWrapper/AppWrapper";
import {SM_BREAKPOINT} from "../../../guiConstants";


interface NextPrevStepBtnsProps {
  nextLabel: string;
  prevLabel?: string;
  nextStepHandler: Function;
  prevStepHandler?: Function;
  nextDisabled?: boolean;
}

const NextPrevStepBtns = ({nextLabel, prevLabel, nextStepHandler, prevStepHandler, nextDisabled} : NextPrevStepBtnsProps) => {
  const {windowWidth} = useContext(AppContext);

  return(
    <Grid container spacing={2} justifyContent={"center"}>
      <Grid item xs={12} sm={6}>
        {prevLabel !== undefined &&
            <Box sx={{display: "flex", justifyContent: windowWidth >= SM_BREAKPOINT ? "flex-start" : "center"}}>
              <PrevStepBtn label={prevLabel} prevStepHandler={prevStepHandler!}/>
            </Box>}
      </Grid>
      <Grid item xs={12} sm={6}>
        <Box sx={{display: "flex", justifyContent: prevLabel === "" || windowWidth < SM_BREAKPOINT ? "center" : "flex-end"}}>
          <NextStepBtn label={nextLabel} nextStepHandler={nextStepHandler} nextDisabled={nextDisabled}/>
        </Box>
      </Grid>
    </Grid>
  )
}

export default NextPrevStepBtns;