import {FormMode} from "../../../../../../utils/appEnums/FormMode";
import {useContext, useEffect, useState} from "react";
import {AppContext, AppModalContext} from "../../../../appWrapper/AppWrapper";
import AppUserRequest from "../../../../../../service/appUser/AppUserRequest";
import useSimpleOpts from "../../../../appSelectField/appSelectField/simpleOption/useSimpleOpts";
import BookingPersonFormValidator from "./BookingPersonFormValidator";
import {Box, Grid, Typography} from "@mui/material";
import AppTextField from "../../../../appTextField/AppTextField";
import {TextFieldType} from "../../../../../../utils/appEnums/TextFieldType";
import AppSelectField from "../../../../appSelectField/AppSelectField";
import AppDatePicker from "../../../../appTimePicker/AppDatePicker";
import EditBtn from "./editBtn/EditBtn";
import {fonts, mainColors} from "../../../../../../../app.style";
import {MD_BREAKPOINT, SM_BREAKPOINT} from "../../../../guiConstants";
import CancelButton from "../../../../appButton/cancelButton/CancelButton";
import SaveButton from "../../../../appButton/saveButton/SaveButton";
import {AuthContext} from "../../../../../../../App";
import {clone, strEquals} from "../../../../../../utils/misc/helpers";
import SubStep from "../../subStep/SubStep";


const BookingPersonData = ({formMode, setFormMode} : {formMode: FormMode, setFormMode: Function}) => {
  const {jwt} = useContext(AuthContext);
  const {windowWidth, translation, appUser, appCacheApi, appLoadersCache} = useContext(AppContext);
  const {setConfirmModalHeaderText, setConfirmModalHandler, setIsConfirmModalOpen} = useContext(AppModalContext)
  const [bookingPersonFormData, setBookinPersonFormData] = useState(AppUserRequest.fromExisting(appUser!));
  const [initialFormData, setInitialFormData] = useState(clone(bookingPersonFormData));
  const [showErrorText, setShowErrorText] = useState(false);
  const {simpleOpts: genderOpts} = useSimpleOpts(translation.form.registerForm.gender.options, false);
  const [bookingPersonFormValidator] = useState(new BookingPersonFormValidator(translation, bookingPersonFormData));
  const [formDisabled, setFormDisabled] = useState(true);
  const [saveBtnDisabled, setSaveBtnDisabled] = useState(false);

  const handleSave = () => {
    setSaveBtnDisabled(true);
    if (bookingPersonFormValidator.validateForm()){
      appCacheApi.updateSelf(bookingPersonFormData, jwt)
    } else {
      setShowErrorText(true);
      console.error("Form is invalid");
    }
  }

  const handleCancel = () => {
    if (strEquals(bookingPersonFormData, initialFormData)){
      setFormMode(FormMode.VIEW);
    } else {
      setConfirmModalHeaderText(translation.modalHeader.discardChanges);
      setConfirmModalHandler(() => () => {
        setBookinPersonFormData(AppUserRequest.fromExisting(appUser!));
        setFormMode(FormMode.VIEW);
      });
      setIsConfirmModalOpen(true);
    }
  }

  useEffect(() => {
    if (appLoadersCache.updateSelfLoading) {
      setShowErrorText(false);
    }
  }, [appLoadersCache.updateSelfLoading]);

  useEffect(() => {
    if (saveBtnDisabled && !appLoadersCache.updateSelfLoading) {
      setSaveBtnDisabled(false);
      setFormMode(FormMode.VIEW);
      setInitialFormData(clone(bookingPersonFormData));
    }
  }, [appLoadersCache.updateSelfLoading, bookingPersonFormData, saveBtnDisabled, setFormMode]);
  
  useEffect(() => {
    if (formMode === FormMode.VIEW){
      setFormDisabled(true);
    } else {
      setFormDisabled(false);
    }
  }, [formMode]);

  return (
    <>
      <Box sx={{display: "flex"}}>
        <SubStep subStepNo={1} totalSteps={3}/>
        <Typography sx={[fonts.spartan16, mainColors.contrast, {mb: 2}]}>
          {translation.form.newBooking.bookingDataStep.bookedByDetails.title}
        </Typography>
      </Box>
      <Grid container >
        <Grid item xs={12} sm={6} md={12} sx={{pr: windowWidth < MD_BREAKPOINT && windowWidth >= SM_BREAKPOINT ? 1 : 0}}>
          <AppTextField
            formData={appUser?.email}
            setFormData={() => {}}
            disabled={true}
            type={TextFieldType.TEXT}
            label={translation.form.loginForm.emailAddress}/>
          <Box sx={{height: "26px"}}/>
        </Grid>
        <Grid item xs={6} sm={3} md={6} sx={{pr: 1}}>
          <AppSelectField
            label={translation.form.registerForm.gender.title}
            options={genderOpts}
            selectedValue={bookingPersonFormData.gender}
            required={true}
            disabled={formDisabled}
            setFormData={bookingPersonFormData.setGender}/>
          <Box sx={{mt: "26px"}}/>
        </Grid>
        <Grid item xs={6} sm={3} md={6}>
          <AppDatePicker
            formData={bookingPersonFormData.born}
            setFormData={bookingPersonFormData.setBorn}
            label={translation.form.registerForm.born}
            showErrorText={showErrorText}
            disabled={formDisabled}
            disableFuture={true}
            validator={bookingPersonFormValidator.bornValidator}/>
        </Grid>
        <Grid item xs={12} sm={4} md={12} sx={{pr: windowWidth < MD_BREAKPOINT && windowWidth >= SM_BREAKPOINT ? 1 : 0}}>
          <AppTextField
            formData={bookingPersonFormData.name}
            setFormData={bookingPersonFormData.setName}
            required={true}
            disabled={formDisabled}
            type={TextFieldType.TEXT}
            label={translation.form.registerForm.name}
            showErrorText={showErrorText}
            validator={bookingPersonFormValidator.nameValidator}/>
        </Grid>
        <Grid item xs={12} sm={4} md={12} sx={{pr: windowWidth < MD_BREAKPOINT && windowWidth >= SM_BREAKPOINT ? 1 : 0}}>
          <AppTextField
            formData={bookingPersonFormData.surname}
            setFormData={bookingPersonFormData.setSurname}
            required={true}
            disabled={formDisabled}
            type={TextFieldType.TEXT}
            label={translation.form.registerForm.surname}
            showErrorText={showErrorText}
            validator={bookingPersonFormValidator.surnameValidator}/>
        </Grid>
        <Grid item xs={12} sm={4} md={12}>
          <AppTextField
            formData={bookingPersonFormData.phone}
            setFormData={bookingPersonFormData.setPhone}
            type={TextFieldType.PHONE}
            label={translation.form.registerForm.phone}
            showErrorText={showErrorText}
            disabled={formDisabled}
            validator={bookingPersonFormValidator.phoneValidator}/>
        </Grid>
      </Grid>
      {formMode === FormMode.VIEW ?
        <EditBtn setFormMode={setFormMode}/> :
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <CancelButton
              handleCancel={handleCancel}/>
          </Grid>
          <Grid item xs={12} sm={6}>
            <SaveButton
              disabled={saveBtnDisabled}
              handleSave={handleSave}/>
          </Grid>
        </Grid>
      }
    </>
  )
}

export default BookingPersonData;