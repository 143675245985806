import {Link, Typography} from "@mui/material";
import {Booking} from "../../../../model/Booking";
import {Cruise} from "../../../../model/Cruise";
import {useContext, useEffect, useState} from "react";
import useGet from "../../../../service/axios/useGet";
import {apiUrl} from "../../../../service/urls";
import {AuthContext} from "../../../../../App";
import SmallSpinner from "../../appSpinner/SmallSpinner";
import {fonts, mediumText, rainbowColors, semiBoldText} from "../../../../../app.style";
import {AppContext} from "../../appWrapper/AppWrapper";
import {getDaysLeft, getFormatPrice} from "../../../../utils/misc/helpers";
import {AppCurrency} from "../../../../model/modelEnums/AppCurrency";
import PolicyModal from "../PolicyModal";

interface CancelBookingInfoProps {
  booking: Booking;
  cruise: Cruise;
}

const CancelBookingInfo = ({booking, cruise} : CancelBookingInfoProps) => {
  const {jwt} = useContext(AuthContext);
  const {translation} = useContext(AppContext);
  const {data, loading, getData} = useGet(apiUrl.getReturnAmt);
  const [daysRemaining] = useState(getDaysLeft(cruise.startDay));
  const [isPolicyOpen, setIsPolicyOpen] = useState(false);
  
  useEffect(() => {
    getData(booking.id, jwt);
  }, [booking, getData, jwt]);

  return (
    <>
    {loading ?
      <SmallSpinner/> :
      <>
        <Typography sx={[fonts.spartan14]}>
          {translation.cancelBookingInfo.cruiseWillBeginIn}&nbsp;
          {daysRemaining}&nbsp;
          {translation.cancelBookingInfo.days}
        </Typography>
        <Typography sx={[fonts.spartan14, mediumText, {mt: 1}]}>
          {daysRemaining < 28 ?
            translation.cancelBookingInfo.lateCancelFeeApplies :
            translation.cancelBookingInfo.lateCancelFeeDoesNotApply
          }
          &nbsp;
          <Link
            sx={[fonts.spartan14, mediumText]}
            component="button"
            variant="body2"
            onClick={() => setIsPolicyOpen(true)}>
            {translation.cancelBookingInfo.policy}&nbsp;
          </Link>
          {translation.cancelBookingInfo.bookingAndCancellation}
        </Typography>
        <Typography sx={[fonts.spartan14, mediumText, {textAlign: "center", mt: 3}]}>
          {translation.cancelBookingInfo.returnAmt}
        </Typography>
        <Typography sx={[fonts.spartan18, semiBoldText, {textAlign: "center", mb: 3}]}>
          {getFormatPrice(parseFloat(data ?? "0") )}
          {booking.currency === AppCurrency.PLN ? "zł" : "€"}
        </Typography>
        <Typography sx={[fonts.spartan14, rainbowColors.red, mediumText, {textAlign: "center"}]}>
          {translation.cancelBookingInfo.finalWarning}
        </Typography>
        <PolicyModal isOpen={isPolicyOpen} setIsOpen={setIsPolicyOpen}/>
      </>
    }
    </>
  )
}

export default CancelBookingInfo;