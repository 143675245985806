import {Box, Typography} from "@mui/material";
import {boldText, fonts, mainColors, mediumText} from "../../../../../../app.style";
import {useContext} from "react";
import {AppContext} from "../../../appWrapper/AppWrapper";


interface PasswordRequirementsProps {
  passContainsSmall: boolean;
  passContainsCapital: boolean;
  passHasMinRequiredChars: boolean;
}

const PasswordRequirements = (props: PasswordRequirementsProps) => {
  const {translation, windowWidth} = useContext(AppContext);

  return (
    <>
      <Typography sx={[fonts.spartan12, mediumText, mainColors.grey]}>
        {translation.form.registerForm.passReq.header}
      </Typography>
      <Box sx={{ display: windowWidth >= 510 ? "flex" : "block"}}>
        <Box sx={[fonts.spartan12, boldText, {mr: 2},
          (props.passHasMinRequiredChars) ? mainColors.primary : mainColors.grey]}>
          {"\u2022"} {translation.form.registerForm.passReq.leastSigns}{" "}
        </Box>
        <Box sx={[fonts.spartan12, boldText, {mr: 2},
          (props.passContainsCapital) ? mainColors.primary : mainColors.grey]}>
          {"\u2022"} {translation.form.registerForm.passReq.capitalLetter}{" "}
        </Box>
        <Box sx={[fonts.spartan12, boldText,
          (props.passContainsSmall) ? mainColors.primary : mainColors.grey]}>
        {"\u2022"} {translation.form.registerForm.passReq.smallLetter}{" "}
        </Box>
      </Box>
    </>
  )
}

export default  PasswordRequirements;