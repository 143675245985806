import AbstractValidator from "./AbstractValidator";
import RegisterRequest from "../../service/auth/RegisterRequest";
import {Translation} from "../../../language/translation/translations";
import PassSetupRequest from "../../service/auth/PassSetupRequest";


export default class PasswordRepeatValidator implements AbstractValidator {
  private _translation: Translation;
  private _isValid= false;
  private _errorText = ".";
  private readonly _registrationFormData: RegisterRequest | PassSetupRequest;

  constructor(translation: Translation, registrationFormData: RegisterRequest | PassSetupRequest) {
    this._translation = translation;
    this._registrationFormData = registrationFormData;
  }

  validate(input: string) {
    this._isValid = false;
    if (input !== this._registrationFormData.password) {
      this._errorText = this._translation.formErrors.mismatchedPassword;
    } else {
      this._errorText = "."
      this._isValid = true;
    }
  }

  public set translation(translation: Translation) {
    this._translation = translation;
  }

  public get isValid(){
    return this._isValid;
  }

  public get errorText(){
    return this._errorText;
  }
}