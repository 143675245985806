import {Button} from "@mui/material";
import {useContext, useEffect, useState} from "react";
import {AppContext, AppModalContext} from "../../../appWrapper/AppWrapper";
import usePost from "../../../../../service/axios/usePost";
import {apiUrl} from "../../../../../service/urls";
import SmallSpinner from "../../../appSpinner/SmallSpinner";
import {button, fonts, mainColors, semiBoldText} from "../../../../../../app.style";
import PassSetupRequest from "../../../../../service/auth/PassSetupRequest";
import PassSetupFormValidator from "../PassSetupFormValidator";
import {PassChange} from "../../../../../utils/appEnums/PassChange";


interface ChangePassButtonProps {
  formData: PassSetupRequest;
  passSetupFormValidator: PassSetupFormValidator;
  setShowErrorText: Function;
  setPassChangeStatus: Function;
  token: string;
}

const ChangePassButton = (props: ChangePassButtonProps) => {
  const {translation} = useContext(AppContext);
  const {setModalHeaderText, setModalChildren} = useContext(AppModalContext);
  const [disabled, setDisabled] = useState(false);
  const {data, loading, status, postData: changePass} = usePost(apiUrl.passChange);

  const tryPassChange = () => {
    setDisabled(true);
    if (props.passSetupFormValidator.validateForm()){
      changePass(props.formData)
    } else {
      props.setShowErrorText(true);
      console.error("Form is invalid");
    }
  }

  useEffect(() => {
    if (loading) {
      props.setShowErrorText(false);
    } else {
      if (status === 200){
        props.setPassChangeStatus(PassChange.SUCCESS);
      } else if (status === 404) {
        props.setPassChangeStatus(PassChange.FAILURE);
      }
    }
  }, [status, loading, data, props, setModalHeaderText, setModalChildren, translation]);

  useEffect(() => {
    if (disabled && !loading) setDisabled(false);
  }, [loading, disabled]);

  return (
    <Button variant="contained"
            disabled={disabled}
            disableElevation fullWidth
            sx={[fonts.spartan14, semiBoldText, button, {mt: 3, background: mainColors.primary.color}]}
            onClick={() => tryPassChange()}>
      {disabled ?
        <SmallSpinner/> :
        translation.form.setPassword.change
      }
    </Button>
  )
}

export default ChangePassButton;