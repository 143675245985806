import {Box, Typography} from "@mui/material";
import {boldText, fonts, mainColors} from "../../../../../../../../../app.style";
import {useContext} from "react";
import {AppContext} from "../../../../../../../common/appWrapper/AppWrapper";

const YourSpaces = ({yourSpaces, justify, isAdmin}: {yourSpaces: number[], justify: string, isAdmin?: boolean}) => {
  const {translation} = useContext(AppContext);

  return (
    <Box sx={{display: "flex", justifyContent: justify, mb: 1}}>
      <Typography sx={[fonts.spartan14, {textAlign: "left"}]}>
        {isAdmin ?
          translation.page.admin.cruiseDetails.bookedSpaces :
          translation.page.bookings.bookingDetails.yourSpaces}
      </Typography>
      <Typography sx={[fonts.spartan14, boldText, mainColors.primary, {textAlign: "left"}]}>
        &nbsp;{yourSpaces}
      </Typography>
    </Box>
  )
}

export default YourSpaces;