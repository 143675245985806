import {useContext, useEffect, useState} from "react";
import {AppContext} from "../../appWrapper/AppWrapper";
import {centeredBox, fonts, mainColors, semiBoldText} from "../../../../../app.style";
import {Box, Link, Typography} from "@mui/material";
import {ForwardToInbox} from '@mui/icons-material';
import usePost from "../../../../service/axios/usePost";
import {apiUrl} from "../../../../service/urls";
import ResendInfo from "../resendInfo/ResendInfo";
import {AuthContext} from "../../../../../App";


const PaymentEmailSent = ({bookingId} : {bookingId: string}) => {
  const {jwt} = useContext(AuthContext);
  const {translation, appUser} = useContext(AppContext);
  const [disabled, setDisabled] = useState(false);
  const {setData, loading, status, postData: resend} = usePost(
    apiUrl.sendInitialPaymentInstructions + bookingId);

  const sendAgain = () => {
    setDisabled(true);
    resend({}, jwt);
  }

  useEffect(() => {
    if (!loading && disabled) {
      setDisabled(!disabled);
      setData(null);
    }
  }, [loading, disabled, setDisabled, setData]);

  return (
    <>
      <Box sx={[centeredBox, {mb: 2}]}>
        <ForwardToInbox fontSize={"large"} color={"primary"}/>
      </Box>
      <Typography align="center" sx={[fonts.spartan14, semiBoldText, mainColors.contrast, {mb: 2}]}>
        {translation.form.newBooking.paymentStep.bankTransferInfo1}{appUser?.email}
      </Typography>
      <Typography align="center" sx={[fonts.spartan14, mainColors.contrast]}>
        {translation.form.newBooking.paymentStep.bankTransferInfo2}
      </Typography>
      <Typography align="center" sx={[fonts.spartan14, semiBoldText, mainColors.contrast, {mt: 3}]}>
        {translation.form.common.dintReceiveEmail}
      </Typography>
      <Box sx={[centeredBox, {my: 1}]}>
        <Link
          component="button"
          disabled={disabled}
          sx={[fonts.spartan14, semiBoldText, mainColors.contrast]}
          onClick={() => sendAgain()}>
          {translation.form.common.sendAgain}
        </Link>
      </Box>
      <ResendInfo status={status}/>
    </>
  );
}

export default PaymentEmailSent;