import {fonts} from "../../../../../../app.style";
import {Paper, Typography} from "@mui/material";
import Image from "mui-image";
import {websiteNameText, welcomeText} from "./welcomeSection.style";
import {useContext} from "react";
import {AppContext} from "../../../../common/appWrapper/AppWrapper";
import {MD_BREAKPOINT} from "../../../../common/guiConstants";
import {commonText} from "../../../../../../language/common";


const WelcomeSection = () => {
  const {windowWidth, translation} = useContext(AppContext);

  const getWelcomeFont = () => {
    return windowWidth >= MD_BREAKPOINT ? fonts.navRunic45 : fonts.navRunic30;
  }

  const getWebsiteNameFont = () => {
    return windowWidth >= MD_BREAKPOINT ? fonts.brandRunic60 : fonts.brandRunic36;
  }

  return (
    <Paper sx={{position: "relative", textAlign: "center"}} elevation={5}>
      <Image src={"/images/marina1.jpg"} duration={0}/>
      <Typography sx={[getWelcomeFont(), welcomeText]}>
        {translation.page.home.welcomeText}
      </Typography>
      <Typography sx={[getWebsiteNameFont(), websiteNameText]}>
        {commonText.websiteName}
      </Typography>
    </Paper>
  )
}

export default WelcomeSection;