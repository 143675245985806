import {IconButton} from "@mui/material";
import {DeleteForever} from "@mui/icons-material";

const DeleteBtn = ({clickHandler} : {clickHandler : Function}) => {
  return (
    <IconButton onClick={() => clickHandler()}>
      <DeleteForever/>
    </IconButton>
  )
}

export default DeleteBtn;