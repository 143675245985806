import {apiUrl} from "../urls";
import {useEffect} from "react";
import useDelete from "../axios/useDelete";
import {deleteFromCache} from "../helpers/cacheUtils";
import {clone} from "../../utils/misc/helpers";
import {Cruise} from "../../model/Cruise";


const useDeleteCruises = (cruises: Cruise[], setCruises: Function) => {
  const {
    data,
    setData,
    loading: deleteCruisesLoading,
    deleteData: deleteCruises
  } = useDelete(apiUrl.cruises);

  useEffect(() => {
    if (data) {
      const deleteResponse = data as Cruise;
      const cacheClone = clone(cruises);
      deleteFromCache(deleteResponse, cacheClone);
      setCruises(cacheClone);
      setData(null);
    }
  }, [data, cruises, setCruises, setData])

  return {deleteCruisesLoading, deleteCruises}
}

export default useDeleteCruises;