import {Button} from "@mui/material";
import {useContext} from "react";
import {AppContext, AppModalContext} from "../../../appWrapper/AppWrapper";
import {button, fonts, semiBoldText} from "../../../../../../app.style";
import RegisterForm from "../../registerForm/RegisterForm";


const CreateAccountButton = () => {
  const {translation} = useContext(AppContext);
  const {setModalHeaderText, setModalChildren} = useContext(AppModalContext)

  const switchToRegister = () => {
    setModalHeaderText(translation.modalHeader.register);
    setModalChildren(<RegisterForm/>)
  }

  return (
    <Button
      variant="outlined"
      color="contrast"
      disableElevation fullWidth
      onClick={() => switchToRegister()}
      sx={[fonts.spartan14, semiBoldText, button, {mt: 1}]}>
        {translation.form.loginForm.createAccount}
    </Button>
  )
}

export default CreateAccountButton;