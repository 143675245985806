import {useCallback, useEffect, useState} from "react";
import axios from "axios";


function usePost(url: string) {
  const [data, setData] = useState(null);
  const [status, setStatus] = useState<number>();
  const [loading, setLoading] = useState(false);

  const postData = useCallback((formData: any, jwt?: string, suffix?: string) => {
    setLoading(true);
    axios
      .post(suffix ? url + suffix : url, formData, {
        headers: {
          "Authorization": jwt ? `Bearer ${jwt}` : "",
          "Content-Type": "multipart/form-data"
        }
      })
      .then((response) => {
        // console.log("RESPONSE STATUS: ", response.status);
        // console.log("RESPONSE DATA: ", response.data);
        setData(response.data)
        setStatus(response.status);
      })
      .catch((err) => {
        console.log(err);
        setStatus(err.response.status);
        setLoading(false);
      })
  }, [url]);

  useEffect(() => {
    if (data != null) setLoading(false);
  }, [data])

  useEffect(() => {
    if (!loading && status !== undefined) setStatus(undefined);
  }, [status, loading]);

  return {data, setData, status, setStatus, loading, postData}
}

export default usePost;