import {Box, Typography} from "@mui/material";
import {fonts, mainColors, mediumText} from "../../../../../app.style";
import {East} from '@mui/icons-material';

interface FromToDateLocationProps {
  startLocation: string,
  finishLocation: string,
  startDay: string,
  finishDay: string
}

const FromToDateLocation = (props: FromToDateLocationProps) => {
  return (
    <Box sx={{height: "50px"}}>
      <Box sx={{display: "flex"}}>
        <Box>
          <Typography sx={[fonts.spartan14, mainColors.grey, mediumText, {textAlign: "center"}]}>
            {props.startLocation}
          </Typography>
          <Typography sx={[fonts.spartan12, mainColors.grey]}>
            {props.startDay}
          </Typography>
        </Box>
        <Box sx={{display: "flex", flexDirection: "column", flexGrow: 1, justifyContent: "center"}}>
          <East sx={[mainColors.grey, {mx: 1}]} fontSize={"small"}/>
        </Box>
        <Box>
          <Typography sx={[fonts.spartan14, mainColors.grey, mediumText, {textAlign: "center"}]}>
            {props.finishLocation}
          </Typography>
          <Typography sx={[fonts.spartan12, mainColors.grey]}>
            {props.finishDay}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default FromToDateLocation;