import {Translation} from "../../../../../language/translation/translations";
import StringValidator from "../../../../utils/validators/StringValidator";
import {validatorSettings} from "../../../../utils/validators/validatorSettings";
import DiscountRequest from "../../../../service/discount/DiscountRequest";
import NumberValidator from "../../../../utils/validators/NumberValidator";


export default class DiscountFormValidator {
  translation: Translation;
  codeValidator: StringValidator;
  discountAmtValidator: NumberValidator;

  constructor(translation: Translation) {
    this.translation = translation;
    this.codeValidator = new StringValidator(
      translation, validatorSettings.codeMaxChars, false, true, false);
    this.discountAmtValidator = new NumberValidator(
      translation, 1, 100);
  }

  validateForm = (discountForm: DiscountRequest) => {
    this.codeValidator.validate(discountForm.code);
    this.discountAmtValidator.validate(discountForm.discountAmt * 100);

    return this.codeValidator.isValid && this.discountAmtValidator.isValid;
  }
}