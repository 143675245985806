import {apiUrl} from "../urls";
import {useEffect} from "react";
import {updateCache} from "../helpers/cacheUtils";
import {clone} from "../../utils/misc/helpers";
import {Cruise} from "../../model/Cruise";
import usePostFile from "../axios/usePostFile";


const usePostCruiseImg = (cruises: Cruise[], setCruises: Function) => {
  const {
    data,
    setData,
    loading: postCruiseImgLoading,
    postData: postCruiseImg,
  } = usePostFile(apiUrl.cruises);

  useEffect(() => {
    if (data) {
      const postResponse = data as Cruise;
      const cacheClone = clone(cruises);
      updateCache([postResponse], cacheClone);
      setCruises(cacheClone);
      setData(null);
    }
  }, [data, cruises, setCruises, setData])

  return {postCruiseImgLoading, postCruiseImg}
}

export default usePostCruiseImg;