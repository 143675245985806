import {Container} from "@mui/material";
import NoCruiseBookings from "./noCruiseBookings/./NoCruiseBookings";
import CruiseBookingCard from "./cruiseBookingCard/CruiseBookingCard";
import {findInCache} from "../../../../../service/helpers/cacheUtils";
import {useContext} from "react";
import {AppContext} from "../../../../common/appWrapper/AppWrapper";
import {Cruise} from "../../../../../model/Cruise";
import ShowCrewBtn from "./showCrewBtn/ShowCrewBtn";


const CruiseBookings = () => {
  const {cruises, cruiseBookings} = useContext(AppContext);

  return (
    <Container sx={{mt: 3, mb: 5}}>
      {cruiseBookings.length === 0 ?
        <NoCruiseBookings/> :
        <>
        {cruiseBookings.map((booking, index) =>
          <CruiseBookingCard booking={booking} cruise={findInCache(booking.cruiseId, cruises) as Cruise} key={index}/>)}
          <ShowCrewBtn/>
        </>
      }
    </Container>
  )
}

export default CruiseBookings;