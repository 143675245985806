export const langOptions = {
  PL: "PL / zł",
  EN: "EN / €"
}

export const currencyOptions = {
  PLN: "zł (PLN)",
  EUR: "€ (EUR)"
}

export const commonText = {
  websiteName: "Chacruna sails"
}