import {Box, Typography} from "@mui/material";
import {boldText, centeredBox, fonts, rainbowColors} from "../../../../app.style";
import {useContext} from "react";
import {AppContext} from "../../common/appWrapper/AppWrapper";

const PassChangeFailure = () => {
  const {translation} = useContext(AppContext);

  return (
    <>
      <Box sx={[centeredBox, {mt: 5}]}>
        <Typography sx={[fonts.spartan20, boldText, rainbowColors.red, {textAlign: "center"}]}>
          {translation.form.setPassword.changeFailure}
        </Typography>
      </Box>
    </>
  )
}

export default PassChangeFailure;