import {button, fonts, mainColors, mediumText, semiBoldText} from "../../../../../app.style";
import {Button, Typography} from "@mui/material";
import {Booking} from "../../../../model/Booking";
import {useContext} from "react";
import {AppContext, AppFsDrawerContext, AppModalContext} from "../../appWrapper/AppWrapper";
import {AuthContext} from "../../../../../App";
import ManageBookingForm from "../../appForm/manageBookingForm/ManageBookingForm";
import {Cruise} from "../../../../model/Cruise";
import CancelBookingInfo from "../cancelBookingInfo/CancelBookingInfo";
import {BookingStatus} from "../../../../model/modelEnums/BookingStatus";


interface ManageBookingModalContentProps {
  booking: Booking,
  cruise: Cruise
}

const ChangeStatusModalContent = ({booking, cruise} : ManageBookingModalContentProps) => {
  const {jwt} = useContext(AuthContext);
  const {translation, appCacheApi} = useContext(AppContext);
  const {setIsFsDrawerOpen, setFsDrawerChildren} = useContext(AppFsDrawerContext);
  const {setIsModalOpen, setIsConfirmModalOpen, setConfirmModalHandler, setConfirmModalHeaderText, setConfirmModalChildren} = useContext(AppModalContext);

  const advanceBookingPrompt = () => {
    setConfirmModalHeaderText(translation.modalHeader.advanceBooking);
    setConfirmModalHandler(() => () => {
      setIsModalOpen(false);
      appCacheApi.advanceBooking({}, jwt, booking.id, () => {
        appCacheApi.getCruises("/" + booking.cruiseId);
        appCacheApi.getCruiseBookings(booking.cruiseId, jwt);
      })
    });
    setIsConfirmModalOpen(true);
  }

  const cancelBookingPrompt = () => {
    setConfirmModalHeaderText(translation.modalHeader.cancelBooking);
    setConfirmModalChildren(<CancelBookingInfo booking={booking} cruise={cruise}/>)
    setConfirmModalHandler(() => () => {
      setIsModalOpen(false);
      appCacheApi.cancelBooking({}, jwt, booking.id, () => {
        appCacheApi.getCruises("/" + booking.cruiseId);//
      })
    });
    setIsConfirmModalOpen(true);
  }

  const showBookingDetails = () => {
    setFsDrawerChildren(<ManageBookingForm cruise={cruise} booking={booking}/>)
    setIsFsDrawerOpen(true);
    setIsModalOpen(false);
  }

  return (
    <>
      <Button
        variant="contained"
        disableElevation
        fullWidth
        onClick={() => showBookingDetails()}
        sx={[fonts.spartan14, semiBoldText, button, {maxWidth: "330px"}]}>
        {translation.manageBooking.changeBookingDetails}
      </Button>
      {booking.bookingStatus !== BookingStatus.FULLY_PAID &&
        <>
          <Typography sx={[fonts.spartan14, mediumText, {my: 2, textAlign: "center"}]}>
            {translation.manageBooking.or}
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            disableElevation
            fullWidth
            onClick={() => advanceBookingPrompt()}
            sx={[fonts.spartan14, semiBoldText, button, mainColors.white, {maxWidth: "330px"}]}>
            {translation.changeBookingStatus.advanceBooking}
          </Button>
        </>
      }
      {booking.bookingStatus !== BookingStatus.CANCELED &&
        <>
          <Typography sx={[fonts.spartan14, mediumText, {my: 2, textAlign: "center"}]}>
            {translation.manageBooking.or}
          </Typography>
          <Button
            variant="outlined"
            color="contrast"
            disableElevation
            fullWidth
            onClick={() => cancelBookingPrompt()}
            sx={[fonts.spartan14, semiBoldText, button, {maxWidth: "330px"}]}>
            {translation.changeBookingStatus.cancelBooking}
          </Button>
        </>
      }
    </>
  )
}

export default ChangeStatusModalContent;