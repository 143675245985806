import {centeredBox, fonts} from "../../../../app.style";
import {Box, Container, Typography} from "@mui/material";
import TokenReader from "../../common/TokenReader";
import {useContext} from "react";
import {AppContext} from "../../common/appWrapper/AppWrapper";
import YouCanNowLogin from "../../common/YouCanNowLogin";
import {apiUrl} from "../../../service/urls";


const VerifyEmailPage = () => {
  const {translation} = useContext(AppContext);

  return (
    <TokenReader apiUrl={apiUrl.verify}>
      <Container sx={{mb: 3}}>
        <Box sx={[centeredBox, {mt: 5}]}>
          <Typography sx={[fonts.mainRunic30, {textAlign: "center"}]}>
            {translation.page.emailVerify.emailVerifyTitle}
          </Typography>
        </Box>
        <Box sx={[centeredBox, {mt: 5}]}>
          <Typography sx={[fonts.spartan20, {textAlign: "center"}]}>
            {translation.page.emailVerify.emailVerifyDescription1}
          </Typography>
        </Box>
        <YouCanNowLogin/>
      </Container>
    </TokenReader>
  )
}

export default VerifyEmailPage;