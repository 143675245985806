import {useContext, useEffect, useState} from "react";
import {Box, ThemeProvider, Typography} from "@mui/material";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers-pro";
import {AdapterDayjs} from "@mui/x-date-pickers-pro/AdapterDayjs";
import dayjs, {Dayjs} from "dayjs";
import 'dayjs/locale/pl';
import 'dayjs/locale/en';
import AbstractValidator from "../../../utils/validators/AbstractValidator";
import {theme} from "./appDatePicker.theme";
import {GuiColors} from "../../../utils/appEnums/GuiColors";
import {fonts, semiBoldText} from "../../../../app.style";
import {AppContext} from "../appWrapper/AppWrapper";
import {Language} from "../../../../language/Language";


interface AppDatePickerProps {
  label: string;
  validator: AbstractValidator;
  formValidatorCallback?: Function;
  formData: string;
  setFormData: Function;
  showErrorText?: boolean;
  disabled?: boolean
  disableFuture: boolean;
}

const AppDatePicker = (props: AppDatePickerProps) => {
  const {selectedLanguage} = useContext(AppContext);
  const [value, setValue] = useState<Dayjs | null>(dayjs(props.formData));
  const [blurred, setBlurred] = useState(props.showErrorText);

  const hasError = () => {
    return props.validator !== undefined ?
      !props.validator.isValid && blurred : false;
  }

  useEffect(() => {
    setBlurred(props.showErrorText);
  }, [props.showErrorText]);

  useEffect(() => {
    setValue(dayjs(props.formData));
  }, [props.formData]);

  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale={selectedLanguage === Language.PL ? "pl" : "en"}>
      <Box >
        <ThemeProvider theme={theme}>
          <DatePicker
            disabled={props.disabled}
            disableFuture={props.disableFuture}
            label={props.label}
            slotProps={{
              openPickerButton: {color: "primary"},
              textField: {
                fullWidth: true,
                size: "small",
                variant: "filled"
              }
            }}
            value={value}
            onChange={(newValue) => {
              props.setFormData(newValue?.format('YYYY-MM-DD'));
              props.validator.validate(newValue?.unix());
              if (props.formValidatorCallback) props.formValidatorCallback();
              setValue(newValue);
              setBlurred(true);
            }}/>
        </ThemeProvider>
      </Box>
      {hasError() ?
        <Typography sx={[fonts.spartan12, semiBoldText]}
                    variant={"caption"}
                    color={GuiColors.ERROR}>
          {props.validator?.errorText}
        </Typography> : props.validator !== undefined && <Box sx={{height: "24px"}}/>}
    </LocalizationProvider>
  )
}

export default AppDatePicker;