import {fonts, mainColors} from "../../../../../../../app.style";

export const colorBadgeStyle = {
  display: "flex",
  justifyContent: "center",
  borderRadius: "8px",
  width: "90px",
  height: "24px"
}

export const badgeTextStyle = {
  ...fonts.spartan12,
  ...mainColors.white,
  display: "flex",
  alignItems: "center"
}