import {Box, Typography} from "@mui/material";
import {fonts, semiBoldText} from "../../../../../../app.style";
import {useContext} from "react";
import {AppContext} from "../../../../common/appWrapper/AppWrapper";

const NoCruises = () => {
  const {translation} = useContext(AppContext);

  return (
    <Box sx={{display: "flex", flexDirection: "column", flexGrow: 1, minHeight: "33vh", justifyContent: "center"}}>
      <Typography sx={[fonts.spartan16, semiBoldText, {textAlign: "center"}]}>
        {translation.page.cruises.notPlannedYet1}
      </Typography>
      <Typography sx={[fonts.spartan14, {textAlign: "center"}]}>
        {translation.page.cruises.notPlannedYet2}
      </Typography>
    </Box>
  )
}

export default NoCruises;