import {Typography} from "@mui/material";
import {centeredBox, fonts} from "../../../../../../app.style";
import {useContext} from "react";
import {AppContext} from "../../../../common/appWrapper/AppWrapper";

const NoReviews = () => {
  const {translation} = useContext(AppContext);

  return (
    <Typography sx={[fonts.spartan14, centeredBox, {mt: 3, fontStyle: "italic"}]}>
      {translation.page.cruises.cruiseDetails.reviews.noReviews}
    </Typography>
  )
}

export default NoReviews;