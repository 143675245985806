import {useContext, useEffect, useState} from "react";
import {AppContext} from "../../../common/appWrapper/AppWrapper";
import {BookingInterval} from "../../../../utils/appEnums/BookingInterval";
import {BookingStatus} from "../../../../model/modelEnums/BookingStatus";

const useBookingIntervalOptions = () => {
  const {myBookings, cruises, translation} = useContext(AppContext);
  const [bookingIntervalOptions, setBookingIntervalOptions] = useState(
      [BookingInterval.UPCOMING])

  useEffect(() => {
    if (myBookings.filter(b => cruises
      .filter(c =>
        c.id === b.cruiseId &&
        new Date(c.startDay).getTime() < new Date().getTime() &&
        b.bookingStatus === BookingStatus.FULLY_PAID)
      .length > 0).length > 0) {
      setBookingIntervalOptions([BookingInterval.HISTORICAL, BookingInterval.UPCOMING])
    } else {
      setBookingIntervalOptions([BookingInterval.UPCOMING])
    }
  }, [myBookings, cruises, translation]);


  return {bookingIntervalOptions};
}

export default useBookingIntervalOptions;