import {Box, Typography} from "@mui/material";
import {useContext} from "react";
import {AppContext} from "../../../appWrapper/AppWrapper";
import {fonts, mainColors} from "../../../../../../app.style";

const SubStep = ({subStepNo, totalSteps} : {subStepNo: number, totalSteps: number}) => {
  const {translation} = useContext(AppContext);

  return (
    <Box sx={{display: "flex", flexDirection: "column", justifyContent: "center", height: "22px", pt: "1px", px: "6px", mr: "4px", backgroundColor: mainColors.secondary.color, borderRadius: "6px"}}>
      <Typography sx={[fonts.spartan10, mainColors.white, {display: "flex", alignItems: "center"}]}>
        {translation.form.newBooking.subStep}&nbsp;{subStepNo}/{totalSteps}
      </Typography>
    </Box>
  )
}

export default SubStep;