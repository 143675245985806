import {Typography} from "@mui/material";
import {AppContext} from "../../appWrapper/AppWrapper";
import {useContext} from "react";
import {Language} from "../../../../../language/Language";
import {clampedTextStyle, fonts} from "../../../../../app.style";


const CruiseDescription = ({textPl, textEn, isClamped}: {textPl: string, textEn: string, isClamped?: boolean}) => {
  const {selectedLanguage} = useContext(AppContext);

  return (
    <Typography  sx={[fonts.spartan12, isClamped ? clampedTextStyle : {}, {textAlign: "left"}]}>
      &emsp;&emsp;{selectedLanguage === Language.PL ? textPl : textEn}
    </Typography>
  )
}

export default CruiseDescription;