import {button, fonts, semiBoldText} from "../../../../../../../app.style";
import {Button} from "@mui/material";
import {KeyboardBackspace} from '@mui/icons-material';

const PrevStepBtn = ({label, prevStepHandler} : {label: string, prevStepHandler: Function}) => {

  return(
    <Button
      variant="outlined"
      color={"contrast"}
      startIcon={<KeyboardBackspace/>}
      disableElevation fullWidth
      onClick={() => prevStepHandler()}
      sx={[fonts.spartan14, semiBoldText, button, {maxWidth: "330px"}]}>
      {label}
    </Button>
  )
}

export default PrevStepBtn;