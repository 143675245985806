import {Translation} from "../../../../../language/translation/translations";
import EmailValidator from "../../../../utils/validators/EmailValidator";
import StringValidator from "../../../../utils/validators/StringValidator";
import {validatorSettings} from "../../../../utils/validators/validatorSettings";
import LoginRequest from "../../../../service/auth/LoginRequest";


export default class LoginFormValidator {
  translation: Translation;
  emailValidator: EmailValidator;
  passwordValidator: StringValidator;

  constructor(translation: Translation) {
    this.translation = translation;
    this.emailValidator = new EmailValidator(translation, validatorSettings.emailMaxChars);
    this.passwordValidator = new StringValidator(translation, validatorSettings.passwordMaxChars,false)
  }

  validateForm = (loginForm: LoginRequest) => {
    this.emailValidator.validate(loginForm.email);
    this.passwordValidator.validate(loginForm.password);

    return this.emailValidator.isValid && this.passwordValidator.isValid;
  }

  setInvalidEmailOrPass = () => {
    this.emailValidator.errorText = this.translation.formErrors.invalidLogin;
    this.passwordValidator.errorText = this.translation.formErrors.invalidLogin;
    this.emailValidator.isValid = false;
    this.passwordValidator.isValid = false;
  }
}