import {Container} from "@mui/material";
import {useContext, useEffect, useState} from "react";
import {AppContext} from "../../../../common/appWrapper/AppWrapper";
import {Discount} from "../../../../../model/Discount";
import {useParams} from "react-router-dom";
import NoCruiseDiscounts from "./noCruiseDiscounts/NoCruiseDiscounts";
import CreateDiscountBtn from "./createDiscountBtn/CreateDiscountBtn";
import CruiseDiscount from "./cruiseDiscount/CruiseDiscount";

const CruiseDiscountCodes = () => {
  const {cruiseId} = useParams();
  const {discounts} = useContext(AppContext);
  const [cruiseDiscounts, setCruiseDiscounts] = useState<Discount[]>([]);

  useEffect(() => {
    setCruiseDiscounts(discounts.filter(d => d.cruiseId === cruiseId));
  }, [cruiseId, discounts]);
  
  return (
    <Container sx={{mt: 3, mb: 5}}>
      {cruiseDiscounts.length === 0 ?
        <NoCruiseDiscounts/> :
        <>
          {cruiseDiscounts.map((discount, index) =>
            <CruiseDiscount discount={discount} key={index}/>)}
        </>}
      <CreateDiscountBtn/>
    </Container>
  )
}

export default CruiseDiscountCodes;