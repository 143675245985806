export const bunkStatusFree = {
  filter: "invert(42%) sepia(41%) saturate(4219%) hue-rotate(197deg) brightness(97%) contrast(99%)"
}

export const bunkStatusTaken = {
  filter: "invert(15%) sepia(53%) saturate(6046%) hue-rotate(29deg) brightness(98%) contrast(97%)"
}

export const bunkStatusSelected = {
  filter: "invert(57%) sepia(71%) saturate(1451%) hue-rotate(51deg) brightness(93%) contrast(90%)"
}