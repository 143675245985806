import {mainColors, rainbowColorsDark} from "../../../../../../../app.style";

export const selectedAllBerthsStyle = {
  display: "flex",
  justifyContent: "center",
  borderRadius: "8px",
  width: "100px",
  height: "24px",
  backgroundColor: mainColors.secondary.color
}

export const selectedNotAllBerthsStyle = {
  display: "flex",
  justifyContent: "center",
  borderRadius: "8px",
  width: "100px",
  height: "24px",
  backgroundColor: rainbowColorsDark.orange.color
}