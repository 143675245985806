import {createTheme} from "@mui/material";
import {theme as appTheme} from "../../../../app.theme"
import {mainColors} from "../../../../app.style";


export const theme = createTheme(appTheme, {
  components: {
    MuiButtonBase: {
      styleOverrides: {
        root: {
          color: mainColors.contrast.color,
          fontWeight: 600,
          fontFamily: "Spartan, sans-serif",
          fontSize: "14px",
          lineSpacing: "18px",
          letterSpacing: "-0.42px",
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: mainColors.contrast.color,
          fontFamily: "Spartan, sans-serif",
          fontSize: "14px",
          lineSpacing: "18px",
          letterSpacing: "-0.42px",
          marginLeft: "-12px",
          marginTop: "-5px",
        }
      }
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: "inherit !important",
          },
          '&.Mui-focused': {
            backgroundColor: "inherit !important",
          },
          '&.Mui-disabled': {
            backgroundColor: "inherit !important",
          },
          backgroundColor: "inherit !important",
          color: mainColors.contrast.color,
          fontWeight: 600,
          fontFamily: "Spartan, sans-serif",
          fontSize: "14px",
          lineSpacing: "18px",
          letterSpacing: "-0.42px",
        },
        input: {
          paddingLeft: "0px",
          '&.Mui-disabled': {
            WebkitTextFillColor: mainColors.disabled.color + " !important"
          }
        }
      }
    },
    MuiPickersArrowSwitcher: {
      styleOverrides: {
        button: {
          color: mainColors.contrast.color + "!important",
        }
      }
    },
    MuiPickersCalendarHeader: {
      styleOverrides: {
        switchViewButton: {
          color: mainColors.contrast.color + "!important",
        }
      }
    },
    MuiDayCalendar: {
      styleOverrides: {
        weekDayLabel: {
          color: mainColors.contrast.color + "!important",
        }
      }
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          color: mainColors.contrast.color + "!important",
          '&:focus': {
            backgroundColor: mainColors.primary.color + "!important",
            color: mainColors.white.color + "!important",
          },
        }
      }
    }
  }
});