import {Box, Container, Grid, Paper} from "@mui/material";
import Image from "mui-image";
import {useContext} from "react";
import {AppContext} from "../../../../../common/appWrapper/AppWrapper";
import {paperStyle, splashImageMd, splashImageSm} from "../../../../../../../app.style";
import {Cruise} from "../../../../../../model/Cruise";
import {MD_BREAKPOINT} from "../../../../../common/guiConstants";
import {Booking} from "../../../../../../model/Booking";
import CruiseBookingDetails from "./cruiseBookingDetails/CruiseBookingDetails";
import {apiUrl} from "../../../../../../service/urls";


const CruiseBookingCard = ({booking, cruise}: {booking: Booking, cruise: Cruise}) => {
  const {windowWidth} = useContext(AppContext);

  return (
    <Paper sx={[paperStyle, {display: "flex", mb: 3}]}>
      <Box>
        <Grid container>
          <Grid item xs={12} md={5} sx={{position: "relative", height: windowWidth >= MD_BREAKPOINT ? "283px" : "100%"}}>
            <Image
              style={windowWidth >= MD_BREAKPOINT ? splashImageMd : splashImageSm}
              src={cruise.splashImg ? apiUrl.cruises + "/" + cruise.splashImg : "/images/mock/default_splash.jpg"}
              duration={1000}/>
          </Grid>
          <Grid item xs={12} md={7}>
            <Container
              sx={{
                height: windowWidth >= MD_BREAKPOINT ? "251px" : undefined,
                my: 2, display: "flex", flexDirection: "column"}}>
              <CruiseBookingDetails booking={booking} cruise={cruise}/>
            </Container>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  )
}

export default CruiseBookingCard;