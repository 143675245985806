import {Cruise} from "../../../../../model/Cruise";
import BookingRequest from "../../../../../service/booking/BookingRequest";
import {Box, Divider, Grid} from "@mui/material";
import NextPrevStepBtns from "../nextPrevStepBtns/NextPrevStepBtns";
import {useContext, useState} from "react";
import {AppContext, AppFsDrawerContext, AppModalContext} from "../../../appWrapper/AppWrapper";
import DiscountCode from "./discountCode/DiscountCode";
import BookingOptions from "./bookingOptions/BookingOptions";
import Summary from "./summary/Summary";
import {MD_BREAKPOINT} from "../../../guiConstants";
import {AuthContext} from "../../../../../../App";
import {AppRole} from "../../../../../model/modelEnums/AppRole";
import {useNavigate} from "react-router-dom";
import PaymentEmailSent from "../../../appModal/paymentEmailSent/PaymentEmailSent";
import usePost from "../../../../../service/axios/usePost";
import {apiUrl} from "../../../../../service/urls";


interface PaymentStepProps {
  cruise: Cruise;
  setActiveStep: Function;
  bookingFormData: BookingRequest;
  setBookingFormData: Function;
  bookingCollisionHandler: Function;
}

const PaymentStep = ({cruise, setActiveStep, bookingFormData, setBookingFormData, bookingCollisionHandler} : PaymentStepProps) => {
  const {jwt, role} = useContext(AuthContext);
  const {translation, windowWidth, appCacheApi} = useContext(AppContext);
  const {setIsFsDrawerOpen} = useContext(AppFsDrawerContext);
  const {setModalHeaderText, setModalChildren, setIsModalOpen} = useContext(AppModalContext);
  const {postData: sendEmail} = usePost(apiUrl.sendInitialPaymentInstructions + bookingFormData.id);
  const [nextDisabled, setNextDisabled] = useState(false);
  const navigate = useNavigate();

  const successHandler = () => {
    if (role === AppRole.ADMIN) {
      appCacheApi.advanceBooking({}, jwt, bookingFormData.id);
    } else {
      sendEmail({}, jwt);
      setModalHeaderText(translation.modalHeader.paymentInfoSent);
      setModalChildren(<PaymentEmailSent bookingId={bookingFormData.id}/>);
      setIsModalOpen(true);
    }
    appCacheApi.getCruises("/" + bookingFormData.cruiseId);
    navigate("/bookings");
    setIsFsDrawerOpen(false);
  }

  const errorHandler = (status: number) => {
    if (status === 409 || status === 404) bookingCollisionHandler();
  }

  const nextStepHandler = () => {
    setNextDisabled(true);
    appCacheApi.advanceBooking({}, jwt, bookingFormData.id, successHandler, errorHandler)
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <DiscountCode
          bookingFormData={bookingFormData}
          setBookingFormData={setBookingFormData}
          bookingCollisionHandler={bookingCollisionHandler}/>
      </Grid>
      {windowWidth < MD_BREAKPOINT &&
        <Grid item xs={12}>
            <Divider variant={"middle"}/>
        </Grid>}
      <Grid item xs={12} md={6}>
        <BookingOptions
          cruiseStartDay={cruise.startDay}
          bookingFormData={bookingFormData}
          setBookingFormData={setBookingFormData}
          bookingCollisionHandler={bookingCollisionHandler}/>
      </Grid>
      {/*<Grid item xs={12}>*/}
      {/*  <Divider variant={"middle"}/>*/}
      {/*</Grid>*/}
      {/*<Grid item xs={12} sm={12}>*/}
      {/*  <PaymentOptions subStepNo={3} totalSteps={3}/>*/}
      {/*</Grid>*/}
      <Grid item xs={12}>
        <Divider variant={"middle"}/>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Box sx={{display: "flex", justifyContent: "center"}}>
          <Summary bookingFormData={bookingFormData}/>
        </Box>
      </Grid>
      <Grid item xs={12} sx={{mb: 5}}>
        <NextPrevStepBtns
          nextLabel={translation.form.newBooking.paymentStep.nextPreviousBtns.next}
          nextStepHandler={nextStepHandler}
          nextDisabled={nextDisabled}
          prevLabel={translation.form.newBooking.paymentStep.nextPreviousBtns.previous}
          prevStepHandler={() => setActiveStep(1)}/>
      </Grid>
    </Grid>
  )
}

export default PaymentStep;