import {Box, Button} from "@mui/material";
import {useContext} from "react";
import {AppContext, AppModalContext} from "../../../../../../../common/appWrapper/AppWrapper";
import {button, centeredBox, fonts, semiBoldText} from "../../../../../../../../../app.style";
import {Booking} from "../../../../../../../../model/Booking";
import ChangeStatusModalContent
  from "../../../../../../../common/appModal/changeStatusModalContent/ChangeStatusModalContent";
import {Cruise} from "../../../../../../../../model/Cruise";


const ChangeStatusBtn = ({booking, cruise} : {booking: Booking, cruise: Cruise}) => {
  const {translation} = useContext(AppContext);
  const {setModalHeaderText, setModalChildren, setIsModalOpen} = useContext(AppModalContext);

  const changeStatus = () => {
    setModalHeaderText(translation.modalHeader.changeBookingStatus);
    setModalChildren(<ChangeStatusModalContent booking={booking} cruise={cruise}/>);
    setIsModalOpen(true);
  }

  return (
    <Box sx={centeredBox}>
      <Button
        variant="outlined"
        color="contrast"
        disableElevation
        fullWidth
        onClick={() => changeStatus()}
        sx={[fonts.spartan14, semiBoldText, button, {maxWidth: "330px"}]}>
          {translation.page.admin.cruiseDetails.changeStatus}
      </Button>
    </Box>
  )
}

export default ChangeStatusBtn;