import {Button, Typography} from "@mui/material";
import {fonts, mediumText} from "../../../../../app.style";
import {GuiColors} from "../../../../utils/appEnums/GuiColors";


interface AppIconAndTextButtonProps {
  text: string;
  icon: any;
  variant: "text" | "outlined" | "contained" | undefined;
  onClick: Function;
  color?: GuiColors;
  sx?: any;
}

const AppIconAndTextButton = (props: AppIconAndTextButtonProps) => {

  return (
    <Button variant={props.variant}
            color={props.color}
            startIcon={props.icon}
            disableElevation
            onClick={() => {
              if (props.onClick) props.onClick()
            }}
            sx={props.sx}>
      <Typography sx={[fonts.spartan12, mediumText, {pt: "4px"}]}>
        {props.text}
      </Typography>
    </Button>
  );
}

export default AppIconAndTextButton;