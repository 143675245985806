import {PropsWithChildren} from "react";
import {Box, Container, Dialog, DialogContent, DialogTitle, IconButton, Typography} from "@mui/material";
import {Close} from '@mui/icons-material';
import {
  centeredBox,
  closeIcon,
  dialogHeaderTypographyStyle,
  mainColors,
  responsiveDialogStyle
} from "../../../../app.style";

interface AppModalProps extends PropsWithChildren {
  isOpen: boolean;
  setIsOpen: Function;
  headerText: string;
  closeHandler?: Function;
}

const AppModal = ({isOpen, setIsOpen, headerText, closeHandler, children} : AppModalProps) => {

  const handleClose = () => {
    if (closeHandler) {
      closeHandler();
    } else {
      setIsOpen(false);
    }
  }

  return (
    <Dialog open={isOpen} sx={responsiveDialogStyle}>
      <DialogTitle>
        <Box sx={centeredBox}>
          <Typography sx={dialogHeaderTypographyStyle}>
            {headerText}
          </Typography>
          <IconButton onClick={() => handleClose()} sx={[closeIcon, mainColors.contrast]}>
            <Close/>
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Container sx={{py: 1}}>
          {children}
        </Container>
      </DialogContent>
    </Dialog>
  )
}

export default AppModal;