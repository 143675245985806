import {PropsWithChildren, useContext, useEffect, useState} from "react";
import axios from "axios";
import {apiUrl, frontendUrl} from "../service/urls";
import {AuthContext} from "../../App";
import PageSpinner from "../components/common/appSpinner/PageSpinner";


const RouteGuard = (props: PropsWithChildren) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const {jwt, setJwt} = useContext(AuthContext);


  useEffect(() => {
    if (!isAuthenticated) {
      axios
        .get(apiUrl.auth as string, {
          headers: {
            "Authorization": `Bearer ${jwt}`
          }
        })
        .then((response) => {
          // setJwt(response.data.jwt) TODO put new token to local storage - needs backend response impl
          console.log("Authenticated")
          setIsAuthenticated(true)
          setIsLoading(false);
        })
        .catch((err) => {
          if (err.response?.status === 403 || err.response?.status === 401)
            setJwt("")
            window.location.replace(frontendUrl.home as string);
        });
    }
  }, [jwt, isAuthenticated, setJwt]);

  return (
    <>
      {isLoading || !isAuthenticated ?
        <PageSpinner/> :
        props.children}
    </>
  )
}

export default RouteGuard;