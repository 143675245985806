import {Translation} from "../../../../../language/translation/translations";
import StringValidator from "../../../../utils/validators/StringValidator";
import PhoneValidator from "../../../../utils/validators/PhoneValidator";
import {validatorSettings} from "../../../../utils/validators/validatorSettings";
import DateTimeValidator from "../../../../utils/validators/DateTimeValidator";
import AppUserRequest from "../../../../service/appUser/AppUserRequest";


export default class ProfileSettingsFormValidator {
  translation: Translation;
  appUserRequest: AppUserRequest;
  nameValidator: StringValidator;
  surnameValidator: StringValidator;
  nicknameValidator: StringValidator;
  bornValidator: DateTimeValidator;
  phoneValidator: PhoneValidator;

  constructor(translation: Translation, appUserRequest: AppUserRequest) {
    this.translation = translation;
    this.appUserRequest = appUserRequest;
    this.nameValidator = new StringValidator(translation, validatorSettings.nameMaxChars, true);
    this.surnameValidator = new StringValidator(translation, validatorSettings.surnameMaxChars, true, true);
    this.nicknameValidator = new StringValidator(translation, validatorSettings.nicknameMaxChars, false, true);
    this.bornValidator = new DateTimeValidator(translation);
    this.phoneValidator = new PhoneValidator(translation);
  }

  validateForm = () => {
    this.nameValidator.validate(this.appUserRequest.name);
    this.surnameValidator.validate(this.appUserRequest.surname);
    this.nicknameValidator.validate(this.appUserRequest.nickname);
    this.phoneValidator.validate(this.appUserRequest.phone)
    this.bornValidator.validate(this.appUserRequest.born);

    return (
      this.nameValidator.isValid &&
      this.surnameValidator.isValid &&
      this.nicknameValidator.isValid &&
      this.bornValidator.isValid &&
      this.phoneValidator.isValid
    )
  }
}