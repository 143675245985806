import {Language} from "../../../language/Language";
import {AppUser} from "../../model/AppUser";
import {SimpleOptionProps} from "../../components/common/appSelectField/appSelectField/simpleOption/SimpleOption";
import {Gender} from "../../model/modelEnums/Gender";

export default class AppUserRequest {
  name: string = "";
  surname: string = "";
  nickname: string = "";
  phone: string = "";
  gender: Gender = Gender.MALE;
  born: string = "";
  lang: Language = Language.PL;


  public static fromEmpty() {
    return new AppUserRequest();
  }

  public static fromExisting(appUser: AppUser) {
    const appUserFormData = new AppUserRequest();
    appUserFormData.name = appUser.name;
    appUserFormData.surname = appUser.surname;
    appUserFormData.nickname = appUser.nickname;
    appUserFormData.phone = appUser.phone ?? "";
    appUserFormData.born = appUser.born;
    appUserFormData.lang = appUser.lang;
    appUserFormData.gender = appUser.gender;
    return appUserFormData;
  }

  setName = (input: string) => {
    this.name = input;
  }

  setSurname = (input: string) => {
    this.surname = input;
  }

  setNickname = (input: string) => {
    this.nickname = input;
  }

  setGender = (input: { props: SimpleOptionProps }) => {
    this.gender = input.props.value as Gender;
  }

  setBorn = (input: string) => {
    this.born = input;
  }

  setPhone = (input: string) => {
    this.phone = input;
  }

  setLang = (input: { props: SimpleOptionProps }) => {
    this.lang = input.props.value as Language;
  }
}