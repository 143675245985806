import {useCallback, useContext, useEffect, useState} from "react";
import {AppContext} from "../../../common/appWrapper/AppWrapper";
import {BookingInterval} from "../../../../utils/appEnums/BookingInterval";
import {BookingStatus} from "../../../../model/modelEnums/BookingStatus";

const useIntervalBookings = (bookingInterval: BookingInterval) => {
  const {cruises, myBookings} = useContext(AppContext);

  const getBookingsInInterval = useCallback(() => {
    const historicalBookings = myBookings.filter(b => {
      return cruises.filter(c => c.id === b.cruiseId && new Date(c.startDay).getTime() < new Date().getTime()).length
    })
    if (bookingInterval === BookingInterval.HISTORICAL){
      return historicalBookings.filter(b => b.bookingStatus === BookingStatus.FULLY_PAID);
    } else {
      return myBookings.filter(b => !historicalBookings.includes(b))
    }
  }, [myBookings, bookingInterval, cruises]);

  const [intervalBookings, setIntervalBookings] = useState(getBookingsInInterval())

  useEffect(() => {
    setIntervalBookings(getBookingsInInterval());
  }, [getBookingsInInterval]);

  return {intervalBookings}
}

export default useIntervalBookings;