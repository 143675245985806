import BookingRequest from "../../../../../../../service/booking/BookingRequest";
import {Grid} from "@mui/material";
import AddParticipantBtn from "./addParticipantBtn/AddParticipantBtn";
import ParticipantFormValidator from "../participantData/ParticipantFormValidator";
import {useContext} from "react";
import {AppContext} from "../../../../../appWrapper/AppWrapper";
import RemoveParticipantBtn from "./removeParticipantBtn/RemoveParticipantBtn";

interface AddRemoveParticipantProps {
  bookingFormData: BookingRequest;
  refresh: boolean;
  setRefresh: Function;
  participantsValidator: ParticipantFormValidator[]
  availBunks: number;
}

const AddRemoveParticipant = ({bookingFormData, refresh, setRefresh, availBunks, participantsValidator}: AddRemoveParticipantProps) => {
  const {translation} = useContext(AppContext);

  const getAddDisabled = () => {
    return bookingFormData.participants.length >= availBunks;
  }

  const handleAddParticipant = () => {
    bookingFormData.addParticipant();
    participantsValidator.push(
      new ParticipantFormValidator(translation, bookingFormData.participants[bookingFormData.participants.length - 1]));
    setRefresh(!refresh);
  }

  const handleRemoveParticipant = () => {
    bookingFormData.removeParticipant();
    participantsValidator.pop()
    setRefresh(!refresh);
  }

  return(
    <Grid container justifyContent={"center"} spacing={2} sx={{mt: 0}}>
      {bookingFormData.participants.length > 1 &&
        <Grid item xs={12} sm={4}>
            <RemoveParticipantBtn handleRemoveParticipant={handleRemoveParticipant}/>
        </Grid>}
      <Grid item xs={12} sm={4}>
        <AddParticipantBtn handleAddParticipant={handleAddParticipant} disabled={getAddDisabled()}/>
      </Grid>
    </Grid>
  )
}

export default AddRemoveParticipant;