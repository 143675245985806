import {useCallback, useContext, useEffect, useState} from "react";
import SaveButton from "../../appButton/saveButton/SaveButton";
import AppTextField from "../../appTextField/AppTextField";
import {TextFieldType} from "../../../../utils/appEnums/TextFieldType";
import {AppContext, AppFsDrawerContext, AppModalContext} from "../../appWrapper/AppWrapper";
import CancelButton from "../../appButton/cancelButton/CancelButton";
import CruiseFormValidator from "./CruiseFormValidator";
import {Box, Grid, Typography} from "@mui/material";
import {centeredBox, fonts, mainColors, mediumText} from "../../../../../app.style";
import {clone, strEquals} from "../../../../utils/misc/helpers";
import {AuthContext} from "../../../../../App";
import {Cruise} from "../../../../model/Cruise";
import CruiseRequest from "../../../../service/cruise/CruiseRequest";
import useSimpleOpts from "../../appSelectField/appSelectField/simpleOption/useSimpleOpts";
import AppSelectField from "../../appSelectField/AppSelectField";
import AppDatePicker from "../../appTimePicker/AppDatePicker";


const CruiseForm = ({cruise, formTitle} : {cruise?: Cruise, formTitle: string}) => {
  const {jwt} = useContext(AuthContext);
  const {translation, appCacheApi} = useContext(AppContext);
  const {setConfirmModalHeaderText, setConfirmModalHandler, setIsConfirmModalOpen} = useContext(AppModalContext);
  const {setOnFsDrawerClose} = useContext(AppFsDrawerContext);
  const {setIsFsDrawerOpen} = useContext(AppFsDrawerContext);
  const [cruiseFormData] = useState(cruise ? CruiseRequest.fromExisting(cruise) : CruiseRequest.fromEmpty());
  const [initialFormData] = useState(clone(cruiseFormData));
  const [cruiseFormValidator] = useState(new CruiseFormValidator(translation));
  const [showErrorText, setShowErrorText] = useState(false);
  const [saveBtnDisabled, setSaveBtnDisabled] = useState(false);
  const {simpleOpts: difficulties} = useSimpleOpts(translation.page.cruises.cruiseDetails.difficulties, false)

  const handleCloseDrawer = useCallback(() => {
    if (strEquals(cruiseFormData, initialFormData)){
      setIsFsDrawerOpen(false);
    } else {
      setConfirmModalHeaderText(translation.modalHeader.discardChanges);
      setConfirmModalHandler(() => () => {
        setIsFsDrawerOpen(false);
      });
      setIsConfirmModalOpen(true);
    }
  }, [cruiseFormData, initialFormData, setConfirmModalHandler, setConfirmModalHeaderText, setIsConfirmModalOpen,
    setIsFsDrawerOpen, translation.modalHeader.discardChanges])

  const successHandler = () => {
    setIsFsDrawerOpen(false);
  }

  const errorHandler = (status: number) => {
    console.log(status)
  }

  const saveCruise = () => {
    if (cruiseFormValidator.validateForm(cruiseFormData)){
      setSaveBtnDisabled(true);
      appCacheApi.postCruise(cruiseFormData, jwt, "", successHandler, errorHandler)
    } else {
      setShowErrorText(true);
      console.error("Form is invalid");
    }
  }

  useEffect(() => {
    setOnFsDrawerClose(() => handleCloseDrawer)
  }, [handleCloseDrawer, setOnFsDrawerClose]);
  
  return (
    <Box sx={{my: 2}}>
      <Box sx={[centeredBox, {mb: 2}]}>
        <Box>
          <Typography sx={[fonts.spartan16, mainColors.contrast, mediumText, {textAlign: "center"}]}>
            {formTitle}
          </Typography>
        </Box>
      </Box>
      <Grid container spacing={1} justifyContent={"center"}>
        <Grid item xs={12} sm={4} md={2}>
          <AppTextField
            formData={cruiseFormData.price}
            setFormData={cruiseFormData.setPrice}
            required={true}
            type={TextFieldType.NUMBER}
            label={translation.page.admin.cruises.cruiseForm.price}
            showErrorText={showErrorText}
            validator={cruiseFormValidator.priceValidator}/>
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <AppSelectField
            selectedValue={cruiseFormData.cruiseDifficulty}
            setFormData={cruiseFormData.setCruiseDifficulty}
            label={translation.page.admin.cruises.cruiseForm.cruiseDifficulty}
            options={difficulties}/>
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <AppDatePicker
            label={translation.page.admin.cruises.cruiseForm.startDay}
            validator={cruiseFormValidator.startDayValidator}
            disableFuture={false}
            formData={cruiseFormData.startDay}
            setFormData={cruiseFormData.setStartDay}/>
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <AppDatePicker
            label={translation.page.admin.cruises.cruiseForm.finishDay}
            validator={cruiseFormValidator.finishDayValidator}
            disableFuture={false}
            formData={cruiseFormData.finishDay}
            setFormData={cruiseFormData.setFinishDay}/>
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <AppTextField
            formData={cruiseFormData.startLocation}
            setFormData={cruiseFormData.setStartLocation}
            required={true}
            type={TextFieldType.TEXT}
            label={translation.page.admin.cruises.cruiseForm.startLocation}
            showErrorText={showErrorText}
            validator={cruiseFormValidator.startLocationValidator}/>
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <AppTextField
            formData={cruiseFormData.finishLocation}
            setFormData={cruiseFormData.setFinishLocation}
            required={true}
            type={TextFieldType.TEXT}
            label={translation.page.admin.cruises.cruiseForm.finishLocation}
            showErrorText={showErrorText}
            validator={cruiseFormValidator.finishLocationValidator}/>
        </Grid>
        <Grid item xs={12} lg={6}>
          <AppTextField
            formData={cruiseFormData.titlePl}
            setFormData={cruiseFormData.setTitlePl}
            required={true}
            type={TextFieldType.TEXT}
            label={translation.page.admin.cruises.cruiseForm.titlePl}
            showErrorText={showErrorText}
            validator={cruiseFormValidator.titlePlValidator}/>
        </Grid>
        <Grid item xs={12} lg={6}>
          <AppTextField
            formData={cruiseFormData.titleEn}
            setFormData={cruiseFormData.setTitleEn}
            required={true}
            type={TextFieldType.TEXT}
            label={translation.page.admin.cruises.cruiseForm.titleEn}
            showErrorText={showErrorText}
            validator={cruiseFormValidator.titleEnValidator}/>
        </Grid>
        <Grid item xs={12} lg={6}>
          <AppTextField
            formData={cruiseFormData.descriptionPl}
            setFormData={cruiseFormData.setDescriptionPl}
            required={true}
            variant={"outlined"}
            type={TextFieldType.TEXT}
            label={translation.page.admin.cruises.cruiseForm.descriptionPl}
            showErrorText={showErrorText}
            rows={5}
            validator={cruiseFormValidator.descrPlValidator}/>
        </Grid>
        <Grid item xs={12} lg={6}>
          <AppTextField
            formData={cruiseFormData.descriptionEn}
            setFormData={cruiseFormData.setDescriptionEn}
            required={true}
            variant={"outlined"}
            type={TextFieldType.TEXT}
            label={translation.page.admin.cruises.cruiseForm.descriptionEn}
            showErrorText={showErrorText}
            rows={5}
            validator={cruiseFormValidator.descrEnValidator}/>
        </Grid>
        <Grid item xs={12} lg={6}>
          <AppTextField
            formData={cruiseFormData.planPl}
            setFormData={cruiseFormData.setPlanPl}
            required={true}
            variant={"outlined"}
            type={TextFieldType.TEXT}
            label={translation.page.admin.cruises.cruiseForm.planPl}
            showErrorText={showErrorText}
            rows={7}
            validator={cruiseFormValidator.planPlValidator}/>
        </Grid>
        <Grid item xs={12} lg={6}>
          <AppTextField
            formData={cruiseFormData.planEn}
            setFormData={cruiseFormData.setPlanEn}
            required={true}
            variant={"outlined"}
            type={TextFieldType.TEXT}
            label={translation.page.admin.cruises.cruiseForm.planEn}
            showErrorText={showErrorText}
            rows={7}
            validator={cruiseFormValidator.planEnValidator}/>
        </Grid>
        <Grid item xs={12} sm={6}>
          <AppTextField
            formData={cruiseFormData.youtubeLink}
            setFormData={cruiseFormData.setYoutubeLink}
            type={TextFieldType.TEXT}
            label={translation.page.admin.cruises.cruiseForm.ytLink}
            showErrorText={showErrorText}
            validator={cruiseFormValidator.youtubeLinkValidator}/>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{mt: 1}}>
        <Grid item xs={12} sm={6} sx={{display: "flex", justifyContent: "center"}}>
          <CancelButton
            sx={{maxWidth: "330px"}}
            handleCancel={handleCloseDrawer}/>
        </Grid>
        <Grid item xs={12} sm={6} sx={{display: "flex", justifyContent: "center"}}>
          <SaveButton
            sx={{maxWidth: "330px"}}
            disabled={saveBtnDisabled}
            handleSave={saveCruise}/>
        </Grid>
      </Grid>
    </Box>
  );
}

export default CruiseForm;