import {useContext} from "react";
import {AppContext, AppModalContext} from "../appWrapper/AppWrapper";
import {centeredBox, fonts, mainColors, semiBoldText} from "../../../../app.style";
import {Box, Link, Typography} from "@mui/material";
import LoginForm from "../appForm/loginForm/LoginForm";

const LoginInfoBtn = ({descr} : {descr: string}) => {
  const {translation} = useContext(AppContext);
  const {setModalHeaderText, setModalChildren, setIsModalOpen} = useContext(AppModalContext);

  const openLoginModal = () => {
    setModalHeaderText(translation.modalHeader.menuLogin);
    setModalChildren(<LoginForm/>)
    setIsModalOpen(true);
  }

  return (
    <Box sx={[centeredBox, {mt: 3}]}>
      <Link
        component="button"
        sx={[fonts.spartan14, semiBoldText, mainColors.primary]}
        onClick={() => openLoginModal()}>
        {translation.login}
      </Link>
      <Typography sx={[fonts.spartan14, mainColors.contrast, {display: "flex", alignItems: "center"}]}>
        &nbsp;{descr}
      </Typography>
    </Box>
  )
}

export default LoginInfoBtn;