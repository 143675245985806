import {Cruise} from "../../model/Cruise";
import {AppCurrency} from "../../model/modelEnums/AppCurrency";
import {Language} from "../../../language/Language";
import {BookingType} from "../../model/modelEnums/BookingType";
import {AppUser} from "../../model/AppUser";
import ParticipantRequest from "./ParticipantRequest";
import {SimpleOptionProps} from "../../components/common/appSelectField/appSelectField/simpleOption/SimpleOption";
import {Booking} from "../../model/Booking";
import {getDaysLeft} from "../../utils/misc/helpers";

export default class BookingRequest {
  id: string = ""
  cruiseId: string = "";
  bookingType = BookingType.TWO_STAGE;
  bunks: number[] = [];
  initialBunks: number[] = [];
  discountCode: string = "";
  currency = AppCurrency.PLN;
  regularPrice: number = 0;
  discountPrice: number = 0;
  bookingPrice: number = 0;
  participants: ParticipantRequest[] = [];
  isPolicyCheck = false;
  isProcessingCheck = false;
  userIsParticipant = true;

  public static fromEmpty(cruise: Cruise, appUser: AppUser, selectedLang: Language) {
    const bookingFormData = new BookingRequest();
    bookingFormData.cruiseId = cruise.id;
    bookingFormData.currency = selectedLang === Language.PL ? AppCurrency.PLN : AppCurrency.EUR;
    bookingFormData.bookingType = getDaysLeft(cruise.startDay) > 14 ? BookingType.TWO_STAGE : BookingType.ONE_STAGE;
    bookingFormData.participants.push(ParticipantRequest.fromUser(appUser));
    bookingFormData.regularPrice = bookingFormData.currency === AppCurrency.PLN ? cruise.pricePln : cruise.priceEur;
    bookingFormData.discountPrice = bookingFormData.regularPrice;
    bookingFormData.bookingPrice = Number.parseFloat((bookingFormData.discountPrice  / 2).toFixed(2));
    return bookingFormData;
  }

  public static fromExisting(booking: Booking) {
    const bookingFormData = new BookingRequest();
    bookingFormData.id = booking.id;
    bookingFormData.cruiseId = booking.cruiseId;
    bookingFormData.bookingType = booking.bookingType;
    bookingFormData.bunks = booking.bunks;
    bookingFormData.initialBunks = booking.bunks;
    bookingFormData.discountCode = booking.discountCode ?? "";
    bookingFormData.currency = booking.currency;
    bookingFormData.regularPrice = booking.regularPrice;
    bookingFormData.discountPrice = booking.discountPrice;
    bookingFormData.bookingPrice = booking.bookingPrice;
    bookingFormData.participants = booking.participants.map(p => ParticipantRequest.fromExisting(p));
    bookingFormData.isPolicyCheck = true;
    bookingFormData.isProcessingCheck = true;
    bookingFormData.userIsParticipant = booking.userIsParticipant;
    return bookingFormData;
  }

  setBunks = (input: number[]) => {
    this.bunks = input;
  }

  setCurrency = (input: { props: SimpleOptionProps }) => {
    this.currency = input.props.value as AppCurrency;
  }

  setBookingType = (input: { props: SimpleOptionProps }) => {
    this.bookingType = input.props.value as BookingType;
  }

  setDiscountCode = (input: string) => {
    this.discountCode = input;
  }

  addParticipant = () => {
    this.participants.push(ParticipantRequest.fromEmpty());
  }

  removeParticipant = () => {
    this.participants.pop();
  }

  setIsPolicyCheck = (input: boolean) => {
    this.isPolicyCheck = input;
  }

  setIsProcessingCheck = (input: boolean) => {
    this.isProcessingCheck = input;
  }

  setUserIsParticipant = (input: boolean) => {
    this.userIsParticipant = input;
  }
}