import {apiUrl} from "../urls";
import {useEffect} from "react";
import useDelete from "../axios/useDelete";
import {deleteFromCache, findInCache} from "../helpers/cacheUtils";
import {clone} from "../../utils/misc/helpers";
import {Review} from "../../model/Review";
import {Cruise} from "../../model/Cruise";


const useDeleteReviews = (cruises: Cruise[], setCruises: Function) => {
  const {
    data,
    setData,
    loading: deleteReviewsLoading,
    deleteData: deleteReviews
  } = useDelete(apiUrl.reviews);

  useEffect(() => {
    if (data) {
      const deleteResponse = data as Review;
      const cacheClone = clone(cruises) as Cruise[];
      let cruise = findInCache(deleteResponse.cruiseId, cacheClone) as Cruise;
      let review = findInCache(deleteResponse.id, cruise.reviews) as Review
      deleteFromCache(review, cruise.reviews);
      setCruises(cacheClone);
      setData(null);
    }
  }, [data, cruises, setCruises, setData])

  return {deleteReviewsLoading, deleteReviews}
}

export default useDeleteReviews;