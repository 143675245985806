import {Box, Grid} from "@mui/material";
import {Cruise} from "../../../../../../../model/Cruise";
import BookingsDiscountsBtn from "./bookingsDiscountsBtn/BookingsDiscountsBtn";
import DeleteCruiseBtn from "./deleteCruiseBtn/DeleteCruiseBtn";
import EditCruiseBtn from "./editCruiseBtn/EditCruiseBtn";
import {useContext} from "react";
import {AppContext} from "../../../../../../common/appWrapper/AppWrapper";
import {SM_BREAKPOINT} from "../../../../../../common/guiConstants";

const AdminCruiseCardBtns = ({cruise} : {cruise: Cruise}) => {
  const {windowWidth} = useContext(AppContext);

  return (
    <Box sx={{display: "flex", flexDirection: "column", justifyContent: "flex-end", flexGrow: 1}}>
      <Grid container
            direction="row"
            justifyContent="center"
            alignItems="flex-end" spacing={1}>
        <Grid item xs={12}sx={{mt: 2}}>
          <BookingsDiscountsBtn cruiseId={cruise.id}/>
        </Grid>
        <Grid item xs={12} sm={6} sx={{mt: windowWidth < SM_BREAKPOINT ? 2 : undefined}}>
          <DeleteCruiseBtn cruiseId={cruise.id}/>
        </Grid>
        <Grid item xs={12} sm={6} sx={{mt: windowWidth < SM_BREAKPOINT ? 2 : undefined}}>
          <EditCruiseBtn cruise={cruise}/>
        </Grid>
      </Grid>
    </Box>
  )
}

export default AdminCruiseCardBtns;