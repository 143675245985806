import {Box, CircularProgress} from "@mui/material";

const PageSpinner = () => {
  return (
    <Box sx={{display: "flex", flexDirection: "column", width: "100%", height: "100vh", justifyContent: "center"}}>
      <CircularProgress sx={{alignSelf: "center"}} size={100}/>
    </Box>
  )
}

export default PageSpinner;