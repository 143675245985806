import {Cruise} from "../../../../../../../model/Cruise";
import {Box, Grid} from "@mui/material";
import {SM_BREAKPOINT} from "../../../../../../common/guiConstants";
import {useContext} from "react";
import {AppContext} from "../../../../../../common/appWrapper/AppWrapper";
import {centeredBox} from "../../../../../../../../app.style";
import {
  isPriceDisplaySm,
  isPriceDisplayXs,
  isRatingDisplaySm,
  isRatingDisplayXs
} from "../../../../../../../utils/misc/helpers";
import CruiseRating from "../../../../../../common/cruiseDetails/cruiseRating/CruiseRating";
import {Booking} from "../../../../../../../model/Booking";
import BookingPrice from "../../../../../bookingsPage/bookings/bookingCard/bookingDetails/bookingPrice/BookingPrice";
import BookingStatusInfo
  from "../../../../../bookingsPage/bookings/bookingCard/bookingDetails/bookingStatusInfo/BookingStatusInfo";
import BookingUserDetails from "./bookingUserDetails/BookingUserDetails";
import BookingTime from "./bookingTime/BookingTime";
import ChangeStatusBtn from "./changeStatusBtn/ChangeStatusBtn";
import ShowParticipantsBtn from "./showParticipantsBtn/ShowParticipantsBtn";


const CruiseBookingDetails = ({booking, cruise}: {booking: Booking, cruise: Cruise}) => {
  const {windowWidth} = useContext(AppContext);

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Box sx={[{display: "flex", justifyContent: "center"}, windowWidth >= SM_BREAKPOINT && {justifyContent: "space-between"}]}>
            <Box>
              <BookingUserDetails booking={booking}/>
              <BookingStatusInfo booking={booking}/>
            </Box>
            {isRatingDisplaySm(windowWidth, cruise) && <CruiseRating reviews={cruise.reviews}/>}
            {isPriceDisplaySm(windowWidth, cruise) && <BookingPrice
                isAdmin={true}
                booking={booking}
                justify={"flex-end"}/>}
          </Box>
        </Grid>
        {isPriceDisplayXs(windowWidth, cruise) &&
        <Grid item xs={12}>
          <BookingPrice
              isAdmin={true}
              booking={booking}
              justify={"center"}/>
        </Grid>}
        <Grid item xs={12} sx={{pt: 2}}>
          <BookingTime  booking={booking}/>
        </Grid>
      </Grid>
      <Box sx={{display: "flex", flexDirection: "column", justifyContent: "flex-end", flexGrow: 1}}>
        <Grid container
              direction="row"
              justifyContent="center"
              alignItems="flex-end" spacing={1}>
          <Grid item xs={12} sm={6} sx={{mt: 2}}>
            <ChangeStatusBtn booking={booking} cruise={cruise}/>
          </Grid>
          <Grid item xs={12} sm={6} sx={{mt: 2}}>
            <ShowParticipantsBtn booking={booking}/>
          </Grid>
        </Grid>
      </Box>
      {isRatingDisplayXs(windowWidth, cruise) &&
      <Box sx={[centeredBox, {mt: 2, justifyContent: "center"}]}>
        <CruiseRating reviews={cruise.reviews}/>
      </Box>}
    </>
  )
}

export default CruiseBookingDetails;