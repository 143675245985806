import {Review} from "../../model/Review";

export default class ReviewRequest {
  id: string = "";
  cruiseId: string = "";
  title: string = "";
  description: string = "";
  score: number = 5;

  public static fromEmpty(cruiseId: string) {
    const reviewFormData = new ReviewRequest();
    reviewFormData.cruiseId = cruiseId;
    return reviewFormData;
  }

  public static fromExisting(review: Review) {
    const reviewFormData = new ReviewRequest();
    reviewFormData.id = review.id;
    reviewFormData.cruiseId = review.cruiseId;
    reviewFormData.title = review.title;
    reviewFormData.description = review.description;
    reviewFormData.score = review.score;
    return reviewFormData;
  }

  setId = (input: string) => {
    this.id = input;
  }

  setCruiseId = (input: string) => {
    this.cruiseId = input;
  }

  setTitle = (input: string) => {
    this.title = input;
  }

  setDescription = (input: string) => {
    this.description = input;
  }

  setScore = (value: number) => {
    this.score = value;
  }
}