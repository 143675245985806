import {Box, Container, Grid, Paper} from "@mui/material";
import Image from "mui-image";
import {paperStyle, splashImageMd, splashImageSm} from "../../../../../../app.style";
import {useContext} from "react";
import {AppContext} from "../../../../common/appWrapper/AppWrapper";
import {MD_BREAKPOINT} from "../../../../common/guiConstants";
import {Booking} from "../../../../../model/Booking";
import {Cruise} from "../../../../../model/Cruise";
import BookingDetails from "./bookingDetails/BookingDetails";
import BookingCardBadge from "./bookingCardBadge/BookingCardBadge";
import {apiUrl} from "../../../../../service/urls";


const BookingCard = ({booking, cruise}: {booking: Booking, cruise: Cruise}) => {
  const {windowWidth} = useContext(AppContext);

  return (
    <Paper sx={[paperStyle, {display: "flex", mb: 3}]}>
      <Box>
        <Grid container>
          <Grid item xs={12} md={5} sx={{position: "relative", height: windowWidth >= MD_BREAKPOINT ? "333px" : "100%"}}>
            <Image
              style={windowWidth >= MD_BREAKPOINT ? splashImageMd : splashImageSm}
              src={cruise.splashImg ? apiUrl.cruises + "/" + cruise.splashImg : "/images/mock/default_splash.jpg"}
              duration={1000}/>
            <BookingCardBadge cruise={cruise}/>
          </Grid>
          <Grid item xs={12} md={7}>
            <Container
              sx={{
                height: windowWidth >= MD_BREAKPOINT ? "301px" : undefined,
                my: 2, display: "flex", flexDirection: "column"}}>
              <BookingDetails booking={booking} cruise={cruise}/>
            </Container>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  )
}

export default BookingCard;