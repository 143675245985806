import {useParams} from "react-router-dom";
import PageSpinner from "./appSpinner/PageSpinner";
import {createContext, PropsWithChildren, useContext, useEffect, useState} from "react";
import ExpiredToken from "./ExpiredToken";
import {AppContext} from "./appWrapper/AppWrapper";
import {apiUrl} from "../../service/urls";
import useGet from "../../service/axios/useGet";

interface TokenReaderContextType {
  token: string | undefined;
}

export const TokenReaderContext = createContext<TokenReaderContextType>(
  {} as TokenReaderContextType
)

interface TokenReaderProps extends PropsWithChildren {
  apiUrl: string;
}

const TokenReader = (props: TokenReaderProps) => {
  const {token} = useParams();
  const {translation} = useContext(AppContext);
  const {data, status, getData: send} = useGet(props.apiUrl + token);
  const [respCode, setResponseCode] = useState<number | undefined>();

  const getExpiredTokenText = () => {
    if (props.apiUrl === apiUrl.verify) {
      return translation.page.expiredVerificationToken;
    } else {
      return translation.page.expiredResetToken;
    }
  }

  useEffect(() => {
    if (status) setResponseCode(status);
  }, [status]);

  useEffect(() => {
    send()
  }, [send]);

  return (
    <TokenReaderContext.Provider value={{token}}>
      {data === "" ? props.children :
        respCode === undefined ? <PageSpinner/> :
          <ExpiredToken text={getExpiredTokenText()}/>}
    </TokenReaderContext.Provider>
  )
}

export default TokenReader;