import {Box, Typography} from "@mui/material";
import {useContext} from "react";
import {AppContext} from "../../../../../common/appWrapper/AppWrapper";
import {fonts, semiBoldText} from "../../../../../../../app.style";

const NoCruiseBookings = () => {
  const {translation} = useContext(AppContext);

  return (
    <Box sx={{display: "flex", flexDirection: "column", flexGrow: 1, minHeight: "33vh", justifyContent: "center"}}>
      <Typography sx={[fonts.spartan16, semiBoldText, {textAlign: "center"}]}>
        {translation.page.admin.cruiseDetails.noBookings}
      </Typography>
    </Box>
  )
}

export default NoCruiseBookings;