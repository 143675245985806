import AbstractValidator from "./AbstractValidator";
import {Translation} from "../../../language/translation/translations";


export default class DateTimeValidator implements AbstractValidator {
  private _translation: Translation;
  private _isValid = true;
  private _errorText = ".";

  constructor(translation: Translation) {
    this._translation = translation;
  }

  validate(input: string) {
    this._isValid = false;
    if (input === "") {
      this._errorText = this._translation.formErrors.fieldRequired;
    } else {
      if (`${new Date(input).getTime()}` === "NaN") {
        this._errorText = this._translation.formErrors.incorrectFormat
      } else {
        this._errorText = "."
        this._isValid = true;
      }
    }
  }

  public set translation(translation: Translation) {
    this._translation = translation;
  }

  public get isValid() {
    return this._isValid;
  }

  public get errorText() {
    return this._errorText;
  }
}