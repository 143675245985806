import {Box, Container, Grid, Paper, Typography} from "@mui/material";
import {Cruise} from "../../../../../../model/Cruise";
import Image from "mui-image";
import {fonts, paperStyle, splashImageMd, splashImageSm} from "../../../../../../../app.style";
import {useContext} from "react";
import {AppContext} from "../../../../../common/appWrapper/AppWrapper";
import {MD_BREAKPOINT} from "../../../../../common/guiConstants";
import CruiseDetails from "../../../../../common/cruiseDetails/CruiseDetails";
import {getCruiseStatus} from "../../../../../../utils/misc/helpers";
import {apiUrl} from "../../../../../../service/urls";


const AdminCruiseCard = ({cruise}: {cruise: Cruise}) => {
  const {translation, windowWidth} = useContext(AppContext);

  return (
    <Paper sx={[paperStyle, {display: "flex", mb: 3}]}>
      <Box sx={{width: "100%"}}>
        <Grid container>
          <Grid item xs={12} md={4} sx={{position: "relative", height: windowWidth >= MD_BREAKPOINT ? "267px" : "100%"}}>
            <Image
              style={windowWidth >= MD_BREAKPOINT ? splashImageMd : splashImageSm}
              src={cruise.splashImg ? apiUrl.cruises + "/" + cruise.splashImg : "/images/mock/default_splash.jpg"}
              duration={1000}/>
            <Typography sx={[fonts.spartan14, {position: "absolute", top: "26px", borderRadius: "0 4px 4px 0", backgroundColor: "#FFFFFFAA", px: 2, pt: "4px", pb: "1px"}]}>
              {translation.page.cruises.cruiseDetails.cruiseStatus[
                getCruiseStatus(cruise) as keyof typeof translation.page.cruises.cruiseDetails.cruiseStatus]}
            </Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <Container
              sx={{
                height: windowWidth >= MD_BREAKPOINT ? "235px" : undefined,
                my: 2, display: "flex", flexDirection: "column"}}>
              <CruiseDetails cruise={cruise} isAdminView={true}/>
            </Container>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  )
}

export default AdminCruiseCard;