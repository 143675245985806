import {Box, Typography} from "@mui/material";
import {fonts, mainColors} from "../../../../../../../../../app.style";
import {useContext} from "react";
import {AppContext} from "../../../../../../../common/appWrapper/AppWrapper";
import {Booking} from "../../../../../../../../model/Booking";
import {getLocaleDateTime} from "../../../../../../../../utils/misc/helpers";

const BookingTime = ({booking} : {booking: Booking}) => {
  const {translation} = useContext(AppContext);

  return(
        <Box sx={{display: "flex", justifyContent: "space-between"}}>
          <Typography sx={[fonts.spartan12, mainColors.grey, {textAlign: "center"}]}>
            {translation.page.admin.cruiseDetails.timeCreate} {getLocaleDateTime(booking.bookedTime)}
          </Typography>
          {booking.lastUpdatedTime &&
          <Typography sx={[fonts.spartan12, mainColors.grey, {textAlign: "center"}]}>
            {translation.page.admin.cruiseDetails.lastModified} {getLocaleDateTime(booking.lastUpdatedTime)}
          </Typography>}
        </Box>
  )
}

export default BookingTime;