import {Box} from "@mui/material";
import EditBtn from "./editBtn/EditBtn";
import DeleteBtn from "./deleteBtn/DeleteBtn";
import {useContext} from "react";
import {AppModalContext} from "../../appWrapper/AppWrapper";

interface DeleteEditBtnsProps {
  editApiCallback: Function;
  deleteApiCallback: Function;
}

const DeleteEditBtns = ({editApiCallback, deleteApiCallback} : DeleteEditBtnsProps) => {
  const {setIsModalOpen, setIsConfirmModalOpen} = useContext(AppModalContext);

  const editHandler = () => {
    editApiCallback();
    setIsModalOpen(true);
  }

  const deleteHandler = () => {
    setIsConfirmModalOpen(true);
    deleteApiCallback();
  }

  return (
    <Box sx={{display: "flex"}}>
      <EditBtn clickHandler={editHandler}/>
      <DeleteBtn clickHandler={deleteHandler}/>
    </Box>
  )
}

export default DeleteEditBtns;