import {Box, Container, Grid, IconButton, Tab, Tabs, Typography} from "@mui/material";
import {footerBox, footerLegalText, footerMenuContainer, footerMenuTab, footerTitle} from "./appFooter.style";
import {centeredBox, fonts, mainColors, verticalBox} from "../../../../../app.style";
import {useContext, useEffect, useRef} from "react";
import {AppContext} from "../AppWrapper";
import Image from "mui-image";
import {NavMenuContext} from "../navMenu/NavMenu";
import {SM_BREAKPOINT, FOOTER_BREAKPOINT} from "../../guiConstants";
import {openLinkInNewWindow} from "../../../../utils/misc/helpers";
import {ReactComponent as MailIcon} from "../../../../../assets/icons/contact/mail.svg";
import {ReactComponent as PhoneIcon} from "../../../../../assets/icons/contact/phone.svg";
import {ReactComponent as YTIcon} from "../../../../../assets/icons/socialMedia/monochrome/youtube.svg";
import {ReactComponent as FbIcon} from "../../../../../assets/icons/socialMedia/monochrome/facebook.svg";
import {ReactComponent as InstaIcon} from "../../../../../assets/icons/socialMedia/monochrome/instagram.svg";

const AppFooter = () => {
  const {translation, windowWidth, setFooterHeight} = useContext(AppContext);
  const {tab, menuOptions, changeTab} = useContext(NavMenuContext);
  const container = useRef<HTMLDivElement>();

  useEffect(() => {
    setFooterHeight(container.current?.offsetHeight);
  }, [translation, windowWidth, setFooterHeight]);

  return (
    <Box sx={footerBox} ref={container}>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={5} sm={5} md={3}>
            <Typography sx={footerTitle}>
              {translation.footer.sections}
            </Typography>
            <Tabs
              orientation="vertical"
              centered={true}
              value={tab}
              sx={footerMenuContainer}>
              {
                menuOptions.map((option, index) => (
                  <Tab
                    onClick={() => changeTab(index)}
                    sx={footerMenuTab} key={index} label={
                    <Typography sx={[fonts.spartan12]}>{option.title}</Typography>
                  }/>
                ))
              }
            </Tabs>
          </Grid>
          {SM_BREAKPOINT < windowWidth &&
              <Grid item sm={2} md={6} sx={centeredBox}>
                <Box sx={verticalBox}>
                  <Image src={"/images/logo_light.png"} duration={1000} height={64}/>
                </Box>
              </Grid>
          }
          <Grid item xs={7} sm={5} md={3}>
            <Typography sx={[footerTitle, {textAlign: "right"}]}>
              {translation.footer.contact}
            </Typography>
            <Box sx={[verticalBox, {mt: 2}]}>
              <Box sx={{display: "flex", justifyContent: "flex-end"}}>
                {FOOTER_BREAKPOINT <= windowWidth && <MailIcon/>}
                <Typography sx={[fonts.spartan12, mainColors.white, {ml: 1}]}>chacrunasails@gmail.com</Typography>
              </Box>
              <Box sx={{display: "flex", justifyContent: "flex-end", mt: 1}}>
                {FOOTER_BREAKPOINT <= windowWidth && <PhoneIcon />}
                <Typography sx={[fonts.spartan12, mainColors.white, {ml: 1}]}>+48 669 477 375</Typography>
              </Box>
              <Box sx={{display: "flex", justifyContent: "flex-end"}}>
                  <IconButton onClick={() => openLinkInNewWindow("https://www.youtube.com/@ChacrunaSails")}>
                    <YTIcon/>
                  </IconButton>
                  <IconButton onClick={() => openLinkInNewWindow("https://www.facebook.com/chacrunasails")}>
                    <FbIcon/>
                  </IconButton>
                  <IconButton onClick={() => openLinkInNewWindow("https://www.instagram.com/chacruna_sails")}>
                    <InstaIcon/>
                  </IconButton>
              </Box>
            </Box>
          </Grid>
          {SM_BREAKPOINT >= windowWidth &&
            <Grid item sm={12} sx={centeredBox}>
              <Image src={"/images/logo_light.png"} duration={1000} width={64} height={64}/>
            </Grid>
          }
          <Grid item xs={12}>
            <Typography sx={footerLegalText}>
              {translation.footer.disclaimer}
            </Typography>
            <Typography sx={[footerLegalText, {pt: 1}]}>
              {translation.footer.copyright}{new Date().getFullYear()}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default AppFooter;