import AppTextField from "../../appTextField/AppTextField";
import useRegisterFormValidator from "./useRegisterFormValidator";
import {useContext, useState} from "react";
import RegisterRequest from "../../../../service/auth/RegisterRequest";
import {AppContext} from "../../appWrapper/AppWrapper";
import {TextFieldType} from "../../../../utils/appEnums/TextFieldType";
import PasswordAdornment from "../../appTextField/passwordAdornment/PasswordAdornment";
import RegisterButton from "./registerButton/RegisterButton";
import PasswordRequirements from "./passwordRequirements/PasswordRequirements";
import {centeredBox, fonts, mainColors, semiBoldText} from "../../../../../app.style";
import {Box, Grid, Typography} from "@mui/material";
import BackToLoginButton from "./backToLoginButton/BackToLoginButton";
import AppDatePicker from "../../appTimePicker/AppDatePicker";
import useSimpleOpts from "../../appSelectField/appSelectField/simpleOption/useSimpleOpts";
import {Gender} from "../../../../model/modelEnums/Gender";
import AppSelectField from "../../appSelectField/AppSelectField";


const RegisterForm = () => {
  const {translation, selectedLanguage} = useContext(AppContext);
  const [showPassword, setShowPassword] = useState(false);
  const [registerFormData] = useState(new RegisterRequest(selectedLanguage));
  const [showErrorText, setShowErrorText] = useState(false);
  const {simpleOpts: genderOpts} = useSimpleOpts(translation.form.registerForm.gender.options, false);
  const {registerFormValidator, validateRegisterForm,
         passContainsSmall, passContainsCapital, passHasMinRequiredChars
        } = useRegisterFormValidator(translation, registerFormData);

  return (
    <>
      <Grid container spacing={1} sx={{maxWidth: "460px"}}>
        <Grid item xs={12} sm={6}>
          <AppTextField
            formData={""}
            setFormData={registerFormData.setName}
            required={true}
            type={TextFieldType.TEXT}
            label={translation.form.registerForm.name}
            showErrorText={showErrorText}
            validator={registerFormValidator.nameValidator}/>
        </Grid>
        <Grid item xs={12} sm={6}>
          <AppTextField
            formData={""}
            setFormData={registerFormData.setSurname}
            required={true}
            type={TextFieldType.TEXT}
            label={translation.form.registerForm.surname}
            showErrorText={showErrorText}
            validator={registerFormValidator.surnameValidator}/>
        </Grid>
      </Grid>
      <Grid container spacing={1} sx={{maxWidth: "460px"}}>
        <Grid item xs={12}>
          <AppTextField
            formData={""}
            setFormData={registerFormData.setNickname}
            required={true}
            type={TextFieldType.TEXT}
            label={translation.form.registerForm.nickname}
            showErrorText={showErrorText}
            validator={registerFormValidator.nicknameValidator}/>
        </Grid>
        <Grid item xs={12} sm={6}>
          <AppSelectField
            label={translation.form.registerForm.gender.title}
            options={genderOpts}
            selectedValue={Gender.MALE}
            required={true}
            setFormData={registerFormData.setGender}/>
          <Box sx={{mt: "26px"}}/>
        </Grid>
        <Grid item xs={12} sm={6}>
          <AppDatePicker
            formData={registerFormData.born}
            setFormData={registerFormData.setBorn}
            label={translation.form.registerForm.born}
            showErrorText={showErrorText}
            validator={registerFormValidator.bornValidator}
            disableFuture={true}/>
        </Grid>
      </Grid>
      <AppTextField
        formData={""}
        setFormData={registerFormData.setEmail}
        required={true}
        type={TextFieldType.TEXT}
        label={translation.form.loginForm.emailAddress}
        showErrorText={showErrorText}
        validator={registerFormValidator.emailValidator}/>
      <AppTextField
        formData={""}
        setFormData={registerFormData.setPhone}
        type={TextFieldType.PHONE}
        label={translation.form.registerForm.phone}
        showErrorText={showErrorText}
        validator={registerFormValidator.phoneValidator}/>
      <AppTextField
        formData={""}
        setFormData={registerFormData.setPassword}
        required={true}
        type={showPassword ? TextFieldType.TEXT : TextFieldType.PASSWORD}
        label={translation.form.registerForm.password}
        showErrorText={showErrorText}
        passwordAdornment={<PasswordAdornment setShowPassword={setShowPassword} showPassword={showPassword}/>}
        validator={registerFormValidator.passwordValidator}
        formValidatorCallback={validateRegisterForm}/>
      <AppTextField
        formData={""}
        setFormData={registerFormData.setRepeatPassword}
        required={true}
        type={showPassword ? TextFieldType.TEXT : TextFieldType.PASSWORD}
        label={translation.form.registerForm.repeatPassword}
        showErrorText={showErrorText}
        validator={registerFormValidator.passwordRepeatValidator}
        formValidatorCallback={validateRegisterForm}/>
      <PasswordRequirements
        passContainsSmall={passContainsSmall}
        passContainsCapital={passContainsCapital}
        passHasMinRequiredChars={passHasMinRequiredChars}/>
      <RegisterButton
        formData={registerFormData}
        registerFormValidator={registerFormValidator}
        setShowErrorText={setShowErrorText}/>
      <Box sx={[centeredBox, {mt: 3}]}>
        <Typography align="center" sx={[fonts.spartan14, semiBoldText, mainColors.contrast]}>
          {translation.form.registerForm.alreadyHaveAccount}
        </Typography>
        <Box sx={{mx: 1}}/>
        <BackToLoginButton/>
      </Box>
    </>
  )
}

export default RegisterForm;