export const validatorSettings =  {
  nameMaxChars: 15,
  surnameMaxChars: 20,
  nicknameMaxChars: 15,
  emailMaxChars: 50,
  passwordMinChars: 8,
  passwordMaxChars: 50,
  reviewTitleMaxChars: 100,
  reviewDescMaxChars: 500,
  codeMaxChars: 10,
  cruiseTitle: 100,
  cruiseDescription: 1000,
  cruisePlan: 3000,
  youtubeLink: 50,
  location: 50,
  splashImg: 1000,
};