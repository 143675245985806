import {mainColors} from "../../../../../../app.style";

export const mobileMenuPaperStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  backgroundColor: "white"
}

export const bottomNavigationStyle = {
  display: "flex",
  flexGrow: 1,
  justifyContent: "space-between",
  '& .MuiBottomNavigationAction-root': mainColors.contrast
}

export const menuDrawerBox = {
  display: "flex",
  flexDirection: "column",
  height: "100vh",
  justifyContent: "center"
}

export const logoBox = {
  display: "flex",
  mx: 1,
  height: "64px",
  justifyContent: "center"
}

export const tabsContainer = {
  my: 10,
  borderRight: 1,
  borderColor: 'divider'
}