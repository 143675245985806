import {fonts, mainColors, rainbowColorsDark} from "../../../../../app.style";
import {Box, Typography} from "@mui/material";
import {useContext} from "react";
import {AppContext} from "../../appWrapper/AppWrapper";
import {CruiseDifficulty} from "../../../../model/modelEnums/CruiseDifficulty";

const CruiseDifficultyLabel = ({difficulty}: {difficulty: CruiseDifficulty}) => {
  const {translation} = useContext(AppContext)

  const getBgColor = () => {
    switch (difficulty) {
      case CruiseDifficulty.TRIVIAL:
        return rainbowColorsDark.green.color;
      case CruiseDifficulty.EASY:
        return rainbowColorsDark.blue.color;
      case CruiseDifficulty.MEDIUM:
        return rainbowColorsDark.orange.color;
      case CruiseDifficulty.HARD:
        return rainbowColorsDark.red.color;
      case CruiseDifficulty.EXTREME:
        return mainColors.contrast.color;
    }
  }

  return (
    <Box sx={{display: "flex", pt: 1}}>
      <Typography sx={[fonts.spartan12, {display: "flex", textAlign: "left", alignItems: "end"}]}>
        {translation.page.cruises.cruiseDetails.difficulty}&nbsp;
      </Typography>
      <Box sx={{bgcolor: getBgColor(), borderRadius: "8px", px: 1, pt: "3px"}}>
        <Typography sx={[fonts.spartan12, mainColors.white]}>
          {translation.page.cruises.cruiseDetails.difficulties[
            difficulty as keyof typeof translation.page.cruises.cruiseDetails.difficulties]}
        </Typography>
      </Box>
    </Box>
    )
}

export default CruiseDifficultyLabel;