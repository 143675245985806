import {Button} from "@mui/material";
import {useContext} from "react";
import {AppContext} from "../../appWrapper/AppWrapper";
import SmallSpinner from "../../appSpinner/SmallSpinner";
import {button, fonts, semiBoldText} from "../../../../../app.style";


interface SaveButtonProps {
  handleSave: Function;
  disabled?: boolean;
  sx?: any
}

const SaveButton = (props: SaveButtonProps) => {
  const {translation} = useContext(AppContext);

  return (
    <Button variant="contained"
            disabled={props.disabled}
            disableElevation fullWidth
            sx={[fonts.spartan14, semiBoldText, button, props.sx]}
            onClick={() => props.handleSave()}>
      {props.disabled ?
        <SmallSpinner/> :
        translation.save
      }
    </Button>
  )
}

export default SaveButton;