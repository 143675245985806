import {apiUrl} from "../urls";
import {useEffect} from "react";
import usePost from "../axios/usePost";
import {updateCache} from "../helpers/cacheUtils";
import {clone} from "../../utils/misc/helpers";
import {Discount} from "../../model/Discount";


const usePostDiscount = (discounts: Discount[], setDiscounts: Function) => {
  const {
    data,
    setData,
    loading: postDiscountLoading,
    postData: postDiscount
  } = usePost(apiUrl.discounts);

  useEffect(() => {
    if (data) {
      const postResponse = data as Discount;
      const cacheClone = clone(discounts);
      updateCache([postResponse], cacheClone);
      setDiscounts(cacheClone);
      setData(null);
    }
  }, [data, discounts, setDiscounts, setData])

  return {postDiscountLoading, postDiscount}
}

export default usePostDiscount;