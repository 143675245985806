import {button, centeredBox, fonts, semiBoldText} from "../../../../../../../../app.style";
import {Box, Button} from "@mui/material";
import {useContext} from "react";
import {AppContext, AppFsDrawerContext, AppModalContext} from "../../../../../../common/appWrapper/AppWrapper";
import {Cruise} from "../../../../../../../model/Cruise";
import {Booking} from "../../../../../../../model/Booking";
import BookingForm from "../../../../../../common/appForm/bookingForm/BookingForm";
import {BookingStatus} from "../../../../../../../model/modelEnums/BookingStatus";
import ManageBookingModalContent from "../../../../../../common/appModal/manageBookingModalContent/ManageBookingModalContent";

const ChangeStatusBtn = ({booking, cruise} : {booking: Booking, cruise: Cruise}) => {
  const {translation} = useContext(AppContext);
  const {setIsFsDrawerOpen, setFsDrawerChildren} = useContext(AppFsDrawerContext);
  const {setModalHeaderText, setModalChildren, setIsModalOpen} = useContext(AppModalContext);

  const showBookingDetails = () => {
    if (booking.bookingStatus === BookingStatus.PENDING){
      setFsDrawerChildren(<BookingForm cruise={cruise} step={2} booking={booking}/>);
      setIsFsDrawerOpen(true);
    } else {
      setModalHeaderText(translation.modalHeader.manageBooking);
      setModalChildren(<ManageBookingModalContent cruise={cruise} booking={booking}/>);
      setIsModalOpen(true);
    }
  }

  return (
    <Box sx={centeredBox}>
      <Button
        variant="contained"
        disableElevation
        fullWidth
        disabled={booking.bookingStatus === BookingStatus.PROCESSING || booking.bookingStatus === BookingStatus.CANCELED}
        onClick={() => showBookingDetails()}
        sx={[fonts.spartan14, semiBoldText, button, {maxWidth: "330px"}]}>
        {booking.bookingStatus === BookingStatus.PENDING || booking.bookingStatus === BookingStatus.PROCESSING ?
          translation.page.bookings.bookingDetails.finalizeBooking :
          translation.page.bookings.bookingDetails.manageBooking}
      </Button>
    </Box>
  )
}

export default ChangeStatusBtn;