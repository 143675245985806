import {useContext, useState} from "react";
import {AppContext, AppModalContext} from "../../appWrapper/AppWrapper";
import {Box, Button} from "@mui/material";
import {button, centeredBox, fonts, mainColors, semiBoldText} from "../../../../../app.style";
import SmallSpinner from "../../appSpinner/SmallSpinner";
import ReviewForm from "../../appForm/reviewForm/ReviewForm";


const WriteReviewBtn = ({cruiseId} : {cruiseId: string}) => {
  const [disabled, setDisabled] = useState(false);
  const {translation} = useContext(AppContext);
  const {setModalHeaderText, setModalChildren, setIsModalOpen} = useContext(AppModalContext);

  const handleClick = () => {
    setDisabled(true)
    setModalHeaderText(translation.modalHeader.writeReview);
    setModalChildren(<ReviewForm setWriteBtnDisabled={setDisabled} cruiseId={cruiseId as string}/>);
    setIsModalOpen(true);
  }

  return (
    <Box sx={centeredBox}>
      <Button variant="contained"
              disabled={disabled}
              disableElevation
              sx={[fonts.spartan14, semiBoldText, button, {display: "flex", flexGrow: 1, maxWidth: "330px", background: mainColors.primary.color}]}
              onClick={() => handleClick()}>
        {disabled ? <SmallSpinner/> : translation.page.cruises.cruiseDetails.reviews.writeReview}
      </Button>
    </Box>
  )
}

export default WriteReviewBtn;