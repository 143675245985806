import {BottomNavigation, BottomNavigationAction, Box, Paper, Tab, Tabs, Typography} from "@mui/material";
import {MenuOutlined, Person, PersonOutlined} from '@mui/icons-material';
import {AppMenuProps} from "../AppMenuProps";
import {useContext, useEffect, useRef, useState} from "react";
import {AppContext} from "../../AppWrapper";
import AppSelectField from "../../../appSelectField/AppSelectField";
import {bottomNavigationStyle, logoBox, menuDrawerBox, mobileMenuPaperStyle, tabsContainer} from "./mobileMenu.style";
import MenuDrawer from "./menuDrawer/MenuDrawer";
import {boldText, fonts, mainColors} from "../../../../../../app.style";
import Image from "mui-image";
import {NavMenuContext} from "../NavMenu";


const MobileMenu = (props: AppMenuProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const {appUser, selectedLanguage, translation, windowWidth, setMenuHeight} = useContext(AppContext);
  const {tab, menuOptions, changeTab, langOpts} = useContext(NavMenuContext);
  const container = useRef<HTMLDivElement>();

  const handleMobileMenuClick = (index: number) => {
    changeTab(index);
    setIsOpen(false);
  }

  useEffect(() => {
    setMenuHeight(container.current?.offsetHeight);
  }, [windowWidth, setMenuHeight]);

  return (
    <>
      <Paper elevation={3}>
        <Box sx={mobileMenuPaperStyle} ref={container}>
          <BottomNavigation sx={bottomNavigationStyle} showLabels>
            <BottomNavigationAction
              label={translation.navMenu.menu}
              icon={<MenuOutlined />}
              onClick={() => setIsOpen(true)} />
            <BottomNavigationAction
              label={appUser ? translation.navMenu.settings : translation.navMenu.login}
              icon={appUser ? <Person/> : <PersonOutlined/>}
              onClick={() => props.handlePersonBtnClick()}/>
          </BottomNavigation>
          <Box sx={{width: "80px", mr: 3}}>
            <AppSelectField
              label={translation.mobileLangLabel}
              options={langOpts}
              selectedValue={selectedLanguage}
              setFormData={props.setLang}/>
          </Box>
        </Box>
      </Paper>
      <MenuDrawer isOpen={isOpen} setIsOpen={setIsOpen}>
        <Box sx={menuDrawerBox}>
          <Box sx={logoBox}>
            <Image src={"/images/logo_dark.png"} duration={1000} width={64} />
          </Box>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={tab}
            sx={tabsContainer}
          >
            {
              menuOptions.map((option, index) => (
                <Tab
                  onClick={() => handleMobileMenuClick(index)}
                  sx={mainColors.contrast} key={index} label={
                  <Typography sx={[fonts.navRunic36, boldText, {my: -1}]}>{option.title}</Typography>
                }/>
              ))
            }
          </Tabs>
        </Box>
      </MenuDrawer>
    </>
  )
}

export default MobileMenu;