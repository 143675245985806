import AbstractValidator from "./AbstractValidator";
import {Translation} from "../../../language/translation/translations";


export default class NumberValidator implements AbstractValidator {
  private _translation: Translation;
  private _isValid= false;
  private _errorText = ".";
  private readonly _minVal: number;
  private readonly _maxVal: number;

  constructor(translation: Translation, minVal: number, maxVal: number) {
    this._translation = translation;
    this._minVal = minVal;
    this._maxVal = maxVal;
  }

  validate(input: number) {
    this._isValid = false;
    if (this._minVal > input || this._maxVal < input){
      this._errorText = this._translation.formErrors.rangeBetween + " " + this._minVal + "-" + this._maxVal;
    } else {
      this._errorText = "."
      this._isValid = true;
    }
  }

  public set translation(translation: Translation) {
    this._translation = translation;
  }

  public get isValid(){
    return this._isValid;
  }

  public set isValid(isValid: boolean){
    this._isValid = isValid;
  }

  public get errorText(){
    return this._errorText;
  }

  public set errorText(text: string){
    this._errorText = text;
  }
}