import {centeredBox, mainColors, fonts, semiBoldText, mediumText} from "../../../../app.style";
import {Accordion, AccordionDetails, AccordionSummary, Box, Container, Typography} from "@mui/material";
import {ExpandMore} from '@mui/icons-material';
import {SyntheticEvent, useContext, useState} from "react";
import {AppContext} from "../../common/appWrapper/AppWrapper";


const FaqPage = () => {
  const {translation} = useContext(AppContext);
  const [expanded, setExpanded] = useState<number | false>(0);

  const handleChange =
    (panel: number) => (event: SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const getBorderRadius = (index: number) => {
    if (index === 0) {
      return {borderTopLeftRadius: "20px !important", borderTopRightRadius: "20px !important"}
    } else if (index === Object.values(translation.page.faq.questions).length - 1) {
      return {borderBottomLeftRadius: "20px !important", borderBottomRightRadius: "20px !important"}
    }
  }

  return (
    <Box sx={{mb: 5}}>
      <Box sx={[centeredBox, {my: 3}]}>
        <Typography sx={[fonts.spartan20, semiBoldText, mainColors.contrast]}>
          {translation.page.faq.title}
        </Typography>
      </Box>
      <Container>
        {Object.values(translation.page.faq.questions).map((v, index) => {
          return (
            <Accordion elevation={5} key={index} expanded={expanded === index} onChange={handleChange(index)} sx={getBorderRadius(index)}>
              <AccordionSummary expandIcon={<ExpandMore color={"primary"}/>}>
                <Typography sx={[fonts.spartan16, mediumText]}>{v.title}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography sx={[fonts.spartan14]} style={{whiteSpace: 'pre-line'}}>
                  {v.content1}
                </Typography>
                <Typography sx={[fonts.spartan14, {my: 2}]} style={{whiteSpace: 'pre-line'}}>
                  {v.content2}
                </Typography>
                <Typography sx={[fonts.spartan14]} style={{whiteSpace: 'pre-line'}}>
                  {v.content3}
                </Typography>
              </AccordionDetails>
            </Accordion>)
        })}
      </Container>
    </Box>
  )
}

export default FaqPage;