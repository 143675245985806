import {Cruise} from "../../../../../../model/Cruise";
import {Box, Grid, Typography} from "@mui/material";
import {fonts, mainColors} from "../../../../../../../app.style";
import {useContext} from "react";
import {AppContext} from "../../../../appWrapper/AppWrapper";
import {MD_BREAKPOINT, SM_BREAKPOINT} from "../../../../guiConstants";
import Image from "mui-image";
import CruiseTitle from "../../../../cruiseDetails/cruiseTitle/CruiseTitle";
import {apiUrl} from "../../../../../../service/urls";

const BookedCruiseInfo = ({cruise} : {cruise: Cruise}) => {
  const {windowWidth, translation} = useContext(AppContext);

  const getDurationDays = () => {
    const startTime = new Date(cruise.startDay).getTime();
    const finishTime = new Date(cruise.finishDay).getTime();
    return Math.round((finishTime - startTime) / (1000 * 3600 * 24));
  }

  return(
    <Grid container justifyContent={"center"} spacing={3}>
      <Grid item xs={12} md={6} lg={5}>
        <Box sx={{maxWidth: windowWidth > MD_BREAKPOINT ? 410 : undefined}}>
          <Image
            height={windowWidth > SM_BREAKPOINT ? 265 : undefined}
            style={{maxWidth: 410, alignSelf: "flex-start"}}
            src={cruise.splashImg ? apiUrl.cruises + "/" + cruise.splashImg : "/images/mock/default_splash.jpg"}
            duration={1000}/>
        </Box>
      </Grid>
      <Grid item xs={12} md={6} lg={7}>
        <Grid container direction="row" justifyContent="center" sx={{height: "100%"}}>
          <Grid item xs={12}>
            <Typography sx={[fonts.spartan16, mainColors.contrast, {display: "flex", justifyContent: "center"}]}>
              {translation.form.newBooking.bookingDataStep.bookedCruiseInfo.title}
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{display: "flex", alignItems: "center", my: 1}}>
            <Grid container spacing={1}>
              <Grid item xs={6} sm={3} md={6} lg={3}>
                <Typography sx={[fonts.spartan14, mainColors.grey, {display: "flex", justifyContent: "flex-end"}]}>
                  {translation.form.newBooking.bookingDataStep.bookedCruiseInfo.from}
                </Typography>
                <Typography sx={[fonts.spartan14, mainColors.grey, {display: "flex", justifyContent: "flex-end"}]}>
                  {translation.form.newBooking.bookingDataStep.bookedCruiseInfo.embarkDate}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={3} md={6} lg={3}>
                <Typography sx={[fonts.spartan14, mainColors.contrast]}>
                  {cruise.startLocation}
                </Typography>
                <Typography sx={[fonts.spartan14, mainColors.contrast]}>
                  {cruise.startDay}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={3} md={6} lg={3}>
                <Typography sx={[fonts.spartan14, mainColors.grey, {display: "flex", justifyContent: "flex-end"}]}>
                  {translation.form.newBooking.bookingDataStep.bookedCruiseInfo.to}
                </Typography>
                <Typography sx={[fonts.spartan14, mainColors.grey, {display: "flex", justifyContent: "flex-end"}]}>
                  {translation.form.newBooking.bookingDataStep.bookedCruiseInfo.disembarkDate}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={3} md={6} lg={3}>
                <Typography sx={[fonts.spartan14, mainColors.contrast]}>
                  {cruise.finishLocation}
                </Typography>
                <Typography sx={[fonts.spartan14, mainColors.contrast]}>
                  {cruise.finishDay}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{display: "flex", alignItems: "center", my: 1}}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography sx={[fonts.spartan14, mainColors.grey, {display: "flex", justifyContent: "flex-end"}]}>
                  {translation.form.newBooking.bookingDataStep.bookedCruiseInfo.duration}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={[fonts.spartan14, mainColors.contrast]}>
                  {getDurationDays()}{translation.form.newBooking.bookingDataStep.bookedCruiseInfo.days}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{display: "flex", alignItems: "end"}}>
            <CruiseTitle titlePl={cruise.titlePl} titleEn={cruise.descriptionEn}/>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default BookedCruiseInfo;