import {Participant} from "../../../../model/Participant";
import {Box, Typography} from "@mui/material";
import {fonts, mainColors, mediumText} from "../../../../../app.style";
import {AppContext} from "../../appWrapper/AppWrapper";
import {useContext} from "react";

const ParticipantsModalContent = ({participants} : {participants: Participant[]}) => {
  const {translation} = useContext(AppContext)

  return (
    <>
      {participants.map((p, index) =>
      <Box key={index} sx={{my: 2}}>
        <Typography sx={[fonts.spartan14, mainColors.contrast, mediumText]}>
          {p.name} {p.surname}
        </Typography>
        <Typography sx={[fonts.spartan12, mainColors.grey]}>
          {translation.form.registerForm.gender.options[p.gender]}
        </Typography>
        <Typography sx={[fonts.spartan12, mainColors.grey]}>
          {p.born}
        </Typography>
      </Box>
      )}
    </>
  )
}

export default ParticipantsModalContent;