import {Container} from "@mui/material";
import TokenReader from "../../common/TokenReader";
import {useState} from "react";
import {apiUrl} from "../../../service/urls";
import PassSetupForm from "../../common/appForm/passSetupForm/PassSetupForm";
import {PassChange} from "../../../utils/appEnums/PassChange";
import PassChangeSuccess from "./PassChangeSuccess";
import PassChangeFailure from "./PassChangeFailure";
import {centeredBox} from "../../../../app.style";


const PasswordResetPage = () => {
  const [passChangeStatus, setPassChangeStatus] = useState(PassChange.IN_PROGRESS);

  return (
    <TokenReader apiUrl={apiUrl.reset}>
      <Container sx={[centeredBox, {mb: 3}]}>
        {passChangeStatus === PassChange.IN_PROGRESS ? <PassSetupForm setPassChangeStatus={setPassChangeStatus}/> :
          passChangeStatus === PassChange.SUCCESS ? <PassChangeSuccess/> : <PassChangeFailure/>}
      </Container>
    </TokenReader>
  )
}

export default PasswordResetPage;