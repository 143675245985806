import {apiUrl} from "../urls";
import {useEffect} from "react";
import usePost from "../axios/usePost";
import {AppUser} from "../../model/AppUser";


const useUpdateSelf = (appUser: AppUser | undefined, setAppUser: Function) => {
  const {
    data,
    setData,
    loading: updateSelfLoading,
    postData: updateSelf,
  } = usePost(apiUrl.users);

  useEffect(() => {
    if (data) {
      const postResponse = data as AppUser;
      setAppUser(postResponse);
      setData(null);
    }
  }, [data, appUser, setAppUser, setData])

  return {updateSelfLoading, updateSelf}
}

export default useUpdateSelf;