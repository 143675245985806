import {Box, Grid, Typography} from "@mui/material";
import {fonts, mainColors, semiBoldText} from "../../../../../../../app.style";
import {useContext} from "react";
import {AppContext} from "../../../../appWrapper/AppWrapper";
import {ALL_BUNKS} from "../../../../../../model/modelConstants/constants";
import {selectedAllBerthsStyle, selectedNotAllBerthsStyle} from "./bunksSelector.style";
import {GuiColors} from "../../../../../../utils/appEnums/GuiColors";
import BunkCheckbox from "./bunkCheckbox/BunkCheckbox";


interface BunksSelectorProps {
  passengerQty: number;
  selectedBunks: number[];
  setSelectedBunks: Function;
  getBunkStatus: Function;
  showError: boolean;
}

const BunksSelector = (props: BunksSelectorProps) => {
  const {translation} = useContext(AppContext);

  const getBadgeStyle = () => {
    return props.selectedBunks.length === props.passengerQty ? selectedAllBerthsStyle : selectedNotAllBerthsStyle;
  }

  return (
    <Grid container height="100%" spacing={2} >
      <Grid item xs={12} sx={{display: "flex", alignItems: "flex-end"}}>
        <Box sx={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
          <Typography sx={[fonts.spartan16, mainColors.contrast, {mb: 2}]}>
            {translation.choose} {translation.form.newBooking.bunksSelectionStep.title}
          </Typography>
          <Box sx={getBadgeStyle()}>
            <Typography sx={[fonts.spartan12, mainColors.white, {display: "flex", alignItems: "center", mt: "2px"}]}>
              {translation.form.newBooking.bunksSelectionStep.selected} {props.selectedBunks.length}/{props.passengerQty}
            </Typography>
          </Box>
        </Box>
      </Grid>
      {ALL_BUNKS.map((bunkNo, index) =>
      <Grid key={index} item xs={12} sm={6} sx={{display: "flex", alignItems: "center"}}>
        <BunkCheckbox
          bunkNo={bunkNo}
          getBunkStatus={props.getBunkStatus}
          passengerQty={props.passengerQty}
          selectedBunks={props.selectedBunks}
          setSelectedBunks={props.setSelectedBunks}/>
      </Grid>)}
      <Grid item xs={12} sx={{display: "flex", alignItems: "center"}}>
        {props.showError ?
          <Typography sx={[fonts.spartan14, semiBoldText, {py: "4px"}]} color={GuiColors.ERROR}>
            {translation.formErrors.youMustChoose} {props.passengerQty} {translation.formErrors.bunks}
          </Typography> : <Box sx={{pt: "29px"}}/>}
      </Grid>
    </Grid>
  )
}

export default BunksSelector;