import BookingRequest from "../../../../../service/booking/BookingRequest";
import ParticipantFormValidator from "./participantsData/participantData/ParticipantFormValidator";
import CheckboxValidator from "../../../../../utils/validators/CheckboxValidator";
import {Translation} from "../../../../../../language/translation/translations";


export default class BookingDataStepValidator {
  translation: Translation;
  bookingRequest: BookingRequest;
  participantsValidator: ParticipantFormValidator[];
  reservationPolicyValidator: CheckboxValidator;
  reservationProcessingValidator: CheckboxValidator;

  constructor(translation: Translation, bookingRequest: BookingRequest) {
    this.translation = translation;
    this.bookingRequest = bookingRequest;
    this.participantsValidator = bookingRequest.participants.map((p) =>
      new ParticipantFormValidator(translation, p));
    this.reservationPolicyValidator = new CheckboxValidator(translation, true);
    this.reservationProcessingValidator = new CheckboxValidator(translation, true);
  }

  validateForm = () => {
    let participantsValidatorIsValid = true;
    this.participantsValidator.forEach((v) => {if (!v.validateForm()) participantsValidatorIsValid = false});
    this.reservationPolicyValidator.validate(this.bookingRequest.isPolicyCheck);
    this.reservationProcessingValidator.validate(this.bookingRequest.isProcessingCheck);

    return (
      participantsValidatorIsValid &&
      this.reservationPolicyValidator.isValid &&
      this.reservationProcessingValidator.isValid
    )
  }
}