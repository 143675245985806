import {Box, Divider, Grid, Typography} from "@mui/material";
import {Cruise} from "../../../../model/Cruise";
import {useCallback, useContext, useEffect, useRef, useState} from "react";
import {AppContext, AppFsDrawerContext, AppModalContext} from "../../appWrapper/AppWrapper";
import BookingRequest from "../../../../service/booking/BookingRequest";
import {Booking} from "../../../../model/Booking";
import Image from "mui-image";
import BookedCruiseInfo from "../bookingForm/bookingDataStep/bookedCruiseInfo/BookedCruiseInfo";
import BunksSelector from "../bookingForm/bunksSelectionStep/bunksSelector/BunksSelector";
import BunkIndicator from "../bookingForm/bunksSelectionStep/bunkIndicator/BunkIndicator";
import {ALL_BUNKS} from "../../../../model/modelConstants/constants";
import BunksLegend from "../bookingForm/bunksSelectionStep/bunksLegend/BunksLegend";
import {BunkStatus} from "../../../../utils/appEnums/BunkStatus";
import {fonts, mainColors} from "../../../../../app.style";
import ParticipantData from "../bookingForm/bookingDataStep/participantsData/participantData/ParticipantData";
import ParticipantFormValidator
  from "../bookingForm/bookingDataStep/participantsData/participantData/ParticipantFormValidator";
import CancelButton from "../../appButton/cancelButton/CancelButton";
import {SM_BREAKPOINT} from "../../guiConstants";
import SaveButton from "../../appButton/saveButton/SaveButton";
import {AuthContext} from "../../../../../App";
import {clone, strEquals} from "../../../../utils/misc/helpers";
import SimpleTextChild from "../../appModal/confirmModal/simpleTextChild/SimpleTextChild";
import {AppRole} from "../../../../model/modelEnums/AppRole";

const ManageBookingForm = ({cruise, booking}: {cruise: Cruise, booking: Booking}) => {
  const {jwt, role} = useContext(AuthContext);
  const {translation, windowWidth, appCacheApi} = useContext(AppContext);
  const {setIsFsDrawerOpen, setOnFsDrawerClose} = useContext(AppFsDrawerContext);
  const {setConfirmModalHeaderText, setConfirmModalHandler, setIsConfirmModalOpen, setConfirmModalChildren} = useContext(AppModalContext);
  const [bookingFormData] = useState(BookingRequest.fromExisting(booking));
  const [initialFormData] = useState(clone(bookingFormData));
  const [participantsValidator] = useState(bookingFormData.participants.map((p) =>
    new ParticipantFormValidator(translation, p)));
  const [selectedBunks, setSelectedBunks] = useState(bookingFormData.bunks);
  const [showParticipantError, setShowParticipantError] = useState(false);
  const [showBunkError, setShowBunkError] = useState(false);
  const [containerWidth, setContainerWidth] = useState(0);
  const layout = useRef<HTMLDivElement>();

  const getBunkStatus = (bunkNo: number) => {
    if (selectedBunks.includes(bunkNo)) {
      return BunkStatus.SELECTED;
    } else {
      if (cruise.availBunks.includes(bunkNo) || bookingFormData.initialBunks.includes(bunkNo)) {
        return BunkStatus.FREE;
      } else {
        return BunkStatus.TAKEN;
      }
    }
  }

  const handleSave = () => {
    let participantsValidatorIsValid = true;
    participantsValidator.forEach((v) => {if (!v.validateForm()) participantsValidatorIsValid = false});
    if (selectedBunks.length === bookingFormData.participants.length && participantsValidatorIsValid){
      setIsFsDrawerOpen(false);
      appCacheApi.postBooking(bookingFormData, jwt, "", () => {
        if (role === AppRole.ADMIN) appCacheApi.getCruiseBookings(booking.cruiseId, jwt);
        appCacheApi.getCruises("/" + booking.cruiseId);
      });
    } else {
      participantsValidatorIsValid ? setShowParticipantError(false) : setShowParticipantError(true);
      selectedBunks.length === bookingFormData.participants.length ? setShowBunkError(false) : setShowBunkError(true);
    }
  }

  const handleCancel = () => {
    closeDrawer();
  }

  const closeDrawer = useCallback(() => {
    if (strEquals(bookingFormData, initialFormData)){
      setIsFsDrawerOpen(false);
    } else {
      setConfirmModalHeaderText(translation.modalHeader.closeBookingDetailsConfirm);
      setConfirmModalChildren(<SimpleTextChild text={translation.modalPrompt.allUnsavedDataWillBeLost}/>);
      setConfirmModalHandler(() => () => {
        setIsFsDrawerOpen(false);
      });
      setIsConfirmModalOpen(true);
    }
  },[bookingFormData, initialFormData, setConfirmModalChildren, setConfirmModalHandler, setConfirmModalHeaderText, 
    setIsConfirmModalOpen, setIsFsDrawerOpen, translation])

  useEffect(() => {
    if (layout.current) setContainerWidth(layout.current.offsetWidth);
  }, [layout.current?.offsetWidth]);

  useEffect(() => {
    bookingFormData.setBunks(selectedBunks);
    //eslint --disable-next-line
  }, [bookingFormData, selectedBunks]);

  useEffect(() => {
    setOnFsDrawerClose(() => closeDrawer)
  }, [closeDrawer, setOnFsDrawerClose]);

  return (
    <Box sx={{ width: '100%', mt: 2 }}>
      <Box sx={{mt: 2}}>
        <Grid container spacing={3} justifyContent={"center"}>
          <Grid item xs={12}>
            <BookedCruiseInfo cruise={cruise}/>
          </Grid>
          <Grid item xs={12}>
            <Divider/>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box sx={{display: "flex"}}>
              <Typography sx={[fonts.spartan16, mainColors.contrast, {mb: 2}]}>
                {translation.form.newBooking.bookingDataStep.passengerDetails.title}
              </Typography>
            </Box>
            {bookingFormData.participants.map((participant, index) =>
              <ParticipantData
                key={index}
                index={index}
                bookingForm={bookingFormData}
                participantFormData={participant}
                participantValidator={participantsValidator[index]}
                showErrorText={showParticipantError}/>)}
          </Grid>
          <Grid item xs={12}>
            <Divider/>
          </Grid>
        </Grid>
        <Grid container spacing={2} justifyContent={"center"}>
          <Grid item xs={12} sm={6}>
            <BunksLegend/>
            <Box sx={{display: "flex", justifyContent: "center", mb: 2}}>
              <Box ref={layout} sx={{position: "relative", width: "170px"}}>
                <Image
                  src={"/images/layout/vertical/layout_numbers_v.png"}
                  duration={0} style={{maxWidth: "170px"}} />
                {ALL_BUNKS.map((bunkNo, index) =>
                  <BunkIndicator key={index} img={`layout_${bunkNo}`}
                                 status={getBunkStatus(bunkNo)} width={containerWidth}/>
                )}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} sx={{mt: 1, mb: 2}}>
            <BunksSelector
              passengerQty={bookingFormData.participants.length}
              selectedBunks={selectedBunks}
              getBunkStatus={getBunkStatus}
              setSelectedBunks={setSelectedBunks}
              showError={showBunkError}/>
          </Grid>
          <Grid item xs={12} sx={{mb: 5}}>
            <Grid container spacing={2} justifyContent={"center"}>
              <Grid item xs={12} sm={6}>
                <Box sx={{display: "flex", justifyContent: windowWidth >= SM_BREAKPOINT ? "flex-start" : "center"}}>
                  <CancelButton handleCancel={handleCancel} sx={{maxWidth: "330px"}}/>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box sx={{display: "flex", justifyContent: windowWidth < SM_BREAKPOINT ? "center" : "flex-end"}}>
                  <SaveButton handleSave={handleSave} sx={{maxWidth: "330px"}}/>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default ManageBookingForm;