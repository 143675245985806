export const MD_BREAKPOINT = 900;

export const SM_BREAKPOINT = 600;

export const MODAL_BREAKPOINT = 700;

export const FOOTER_BREAKPOINT = 380;

export const MENU_DRAWER_WIDTH = 320;

export const ANIMATION_SPEED = 500;