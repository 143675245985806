import SubStep from "../../subStep/SubStep";
import {Box, Typography} from "@mui/material";
import {fonts, mainColors, semiBoldText} from "../../../../../../../app.style";
import {useContext, useState} from "react";
import {AppContext} from "../../../../appWrapper/AppWrapper";
import AppTextField from "../../../../appTextField/AppTextField";
import BookingRequest from "../../../../../../service/booking/BookingRequest";
import {GuiColors} from "../../../../../../utils/appEnums/GuiColors";
import UseCodeBtn from "./useCodeBtn/UseCodeBtn";
import {AuthContext} from "../../../../../../../App";
import {Booking} from "../../../../../../model/Booking";
import {MD_BREAKPOINT} from "../../../../guiConstants";


interface DiscountCodeProps {
  bookingFormData: BookingRequest;
  setBookingFormData: Function;
  bookingCollisionHandler: Function;
}

const DiscountCode = ({bookingFormData, setBookingFormData, bookingCollisionHandler} : DiscountCodeProps) => {
  const {jwt} = useContext(AuthContext);
  const {translation, windowWidth, appCacheApi} = useContext(AppContext);
  const [isError, setIsError] = useState(false);
  const [isValid, setIsValid] = useState(bookingFormData.discountCode !== "");
  const [disabled, setDisabled] = useState(isValid);
  const [discountCode, setDiscountCode] = useState(bookingFormData.discountCode);

  const handleUseCode = () => {
    bookingFormData.setDiscountCode(discountCode);
    if (bookingFormData.discountCode !== "") {
      setDisabled(true);
      appCacheApi.postBooking(bookingFormData, jwt, "",
        (b: Booking) => {
          setIsValid(true);
          setIsError(false);
          setBookingFormData(BookingRequest.fromExisting(b))
        },
        (status: number) => {
          if (status === 404) {
            setIsError(true);
            bookingFormData.setDiscountCode("");
          } else if (status === 409) {
            bookingCollisionHandler();
          }
          setDisabled(false);
        }
      );
    }
  }

  return(
    <>
      <Box sx={{display: "flex"}}>
        <SubStep subStepNo={1} totalSteps={2}/>
        <Typography sx={[fonts.spartan16, mainColors.contrast, {mb: 2}]}>
          {translation.form.newBooking.paymentStep.discountCode.title}
        </Typography>
      </Box>
      <Box sx={{display: "flex", justifyContent: windowWidth < MD_BREAKPOINT ? "center" : undefined}}>
        <Box sx={{maxWidth: "330px"}}>
          <Typography sx={[fonts.spartan12, mainColors.contrast, {mb: 2}]}>
            {translation.form.newBooking.paymentStep.discountCode.description}
          </Typography>
          <AppTextField
            type={"text"}
            disabled={disabled}
            variant={"outlined"}
            sx={{maxWidth: "330px"}}
            label={translation.form.newBooking.paymentStep.discountCode.label}
            formData={discountCode}
            setFormData={setDiscountCode}/>
          {isError || isValid ?
          <Typography sx={[fonts.spartan12, semiBoldText, {py: "4px"}]}
                    color={isError ? GuiColors.ERROR : GuiColors.SECONDARY}>
            {isError ?
              translation.form.newBooking.paymentStep.discountCode.invalid :
              translation.form.newBooking.paymentStep.discountCode.valid}
          </Typography> : <Box sx={{height: "26px"}}/>}
          <UseCodeBtn
            disabled={disabled}
            label={translation.form.newBooking.paymentStep.discountCode.useCode}
            handleUse={handleUseCode}/>
        </Box>
      </Box>
    </>
  )
}

export default DiscountCode;