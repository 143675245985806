import {Button} from "@mui/material";
import {useCallback, useContext} from "react";
import {AppContext, AppModalContext} from "../../../appWrapper/AppWrapper";
import {button, fonts, semiBoldText} from "../../../../../../app.style";
import {AuthContext} from "../../../../../../App";


const LogoutButton = () => {
  const {setJwt} = useContext(AuthContext);
  const {translation, setAppUser, setMyBookings, setCruiseBookings} = useContext(AppContext);
  const {setIsModalOpen} = useContext(AppModalContext)

  const logout = useCallback(() => {
    setJwt("");
    setIsModalOpen(false);
    setAppUser(undefined);
    setMyBookings([]);
    setCruiseBookings([]);
  }, [setAppUser, setMyBookings, setCruiseBookings, setIsModalOpen, setJwt])

  return (
    <Button
      variant="outlined"
      color="contrast"
      disableElevation fullWidth
      onClick={() => logout()}
      sx={[fonts.spartan14, semiBoldText, button, {mt: 2}]}>
      {translation.form.profileSettings.logout}
    </Button>
  )
}

export default LogoutButton;