import {useContext, useEffect, useState} from "react";
import SaveButton from "../../appButton/saveButton/SaveButton";
import AppTextField from "../../appTextField/AppTextField";
import {TextFieldType} from "../../../../utils/appEnums/TextFieldType";
import {AppContext, AppModalContext} from "../../appWrapper/AppWrapper";
import CancelButton from "../../appButton/cancelButton/CancelButton";
import DiscountFormValidator from "./DiscountFormValidator";
import {Box, Grid, Typography} from "@mui/material";
import {centeredBox, fonts, mainColors} from "../../../../../app.style";
import {clone, strEquals} from "../../../../utils/misc/helpers";
import {MODAL_BREAKPOINT, SM_BREAKPOINT} from "../../guiConstants";
import {AuthContext} from "../../../../../App";
import {Discount} from "../../../../model/Discount";
import DiscountRequest from "../../../../service/discount/DiscountRequest";


interface DiscountFormProps {
  discount?: Discount,
  cruiseId: string,
}

const DiscountForm = ({discount, cruiseId} : DiscountFormProps) => {
  const {jwt} = useContext(AuthContext);
  const {windowWidth, translation, appCacheApi, appLoadersCache} = useContext(AppContext);
  const {setModalCloseHandler, setIsModalOpen, setConfirmModalHeaderText, setConfirmModalHandler, setIsConfirmModalOpen} = useContext(AppModalContext);
  const [discountFormData] = useState(discount ? DiscountRequest.fromExisting(discount) : DiscountRequest.fromEmpty(cruiseId));
  const [initialFormData] = useState(clone(discountFormData));
  const [discountFormValidator] = useState(new DiscountFormValidator(translation));
  const [showErrorText, setShowErrorText] = useState(false);
  const [saveBtnDisabled, setSaveBtnDisabled] = useState(false);

  const handleCloseFormModalClick = () => {
    if (strEquals(discountFormData, initialFormData)){
      setIsModalOpen(false);
      setModalCloseHandler();
    } else {
      setConfirmModalHeaderText(translation.modalHeader.discardChanges);
      setConfirmModalHandler(() => () => {
        setIsModalOpen(false);
        setModalCloseHandler();
      });
      setIsConfirmModalOpen(true);
    }
  }

  const saveDiscount = () => {
    if (discountFormValidator.validateForm(discountFormData)){
      setSaveBtnDisabled(true);
      appCacheApi.postDiscount(discountFormData, jwt)
    } else {
      setShowErrorText(true);
      console.error("Form is invalid");
    }
  }

  useEffect(() => {
    if (saveBtnDisabled && !appLoadersCache.postDiscountLoading) {
      setIsModalOpen(false);
    }
    // eslint-disable-next-line
  }, [appLoadersCache.postReviewLoading, saveBtnDisabled, setIsModalOpen]);
  
  useEffect(() => {
    setModalCloseHandler(() => handleCloseFormModalClick);
    // eslint-disable-next-line
  }, []);
  
  return (
    <Box sx={{width: windowWidth >= MODAL_BREAKPOINT ? "500px" : windowWidth >= SM_BREAKPOINT ? "420px" : undefined}}>
      <Box sx={centeredBox}>
        <Box>
          <Typography sx={[fonts.spartan14, mainColors.contrast, {textAlign: "center"}]}>
            {translation.page.cruises.cruiseDetails.reviews.rating}
          </Typography>
        </Box>
      </Box>
      <AppTextField
        formData={discountFormData.code}
        setFormData={discountFormData.setCode}
        required={true}
        type={TextFieldType.TEXT}
        label={translation.page.admin.cruiseDetails.discount.code}
        showErrorText={showErrorText}
        validator={discountFormValidator.codeValidator}/>
      <AppTextField
        formData={discountFormData.discountAmt * 100}
        setFormData={discountFormData.setDiscountAmt}
        required={true}
        type={TextFieldType.NUMBER}
        label={translation.page.admin.cruiseDetails.discount.amount + " %"}
        showErrorText={showErrorText}
        validator={discountFormValidator.discountAmtValidator}/>
      <Grid container spacing={2} sx={{mt: 1}}>
        <Grid item xs={12} sm={6}>
          <CancelButton
            handleCancel={handleCloseFormModalClick}/>
        </Grid>
        <Grid item xs={12} sm={6}>
          <SaveButton
            handleSave={saveDiscount}
            disabled={saveBtnDisabled}/>
        </Grid>
      </Grid>
    </Box>
  );
}

export default DiscountForm;