import {Checkbox, FormControlLabel} from "@mui/material";
import CheckboxValidator from "../../../utils/validators/CheckboxValidator";
import {ChangeEvent, useEffect, useState} from "react";


interface AppCheckboxProps {
  label: any;
  setFormData: Function;
  required?: boolean;
  disabled?: boolean;
  checked?: boolean;
  validator?: CheckboxValidator;
  formValidatorCallback?: Function;
}

const AppCheckbox = (props: AppCheckboxProps) => {

  const [checked, setChecked] = useState(props.checked);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    props.setFormData(event.target.checked);
    setChecked(event.target.checked);
    if (props.validator && props.formValidatorCallback) {
      props.validator.validate(event.target.checked);
      props.formValidatorCallback();
    }
  }

  useEffect(() => {
    if (props.disabled) setChecked(props.checked)
  }, [props.disabled, props.checked])

  useEffect(() => {
    setChecked(props.checked)
  }, [props.checked])

  return (
    <FormControlLabel
      disabled={props.disabled}
      label={props.label}
      labelPlacement="end"
      required={props.required}
      control={
        <Checkbox checked={checked} onChange={handleChange}/>}
    />
  )
}

export default AppCheckbox;