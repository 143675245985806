import {Box, Typography} from "@mui/material";
import {boldText, fonts, mainColors} from "../../../../../../app.style";
import {useContext} from "react";
import {AppContext} from "../../../appWrapper/AppWrapper";

const FreeSpaces = ({spacesLeft, justify}: {spacesLeft: number, justify: string}) => {
  const {translation} = useContext(AppContext);

  return (
    <Box sx={{display: "flex", justifyContent: justify, mb: 1}}>
      <Typography sx={[fonts.spartan14]}>
        {spacesLeft ?
          translation.page.cruises.cruiseDetails.freeSpaces :
          translation.page.cruises.cruiseDetails.noFreeSpaces
        }
      </Typography>
      {spacesLeft !== 0 &&
        <Typography sx={[fonts.spartan14, boldText, mainColors.primary]}>
          &nbsp;{spacesLeft}
        </Typography>}
    </Box>
  )
}

export default FreeSpaces;