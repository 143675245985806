import {Button} from "@mui/material";
import {useContext} from "react";
import {AppContext} from "../../appWrapper/AppWrapper";
import {button, fonts, semiBoldText} from "../../../../../app.style";


const CancelButton = ({handleCancel, sx} : {handleCancel: Function, sx?: any}) => {
  const {translation} = useContext(AppContext);

  return (
    <Button
      variant="outlined"
      color="contrast"
      disableElevation fullWidth
      onClick={() => handleCancel()}
      sx={[fonts.spartan14, semiBoldText, button, sx]}>
        {translation.cancel}
    </Button>
  )
}

export default CancelButton;