import {centeredBox, fonts, rainbowColors, semiBoldText} from "../../../../../app.style";
import {Box, Typography} from "@mui/material";
import {useContext, useEffect, useState} from "react";
import {AppContext} from "../../appWrapper/AppWrapper";

const ResendInfo = (props: {status: number | undefined}) => {
  const [status, setStatus] = useState(props.status);
  const {translation} = useContext(AppContext);

  useEffect(() => {
    if (props.status !== undefined) setStatus(props.status)
  }, [props.status, setStatus]);

  return (
    <Box sx={centeredBox}>
    {status === undefined ?
      <></> : status === 200 ?
        <Typography align="center" sx={[fonts.spartan14, semiBoldText, rainbowColors.green, {mt: 1}]}>
          {translation.form.common.sendAgainSuccess}
        </Typography> :
        <Typography align="center" sx={[fonts.spartan14, semiBoldText, rainbowColors.red, {mt: 1}]}>
          {translation.form.common.sendAgainFailure}
        </Typography>}
    </Box>
  )
}

export default ResendInfo;