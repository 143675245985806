import {useCallback, useEffect, useState} from "react";
import axios from "axios";
import {clone} from "../../utils/misc/helpers";


function useDelete(url: string) {
  const [data, setData] = useState(null);
  const [status, setStatus] = useState<number>();
  const [loading, setLoading] = useState(false);
  const [ids, setIds] = useState<string[]>([])
  const [token, setToken] = useState<string>();

  const deleteSingle = useCallback((jwt?: string) => {
    setLoading(true);
    axios
      .delete(url + `/${ids[0]}`, {
        headers: {
          "Authorization": jwt ? `Bearer ${jwt}` : "",
        }
      })
      .then((response) => {
        setData(response.data)
        setStatus(response.status);
        const newArr = clone(ids);
        newArr.shift();
        setIds(newArr);
      })
      .catch((err) => {
        console.log(err);
        setStatus(err.response.status);
        setLoading(false);
      })
  }, [url, ids]);

  const deleteData = (initalIds: string[], jwt?: string) => {
    setIds(initalIds);
    setToken(jwt);
  }

  useEffect(() => {
    if (ids.length > 0) deleteSingle(token)
  }, [ids, token, deleteSingle])

  useEffect(() => {
    if (data) setLoading(false);
  }, [data])

  return {data, setData, status, loading, deleteData}
}

export default useDelete;