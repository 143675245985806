import {jwtDecode} from "jwt-decode";
import {AppJwtPayload} from "../../security/AppJwtPayload";
import {AppRole} from "../../model/modelEnums/AppRole";
import {SimpleOptionProps} from "../../components/common/appSelectField/appSelectField/simpleOption/SimpleOption";
import {Cruise} from "../../model/Cruise";
import {CruiseStatus} from "../appEnums/CruiseStatus";
import {Booking} from "../../model/Booking";
import {SM_BREAKPOINT} from "../../components/common/guiConstants";

export function clone(obj: any) {
  return JSON.parse(JSON.stringify(obj));
}

export function strEquals(obj1: any, obj2: any) {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
}

export const tryDecode = (jwt: string) : AppRole | undefined => {
  try {
    return (jwtDecode(jwt) as AppJwtPayload).role;
  } catch (error) {
    return undefined;
  }
}

export const getRole = (jwt: string) => {
  return jwt === "" ? undefined : tryDecode(jwt);
}

export function getSimpleOptionByValue(options: { props: SimpleOptionProps }[], value: any) {
  return options.find((o) => o.props.value === value) as any
}

export function openLinkInNewWindow(url: string) {
  window.open(url, '_blank')
}

export function getCruiseStatus(cruise: Cruise) {
  const timeNow = new Date().getTime();
  const timeStart = new Date(cruise.startDay).getTime();
  const timeFinish = new Date(cruise.finishDay).getTime();
  if (timeNow < timeStart) {
    return CruiseStatus.UPCOMING;
  } else if (timeNow > timeStart) {
    if (timeNow < timeFinish) {
      return CruiseStatus.ONGOING;
    } else {
      return CruiseStatus.ENDED;
    }
  }
}

export function getLocaleDateTime(timestamp: number) {
  const dateObj = new Date(timestamp)
  const date = dateObj.toISOString().split("T")[0];
  let hours = dateObj.getHours().toString();
  let minutes = dateObj.getMinutes().toString();
  if (hours.length === 1) hours = "0" + hours;
  if (minutes.length === 1) minutes = "0" + minutes;
  return date + " " + hours + ":" + minutes;
}

export function getDaysLeft(startDay: string) {
  const nowTime = new Date().getTime();
  const startTime = new Date(startDay).setHours(0, 0, 0);
  return Math.ceil((startTime - nowTime) / (24 * 3600 * 1000));
}

export function getDuePaymentDate(startDay: string) {
  const startTime = new Date(startDay).setHours(0, 0, 0);
  const dueTime = startTime - (14 * 24 * 3600 * 1000);
  return new Date(dueTime).toISOString().split("T")[0];
}

export function addToArray(obj: any, arr: any[]) {
  arr.push(obj);
  return arr;
}

export function removeFromArray(obj: any, arr: any[]) {
  arr.splice(arr.indexOf(obj), 1);
  return arr;
}

export function isCruiseBookedByUser(myBookings: Booking[], cruise: Cruise) {
  return myBookings.find(b => b.cruiseId === cruise.id) !== undefined;
}

export function getFormatPrice(price: number) {
  return Number.isInteger(price) ? price : price.toFixed(2);
}

export function isRatingDisplaySm(windowWidth: number, cruise: Cruise) {
  return windowWidth >= SM_BREAKPOINT && getCruiseStatus(cruise) !== CruiseStatus.UPCOMING
}

export function isPriceDisplaySm(windowWidth: number, cruise: Cruise) {
  return windowWidth >= SM_BREAKPOINT && getCruiseStatus(cruise) === CruiseStatus.UPCOMING
}

export function isRatingDisplayXs(windowWidth: number, cruise: Cruise) {
  return windowWidth < SM_BREAKPOINT && getCruiseStatus(cruise) !== CruiseStatus.UPCOMING
}

export function isPriceDisplayXs(windowWidth: number, cruise: Cruise) {
  return windowWidth < SM_BREAKPOINT && getCruiseStatus(cruise) === CruiseStatus.UPCOMING
}