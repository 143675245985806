import {Box} from "@mui/material";
import {useContext, useState} from "react";
import {AppContext} from "../../common/appWrapper/AppWrapper";
import PageSpinner from "../../common/appSpinner/PageSpinner";
import YearTabs from "../../common/yearTabs/YearTabs";
import AdminCruises from "./adminCruisesPage/adminCruises/AdminCruises";


const AdminPage = () => {
  const {appLoadersCache} = useContext(AppContext);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  return (
    <Box sx={{mt: 2}}>
      {appLoadersCache.getCruisesLoading ?
        <PageSpinner/> :
        <>
          <YearTabs setSelectedYear={setSelectedYear}/>
          <AdminCruises selectedYear={selectedYear}/>
        </>
      }
    </Box>
  )
}

export default AdminPage;