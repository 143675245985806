import {Box, Typography} from "@mui/material";
import {fonts, mainColors} from "../../../../../../../app.style";
import {useContext, useEffect, useState} from "react";
import {AppContext} from "../../../../appWrapper/AppWrapper";
import BookingPrice from "../../../../../pages/bookingsPage/bookings/bookingCard/bookingDetails/bookingPrice/BookingPrice";
import BookingRequest from "../../../../../../service/booking/BookingRequest";
import {findInCache} from "../../../../../../service/helpers/cacheUtils";
import {Booking} from "../../../../../../model/Booking";
import SmallSpinner from "../../../../appSpinner/SmallSpinner";
import YourBunks from "./yourBunks/YourBunks";


const Summary = ({bookingFormData, isFinal} : {bookingFormData: BookingRequest, isFinal?: boolean}) => {
  const {translation, myBookings} = useContext(AppContext);
  const [currentBooking, setCurrentBooking] = useState<Booking | undefined>()

  useEffect(() => {
    if (bookingFormData.id !== "") setCurrentBooking(findInCache(bookingFormData.id, myBookings) as Booking | undefined); 
  }, [bookingFormData, myBookings]);

  return(
    <>
      <Box sx={{display: "flex", flexDirection: "column", width: "288px"}}>
        <Typography sx={[fonts.spartan16, mainColors.contrast, {mb: 2}]}>
          {translation.form.newBooking.paymentStep.summary.title}
        </Typography>
        {currentBooking ?
          <>
            <YourBunks justify={"center"} yourSpaces={currentBooking.bunks}/>
            <BookingPrice booking={currentBooking} isFinal={isFinal} justify={"center"}/>
          </> :
          <SmallSpinner/>}
      </Box>
    </>
  )
}

export default Summary;