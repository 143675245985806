import {useCallback} from "react";
import {useDropzone} from "react-dropzone";
import {Box, Typography} from "@mui/material";
import {fonts, mainColors} from "../../../../app.style";
import {appDropZoneStyle} from "./appDropZone.style";


interface AppDropZoneProps {
  handleUpload: Function;
  primaryText: string;
  dropText: string;
}

const AppDropZone = ({handleUpload, primaryText, dropText} : AppDropZoneProps) => {
  const onDrop = useCallback((acceptedFiles: any[]) => {
    const formData = new FormData();
    formData.append("file", acceptedFiles[0]);
    handleUpload(formData)
  }, [handleUpload])
  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

  return (
    <Box {...getRootProps()} sx={appDropZoneStyle}>
      <input {...getInputProps()}/>
        <Typography sx={[fonts.spartan16, mainColors.contrast, {mx: 2, my: 2}]}>
          {isDragActive ? dropText : primaryText}
        </Typography>
    </Box>
  )
}

export default AppDropZone;