import {Box, Container, Typography} from "@mui/material";
import {useContext, useEffect, useState} from "react";
import CruiseBookings from "./cruiseBookings/CruiseBookings";
import CruiseDetailsTabs from "./cruiseDetailsTabs/CruiseDetailsTabs";
import {AppContext} from "../../../common/appWrapper/AppWrapper";
import PageSpinner from "../../../common/appSpinner/PageSpinner";
import {CruiseDetailOption} from "../../../../utils/appEnums/CruiseDetailOption";
import {useParams} from "react-router-dom";
import {AuthContext} from "../../../../../App";
import {findInCache} from "../../../../service/helpers/cacheUtils";
import {Cruise} from "../../../../model/Cruise";
import CruiseDiscountCodes from "./cruiseDiscountCodes/CruiseDiscountCodes";
import {Language} from "../../../../../language/Language";
import {fonts, mainColors, semiBoldText} from "../../../../../app.style";


const AdminCruiseDetailsPage = () => {
  const {cruiseId} = useParams();
  const {jwt} = useContext(AuthContext);
  const {selectedLanguage, appCacheApi, appLoadersCache, cruises} = useContext(AppContext);
  const [selectedCruiseDetailOption, setSelectedCruiseDetailOption] = useState(CruiseDetailOption.BOOKINGS);
  const [cruise, setCruise] = useState<Cruise | undefined>()

  useEffect(() => {
    appCacheApi.getCruiseBookings(cruiseId, jwt);
    appCacheApi.getDiscounts("", jwt);
  }, [appCacheApi, cruiseId, jwt]);

  useEffect(() => {
    if (!appLoadersCache.getCruisesLoading)
      setCruise(findInCache(cruiseId as string, cruises) as Cruise | undefined);
  }, [appLoadersCache.getCruisesLoading, cruiseId, cruises]);

  return (
    <Box sx={{mt: 2}}>
      {appLoadersCache.getCruiseBookingsLoading || appLoadersCache.getCruisesLoading ?
        <PageSpinner/> :
        <>
          <Container>
            <Typography sx={[fonts.spartan16, semiBoldText, {display: "flex", justifyContent: "center", mb: 1}]}>
              {selectedLanguage === Language.PL? cruise?.titlePl : cruise?.titleEn}
            </Typography>
            <Typography sx={[fonts.spartan14, mainColors.grey, {display: "flex", justifyContent: "center", mb: 2}]}>
              #{cruise?.id}
            </Typography>
          </Container>
          <CruiseDetailsTabs setSelectedTab={setSelectedCruiseDetailOption}/>
          {selectedCruiseDetailOption === CruiseDetailOption.BOOKINGS ?
            <CruiseBookings/> :
            <CruiseDiscountCodes/>}
        </>
      }
    </Box>
  )
}

export default AdminCruiseDetailsPage;