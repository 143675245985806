import {Container} from "@mui/material";
import useSeasonCruises from "../../../../common/yearTabs/useSeasonCruises";
import AdminCruiseCard from "./adminCruiseCard/AdminCruiseCard";
import NoAdminCruises from "./noAdminCruises/NoAdminCruises";
import CreateCruiseBtn from "./createCruiseBtn/CreateCruiseBtn";


interface CruisesProps {
  selectedYear: number;
}

const AdminCruises = ({selectedYear}: CruisesProps) => {
  const {seasonCruises} = useSeasonCruises(selectedYear);

  return (
    <Container sx={{mt: 3, mb: 5}}>
      {seasonCruises.length === 0 ?
        <NoAdminCruises/> :
        seasonCruises.map((cruise, index) => <AdminCruiseCard cruise={cruise} key={index}/>)}
      <CreateCruiseBtn/>
    </Container>
  )
}

export default AdminCruises;