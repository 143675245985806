import {Box, Button} from "@mui/material";
import {useContext} from "react";
import {AppContext, AppModalContext} from "../../../../../../../common/appWrapper/AppWrapper";
import {button, centeredBox, fonts, semiBoldText} from "../../../../../../../../../app.style";
import {AuthContext} from "../../../../../../../../../App";
import {DeleteForever} from "@mui/icons-material";

const DeleteCruiseBtn = ({cruiseId} : {cruiseId: string}) => {
  const {jwt} = useContext(AuthContext);
  const {translation, appCacheApi} = useContext(AppContext);
  const {setConfirmModalHandler, setConfirmModalHeaderText, setIsConfirmModalOpen} = useContext(AppModalContext);

  const deleteCruise = () => {
    setConfirmModalHeaderText(translation.modalHeader.deleteCruise);
    setConfirmModalHandler(() => () => {
      appCacheApi.deleteCruises([cruiseId], jwt);
    })
    setIsConfirmModalOpen(true);
  }

  return (
    <Box sx={centeredBox}>
      <Button
        variant="text"
        color="contrast"
        disableElevation
        fullWidth
        endIcon={<DeleteForever sx={[{width: "26px", height: "26px"}]}/>}
        onClick={() => deleteCruise()}
        sx={[fonts.spartan14, semiBoldText, button, {maxWidth: "330px"}]}>
        {translation.delete}
      </Button>
    </Box>
  )
}

export default DeleteCruiseBtn;