import WelcomeSection from "./section/welcomeSection/WelcomeSection";
import AboutChacrunaSection from "./section/aboutChacrunaSection/AboutChacrunaSection";
import OfferSection from "./section/offerSection/OfferSection";
import SocialMediaSection from "./section/socialMediaSection/SocialMediaSection";
import AboutMeSection from "./section/aboutMeSection/AboutMeSection";


const HomePage = () => {
  return (
    <>
      <WelcomeSection/>
      <OfferSection/>
      <SocialMediaSection/>
      <AboutChacrunaSection/>
      <AboutMeSection/>
    </>
  )
}

export default HomePage;