import {Cruise} from "../../../model/Cruise";
import {Box, Grid} from "@mui/material";
import {SM_BREAKPOINT} from "../guiConstants";
import {useContext} from "react";
import {AppContext} from "../appWrapper/AppWrapper";
import {centeredBox} from "../../../../app.style";
import FromToDateLocation from "./fromToDateLocation/FromToDateLocation";
import CruiseRating from "./cruiseRating/CruiseRating";
import CruisePrice from "./cruisePrice/CruisePrice";
import CruiseTitle from "./cruiseTitle/CruiseTitle";
import CruiseDifficultyLabel from "./cruiseDifficultyLabel/CruiseDifficultyLabel";
import CruiseDescription from "./cruiseDescription/CruiseDescription";
import {isPriceDisplaySm, isPriceDisplayXs, isRatingDisplaySm, isRatingDisplayXs} from "../../../utils/misc/helpers";
import AdminCruiseCardBtns
  from "../../pages/adminPage/adminCruisesPage/adminCruises/adminCruiseCard/adminCruiseCardBtns/AdminCruiseCardBtns";

const CruiseDetails = ({cruise, isFullDetails, isAdminView}: {cruise: Cruise, isFullDetails?: boolean, isAdminView?: boolean}) => {
  const {windowWidth} = useContext(AppContext);

  return (
    <>
      <Grid container >
        <Grid item xs={12}>
          <Box sx={[{display: "flex", justifyContent: "center"}, windowWidth >= SM_BREAKPOINT && {justifyContent: "space-between"}]}>
            <FromToDateLocation
              startLocation={cruise.startLocation}
              finishLocation={cruise.finishLocation}
              startDay={cruise.startDay}
              finishDay={cruise.finishDay}/>
            {isRatingDisplaySm(windowWidth, cruise) && <CruiseRating reviews={cruise.reviews}/>}
            {isPriceDisplaySm(windowWidth, cruise) && <CruisePrice
                pricePln={cruise.pricePln}
                priceEur={cruise.priceEur}
                spacesLeft={cruise.availBunks.length}/>}
          </Box>
        </Grid>
        <Grid item xs={12} sx={{pt: 1}}>
          <CruiseTitle titlePl={cruise.titlePl} titleEn={cruise.titleEn}/>
        </Grid>
        {!isAdminView &&
        <Grid item xs={12}>
          <CruiseDifficultyLabel difficulty={cruise.cruiseDifficulty}/>
        </Grid>}
      </Grid>
      {isAdminView ?
        <AdminCruiseCardBtns cruise={cruise}/> :
        <Box sx={{display: "flex", flexDirection: "column", flexGrow: 1, justifyContent: "flex-end", pt: 2, overflow: "hidden"}}>
          <CruiseDescription textPl={cruise.descriptionPl} textEn={cruise.descriptionEn} isClamped={!isFullDetails}/>
        </Box>}
      {isFullDetails &&
        <Box sx={{display: "flex", flexDirection: "column", flexGrow: 1, justifyContent: "flex-end", pt: 2, overflow: "hidden"}}>
            <CruiseDescription textPl={cruise.planPl} textEn={cruise.planEn}/>
        </Box>
      }
      {(isRatingDisplayXs(windowWidth, cruise) || isPriceDisplayXs(windowWidth, cruise)) &&
          <Box sx={[centeredBox, {mt: 1, justifyContent: "flex-end"}]}>
          {isRatingDisplayXs(windowWidth, cruise) && <CruiseRating reviews={cruise.reviews}/>}
          {isPriceDisplayXs(windowWidth, cruise) && <CruisePrice
              pricePln={cruise.pricePln}
              priceEur={cruise.priceEur}
              spacesLeft={cruise.availBunks.length}/>}
        </Box>}
    </>
  )
}

export default CruiseDetails;