export default class PassSetupRequest {
  token: string;
  password = "";
  repeatPassword = "";

  constructor(token: string) {
    this.token = token;
  }

  setPassword = (input: string) => {
    this.password = input;
  }

  setRepeatPassword = (input: string) => {
    this.repeatPassword = input;
  }
}