import {button, centeredBox, fonts, semiBoldText} from "../../../../../../../../app.style";
import {Box, Button} from "@mui/material";
import {FormMode} from "../../../../../../../utils/appEnums/FormMode";
import {useContext} from "react";
import {AppContext} from "../../../../../appWrapper/AppWrapper";

const EditBtn = ({setFormMode} : {setFormMode: Function}) => {
  const {translation} = useContext(AppContext);

  const editForm = () => {
    setFormMode(FormMode.EDIT);
  }

  return (
    <Box sx={centeredBox}>
      <Button
        variant="contained"
        color="primary"
        disableElevation
        fullWidth
        onClick={() => editForm()}
        sx={[fonts.spartan14, semiBoldText, button, {maxWidth: "330px"}]}>
        {translation.edit}
      </Button>
    </Box>
  )
}

export default EditBtn;