import useGet from "../axios/useGet";
import {apiUrl} from "../urls";
import {useContext, useEffect, useState} from "react";
import {AppUser} from "../../model/AppUser";
import {AuthContext} from "../../../App";

const useGetSelf = () => {
  const [appUser, setAppUser] = useState<undefined | AppUser>();
  const {data, setData, loading: getSelfLoading, getData: getSelf, status: getSelfStatus} = useGet(apiUrl.users);
  const {setJwt} = useContext(AuthContext);

  useEffect(() => {
    if (data) {
      setAppUser(data as unknown as AppUser);
      setData(null);
    }
  }, [data, setData]);

  useEffect(() => {
    if (getSelfStatus === 403) {
      setJwt("");
    }
  }, [setJwt, getSelfStatus]);

  return {appUser, setAppUser, getSelf, getSelfLoading, getSelfStatus}
}

export default useGetSelf;