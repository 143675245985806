import {useContext, useState} from "react";
import {Box, Link, Typography} from "@mui/material";
import LoginButton from "./loginButton/LoginButton";
import AppTextField from "../../appTextField/AppTextField";
import {TextFieldType} from "../../../../utils/appEnums/TextFieldType";
import {AppContext, AppModalContext} from "../../appWrapper/AppWrapper";
import LoginRequest from "../../../../service/auth/LoginRequest";
import {centeredBox, fonts, mainColors, semiBoldText} from "../../../../../app.style";
import CreateAccountButton from "./createAccountButton/CreateAccountButton";
import PassResetForm from "../passResetForm/PassResetForm";
import LoginFormValidator from "./LoginFormValidator";


const LoginForm = () => {
  const {translation} = useContext(AppContext);
  const {setModalHeaderText, setModalChildren} = useContext(AppModalContext);
  const [loginFormData] = useState(new LoginRequest());
  const [loginFormValidator] = useState(new LoginFormValidator(translation));
  const [showErrorText, setShowErrorText] = useState(false);


  const switchToPassResetForm = () => {
    setModalHeaderText(translation.modalHeader.passReset);
    setModalChildren(<PassResetForm/>);
  }

  return (
    <>
      <AppTextField
        formData={""}
        setFormData={loginFormData.setEmail}
        required={true}
        type={TextFieldType.TEXT}
        label={translation.form.loginForm.emailAddress}
        showErrorText={showErrorText}
        validator={loginFormValidator.emailValidator}/>
      <AppTextField
        formData={""}
        setFormData={loginFormData.setPassword}
        required={true}
        type={TextFieldType.PASSWORD}
        label={translation.form.loginForm.password}
        showErrorText={showErrorText}
        validator={loginFormValidator.passwordValidator}/>
      <LoginButton
        formData={loginFormData}
        loginFormValidator={loginFormValidator}
        setShowErrorText={setShowErrorText}/>
      <Box sx={centeredBox}>
        <Link
          component="button"
          sx={[fonts.spartan14, semiBoldText, mainColors.primary, {mt: "24px"}]}
          onClick={() => switchToPassResetForm()}>
          {translation.form.loginForm.forgotPass}
        </Link>
      </Box>
      <Box sx={[centeredBox, {mt: "40px"}]}>
        <Typography align="center" sx={[fonts.spartan14, semiBoldText, mainColors.contrast]}>
          {translation.form.loginForm.noAccount}
        </Typography>
      </Box>
      <CreateAccountButton/>
    </>
  );
}

export default LoginForm;