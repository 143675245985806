import {useContext} from "react";
import {AppContext, AppModalContext} from "./appWrapper/AppWrapper";
import {useNavigate} from "react-router-dom";
import LoginForm from "./appForm/loginForm/LoginForm";
import {centeredBox, fonts, mainColors, semiBoldText} from "../../../app.style";
import {Box, Link, Typography} from "@mui/material";

const YouCanNowLogin = () => {
  const {translation} = useContext(AppContext);
  const {setIsModalOpen, setModalHeaderText, setModalChildren} = useContext(AppModalContext);
  const navigate = useNavigate();

  const handleLoginShortcut = () => {
    navigate("/");
    setModalHeaderText(translation.modalHeader.menuLogin);
    setModalChildren(<LoginForm/>)
    setIsModalOpen(true);
  }

  return (
    <Box sx={[centeredBox, {mt: 5}]}>
      <Typography sx={[fonts.spartan20, {textAlign: "center"}]}>
        {translation.page.emailVerify.emailVerifyDescription2}
        <Link
          component="button"
          sx={[fonts.spartan20, semiBoldText, mainColors.primary, {ml: 1}]}
          onClick={() => handleLoginShortcut()}>
          {translation.page.emailVerify.emailVerifyLogin}
        </Link>
      </Typography>
    </Box>
  )
}

export default YouCanNowLogin;