import {Box, Button} from "@mui/material";
import {useContext} from "react";
import {AppContext, AppModalContext} from "../../../../../common/appWrapper/AppWrapper";
import {button, centeredBox, fonts, mainColors, semiBoldText} from "../../../../../../../app.style";
import ParticipantsModalContent from "../../../../../common/appModal/participantsModalContent/ParticipantsModalContent";
import {BookingStatus} from "../../../../../../model/modelEnums/BookingStatus";


const ShowCrewBtn = () => {
  const {translation, cruiseBookings} = useContext(AppContext);
  const {setModalHeaderText, setModalChildren, setIsModalOpen} = useContext(AppModalContext);

  const getParticipants = () => {
    const bookings = cruiseBookings
      .filter(b => [BookingStatus.PROCESSING, BookingStatus.ADVANCE_PAID, BookingStatus.FULLY_PAID]
        .includes(b.bookingStatus))
    return bookings.flatMap(b => b.participants);
  }

  const showCrewList = () => {
    setModalHeaderText(translation.modalHeader.crewList);
    setModalChildren(<ParticipantsModalContent participants={getParticipants()}/>);
    setIsModalOpen(true);
  }

  return (
    <Box sx={centeredBox}>
      <Button
        variant="contained"
        color="secondary"
        disableElevation
        fullWidth
        onClick={() => showCrewList()}
        sx={[fonts.spartan14, semiBoldText, button, mainColors.white, {maxWidth: "330px"}]}>
        {translation.page.admin.cruiseDetails.showCrewList}
      </Button>
    </Box>
  )
}

export default ShowCrewBtn;