export default class LoginRequest {
  email = "";
  password = "";


  setEmail = (input: string) => {
    this.email = input.toLowerCase().trim();
  }

  setPassword = (input: string) => {
    this.password = input;
  }
}