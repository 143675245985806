import {useNavigate} from "react-router-dom";
import {Box, Button} from "@mui/material";
import {useContext} from "react";
import {AppContext} from "../../../../../../../common/appWrapper/AppWrapper";
import {button, centeredBox, fonts, semiBoldText} from "../../../../../../../../../app.style";


const BookingsDiscountsBtn = ({cruiseId} : {cruiseId: string}) => {
  const {translation} = useContext(AppContext);
  const navigate = useNavigate();

  const displayBookingsDiscounts = () => {
    navigate("/admin/cruise/" + cruiseId);
  }

  return (
    <Box sx={centeredBox}>
      <Button
        variant="contained"
        disableElevation
        fullWidth
        onClick={() => displayBookingsDiscounts()}
        sx={[fonts.spartan14, semiBoldText, button, {maxWidth: "330px"}]}>
        {translation.page.admin.cruises.bookingsOrDiscounts}
      </Button>
    </Box>
  )
}

export default BookingsDiscountsBtn;