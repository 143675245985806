import {Cruise} from "../../../../model/Cruise";
import {Box, Typography} from "@mui/material";
import {centeredBox, fonts} from "../../../../../app.style";
import {useContext} from "react";
import {AppContext} from "../../../common/appWrapper/AppWrapper";
import CruiseReview from "./cruiseReview/CruiseReview";
import NoReviews from "./noReviews/NoReviews";
import LoginInfoBtn from "../../../common/loginInfoBtn/LoginInfoBtn";
import WriteReviewBtn from "../../../common/appButton/writeReviewBtn/WriteReviewBtn";
import useReviewsLeft from "../../../../utils/hooks/useReviewsLeft";

const CruiseReviews = ({cruise} : {cruise: Cruise}) => {
  const {appUser, translation} = useContext(AppContext);
  const {reviewsLeft} = useReviewsLeft(cruise);

  return (
    <Box mt={3}>
      <Typography sx={[fonts.spartan14, centeredBox]}>
        {translation.page.cruises.cruiseDetails.reviews.sectionTitle}
      </Typography>
      {cruise.reviews.length === 0 ?
        <NoReviews/> :
        cruise.reviews.map((review, index) => <CruiseReview review={review} key={index}/>)}
      {!appUser && <LoginInfoBtn descr={translation.page.cruises.cruiseDetails.reviews.loginToReview}/>}
      {appUser && reviewsLeft !== 0 && <Box sx={{mt: 3}}><WriteReviewBtn cruiseId={cruise.id}/></Box>}
    </Box>
  )
}

export default CruiseReviews;