import React, {createContext, useEffect, useState} from "react";
import {BrowserRouter as Router, Navigate, Route, Routes} from "react-router-dom";
import './app.css';
import {LicenseInfo} from "@mui/x-date-pickers-pro";
import useLocalStorage from "./app/utils/hooks/useLocalStorage";
import {getRole} from "./app/utils/misc/helpers";
import {theme} from "./app.theme";
import {CssBaseline, ThemeProvider} from "@mui/material";
import HomePage from "./app/components/pages/homePage/HomePage";
import CruisesPage from "./app/components/pages/cruisesPage/CruisesPage";
import PasswordResetPage from "./app/components/pages/passwordResetPage/PasswordResetPage";
import {AppRole} from "./app/model/modelEnums/AppRole";
import RouteGuard from "./app/security/RouteGuard";
import AppWrapper from "./app/components/common/appWrapper/AppWrapper";
import BookingsPage from "./app/components/pages/bookingsPage/BookingsPage";
import AdminPage from "./app/components/pages/adminPage/AdminPage";
import FaqPage from "./app/components/pages/faqPage/FaqPage";
import VerifyEmailPage from "./app/components/pages/emailVerifyPage/VerifyEmailPage";
import CruiseDetailsPage from "./app/components/pages/cruiseDetailsPage/CruiseDetailsPage";
import AdminCruiseDetailsPage from "./app/components/pages/adminPage/adminCruiseDetailsPage/AdminCruiseDetailsPage";


LicenseInfo.setLicenseKey("12d8427662ce94370758cff217ab6d62Tz0xMjMsRT0yNTI3MjgyODAwMDAwLFM9cHJlbWl1bSxMTT1wZXJwZXR1YWwsS1Y9Mg==");

interface AuthContextType {
  jwt: string;
  setJwt: Function;
  role: AppRole | undefined;
}

export const AuthContext = createContext<AuthContextType>({} as AuthContextType)

function App() {
  const [jwt, setJwt] = useLocalStorage("", "jwt");
  const [role, setRole] = useState(getRole(jwt));

  useEffect(() => {
    if (!role) {
      setJwt("")
    } else {
      console.log(`Welcome ${role}!`)
    }
  }, [role, setJwt])

  useEffect(() => {
    jwt !== "" ? setRole(getRole(jwt)) : setRole(undefined);
  }, [jwt, setRole]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline/>
        <AuthContext.Provider value={{jwt, setJwt, role}}>
          <Router>
            <AppWrapper>
              <Routes>
                <Route path="/home" element={<HomePage/>}/>
                <Route path="/cruises" element={<CruisesPage/>}/>
                <Route path="/cruises/:cruiseId" element={<CruiseDetailsPage/>}/>
                <Route path="/bookings" element={<BookingsPage/>}/>
                <Route path="/faq" element={<FaqPage/>}/>
                <Route path="/reset/:token" element={<PasswordResetPage/>}/>
                <Route path="/verify/:token" element={<VerifyEmailPage/>}/>
                <Route path="/admin/*" element={
                  <RouteGuard>
                    <Routes>
                      <Route path="/" element={<AdminPage/>}/>
                      <Route path="/cruise/:cruiseId" element={<AdminCruiseDetailsPage/>}/>
                    </Routes>
                  </RouteGuard>
                }/>
                <Route path="/*" element={<Navigate to={"/home"}/>}/>
              </Routes>
            </AppWrapper>
          </Router>
        </AuthContext.Provider>
    </ThemeProvider>
  );
}

export default App;
