import {Box, Button} from "@mui/material";
import {useContext} from "react";
import {AppContext, AppModalContext} from "../../../../../common/appWrapper/AppWrapper";
import {button, centeredBox, fonts, mainColors, semiBoldText} from "../../../../../../../app.style";
import DiscountForm from "../../../../../common/appForm/discountForm/DiscountForm";
import {useParams} from "react-router-dom";


const CreateDiscountBtn = () => {
  const {cruiseId} = useParams();
  const {translation} = useContext(AppContext);
  const {setModalHeaderText, setModalChildren, setIsModalOpen} = useContext(AppModalContext);

  const openCreateDiscountModal = () => {
    setModalHeaderText(translation.modalHeader.createDiscount);
    setModalChildren(<DiscountForm cruiseId={cruiseId!}/>);
    setIsModalOpen(true);
  }

  return (
    <Box sx={centeredBox}>
      <Button
        variant="contained"
        color="secondary"
        disableElevation
        fullWidth
        onClick={() => openCreateDiscountModal()}
        sx={[fonts.spartan14, semiBoldText, button, mainColors.white, {maxWidth: "330px"}]}>
        {translation.create}
      </Button>
    </Box>
  )
}

export default CreateDiscountBtn;