import {useContext} from "react";
import {AppContext} from "../../../../../../common/appWrapper/AppWrapper";
import {Box, Typography} from "@mui/material";
import {fonts, mainColors, rainbowColorsDark, semiBoldText} from "../../../../../../../../app.style";
import {Booking} from "../../../../../../../model/Booking";
import {BookingStatus} from "../../../../../../../model/modelEnums/BookingStatus";

const BookingStatusInfo = ({booking} : {booking: Booking}) => {
  const {translation} = useContext(AppContext);

  const getStatusColor = () => {
    switch (booking.bookingStatus){
      case BookingStatus.PENDING:
        return rainbowColorsDark.red;
      case BookingStatus.PROCESSING:
        return rainbowColorsDark.blue;
      case BookingStatus.ADVANCE_PAID:
        return rainbowColorsDark.orange;
      case BookingStatus.FULLY_PAID:
        return rainbowColorsDark.green;
      case BookingStatus.CANCELLING:
        return rainbowColorsDark.violet;
      case BookingStatus.CANCELED:
        return mainColors.contrast;
    }
  }

  return (
    <Box sx={{my: 1}}>
      <Typography sx={[fonts.spartan12, {textAlign: "center"}]}>
        {translation.page.bookings.bookingDetails.bookingStatusTitle}
      </Typography>
      <Typography sx={[fonts.spartan14, semiBoldText, getStatusColor(), {textAlign: "center"}]}>
        {translation.page.bookings.bookingDetails.bookingStatus[booking.bookingStatus]}
      </Typography>
    </Box>
  )
}

export default BookingStatusInfo;