import {SyntheticEvent, useContext, useEffect, useState} from "react";
import SaveButton from "../../appButton/saveButton/SaveButton";
import AppTextField from "../../appTextField/AppTextField";
import {TextFieldType} from "../../../../utils/appEnums/TextFieldType";
import {AppContext, AppModalContext} from "../../appWrapper/AppWrapper";
import CancelButton from "../../appButton/cancelButton/CancelButton";
import ReviewFormValidator from "./ReviewFormValidator";
import ReviewRequest from "../../../../service/review/ReviewRequest";
import {Review} from "../../../../model/Review";
import {Box, Grid, Rating, Typography} from "@mui/material";
import {centeredBox, fonts, mainColors} from "../../../../../app.style";
import {clone, strEquals} from "../../../../utils/misc/helpers";
import {MODAL_BREAKPOINT, SM_BREAKPOINT} from "../../guiConstants";
import {AuthContext} from "../../../../../App";


interface ReviewFormProps {
  cruiseId: string;
  review?: Review;
  setWriteBtnDisabled?: Function;
}

const ReviewForm = ({setWriteBtnDisabled, cruiseId, review} : ReviewFormProps) => {
  const {jwt} = useContext(AuthContext);
  const {windowWidth, translation, appCacheApi, appLoadersCache} = useContext(AppContext);
  const {setModalCloseHandler, setIsModalOpen, setConfirmModalHeaderText, setConfirmModalHandler, setIsConfirmModalOpen} = useContext(AppModalContext);
  const [reviewFormData] = useState(review ? ReviewRequest.fromExisting(review) : ReviewRequest.fromEmpty(cruiseId));
  const [initialFormData] = useState(clone(reviewFormData));
  const [reviewFormValidator] = useState(new ReviewFormValidator(translation));
  const [showErrorText, setShowErrorText] = useState(false);
  const [score, setScore] = useState(reviewFormData.score);
  const [saveBtnDisabled, setSaveBtnDisabled] = useState(false);

  const handleCloseFormModalClick = () => {
    if (strEquals(reviewFormData, initialFormData)){
      setIsModalOpen(false);
      setModalCloseHandler();
      if(setWriteBtnDisabled) setWriteBtnDisabled(false);
    } else {
      setConfirmModalHeaderText(translation.modalHeader.discardChanges);
      setConfirmModalHandler(() => () => {
        setIsModalOpen(false);
        setModalCloseHandler();
        if(setWriteBtnDisabled) setWriteBtnDisabled(false);
      });
      setIsConfirmModalOpen(true);
    }
  }

  const sendReview = () => {
    if (reviewFormValidator.validateForm(reviewFormData)){
      setSaveBtnDisabled(true);
      appCacheApi.postReview(reviewFormData, jwt)
    } else {
      setShowErrorText(true);
      console.error("Form is invalid");
    }
  }

  const handleScoreChange = (_event: SyntheticEvent<Element, Event>, value: number | null) => {
    reviewFormData.setScore(value ?? 5)
    setScore(value ?? 5);
  }

  useEffect(() => {
    if (saveBtnDisabled && !appLoadersCache.postReviewLoading) {
      setIsModalOpen(false);
      if (setWriteBtnDisabled) setWriteBtnDisabled(false);
    }
    // eslint-disable-next-line
  }, [appLoadersCache.postReviewLoading, saveBtnDisabled, setIsModalOpen]);
  
  useEffect(() => {
    setModalCloseHandler(() => handleCloseFormModalClick);
    // eslint-disable-next-line
  }, []);
  
  return (
    <Box sx={{width: windowWidth >= MODAL_BREAKPOINT ? "500px" : windowWidth >= SM_BREAKPOINT ? "420px" : undefined}}>
      <Box sx={centeredBox}>
        <Box>
          <Typography sx={[fonts.spartan14, mainColors.contrast, {textAlign: "center"}]}>
            {translation.page.cruises.cruiseDetails.reviews.rating}
          </Typography>
          <Rating value={score} onChange={handleScoreChange}/>
        </Box>
      </Box>
      <AppTextField
        formData={reviewFormData.title}
        setFormData={reviewFormData.setTitle}
        required={true}
        type={TextFieldType.TEXT}
        label={translation.page.cruises.cruiseDetails.reviews.title}
        showErrorText={showErrorText}
        validator={reviewFormValidator.titleValidator}/>
      <AppTextField
        formData={reviewFormData.description}
        setFormData={reviewFormData.setDescription}
        required={true}
        rows={5}
        type={TextFieldType.TEXT}
        label={translation.page.cruises.cruiseDetails.reviews.description}
        showErrorText={showErrorText}
        validator={reviewFormValidator.descriptionValidator}/>
      <Grid container spacing={2} sx={{mt: 1}}>
        <Grid item xs={12} sm={6}>
          <CancelButton
            handleCancel={handleCloseFormModalClick}/>
        </Grid>
        <Grid item xs={12} sm={6}>
          <SaveButton
            handleSave={sendReview}
            disabled={saveBtnDisabled}/>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ReviewForm;