import {useCallback, useContext, useEffect, useState} from "react";
import {BookingStatus} from "../../model/modelEnums/BookingStatus";
import {Cruise} from "../../model/Cruise";
import {AppContext} from "../../components/common/appWrapper/AppWrapper";

const useReviewsLeft = (cruise: Cruise) => {
  const {appUser, myBookings} = useContext(AppContext);

  const getReviewsLeft = useCallback(() => {
    const userId = appUser?.id;
    if (userId) {
      const userCruiseBunks = myBookings
        .filter(b => b.bookedBy.id === appUser?.id
          && b.cruiseId === cruise.id
          && b.bookingStatus === BookingStatus.FULLY_PAID)
        .flatMap(b => b.bunks);
      const userCruiseReviews = cruise.reviews.filter(c => c.createdById === userId);
      return userCruiseBunks.length - userCruiseReviews.length;
    } else {
      return 0;
    }
  }, [appUser, myBookings, cruise]);

  const [reviewsLeft, setReviewsLeft] = useState(getReviewsLeft());

  useEffect(() => {
    setReviewsLeft(getReviewsLeft())
  }, [getReviewsLeft]);

  return {reviewsLeft}
}

export default useReviewsLeft;