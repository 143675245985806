import {Button} from "@mui/material";
import {useContext, useEffect, useState} from "react";
import {AppContext, AppModalContext} from "../../../appWrapper/AppWrapper";
import usePost from "../../../../../service/axios/usePost";
import {apiUrl} from "../../../../../service/urls";
import SmallSpinner from "../../../appSpinner/SmallSpinner";
import {button, fonts, mainColors, semiBoldText} from "../../../../../../app.style";
import RegisterRequest from "../../../../../service/auth/RegisterRequest";
import RegisterFormValidator from "../RegisterFormValidator";
import RegisterSuccess from "../../../appModal/registerSuccess/RegisterSuccess";


interface RegisterButtonProps {
  formData: RegisterRequest;
  registerFormValidator: RegisterFormValidator;
  setShowErrorText: Function;
}

const RegisterButton = (props: RegisterButtonProps) => {
  const {translation} = useContext(AppContext);
  const {setModalHeaderText, setModalChildren} = useContext(AppModalContext);
  const [disabled, setDisabled] = useState(false);
  const {data, loading, status, postData: register} = usePost(apiUrl.auth + "/register");

  const tryRegister = () => {
    setDisabled(true);
    if (props.registerFormValidator.validateForm()){
      register(props.formData)
      return;
    } else {
      props.setShowErrorText(true);
      console.error("Form is invalid");
    }
  }

  useEffect(() => {
    if (loading) {
      props.setShowErrorText(false);
    } else {
      if (status === 409){
        props.registerFormValidator.setEmailTaken();
        props.setShowErrorText(true);
      } else if (status === 200){
        const respData = data as unknown as {email: string};
        setModalHeaderText(translation.modalHeader.regSuccess)
        setModalChildren(<RegisterSuccess email={respData.email}/>)
      }
    }
  }, [status, loading, data, props, setModalHeaderText, setModalChildren, translation]);

  useEffect(() => {
    if (disabled && !loading) setDisabled(false);
  }, [loading, disabled]);

  return (
    <Button variant="contained"
            disabled={disabled}
            disableElevation fullWidth
            sx={[fonts.spartan14, semiBoldText, button, {mt: 3, background: mainColors.primary.color}]}
            onClick={() => tryRegister()}>
      {disabled ?
        <SmallSpinner/> :
        translation.form.registerForm.registerBtn
      }
    </Button>
  )
}

export default RegisterButton;