import {useContext, useEffect, useState} from "react";
import {AppContext} from "../../../../appWrapper/AppWrapper";
import {Box, Typography} from "@mui/material";
import BookingRequest from "../../../../../../service/booking/BookingRequest";
import ParticipantData from "./participantData/ParticipantData";
import {fonts, mainColors} from "../../../../../../../app.style";
import SubStep from "../../subStep/SubStep";
import ParticipantFormValidator from "./participantData/ParticipantFormValidator";
import AddRemoveParticipant from "./addRemoveParticipant/AddRemoveParticipant";

interface ParticipantsDataProps {
  availBunks: number;
  bookingFormData: BookingRequest;
  participantsValidator: ParticipantFormValidator[]
  bookingIsParticipant: boolean;
  showErrorText: boolean;
  forceBlur?: boolean;
}

const ParticipantsData = ({availBunks, bookingFormData, participantsValidator, bookingIsParticipant, showErrorText, forceBlur} : ParticipantsDataProps) => {
  const {translation, appUser} = useContext(AppContext);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    for (let i = 0; i < bookingFormData.participants.length; i++) {
      participantsValidator[i] = new ParticipantFormValidator(translation, bookingFormData.participants[i]);
    }
    setRefresh(!refresh);
    // eslint-disable-next-line
  }, [appUser, bookingFormData, participantsValidator, translation]);

  return (
    <>
      <Box sx={{display: "flex"}}>
        <SubStep subStepNo={2} totalSteps={3}/>
        <Typography sx={[fonts.spartan16, mainColors.contrast, {mb: 2}]}>
          {translation.form.newBooking.bookingDataStep.passengerDetails.title}
        </Typography>
      </Box>
      {bookingFormData.participants.map((participant, index) =>
        <ParticipantData
          key={index}
          index={index}
          bookingForm={bookingFormData}
          participantFormData={participant}
          participantValidator={participantsValidator[index]}
          showErrorText={showErrorText}
          forceBlur={forceBlur}
          appUser={bookingIsParticipant && index === 0 ? appUser : undefined}/>)}
      <AddRemoveParticipant
        bookingFormData={bookingFormData}
        refresh={refresh}
        setRefresh={setRefresh}
        participantsValidator={participantsValidator}
        availBunks={availBunks}/>
    </>
  )
}

export default ParticipantsData;