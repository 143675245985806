import {Checkbox, FormControl, FormControlLabel, FormHelperText} from "@mui/material";
import CheckboxValidator from "../../../utils/validators/CheckboxValidator";
import {ChangeEvent, useEffect, useState} from "react";
import {fonts, semiBoldText} from "../../../../app.style";
import {GuiColors} from "../../../utils/appEnums/GuiColors";


interface AppCheckboxProps {
  label: any;
  setFormData: Function;
  disabled?: boolean;
  checked?: boolean;
  validator: CheckboxValidator;
  showErrorText: boolean;
}

const AppRequiredCheckbox = (props: AppCheckboxProps) => {
  const [checked, setChecked] = useState(props.checked);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    props.setFormData(event.target.checked);
    setChecked(event.target.checked);
    if (props.validator) {
      props.validator.validate(event.target.checked);
    }
  }

  const isDisplayError = () => {
    return !props.validator.isValid && props.showErrorText;
  }

  useEffect(() => {
    if (props.disabled) setChecked(props.checked)
  }, [props.disabled, props.checked])

  useEffect(() => {
    setChecked(props.checked)
  }, [props.checked])

  return (
    <FormControl
      required
      error={isDisplayError()}
      variant="standard">
      <FormControlLabel
        disabled={props.disabled}
        label={props.label}
        labelPlacement="end"
        control={
          <Checkbox checked={checked} onChange={handleChange}/>}
      />
      <FormHelperText sx={[fonts.spartan12, semiBoldText]} color={GuiColors.ERROR}>
          {isDisplayError() ? props.validator.errorText : " "}
      </FormHelperText>
    </FormControl>
  )
}

export default AppRequiredCheckbox;