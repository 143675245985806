import {Box, Typography} from "@mui/material";
import {fonts, mainColors, mediumText} from "../../../../../app.style";

const SectionElement = ({number, text} : {number: string, text: string}) => {

  return (
      <Box sx={{display: "flex", mb: 2}}>
        <Typography sx={[fonts.spartan14, mainColors.contrast, mediumText, {minWidth: "28px"}]}>
          {number}.
        </Typography>
        <Typography sx={[fonts.spartan14, mainColors.contrast]}>
          {text}
        </Typography>
      </Box>
  )
}

export default SectionElement;