import {Box, TextField, TextFieldVariants, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import {mainColors, fonts, semiBoldText} from "../../../../app.style";
import AbstractValidator from "../../../utils/validators/AbstractValidator";
import {GuiColors} from "../../../utils/appEnums/GuiColors";

interface AppTextFieldProps {
  type: "text" | "number" | "tel" | "email" | "password",
  label: string,
  formData: any,
  setFormData: Function,
  required?: boolean,
  rows?: number,
  variant?: TextFieldVariants,
  validator?: AbstractValidator,
  formValidatorCallback?: Function,
  disabled?: boolean,
  showErrorText?: boolean,
  passwordAdornment?: any,
  InputLabelProps?: { shrink: boolean },
  readOnly?: boolean,
  forceBlur?: boolean;
  sx?: any
}

const AppTextField = (props: AppTextFieldProps) => {
  const [input, setInput] = useState(props.formData);
  const [blurred, setBlurred] = useState(props.showErrorText);

  const hasError = () => {
    return props.validator !== undefined ?
      !props.validator.isValid && blurred && !props.disabled : false;
  }

  useEffect(() => {
    setBlurred(props.showErrorText);
  }, [props.showErrorText]);

  useEffect(() => {
    setBlurred(true);
  }, [props.forceBlur]);

  useEffect(() => {
    if (props.disabled) setInput(props.formData)
  }, [props.disabled, props.formData])

  useEffect(() => {
    setInput(props.formData)
  }, [props.formData])

  useEffect(() => {
    setBlurred(false);
  }, [props.validator])

  return (
    <Box sx={{display: "block"}}>
      <TextField
        sx={props.sx ?? {}}
        required={props.required}
        multiline={!!props.rows}
        rows={props.rows}
        fullWidth
        disabled={props.disabled}
        type={props.type}
        value={input}
        variant={props.variant ? props.variant : "standard"}
        error={hasError()}
        label={props.label}
        InputProps={{
          readOnly: props.readOnly ? props.readOnly : false,
          sx: [fonts.spartan14, mainColors.contrast, semiBoldText],
          endAdornment: (props.passwordAdornment ?? props.passwordAdornment)
        }}
        InputLabelProps={props.InputLabelProps}

        onChange={(e) => {
          props.setFormData(e.target.value);
          props.validator?.validate(e.target.value);
          if (props.formValidatorCallback) props.formValidatorCallback();
          setInput(e.target.value);
        }}
        onBlur={() => {
          props.validator?.validate(input);
          setBlurred(true);
        }}
      />
      {hasError() ?
        <Typography sx={[fonts.spartan12, semiBoldText, {py: "4px"}]}
                    color={GuiColors.ERROR}>
          {props.validator?.errorText}
        </Typography> : props.validator !== undefined && <Box sx={{height: "26px"}}/>}
    </Box>
  )
}

export default AppTextField;