import {Box, Typography} from "@mui/material";
import {fonts, mainColors, mediumText} from "../../../../../../../../../app.style";
import {useContext} from "react";
import {AppContext} from "../../../../../../../common/appWrapper/AppWrapper";
import {Booking} from "../../../../../../../../model/Booking";

const BookingUserDetails = ({booking} : {booking: Booking}) => {
  const {translation} = useContext(AppContext);

  return(
        <Box>
          <Typography sx={[fonts.spartan14, mainColors.grey, mediumText]}>
            {translation.page.admin.cruiseDetails.bookedBy} {booking.bookedBy.name} {booking.bookedBy.surname}
          </Typography>
          <Typography sx={[fonts.spartan12, mainColors.grey]}>
            {booking.bookedBy.email}
          </Typography>
          {booking.bookedBy.phone &&
          <Typography sx={[fonts.spartan12, mainColors.grey]}>
            {booking.bookedBy.phone}
          </Typography>}
        </Box>
  )
}

export default BookingUserDetails;