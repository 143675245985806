import {Box, Typography} from "@mui/material";
import {useContext} from "react";
import {AppContext} from "../../../../../appWrapper/AppWrapper";
import {boldText, fonts, mainColors} from "../../../../../../../../app.style";

const YourBunks = ({yourSpaces, justify}: {yourSpaces: number[], justify: string}) => {
  const {translation} = useContext(AppContext);

  return (
    <Box sx={{display: "flex", justifyContent: justify, mb: 1}}>
      <Typography sx={[fonts.spartan14, {textAlign: "left"}]}>
        {translation.form.newBooking.paymentStep.summary.yourBunks}
      </Typography>
      <Typography sx={[fonts.spartan14, boldText, mainColors.primary, {textAlign: "left"}]}>
        &nbsp;{JSON.stringify(yourSpaces)
        .replace("[", "")
        .replace("]", "")
        .replaceAll(",", ", ")}
      </Typography>
    </Box>
  )
}

export default YourBunks;