import {Translation} from "../../../../../language/translation/translations";
import RegisterRequest from "../../../../service/auth/RegisterRequest";
import StringValidator from "../../../../utils/validators/StringValidator";
import EmailValidator from "../../../../utils/validators/EmailValidator";
import PhoneValidator from "../../../../utils/validators/PhoneValidator";
import PasswordValidator from "../../../../utils/validators/PasswordValidator";
import PasswordRepeatValidator from "../../../../utils/validators/PasswordRepeatValidator";
import {validatorSettings} from "../../../../utils/validators/validatorSettings";
import DateTimeValidator from "../../../../utils/validators/DateTimeValidator";


export default class RegisterFormValidator {
  translation: Translation;
  registerRequest: RegisterRequest;
  nameValidator: StringValidator;
  surnameValidator: StringValidator;
  nicknameValidator: StringValidator;
  bornValidator: DateTimeValidator;
  emailValidator: EmailValidator;
  phoneValidator: PhoneValidator;
  passwordValidator: PasswordValidator;
  passwordRepeatValidator: PasswordRepeatValidator;

  constructor(translation: Translation, registerRequest: RegisterRequest) {
    this.translation = translation;
    this.registerRequest = registerRequest;
    this.nameValidator = new StringValidator(translation, validatorSettings.nameMaxChars, true);
    this.surnameValidator = new StringValidator(translation, validatorSettings.surnameMaxChars, true, true);
    this.nicknameValidator = new StringValidator(translation, validatorSettings.nicknameMaxChars, false, true);
    this.bornValidator = new DateTimeValidator(translation);
    this.emailValidator = new EmailValidator(translation, validatorSettings.emailMaxChars);
    this.phoneValidator = new PhoneValidator(translation);
    this.passwordValidator = new PasswordValidator(translation, validatorSettings.passwordMinChars, validatorSettings.passwordMaxChars);
    this.passwordRepeatValidator = new PasswordRepeatValidator(translation, registerRequest)
  }

  setEmailTaken = () => {
    this.emailValidator.errorText = this.translation.formErrors.emailTaken;
    this.emailValidator.isValid = false;
  }

  validateForm = () => {
    this.nameValidator.validate(this.registerRequest.name);
    this.surnameValidator.validate(this.registerRequest.surname);
    this.nicknameValidator.validate(this.registerRequest.nickname);
    this.bornValidator.validate(this.registerRequest.born);
    this.emailValidator.validate(this.registerRequest.email);
    this.phoneValidator.validate(this.registerRequest.phone)
    this.passwordValidator.validate(this.registerRequest.password);
    this.passwordRepeatValidator.validate(this.registerRequest.repeatPassword);

    return (
      this.nameValidator.isValid &&
      this.surnameValidator.isValid &&
      this.nicknameValidator.isValid &&
      this.bornValidator.isValid &&
      this.emailValidator.isValid &&
      this.phoneValidator.isValid &&
      this.passwordValidator.isValid &&
      this.passwordRepeatValidator.isValid
    )
  }
}