import {Box, Button, Link, Typography} from "@mui/material";
import {useContext, useEffect, useState} from "react";
import {AppContext, AppModalContext} from "../../../appWrapper/AppWrapper";
import usePost from "../../../../../service/axios/usePost";
import {apiUrl} from "../../../../../service/urls";
import SmallSpinner from "../../../appSpinner/SmallSpinner";
import {button, centeredBox, fonts, mainColors, semiBoldText} from "../../../../../../app.style";
import PassResetFormValidator from "../PassResetFormValidator";
import PassResetRequest from "../../../../../service/auth/PassResetRequest";
import LoginForm from "../../loginForm/LoginForm";


interface PassResetButtonProps {
  formData: PassResetRequest;
  passResetFormValidator: PassResetFormValidator;
  setShowErrorText: Function;
}

const PassResetButton = (props: PassResetButtonProps) => {
  const {translation} = useContext(AppContext);
  const {setModalHeaderText, setModalChildren} = useContext(AppModalContext);
  const [disabled, setDisabled] = useState(false);
  const {setData, loading, status, postData: resetPass} = usePost(apiUrl.reset);
  const [requestSent, setRequestSent] = useState(false);

  const tryPassReset = () => {
    setDisabled(true);
    if (props.passResetFormValidator.validateForm(props.formData)){
      resetPass({}, undefined, props.formData.email)
    } else {
      props.setShowErrorText(true);
      console.error("Form is invalid");
    }
  }

  const switchToLoginForm = () => {
    setModalHeaderText(translation.modalHeader.menuLogin);
    setModalChildren(<LoginForm/>)
  }

  useEffect(() => {
    if (loading) {
      props.setShowErrorText(false);
    } else if (status === 200) {
      setRequestSent(true);
      setData(null);
    }
  }, [loading, props, status, setData]);

  useEffect(() => {
    if (disabled && !loading) setDisabled(false);
  }, [loading, disabled]);

  return (
    <>
      <Button variant="contained"
              disabled={disabled}
              disableElevation fullWidth
              sx={[fonts.spartan14, semiBoldText, button, {mt: 3, background: mainColors.primary.color}]}
              onClick={() => tryPassReset()}>
        {disabled ?
          <SmallSpinner/> :
          translation.form.resetPassword.reset
        }
      </Button>
      { requestSent &&
        <Typography align="center" sx={[fonts.spartan14, mainColors.contrast, {mt: 2, maxWidth: "248px"}]}>
          {translation.form.resetPassword.mailInfo}
        </Typography>}
      <Box sx={centeredBox}>
        <Link
          component="button"
          sx={[fonts.spartan14, semiBoldText, mainColors.primary, {mt: "24px"}]}
          onClick={() => switchToLoginForm()}>
          {translation.form.resetPassword.iRemember}
        </Link>
      </Box>
    </>
  )
}

export default PassResetButton;