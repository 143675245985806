import {useNavigate} from "react-router-dom";
import {button, centeredBox, fonts, semiBoldText} from "../../../../../../../../app.style";
import {Box, Button} from "@mui/material";
import {useContext} from "react";
import {AppContext} from "../../../../../../common/appWrapper/AppWrapper";

const CruiseDetailsBtn = ({cruiseId} : {cruiseId: string}) => {
  const {translation} = useContext(AppContext);
  const navigate = useNavigate();

  const displayFullDetails = () => {
    navigate("/cruises/" + cruiseId);
  }

  return (
    <Box sx={centeredBox}>
      <Button
        variant="outlined"
        color="contrast"
        disableElevation
        fullWidth
        onClick={() => displayFullDetails()}
        sx={[fonts.spartan14, semiBoldText, button, {maxWidth: "330px"}]}>
        {translation.page.bookings.bookingDetails.cruiseDetails}
      </Button>
    </Box>
  )
}

export default CruiseDetailsBtn;