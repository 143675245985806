import {Box, Button} from "@mui/material";
import {useContext} from "react";
import {AppContext, AppModalContext} from "../../../../../../../common/appWrapper/AppWrapper";
import {button, centeredBox, fonts, semiBoldText} from "../../../../../../../../../app.style";
import {Booking} from "../../../../../../../../model/Booking";
import ParticipantsModalContent
  from "../../../../../../../common/appModal/participantsModalContent/ParticipantsModalContent";


const ShowParticipantsBtn = ({booking} : {booking: Booking}) => {
  const {translation} = useContext(AppContext);
  const {setModalHeaderText, setModalChildren, setIsModalOpen} = useContext(AppModalContext);

  const showParticipants = () => {
    setModalHeaderText(translation.modalHeader.bookingParticipants);
    setModalChildren(<ParticipantsModalContent participants={booking.participants}/>);
    setIsModalOpen(true);
  }

  return (
    <Box sx={centeredBox}>
      <Button
        variant="contained"
        disableElevation
        fullWidth
        onClick={() => showParticipants()}
        sx={[fonts.spartan14, semiBoldText, button, {maxWidth: "330px"}]}>
          {translation.page.admin.cruiseDetails.showParticipants}
      </Button>
    </Box>
  )
}

export default ShowParticipantsBtn;