import {useEffect, useState} from "react";
import {Translation} from "../../../../../language/translation/translations";
import PassSetupFormValidator from "./PassSetupFormValidator";
import PassSetupRequest from "../../../../service/auth/PassSetupRequest";

const usePassSetupFormValidator = (translation: Translation, passSetupFormData : PassSetupRequest) => {
  const [passSetupFormValidator] = useState(new PassSetupFormValidator(translation, passSetupFormData));
  const [, setRegistrationFormIsValid] = useState(false);
  const [, setPasswordsMatch] = useState(false);
  const [passContainsSmall, setPassContainsSmall] = useState(false);
  const [passContainsCapital, setPassContainsCapital] = useState(false);
  const [passHasMinRequiredChars, setPassHasMinRequiredChars] = useState(false);

  const validatePassSetupForm = () => {
    setRegistrationFormIsValid(passSetupFormValidator.validateForm());
    setPasswordsMatch(passSetupFormValidator.passwordRepeatValidator.isValid);
    setPassContainsSmall(passSetupFormValidator.passwordValidator.containsSmall);
    setPassContainsCapital(passSetupFormValidator.passwordValidator.containsCapital);
    setPassHasMinRequiredChars(passSetupFormValidator.passwordValidator.hasMinRequiredChars);
  }

  useEffect(() => {
    passSetupFormValidator.setTranslation(translation);
  }, [passSetupFormValidator, translation]);

  return {passSetupFormValidator, validatePassSetupForm, passContainsSmall, passContainsCapital, passHasMinRequiredChars}
}

export default usePassSetupFormValidator;