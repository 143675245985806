import {Review} from "../../../../model/Review";
import {Box, Rating, Typography} from "@mui/material";
import {useCallback, useContext, useEffect, useState} from "react";
import {fonts, mainColors} from "../../../../../app.style";
import {AppContext} from "../../appWrapper/AppWrapper";

const CruiseRating = ({reviews}: {reviews: Review[]}) => {
  const {translation} = useContext(AppContext);

  const getValue = useCallback(()=> {
    if (reviews.length > 0) {
      return reviews
        .map(r => r.score)
        .reduce((acc, curr) => acc + curr, 0) / reviews.length;
    } else {
      return 0;
    }
  }, [reviews])

  const [value, setValue] = useState(getValue())

  useEffect(() => {
    setValue(getValue);
  }, [getValue, setValue]);

  return (
    <Box>
      <Box sx={{display: "flex", justifyContent: "center"}}>
        <Rating  value={value} precision={0.5} readOnly />
        <Typography sx={[fonts.spartan12, mainColors.primary, {display: 'flex', alignItems: "center", pt: "2px", pl: "4px"}]}>
          ({reviews.length})
        </Typography>
      </Box>
      <Typography sx={[fonts.spartan12, mainColors.grey, {alignItems: "center",textAlign: "center",  pt: "2px"}]}>
        {reviews.length === 0 ? translation.page.cruises.cruiseDetails.notRatedYet : value.toFixed(1) + " / 5"}
      </Typography>
    </Box>
  )
}

export default CruiseRating;