import {Link} from "@mui/material";
import {useContext} from "react";
import {AppContext, AppModalContext} from "../../../appWrapper/AppWrapper";
import {fonts, mainColors, semiBoldText} from "../../../../../../app.style";
import LoginForm from "../../loginForm/LoginForm";


const BackToLoginButton = () => {
  const {translation} = useContext(AppContext);
  const {setModalHeaderText, setModalChildren} = useContext(AppModalContext)

  const switchToLogin = () => {
    setModalHeaderText(translation.modalHeader.menuLogin);
    setModalChildren(<LoginForm/>);
  }

  return (
    <Link
      component="button"
      sx={[fonts.spartan14, semiBoldText, mainColors.primary]}
      onClick={() => switchToLogin()}>
      {translation.form.registerForm.backToLogin}
    </Link>
  )
}

export default BackToLoginButton;