import {Box, Button} from "@mui/material";
import {useContext} from "react";
import {AppContext, AppFsDrawerContext} from "../../../../../../../common/appWrapper/AppWrapper";
import {button, centeredBox, fonts, semiBoldText} from "../../../../../../../../../app.style";
import {Cruise} from "../../../../../../../../model/Cruise";
import {EditNote} from '@mui/icons-material';
import CruiseForm from "../../../../../../../common/appForm/cruiseForm/CruiseForm";

const EditCruiseBtn = ({cruise} : {cruise: Cruise}) => {
  const {translation} = useContext(AppContext);
  const {setFsDrawerChildren, setIsFsDrawerOpen} = useContext(AppFsDrawerContext);

  const editCruise = () => {
    setFsDrawerChildren(<CruiseForm cruise={cruise} formTitle={translation.page.admin.cruises.cruiseForm.editCruise}/>);
    setIsFsDrawerOpen(true);
  }

  return (
    <Box sx={centeredBox}>
      <Button
        variant="text"
        color="contrast"
        disableElevation
        fullWidth
        endIcon={<EditNote sx={{width: "26px", height: "26px"}}/>}
        onClick={() => editCruise()}
        sx={[fonts.spartan14, semiBoldText, button, {maxWidth: "330px"}]}>
        {translation.edit}
      </Button>
    </Box>
  )
}

export default EditCruiseBtn;