import {apiUrl} from "../urls";
import {useEffect} from "react";
import usePost from "../axios/usePost";
import {findInCache, updateCache} from "../helpers/cacheUtils";
import {clone} from "../../utils/misc/helpers";
import {Review} from "../../model/Review";
import {Cruise} from "../../model/Cruise";


const usePostReview = (cruises: Cruise[], setCruises: Function) => {
  const {
    data,
    setData,
    loading: postReviewLoading,
    postData: postReview
  } = usePost(apiUrl.reviews);

  useEffect(() => {
    if (data) {
      const postResponse = data as Review;
      const cacheClone = clone(cruises) as Cruise[];
      let cruise = findInCache(postResponse.cruiseId, cacheClone) as Cruise;
      updateCache([postResponse], cruise.reviews);
      setCruises(cacheClone);
      setData(null);
    }
  }, [data, cruises, setCruises, setData])

  return {postReviewLoading, postReview}
}

export default usePostReview;