import {validatorSettings} from "../../../../../../../utils/validators/validatorSettings";
import StringValidator from "../../../../../../../utils/validators/StringValidator";
import DateTimeValidator from "../../../../../../../utils/validators/DateTimeValidator";
import {Translation} from "../../../../../../../../language/translation/translations";
import ParticipantRequest from "../../../../../../../service/booking/ParticipantRequest";


export default class ParticipantFormValidator {
  translation: Translation;
  participantRequest: ParticipantRequest;
  nameValidator: StringValidator;
  surnameValidator: StringValidator;
  bornValidator: DateTimeValidator;

  constructor(translation: Translation, participantRequest: ParticipantRequest) {
    this.translation = translation;
    this.participantRequest = participantRequest;
    this.nameValidator = new StringValidator(translation, validatorSettings.nameMaxChars, true);
    this.surnameValidator = new StringValidator(translation, validatorSettings.surnameMaxChars, true, true);
    this.bornValidator = new DateTimeValidator(translation);
  }

  validateForm = () => {
    this.nameValidator.validate(this.participantRequest.name);
    this.surnameValidator.validate(this.participantRequest.surname);
    this.bornValidator.validate(this.participantRequest.born);

    return (
      this.nameValidator.isValid &&
      this.surnameValidator.isValid &&
      this.bornValidator.isValid
    );
  }
}