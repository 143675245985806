import {useContext, useEffect, useState} from "react";
import {AppContext} from "../appWrapper/AppWrapper";

const useYearOptions = () => {
  const {cruises} = useContext(AppContext);
  const [yearOptions, setYearOptions] = useState([new Date().getFullYear()])

  useEffect(() => {
    if (cruises.length > 0) {
      const years = cruises.map(c => parseInt(c.startDay.split("-")[0]));
      years.push(new Date().getFullYear())
      setYearOptions([...new Set(years.sort())]);
    }
  }, [cruises]);


  return {yearOptions};
}

export default useYearOptions;