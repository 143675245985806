import {Button} from "@mui/material";
import {button, fonts, semiBoldText} from "../../../../../../../../app.style";

const UseCodeBtn = ({label, handleUse, disabled} : {label: string, handleUse: Function, disabled?: boolean}) => {

  return(
    <Button
      variant="contained"
      disabled={disabled}
      disableElevation fullWidth
      onClick={() => handleUse()}
      sx={[fonts.spartan14, semiBoldText, button, {maxWidth: "330px", mt: 1}]}>
      {label}
    </Button>
  )
}

export default UseCodeBtn;