import {useContext, useEffect, useState} from "react";
import {AppContext} from "../../common/appWrapper/AppWrapper";
import PageSpinner from "../../common/appSpinner/PageSpinner";
import {Cruise} from "../../../model/Cruise";
import {useParams} from "react-router-dom";
import CruiseFullDetails from "./cruiseFullDetails/CruiseFullDetails";


const CruiseDetailsPage = () => {
  const {cruiseId} = useParams();
  const {cruises, appCacheApi} = useContext(AppContext);
  const [cruise, setCruise] = useState<Cruise>()

  useEffect(() => {
    if (cruises.length > 0) setCruise(cruises.find(c => c.id === cruiseId))
  }, [cruises, appCacheApi, cruiseId]);

  return (
    <>
      {cruise ? <CruiseFullDetails cruise={cruise}/> : <PageSpinner/>}
    </>
  )
}

export default CruiseDetailsPage;