import {TextFieldType} from "../../../../utils/appEnums/TextFieldType";
import AppTextField from "../../appTextField/AppTextField";
import {useContext, useState} from "react";
import {AppContext} from "../../appWrapper/AppWrapper";
import PassResetRequest from "../../../../service/auth/PassResetRequest";
import PassResetFormValidator from "./PassResetFormValidator";
import PassResetButton from "./passResetButton/PassResetButton";

const PassResetForm = () => {
  const {translation} = useContext(AppContext);
  const [passResetFormData] = useState(new PassResetRequest());
  const [passResetFormValidator] = useState(new PassResetFormValidator(translation));
  const [showErrorText, setShowErrorText] = useState(false);

  return (
    <>
      <AppTextField
        formData={""}
        setFormData={passResetFormData.setEmail}
        required={true}
        type={TextFieldType.TEXT}
        label={translation.form.loginForm.emailAddress}
        showErrorText={showErrorText}
        validator={passResetFormValidator.emailValidator}/>
      <PassResetButton
        formData={passResetFormData}
        passResetFormValidator={passResetFormValidator}
        setShowErrorText={setShowErrorText}/>
    </>
  )
}

export default PassResetForm;