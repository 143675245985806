import {fonts, mainColors} from "../../../../../app.style";

export const footerBox = {
  py: 3,
  position: "absolute",
  bottom: 0,
  left: 0,
  right: 0,
  // background: "radial-gradient(circle, rgba(29,157,255,1) 0%, rgba(30,129,247,1) 75%, rgba(30,129,247,1) 100%)",
  backgroundColor: mainColors.footer.color
}

export const footerTitle = {
  ...mainColors.white,
  ...fonts.spartan16
}

export const footerLegalText = {
  ...mainColors.white,
  ...fonts.spartan12,
  textAlign: "center"
}

export const footerMenuContainer = {
  '.MuiTabs-indicator': {display: "none"},
  mt: 2,
}

export const footerMenuTab = {
  ...mainColors.white,
  py: 0,
  px: 0,
  alignItems: "start",
  minHeight: "24px"
}