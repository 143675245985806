import {Typography} from "@mui/material";
import {fonts} from "../../../../../../../app.style";
import {getCruiseStatus, getDaysLeft} from "../../../../../../utils/misc/helpers";
import {useContext} from "react";
import {AppContext} from "../../../../../common/appWrapper/AppWrapper";
import {Cruise} from "../../../../../../model/Cruise";
import {CruiseStatus} from "../../../../../../utils/appEnums/CruiseStatus";
import useReviewsLeft from "../../../../../../utils/hooks/useReviewsLeft";

const BookingCardBadge = ({cruise}: {cruise: Cruise}) => {
  const {translation} = useContext(AppContext);
  const {reviewsLeft} = useReviewsLeft(cruise)

  const getBadgeText = () => {
    if(getCruiseStatus(cruise) === CruiseStatus.UPCOMING){
      return translation.page.bookings.imgBadge.in + " " + getDaysLeft(cruise.startDay) + " " + translation.page.bookings.imgBadge.days;
    } else {
      if (reviewsLeft === 0){
        return translation.page.bookings.imgBadge.reviewed;
      } else {
        return translation.page.bookings.imgBadge.write + " " + reviewsLeft + " " + translation.page.bookings.imgBadge.review;
      }
    }
  }

  return (
    <Typography sx={[fonts.spartan14, {position: "absolute", top: "26px", borderRadius: "0 4px 4px 0", backgroundColor: "#FFFFFFAA", px: 2, pt: "4px", pb: "1px"}]}>
      {getBadgeText()}
    </Typography>
  )
}

export default BookingCardBadge;