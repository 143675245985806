import {Typography} from "@mui/material";
import {fonts, mainColors, mediumText} from "../../../../../../app.style";

const SimpleTextChild = ({text}: {text: string}) => {
  return (
    <Typography sx={[fonts.spartan14, mainColors.grey, mediumText, {display: "flex", justifyContent: "center"}]}>
      {text}
    </Typography>
  )
}

export default SimpleTextChild;