import {useCallback, useEffect, useState} from "react";
import axios from "axios";
import {clone} from "../../utils/misc/helpers";


function usePost(url: string) {
  const [data, setData] = useState(null);
  const [status, setStatus] = useState<number>();
  const [loading, setLoading] = useState(false);

  function parsePayload(payload: any) {
    const cloned = clone(payload)
    Object.keys(cloned).forEach((key) => {
      if (cloned[key] === "") cloned[key] = null;
    });
    return cloned;
  }

  const postData = useCallback((payload: any, jwt?: string, suffix?: string, successHandler?: Function, errorHandler?: Function) => {
    setLoading(true);
    axios
      .post(suffix ? url + suffix : url, parsePayload(payload), {
        headers: {
          "Authorization": jwt ? `Bearer ${jwt}` : "",
          "Content-Type": "application/json"
        }
      })
      .then((response) => {
        // console.log("RESPONSE STATUS: ", response.status);
        // console.log("RESPONSE DATA: ", response.data);
        setData(response.data)
        setStatus(response.status);
        if (successHandler) successHandler(response.data);
      })
      .catch((err) => {
        console.log(err);
        setStatus(err.response.status);
        if (errorHandler) errorHandler(err.response.status);
        setLoading(false);
      })
  }, [url]);

  useEffect(() => {
    if (data != null) setLoading(false);
  }, [data])

  useEffect(() => {
    if (!loading && status !== undefined) setStatus(undefined);
  }, [status, loading]);

  return {data, setData, status, setStatus, loading, postData}
}

export default usePost;