import AbstractValidator from "./AbstractValidator";
import {Translation} from "../../../language/translation/translations";


export default class PhoneValidator implements AbstractValidator  {
  private _translation: Translation;
  private readonly _mandatory: boolean;
  private _isValid= false;
  private _errorText = ".";

  constructor(translation: Translation, mandatory?: boolean) {
    this._mandatory = mandatory ?? false;
    this._translation = translation
  }

  validate(input: string) {
    this._isValid = false;
    if (input.length === 0) {
      if (this._mandatory) {
        this._errorText = this._translation.formErrors.fieldRequired;
      } else {
        this._errorText = ".";
        this._isValid = true;
      }
    } else if (!/^(\+\d{1,4}[- ]?)?\d{10}$/.test(input)) {
      this._errorText = this._translation.formErrors.phoneFormat;
    } else {
      this._errorText = "."
      this._isValid = true;
    }
  }

  public set translation(translation: Translation) {
    this._translation = translation;
  }

  public get isValid(){
    return this._isValid;
  }

  public get errorText(){
    return this._errorText;
  }
}