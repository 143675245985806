import {Box, Typography} from "@mui/material";
import {useContext} from "react";
import {AppContext} from "../../appWrapper/AppWrapper";
import {Language} from "../../../../../language/Language";
import {fonts, semiBoldText} from "../../../../../app.style";
import FreeSpaces from "./freeSpaces/FreeSpaces";
import {getFormatPrice} from "../../../../utils/misc/helpers";

interface CruisePriceProps {
  spacesLeft: number;
  pricePln: number;
  priceEur: number;
}

const CruisePrice = (props: CruisePriceProps) => {
  const {translation, selectedLanguage} = useContext(AppContext);

  return (
    <Box>
      <FreeSpaces spacesLeft={props.spacesLeft} justify={"flex-end"}/>
      {props.spacesLeft !== 0 &&
        <Box sx={{display: "flex", justifyContent: "flex-end"}}>
          <Typography sx={[fonts.spartan16, semiBoldText]}>
            {selectedLanguage === Language.PL ? getFormatPrice(props.pricePln) : getFormatPrice(props.priceEur)}
            {translation.page.cruises.cruiseDetails.currency}
          </Typography>
          <Typography sx={[fonts.spartan16]}>
            &nbsp;{translation.page.cruises.cruiseDetails.perPerson}
          </Typography>
        </Box>}
    </Box>
  )
}

export default CruisePrice;