import {Translation} from "../../../language/translation/translations";
import AbstractValidator from "./AbstractValidator";

export default class CheckboxValidator implements AbstractValidator {
  private _isValid = true;
  private _translation: Translation;
  private readonly _isRequired: boolean;
  private _errorText = ".";

  constructor(translation: Translation, required = false) {
    this._translation = translation;
    this._isRequired = required;
  }

  validate(input: boolean) {
    this._isValid = false;
    if (this._isRequired && !input) {
      this._errorText = this._translation.formErrors.agreementRequired;
    } else {
      this._errorText = "."
      this._isValid = true;
    }
  }

  public get isValid() {
    return this._isValid;
  }

  public get errorText(): string {
    return this._errorText;
  }
}