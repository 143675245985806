import {AppBar, Box, IconButton, Slide, SvgIcon, Tab, Tabs, Typography, useScrollTrigger} from "@mui/material";
import {boldText, fonts, mainColors} from "../../../../../../app.style";
import Image from "mui-image";
import {Person, PersonOutlined} from "@mui/icons-material";
import AppSelectField from "../../../appSelectField/AppSelectField";
import {useContext, useEffect, useRef} from "react";
import {AppContext} from "../../AppWrapper";
import {AppMenuProps} from "../AppMenuProps";
import {desktopMenuAppBarStyle, desktopMenuMainBoxStyle} from "./desktopMenu.style";
import {NavMenuContext} from "../NavMenu";


const DesktopMenu = (props: AppMenuProps) => {
  const {selectedLanguage, translation, appUser, windowWidth, setMenuHeight} = useContext(AppContext);
  const {tab, menuOptions, changeTab, langOpts} = useContext(NavMenuContext);
  const container = useRef<HTMLDivElement>();

  useEffect(() => {
    setMenuHeight(container.current?.offsetHeight);
  }, [windowWidth, setMenuHeight]);

  return (
    <Slide appear={false} direction="down" in={!useScrollTrigger()}>
      <AppBar sx={desktopMenuAppBarStyle}>
        <Box sx={desktopMenuMainBoxStyle} ref={container}>
          <Box sx={{mx: 1, height: "64px"}}>
            <Image src={"/images/logo_dark.png"} duration={1000} width={64} />
          </Box>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', ml: 10}}>
            <Tabs value={tab}>
              {
                menuOptions.map((option, index) => (
                  <Tab
                    onClick={() => changeTab(index)}
                    sx={mainColors.contrast} key={index} label={
                    <Typography sx={[fonts.navRunic36, boldText, {my: -1}]}>{option.title}</Typography>
                  }/>
                ))
              }
            </Tabs>
          </Box>
          <Box sx={{display: "flex"}}>
            <IconButton color={"contrast"} onClick={() => props.handlePersonBtnClick()} sx={{mr: 2}}>
              <SvgIcon sx={{fontSize: 36} } component={
                appUser ?
                  Person : PersonOutlined} inheritViewBox/>
            </IconButton>
            <Box sx={{width: "120px"}}>
              <AppSelectField
                label={translation.desktopLangLabel}
                options={langOpts}
                selectedValue={selectedLanguage}
                setFormData={props.setLang}/>
            </Box>
          </Box>
        </Box>
      </AppBar>
    </Slide>
  )
}

export default DesktopMenu;