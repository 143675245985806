import {useCallback, useState} from "react";
import axios from "axios";


function useGet(url: string) {
  const [data, setData] = useState(null);
  const [status, setStatus] = useState<number>();
  const [loading, setLoading] = useState(false);

  const getData = useCallback((suffix?: string, jwt?: string) => {
    setLoading(true);
    axios
      .get(suffix ? url + suffix : url, {
        headers: {
          "Authorization": jwt ? `Bearer ${jwt}` : ""
        }
      })
      .then((response) => {
        // console.log("RESPONSE STATUS: ", response.status);
        // console.log("RESPONSE DATA: ", response.data);
        setData(response.data)
        setStatus(response.status);
      })
      .catch((err) => {
        console.log(err);
        setStatus(err.response.status);
      })
      .finally(() => {
        setLoading(false);
      })
  }, [url]);

  return {data, setData, status, loading, getData}
}

export default useGet;