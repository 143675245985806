import {Button} from "@mui/material";
import {useContext} from "react";
import {AppContext} from "../../appWrapper/AppWrapper";
import {button, fonts, semiBoldText} from "../../../../../app.style";


const NoButton = ({onClick} : {onClick : Function}) => {
  const {translation} = useContext(AppContext);

  return (
    <Button
      variant="outlined"
      color="contrast"
      disableElevation fullWidth
      onClick={() => onClick()}
      sx={[fonts.spartan14, semiBoldText, button]}>
        {translation.modalPrompt.no}
    </Button>
  )
}

export default NoButton;