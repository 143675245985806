import {Translation} from "../../../../../language/translation/translations";
import PasswordValidator from "../../../../utils/validators/PasswordValidator";
import PasswordRepeatValidator from "../../../../utils/validators/PasswordRepeatValidator";
import {validatorSettings} from "../../../../utils/validators/validatorSettings";
import PassSetupRequest from "../../../../service/auth/PassSetupRequest";


export default class PassSetupFormValidator {
  translation: Translation;
  passSetupRequest: PassSetupRequest;
  passwordValidator: PasswordValidator;
  passwordRepeatValidator: PasswordRepeatValidator;

  constructor(translation: Translation, passSetupRequest: PassSetupRequest) {
    this.translation = translation;
    this.passSetupRequest = passSetupRequest;
    this.passwordValidator = new PasswordValidator(translation, validatorSettings.passwordMinChars, validatorSettings.passwordMaxChars);
    this.passwordRepeatValidator = new PasswordRepeatValidator(translation, passSetupRequest)
  }

  setTranslation = (translation: Translation) => {
    this.translation = translation;
    this.passwordValidator.translation = translation;
    this.passwordRepeatValidator.translation = translation;
  }

  validateForm = () => {
    this.passwordValidator.validate(this.passSetupRequest.password);
    this.passwordRepeatValidator.validate(this.passSetupRequest.repeatPassword);

    return (
      this.passwordValidator.isValid &&
      this.passwordRepeatValidator.isValid
    )
  }
}