import {BunkStatus} from "../../../../../../utils/appEnums/BunkStatus";
import {bunkStatusFree, bunkStatusSelected, bunkStatusTaken} from "./bunksIndicator.style";

const BunkIndicator = ({img, status, width} : {img: string, status: BunkStatus, width: number | undefined}) => {

  const getStatusColor = () => {
    switch (status) {
      case BunkStatus.FREE:
        return bunkStatusFree;
      case BunkStatus.TAKEN:
        return bunkStatusTaken;
      case BunkStatus.SELECTED:
        return bunkStatusSelected;
    }
  }

  return (
    <img
      src={`/images/layout/vertical/${img}_v.png`}
      alt={img}
      style={{
        maxWidth: width,
        position: "absolute", top: 0, left: 0, opacity: 0.5,
        ...getStatusColor()
    }} />
  )
}

export default BunkIndicator;