import useGet from "../axios/useGet";
import {apiUrl} from "../urls";
import {useEffect, useState} from "react";
import {Booking} from "../../model/Booking";
import {updateCache} from "../helpers/cacheUtils";
import {clone, strEquals} from "../../utils/misc/helpers";

const useGetCruiseBookings = (myBookings: Booking[], setMyBookings: Function) => {
  const [cruiseBookings, setCruiseBookings] = useState([] as Booking[]);
  const {data, setData, loading: getCruiseBookingsLoading, getData: getCruiseBookings} = useGet(apiUrl.bookings + "/cruise/");

  useEffect(() => {
    if (data) {
      const bookings = data as unknown as Booking[]
      setCruiseBookings(bookings);
      const myBookingsClone = clone(myBookings);
      updateCache(bookings, myBookingsClone, true);
      if (strEquals(myBookingsClone, myBookings)) setMyBookings(myBookingsClone);
      setData(null);
    }
  }, [data, myBookings, setData, setMyBookings]);

  return {cruiseBookings, setCruiseBookings, getCruiseBookings, getCruiseBookingsLoading}
}

export default useGetCruiseBookings;