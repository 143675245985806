import AppTextField from "../../appTextField/AppTextField";
import {useContext, useState} from "react";
import {AppContext} from "../../appWrapper/AppWrapper";
import {TextFieldType} from "../../../../utils/appEnums/TextFieldType";
import AppDatePicker from "../../appTimePicker/AppDatePicker";
import AppUserRequest from "../../../../service/appUser/AppUserRequest";
import ProfileSettingsFormValidator from "./ProfileSettingsFormValidator";
import UpdateButton from "./updateButton/UpdateButton";
import {Box} from "@mui/material";
import LogoutButton from "./logoutButton/LogoutButton";
import useSimpleOpts from "../../appSelectField/appSelectField/simpleOption/useSimpleOpts";
import AppSelectField from "../../appSelectField/AppSelectField";


const ProfileSettingsForm = () => {
  const {translation, appUser} = useContext(AppContext);
  const [profileSettingsFormData] = useState(appUser ? AppUserRequest.fromExisting(appUser) : AppUserRequest.fromEmpty());
  const [showErrorText, setShowErrorText] = useState(false);
  const {simpleOpts: genderOpts} = useSimpleOpts(translation.form.registerForm.gender.options, false);
  const [profileSettingsFormValidator] = useState(new ProfileSettingsFormValidator(translation, profileSettingsFormData));

  return (
    <Box sx={{maxWidth: "380px"}}>
      <AppTextField
        formData={profileSettingsFormData.name}
        setFormData={profileSettingsFormData.setName}
        required={true}
        type={TextFieldType.TEXT}
        label={translation.form.registerForm.name}
        showErrorText={showErrorText}
        validator={profileSettingsFormValidator.nameValidator}/>
      <AppTextField
        formData={profileSettingsFormData.surname}
        setFormData={profileSettingsFormData.setSurname}
        required={true}
        type={TextFieldType.TEXT}
        label={translation.form.registerForm.surname}
        showErrorText={showErrorText}
        validator={profileSettingsFormValidator.surnameValidator}/>
      <AppTextField
        formData={profileSettingsFormData.nickname}
        setFormData={profileSettingsFormData.setNickname}
        required={true}
        type={TextFieldType.TEXT}
        label={translation.form.registerForm.nickname}
        showErrorText={showErrorText}
        validator={profileSettingsFormValidator.nicknameValidator}/>
      <AppSelectField
        label={translation.form.registerForm.gender.title}
        options={genderOpts}
        selectedValue={profileSettingsFormData.gender}
        required={true}
        setFormData={profileSettingsFormData.setGender}/>
      <Box sx={{mt: "26px"}}/>
      <AppDatePicker
        formData={profileSettingsFormData.born}
        setFormData={profileSettingsFormData.setBorn}
        label={translation.form.registerForm.born}
        showErrorText={showErrorText}
        validator={profileSettingsFormValidator.bornValidator}
        disableFuture={true}/>
      <AppTextField
        formData={profileSettingsFormData.phone}
        setFormData={profileSettingsFormData.setPhone}
        type={TextFieldType.PHONE}
        label={translation.form.registerForm.phone}
        showErrorText={showErrorText}
        validator={profileSettingsFormValidator.phoneValidator}/>
      <UpdateButton
        formData={profileSettingsFormData}
        profileSettingsFormValidator={profileSettingsFormValidator}
        showErrorText={showErrorText}
        setShowErrorText={setShowErrorText}/>
      <LogoutButton/>
    </Box>
  )
}

export default ProfileSettingsForm;