import {Box, Drawer} from '@mui/material'
import {PropsWithChildren} from "react";
import {ANIMATION_SPEED, MENU_DRAWER_WIDTH} from "../../../../guiConstants";


export interface MenuDrawerProps extends PropsWithChildren{
  isOpen: boolean;
  setIsOpen: Function;
}

const MenuDrawer = (props: MenuDrawerProps) => {

  return (
    <Drawer
      variant="temporary"
      transitionDuration={ANIMATION_SPEED}
      anchor={"left"}
      open={props.isOpen}
      onClose={() => props.setIsOpen(false)}>
      <Box sx={{width: MENU_DRAWER_WIDTH, height: "100vh"}}>
        {props.children}
      </Box>
    </Drawer>
  )
}
export default MenuDrawer