import {useState} from "react";
import RegisterRequest from "../../../../service/auth/RegisterRequest";
import {Translation} from "../../../../../language/translation/translations";
import RegisterFormValidator from "./RegisterFormValidator";

const useRegistrationFormValidator = (translation: Translation, registrationFormData : RegisterRequest) => {
  const [registerFormValidator] = useState(new RegisterFormValidator(translation, registrationFormData));
  const [, setRegistrationFormIsValid] = useState(false);
  const [, setPasswordsMatch] = useState(false);
  const [passContainsSmall, setPassContainsSmall] = useState(false);
  const [passContainsCapital, setPassContainsCapital] = useState(false);
  const [passHasMinRequiredChars, setPassHasMinRequiredChars] = useState(false);

  const validateRegisterForm = () => {
    setRegistrationFormIsValid(registerFormValidator.validateForm());
    setPasswordsMatch(registerFormValidator.passwordRepeatValidator.isValid);
    setPassContainsSmall(registerFormValidator.passwordValidator.containsSmall);
    setPassContainsCapital(registerFormValidator.passwordValidator.containsCapital);
    setPassHasMinRequiredChars(registerFormValidator.passwordValidator.hasMinRequiredChars);
  }

  return {registerFormValidator, validateRegisterForm, passContainsSmall, passContainsCapital, passHasMinRequiredChars}
}

export default useRegistrationFormValidator;