import {Translation} from "./translations";

export const translationEn : Translation = {
  desktopLangLabel: "Language / Currency",
  mobileLangLabel: "Language",
  undefined: "Undefined",
  choose: "Choose",
  save: "Save",
  cancel: "Cancel",
  login: "Log in",
  close: "Close",
  edit: "Edit",
  delete: "Delete",
  create: "Create",
  navMenu: {
    menu: "Menu",
    home: "Start",
    cruises: "Cruises",
    bookings: "Bookings",
    faq: "FAQ",
    admin: "Admin",
    login: "Login",
    settings: "Settings"
  },
  footer: {
    sections: "Sections",
    contact: "Contact",
    disclaimer: "Announcements, adverts, pricing and information presented on chacrunasails.eu website are not an offer as stated in Civil Codex.",
    copyright: "Copyright © Chacruna Sails ",
  },
  form: {
    common: {
      dintReceiveEmail: "Didn't receive email?",
      sendAgain: "Send again",
      sendAgainSuccess: "Check again your mailbox",
      sendAgainFailure: "Something went wrong :("
    },
    loginForm: {
      emailAddress: "E-mail address",
      password: "Password",
      loginBtn: "Login",
      forgotPass: '"I forgot my password..."',
      noAccount: "Don't have an account?",
      createAccount: "Create account",
    },
    registerForm: {
      gender: {
        title: "Gender",
        options: {
          MALE: "Male",
          FEMALE: "Female",
        }
      },
      name: "Name",
      surname: "Surname",
      nickname: "Nickname",
      email: "Email address",
      phone: "Phone no.",
      password: "Password",
      repeatPassword: "Repeat password",
      born: "Date of birth",
      registerBtn: "Register",
      alreadyHaveAccount: "Already have an account?",
      backToLogin: "Log-in",
      passReq: {
        header: "Your password must contain:",
        leastSigns: "at least 8 signs",
        capitalLetter: "capital letter",
        smallLetter: "small letter"
      },
      regSuccessInfo1: "Verification email has been sent to: ",
      regSuccessInfo2: "Check your inbox (including \"Spam\" folder) and verify your address in order to login.",
    },
    resetPassword: {
      title: "Provide registered account email address",
      reset: "Reset",
      mailInfo: "If account with given email address exists, instructions have been sent regarding next steps to change your password.",
      iRemember: "\"I remember password...\""
    },
    setPassword: {
      title: "Provide new password",
      change: "Change password",
      changeSuccess: "Password has been successfully changed!",
      changeFailure: "Something went wrong :(",
    },
    profileSettings: {
      update: "Update",
      updated: "Changes saved",
      logout: "Logout",
    },
    newBooking: {
      steps: {
        data: "Data",
        bunks: "Bunks",
        payment: "Payment",
      },
      subStep: "Step",
      bookingDataStep: {
        bookedCruiseInfo: {
          title: "Booking information:",
          from: "Cruise from:",
          to: "Cruise to:",
          embarkDate: "Embark:",
          disembarkDate: "Disembark:",
          duration: "Duration time:",
          days: "days",
        },
        bookedByDetails: {
          title: "Booking person details:",
          iAmAlsoPassenger: "I am also cruise participant.",
        },
        passengerDetails: {
          title: "Cruise participants data:",
          passenger: "Participant",
          details: "data",
          addAnotherPassenger: "Add participant",
          noMoreSpaces: "No more spaces",
          removePassenger: "Remove participant",
        },
        requiredAgreements: {
          title: "Necessary agreements:",
          reservationPolicyConfirm1: "I have read and accept",
          policy: "policy",
          reservationPolicyConfirm2: "of making and cancelling reservation on \"Chacruna Sails\" website.",
          reservationProcessingConfirm: "I agree to my personal data being processed with respect to proper and necessary reservation conduct.",
        },
        nextPreviousBtns: {
          next: "Choose Bunks",
          previous: "",
        }
      },
      bunksSelectionStep: {
        title: "your bunks:",
        available: "Available",
        taken: "Taken",
        selected: "Selected",
        nextPreviousBtns: {
          next: "To Payment",
          previous: "Change Data",
        },
        bunkName: {
          bowPort: "1: Bow - port",
          bowStarboard: "2: Bow - starboard",
          mesaPort: "3: Mesa - port",
          mesaStarboard: "4: Mesa - starboard",
          sternPort: "5: Stern - port",
          sternStarboard: "6: Stern - starboard",
        }
      },
      paymentStep: {
        discountCode: {
          title: "Discount Code:",
          label: "Code",
          description: "Jeśli posiadasz kod zniżki, możesz go użyć aby zobaczyć nową cenę.",
          valid: "Kod poprawny",
          invalid: "Kod niepoprawny",
          useCode: "Apply",
        },
        bookingOptions: {
          title: "Booking options:",
          fullPaymentReq: "Only full payment is allowed due to close, cruise start date.",
          remainingPaymentDueInfo: "Full payment will be required up to 14 days before cruise start day.",
          remainingPaymentDueDate: "Pay remaining booking fee before end of: ",
          bookingType: "Booking type",
          options: {
            ONE_STAGE: "Full Payment (100%)",
            TWO_STAGE: "Only Advance (50%)"
          }
        },
        paymentOptions: {
          title: "Payment options:",
          currency: "Currency",
        },
        summary: {
          title: "Summary:",
          yourBunks: "Your bunk number/s:",
          bookedQty: "Booked spaces:",
          pricePerPerson: "Price per person:",
          discountPricePerPerson: "Price per person after discount:",
          totalPrice: "Total price:",
          amountToPay: "Amount to pay:"
        },
        nextPreviousBtns: {
          next: "Pay",
          previous: "Change Bunks",
        },
        bankTransferInfo1: "An email email with bank transfer details has been sent to: ",
        bankTransferInfo2: "Check your inbox (including \"Spam\" folder) and follow the instructions included in " +
          "the email message.",
      }
    }
  },
  modalHeader: {
    menuLogin: "Log into service",
    bookingLogin: "Login to make a booking",
    register: "Service registration",
    profileSettings: "Profile settings",
    passReset: "Password reset",
    regSuccess: "Registration successful",
    writeReview: "New review",
    editReview: "Edit review",
    editDiscount: "Edit discount",
    createDiscount: "Create discount",
    closeBookingDetailsConfirm: "Are you sure you want to close booking details? (All unsaved changes will be lost)",
    cancelBooking: "Are you sure you want to cancel booking?",
    deleteCruise: "Are you sure you want to delete this cruise?",
    deleteDiscount: "Are you sure you want to delete discount?",
    deleteReview: "Are you sure you want to delete review?",
    discardChanges: "Are you sure you want to discard changes?",
    manageBooking: "Booking management",
    changeBookingStatus: "Change booking",
    advanceBooking: "Change to next status?",
    bookingParticipants: "Booking participants",
    crewList: "Crew list",
    paymentInfoSent: "Bank transfer details sent",
    policy: "Booking Policy",
    bookingCollision: "Booking collision :(",
  },
  bookingCollisionModal: {
    text: "Ops! Looks like your booking has expired or someone else already booked the bunks you are trying to book. Please try making new booking again..."
  },
  modalPrompt: {
    yes: "Yes",
    no: "No",
    allUnsavedDataWillBeLost: "All unsaved changes will be lost",
  },
  policyModal: {
    text1: "By making reservation (booking) in \"Chacruna Sails\" service, User declares that he is an adult and his (or cruise participants for which he is making reservation) physical and metal health condition does not constitute any serious contraindication to participation in cruise on Bavaria 36 \"Chacruna\" sailboat.",
    text2: "By making booking in \"Chacruna Sails\" website, User is first making a preliminary booking which will be further confirmed via email, only after paying booking fee. Booking fee should be paid in timely manner (after receiving instructions email) in order to secure booked bunks and space on the cruise. Organizer reserves right (in rare circumstances) to make reservation void in case if booking fee was paid by another user, before booking fee was paid by initial User, and would be booking the same bunks (beds) like those booked by another user. In that case, User will get full refund immediately at no cost.",
    text3: "After initial booking, User will receive via email address from Organizer, \"Tourist Services Agreement\" and bank transfer details. After making bank transfer by User, his reservation will be confirmed and secured.",
    text4: "With respect to booked cruise by the User, he should not make any logistical preparations (buy mass transport tickets, book hotels) before receiving official confirmation validity of his booking via email from \"Chacruna Sails\". Organizer does not take any liability for any eventual financial losses incurred by the User who would undertake such actions before receiving official booking confirmation. Official booking confirmation is booking status: ADVANCE PAID or FULLY PAID.",
    text5: "User maintains unlimited right to cancel previously made booking and according to how many days are left from cancellation date to cruise start date, \"late cancellation fee\" might be applicable according to following algorithm:",
    text5a: "Booking cancellation earlier than 28 days before cruise start date is free and User will receive full return of booking fees paid.",
    text5b: "Booking cancellation in the range between 28 to 14 days before cruise start date will incur partial or full loss of paid advance (in case of selected booking option to pay only advance 50%), loss of partial up to maximum 50% full payment (in case of selected booking option to pay full price 100%). Cancellation fee amount is calculated proportionally to number of days that elapsed within the span of 28 to 14 days before cruise start date, with general rule of thumb - the closer to date that is 14 days before cruise start date, the higher the cancellation fee.",
    text5c: "Booking cancellation in the range between 14 to 7 days before cruise start date will incur partial or full loss of total booking fee paid. \"Advance 50%\" part is not subject to return in this time frame. The remaining fee is calculated similarly as described in paragraph b) (amount that will not be returned is calculated by proportion for days that elapsed in the time frame between 14 to 7 days before cruise start date).",
    text5d: "Booking cancellation under 7 days before cruise start date is not viable for return and will mean full booking fee loss.",
    text5ex: "Explanation: Booking cancellation policy, results from need of securing Organizer from significant financial losses which might arise in situation without fore mentioned policy - User could cancel booking, leaving too little time for Organizer to find crew replacement (other crew member). Finding replacement is even more difficult the closer the cruise start date. It also takes into account the fact that other cruise participants share the marinas costs evenly which would be split among less participants and more felt by them individually. In order to better understand booking cancellation policy, please check \"Frequently Asked Questions\" (FAQ), question: \"Can I cancel my booking?\".",
    text6: "After User makes booking, he can change participants data at any moment which allows him to replace one participant with the other. It is useful in case when any participant for which User made booking, could not participate for some reason in the cruise. This allows User to find replacement, which is fully allowed by Organizer, but Organizer does not take any responsibility for any agreements and arrangements made between User and appointed replacing crew member.",
    text7: "In case of making reservation (booking) with option to pay only advance (50% of total booking fee), User should pay the remaining 50% booking fee, at latest before 14 days before cruise start date. User will be informed by email with reminding emails about this. In case when remaining 50% would not be paid by the User, he risks loosing his booking, making it void and is not eligible to any returns. Booking that was not FULLY PAID before 14 days before cruise start date, enters a CANCELLING state, which means that it is not yet cancelled but initially booked bunks by User are now available on website and someone else can book them. If that happens (other user makes booking for berths that were booked in booking that is now CANCELLING), initial booking enters the CANCELED state, and advance paid is not subject to return.",
    text8: "Organizer does not hold responsibility for any financial losses tied to inability to undertake cruise partially or fully in designated time frame that would occur as result of situations such as: natural disasters, military conflict (war), pandemic, area contamination, cataclysm, national borders blockade enforced by government, and other similar events that remain completely outside of Organizer control and influence.",
    text9: "Organizer does take liability and responsibility for technical condition of sailing vessel and does take responsibility for situations in which it might be impossible to undertake cruise partially or fully, in designated time frame, due to his personal negligence or gross misconduct that include situations in which (from his fault) would result in serious technical failure, making yacht impossible to sail including sinking. In that case, Organizer will make every effort to compensate cruise participants for any financial and/or moral losses they sustained.",
    text10: "Organizer will inform cruise participants about any possible arrangements with respect to booked cruise via email or phone, aimed at participants satisfaction and safety (with disclaimer that safety will be higher priority), such as:",
    text10a: "advisory help with regards to transport \"to\" embarking location and \"from\" disembarking location,",
    text10b: "making arrangements regarding eating preferences by cruise participants, setting up cooking shifts,",
    text10c: "making general provisioning list as agreed with other cruise participants,",
    text10d: "other arrangements which may affect comfort and/or safety of the ongoing cruise.",
    text11: "These and any other Terms and Conditions of Service Provision, will be contained in \"Tourist Services Agreement\" document, which User declares to familiarize himself with, before making booking fee payment.",
  },
  manageBooking: {
    or: "OR",
    payRemaining: "Pay remaining",
    changeBookingDetails: "Change booking",
    cancelBooking: "Cancel booking",
  },
  changeBookingStatus: {
    advanceBooking: "Next status",
    cancelBooking: "Cancel booking",
  },
  cancelBookingInfo: {
    cruiseWillBeginIn: "Cruise will begin in:",
    days: "days",
    lateCancelFeeApplies: "Late cancellation fee will be applied according to",
    lateCancelFeeDoesNotApply: "You can cancel booking at no cost according to",
    policy: "policy",
    bookingAndCancellation: "of making and cancelling booking on \"Chacruna Sails\" website.",
    returnAmt: "Returned amount to you:",
    finalWarning: "Warning: Booking cancellation is immediate and final.",
  },
  page: {
    home: {
      welcomeText: "Welcome on page",
      offerSectionTitle: "Join The Chacruna Tribe!",
      offerSectionSubtitle: "\"We sail not to escape life, but for life not to escape us.\"",
      offerSectionDescription1: "Welcome to the page aimed at creating association, focused around sailing topic on \"Chacruna\" sailboat. This page is divided into sections where You can familiarize yourself with planned cruises, make a booking, follow our adventures and gain basic knowledge about sailing on a sea yacht in FAQ section (frequently asked questions), which is going to be an excellent preparation before Your first cruise with us on \"Chacruna\", which You are most welcome to join!",
      offerSectionDescription2: "This website allows you to become a member of our growing \"tribe\" by simply creating an account. Having an account will allow you to book cruises, comment and rate cruises you participated in and will allow us to stay in touch with you with respect to your booking processing and preparation for amazing and unforgettable adventure! Check cruises section to see what is coming up, or have a peek on some past cruises to get an idea how it was!",
      socialMediaTitle: "Building social media content together!",
      socialMediaSubtitle: "\"Make visible what, without you, might perhaps never have been seen.\"",
      socialMediaDescription1: "Our adventures do not end with the end of cruise and souvenirs are not just fridge magnets. In the age of internet, almost all of us use social media and we want to share our lifetime highlights with the rest of the world. This is why our YouTube channel was created and dedicated to \"Chacruna\" crew members, where we display best moments of our cruises. If You want to show your friends where have you been, You will get an amazing souvenir from the cruise - video uploaded to YouTube channel @ChacrunaSails completely for free!",
      socialMediaDescription2: "Creating content for either YT, Facebook or Instagram would not be possible without You. You can share your photos or videos made by your phone with us, to be used in souvenir video or in social media which we highly encourage! Doing this, everyone will be able to your adventure on \"Chacruna\" through your own eyes! Of course, no materials will be published without your consent - nothing about You without You.",
      socialMediaTitle2: "Check our content:",
      aboutSailboatTitle: "About \"Chacruna\" sailing yacht...",
      aboutSailboatSubtitle: "\"Boats in the harbor are safe but that is not what they are meant for.\"",
      aboutSailboatDescription1: "\"Chacruna\" is category \"A\" sailing vessel - ocean class. This category means that the jacht can sail in winds exceeding 8 Beauforts and significant wave heights above 4m. Sea yachts are significantly different from inland. On \"Chacruna\" we have fixed keel ballast (instead of centerboard ballast which is common in inland yachts), fixed diesel engine and mast which is rigidly fixed to the hull (does not fold). Rigging is typical for these kind of vessels and consists of mainsail and front sail (geona).",
      aboutSailboatDescription2: "Laminate construction hull has been built in 2004r. and launched on water in 2005r. by Bavaria Yachtbau GmbH shipyard. Length of the hull is 36 feet (11m) which is sufficient size to provide comfortable living conditions for six adults (certified for 8 adults). Yacht has 2 large cabins - bow and stern (with large bunks) and two additional bunks for sleeping in mesa (on midship). On the boat we can find all necessary safety equipment, full sailing equipment, sanitary installations, kitchen and additional devices for daily use.",
      aboutSailboatTitle2: "Equipment",
      equipment: {
        propulsion: {
          title: "Propulsion",
          description: "Fixed engine \"Volvo Penta D1-30\" of 30 Horsepower. Economical fuel consumption 1.2l/h (cruise speed 5 knots)."
        },
        sails: {
          title: "Sails",
          description: "Both mainsail and genua on roller - sails can be operated from cockpit. Full sails speed up to 7.5 knots."
        },
        hull: {
          title: "Hull",
          description: "Laminate construction. Deck covered with decorative teak wood in cockpit, on sides and bow."
        },
        tanks: {
          title: "Tanks",
          description: "80L fuel tank and two sweet water tanks: 120L i 80L lasting on average to about 2 days of use."
        },
        battery: {
          title: "Battery",
          description: "Batteries are charged from alternator and solar panels, provide energy for yacht even outside of marinas."
        },
        storage: {
          title: "Storage",
          description: "Two stern lockers, multiple cabinets and storage spaces provide room for food and personal belongings."
        },
        gps: {
          title: "GPS",
          description: "Along with plotter is invaluable navigational aid and allows localization even in the middle of the night."
        },
        autopilot: {
          title: "Autopilot",
          description: "Turned on autopilot will hold the given course allowing us to let go of steering wheel and do other stuff."
        },
        vhf: {
          title: "VHF Radio",
          description: "Allows to maintain radio communication with other radio stations (ships or marinas). Used also to call for help."
        },
        internet: {
          title: "Internet",
          description: "\"Starlink\" system provides on boat access to fast internet during the whole cruise time."
        },
        soundSystem: {
          title: "Sound System",
          description: "Standard music radio with adapters loudspeakers, allows for unlimited enjoyment of our favourite music."
        },
        sockets: {
          title: "Sockets",
          description: "230V sockets available to use in ports as well as 12V at any time (for example charging phones or tablets)."
        },
        bimini: {
          title: "Bimini",
          description: "Provides shelter from strong sun which allows us to relax in shaded cockpit."
        },
        sprayHood: {
          title: "Spray Hood",
          description: "Useful during cruises in windy or rainy weather, shielding crew in cockpit from elements."
        },
        solar: {
          title: "Solar",
          description: "Strong solar panels provide battery charging during the day even in cloudy weather conditions."
        },
        tables: {
          title: "Tables",
          description: "One big table in mesa, second - small and folding resides in cockpit."
        },
        snorkeling: {
          title: "Diving",
          description: "We have snorkeling equipment which allows to admire sea animals and plants as well as swimming goggles."
        },
        dinghy: {
          title: "Dinghy",
          description: "Dingy with capacity for several people and own propulsion allows for fast transport between yacht and land."
        },
        oven: {
          title: "Stove",
          description: "Dual-burner gas stove with oven enables preparation of any kind hot meals."
        },
        fridge: {
          title: "Fridge",
          description: "Using fridge we can store for long time and cool down food and our favourite beverages."
        },
        kitchen: {
          title: "Kitchen",
          description: "Fully equipped kitchen contains all kinds of dishes, cutlery, pots, pans, and other utensils."
        },
        toilet: {
          title: "Toilet",
          description: "Equipped with manual pump, allows to take care of our physiological needs without the necessity to go into port."
        },
        shower: {
          title: "Shower",
          description: "Bathroom shower and stern shower gives us option to wash ourselves or just rinse from salt after swimming in sea water."
        },
        sink: {
          title: "Sink",
          description: "You can brush your teeth, wash hands or stare in the mirror. Both hot and cold water is available."
        },
        anchor: {
          title: "Anchor",
          description: "Main anchor with electric winch with 70m chain and spare anchor with short chain allows us to stay in the anchorages."
        },
        fenders: {
          title: "Fenders",
          description: "Set of fenders is useful in ports during mooring maneuvers protecting hull from hitting or scratching."
        },
        moorings: {
          title: "Moorings",
          description: "Set of ropes is of course essential to safely moor in marinas. Ropes of various length are available."
        },
        lifeRaft: {
          title: "Life Raft",
          description: "Life raft was never needed but better to have one than not. In major disaster it saves lives."
        },
        epirb: {
          title: "EPIRB",
          description: "It's an emergency buoy which automatically sends signal with its location, calling SAR teams."
        },
        lifeVests: {
          title: "Life vests",
          description: "We have 6 pieces of life vests which are useful especially for people who can't swim. "
        },
        heater: {
          title: "Heating",
          description: "Electric 230V heaters is only useful during colder periods or on cold waters."
        },
        vacuumCleaner: {
          title: "Vacuum cleaner",
          description: "When something spills over or there is dirt, cordless vacuum cleaner will help us maintain cleanliness on boat."
        },
        oilLamp: {
          title: "Oil lamp",
          description: "Emergency light source. We use it often in the evening because we think that it provides nice mood."
        },
        lights: {
          title: "Lights",
          description: "Low current LED lights consumes very little power. Every room on yacht has it's own lighting."
        },
        linen: {
          title: "Linen",
          description: "Every crew member receives his own set of clean and fresh linen (sheets, duvets, pillows)."
        },
        towels: {
          title: "Towels",
          description: "Every crew member receives his own fresh bathing towel and if the need arises - even two!"
        }
      },
      aboutMeTitle: "About me...",
      aboutMeSubtitle: "\"Welcome everyone! My name is Michael also known as Kaptain Klar due to my bald head...",
      aboutMeDescription1: "I always wanted to have a daughter and son but since no second child is seen in the future, I adopted \"Chacruna\" (which is sometimes whimsical like small girl). \"Chacruna\" is my private yacht which eats a lot of finances, but my goal was never to buy a boat for huge profits. I took this path because, I always wanted to travel the world. I figured that most freedom and liberty to do that can be provided by having my own sailing yacht. Exploring the globe is even more interesting if it is accompanied by group of cool and positive people. I can say that I'm more interested in good company during cruises than financial gains. This is why prices for cruises on \"Chacrunia\" are somewhat lower than market average but should suffice to cover yacht maintenance and running costs.",
      aboutMeDescription2: "By education I'm an airplane pilot, mechanical design engineer and since some time, also a programmer. I don't know where did the drive for sailing came from but sailing is somewhat similar to flying and airship. On water, similar like in air - there are procedures, similar conduct of radio communication, good degree of caution is required and first and foremost - imagination. I operate the yacht by myself and I do not require experience among the crew, but if someone would like to help during maneuvers, steer the boat during cruises or simply find out how something works, I'm more than happy to share my modest knowledge. Due to technical education, most of the repairs and upgrades are done by myself (which allows me to save some money on labour). I know this yacht pretty good, \"like my own pocket\" one could say. Without my toolbox I would be like without a hand. Surely I'm not without flaws but I try to smith them into advantages, make up with sense of humor and distance towards myself. Driving a yacht is not an easy task because as a skipper, I need to take care not just about the boat itself but first and foremost about safety and well being of my crew. I care for loose atmosphere, tolerance of different views and of course - awesome fun! Ahoj! :)",
    },
    cruises: {
      notPlannedYet1: "No cruises are planned yet for this year.",
      notPlannedYet2: "Check again in some time...",
      cruiseDetails: {
        myBooking: "MY BOOKING",
        bookNow: "I'M BOOKING THIS CRUISE!",
        bookAgain: "I'M BOOKING AGAIN!",
        cruiseStatus: {
          upcoming: "UPCOMING",
          ongoing: "ONGOING",
          ended: "ENDED"
        },
        difficulty: "Difficulty level:",
        difficulties: {
          TRIVIAL: "TRIVIAL",
          EASY: "EASY",
          MEDIUM: "MEDIUM",
          HARD: "HARD",
          EXTREME: "EXTREME",
        },
        freeSpaces: "Free spaces:",
        noFreeSpaces: "No free spaces left",
        notRatedYet: "No reviews",
        currency: "€",
        perPerson: "/pers.",
        reviews: {
          sectionTitle: "Cruise participants reviews:",
          noReviews: "No reviews yet...",
          title: "Title",
          description: "Review",
          rating: "Score",
          createdAt: "Created at:",
          modifiedAt: "Modified at:",
          loginToReview: "to write review",
          writeReview: "Write Review",
        },
        splashUploadPrimary: "Update splash image",
        splashUploadDrop: "Drop to update",
        crusePicsUploadPrimary: "Add cruise image",
        crusePicsUploadDrop: "Drop to add",
        backToCruises: "Back to cruises",
      }
    },
    bookings: {
      loginToSeeBookings: "to see your bookings...",
      noUpcomingBookingsTitle: "No upcoming bookings.",
      noUpcomingBookingsDescription: "To make a new booking go to \"CruiseBookings\" section and pick your trip of interest.",
      bookingInterval: {
        HISTORICAL: "Historical",
        UPCOMING: "Upcoming"
      },
      bookingDetails: {
        yourSpaces: "Your spaces:",
        cruiseDetails: "Cruise Details",
        manageBooking: "Manage Booking",
        finalizeBooking: "Finalize Booking",
        bookingStatusTitle: "Booking Status",
        bookingStatus: {
          PENDING: "NOT PAID",
          PROCESSING: "PROCESSING PAYMENT",
          ADVANCE_PAID: "ADVANCE PAID",
          FULLY_PAID: "PAID",
          CANCELLING: "CANCELLING",
          CANCELED: "CANCELED",
        }
      },
      imgBadge: {
        in: "IN",
        days: "DAYS",
        write: "WRITE",
        review: "REVIEW",
        reviewed: "REVIEWED",
      }
    },
    faq: {
      title: "Frequently asked questions",
      questions: {
        question1: {
          title: "What is contained in the booking?",
          content1: "Your booking contains space for you on the boat (booked bunks), storage space in drawers " +
            "and lockers where you can store your private belongings , access to all yacht equipment " +
            "and skipper's care during whole cruise time.",
          content2: "Booking does not include port fees paid in marinas, fuel and provisions contribution, " +
            "as well as cost of transport to embark location and from disembark location back to your home.",
          content3: "Docking fees for the whole yacht, range between 25€-125€ per day in the marina. In case of " +
            "6 crew members, this cost is naturally split between all crew members so if during the cruise we would stay " +
            "in six marinas then we have to assume expense of about 25€-125€ per person for the whole cruise. Fuel is " +
            "much lower cost because even if we used up whole 80L fuel tank, we would be talking about a cost of about 20€ " +
            "per person for the whole cruise. Food prices in the markets are typical european prices, so it is wise to " +
            "cook on the boat and limit eating out in expensive restaurants only to couple of dinners during the cruise.",
        },
        question2: {
          title: "Can I cancel my booking?",
          content1: "You can cancel your booking at any moment, however depending on the number of days left to cruise " +
            "start date, you may be charged with a fee for late booking cancellation. In case of cancelling booking " +
            "before 28 days before cruise start, whole amount you paid is returned. Cancelling 7 days or less, before start " +
            "of the cruise, return is not possible.",
          content2: "Booking cancellation in the span from 28 to 14 days before cruise start date:\n" +
            "• If you paid only advance (50%), the returned amount is calculated with a formula: ((days left to start - 14) / 14) x Advance you paid.\n" +
            "• If you paid whole amount, you will be returned 50% of the full amount plus part of advance (explained above).\n \n" +
            "Example 1: User wants to cancel booking 21 days before cruise start and paid whole amount. " +
            "User will receive return of 75% of total amount paid.\n  \n" +
            "Example 2: User wants to cancel booking 21 days before cruise start and paid only advance. " +
            "User will receive return of 50% of advance paid.",
          content3: "Booking cancellation in the span from 14 to 7 days before cruise start date:\n" +
            "• Advance (50% of the whole amount) will not be returned.\n" +
            "• Returned amount is calculated with a formula: ((days left to start - 7) / 7) x 50% total amount.\n \n" +
            "Example 3: User wants to cancel booking 14 days before cruise start and paid whole amount. " +
            "User will receive return of 50% of total amount.\n \n" +
            "Example 4: User wants to cancel booking 10 days before cruise start and paid whole amount. " +
            "User will receive return of 21.43% of total amount.\n  \n" +
            "In all above examples, really it doesn't make a difference whether user paid whole amount for booking or " +
            "just the 50% advance because late cancellation fee is independent of booking type.",
        },
        question3: {
          title: "Do I need to have skipper license to book a cruise?",
          content1: "In short - skipper license is not required in any cruises except extreme difficulty cruises. " +
            "Offer is addressed to both people with and without skipper license.",
          content2: "If you do not have skipper license - don't worry. You can relax and do nothing related to " +
            "yacht handling if you don't feel like it. If you are interested however in getting some experience " +
            "or you just seek to help, then simply tell skipper about it, and you will be given tasks with which you " +
            "will feel comfortable. And don't worry if something didn't work out - skipper is taking this into consideration " +
            "and will not shout on you. :)",
          content3: "If you have license and would like to gain experience, also tell your skipper about it. If you wish, " +
            "after the cruise, you will receive so called \"cruise opinion\" which is a legal document that may be " +
            "useful to you if you seek to get higher level license in the future."
        },
        question4: {
          title: "How is the booking process conducted?",
          content1: "Booking process consists of few simple steps. In the first step, user is providing information about " +
            "people for which he is making booking. In second, he can choose bunks from available bunks pool. Next, " +
            "he can choose, which reservation option he would like to use (full payment or just 50% advance) and provide " +
            "discount code if user has any.",
          content2: "After third step confirmation in booking form, an email will be sent to the address which you provided " +
            "during account registration. Email message consists of bank transfer details which you should make as soon " +
            "as possible in order to finally confirm your booking. In the attachment you will find also an agreement " +
            "which you should read, fill in, sign on last page and send back to our email address: chacrunasails@gmail.com before " +
            "cruise start date. Signed agreement can also be handed over directly to skipper hands at the day of embarking.",
          content3: "After issuing bank transfer in your bank, in order to speed up booking confirmation, " +
            "it's best to send back to our email address, bank transfer confirmation so that your bunks will become " +
            "unavailable for other users in timely manner."
          // title: "My payment has been rejected. What about my booking?",
          // content1: "If your payment has been rejected by payment processor it means that the error occurred on the side " +
          //   "of payment method provider and your money had not left your account. Your booking, most probably will be " +
          //   "in \"PROCESSING PAYMENT\" state.",
          // content2: "If your booking (in BOOKINGS tab) is in \"PROCESSING PAYMENT\" state, check your mailbox" +
          //   "and make sure that the transaction did not went through. If this payment problem persists " +
          //   "contact us via email: chacrunasails@gmail.com or call: +48 669 477 375.",
          // content3: "If your booking (in BOOKINGS tab) is in \"NOT PAID\" state, it means that you were not " +
          //   "even redirected to the payment processor and you can still finalize your booking. " +
          //   "All it takes is to click \"Finalize Booking\" button."
        },
        question5: {
          title: "Where can I change my booking details?",
          content1: "If you paid whole amount or just 50% advance, in bookings tab you can click " +
            "\"Manage Booking\" button. Depending on the booking state you will be presented with 3 options: " +
            "pay the remaining price of the booking (in case of paying only 50% advance initially), change booking " +
            "(participants data or selected bunks), cancel booking.",
          content2: "At any moment you can change your booked bunks to the ones which are not taken. " +
            "You can also change participants data which is useful in situations when other person who was supposed to " +
            "sail with you, could not participate for some reason and someone else would be filling in instead. It's good " +
            "to provide real participants data because it makes it easier for skipper to make a crew list.",
          content3: "Cancelling booking will open a window in which you will be notified about calculated return amount " +
            "which will be returned to your account after clicking confirmation button. Cancelling booking " +
            "is final and money will return to your account within maximum 7 days."
        },
        question6: {
          title: "How to understand \"cruise difficulty\" level?",
          content1: "AdminCruises have been split into five distinct difficulty levels: trivial, easy, medium, hard and extreme. " +
            "Difficulty level is rather subjective matter and \"medium\" for one person can be felt like \"trivial\" for other, " +
            "while for another as \"hard\". The scale however is based on objective factors such as: " +
            "average amount of hours per day sailing, acceptable limit of weather conditions in which we decide to sail, " +
            "night passages, etc.",
          content2: "In general, the less amount of hours per day sailing the easier the cruise. If there are night passages " +
            "then the cruise will be a bit more difficult. The smaller the waves and lighter winds the easier the cruise, " +
            "and if we agree to sail in rougher seas and stronger winds the harder the cruise.",
          content3: "• Trivial cruise: Up to 2.5h per day sailing during daylight, only during small waves and wind force " +
            "up to 4B, without night stay in anchorages.\n \n" +
            "• Easy cruise: Up to 4.5h per day sailing during daylight, sailing only in light waves and wind force " +
            "up to 5B, with possibility to night stay in anchorages in well protected bays.\n \n" +
            "• Medium cruise: Up to 7h per day sailing including night, also in moderate waves and wind force " +
            "up to 6B, with possibility to stay in anchorages in well protected bays and anchor shifts during the night.\n \n" +
            "• Hard cruise: Up to 10h sailing per day including night, also in significant waves and wind force " +
            "up to 7B with necessity to take shifts behind the helm, with possibility to stay in anchorages in " +
            "somewhat protected bays and anchor shifts during the night.\n \n" +
            "• Extreme cruise: Over 10h sailing per day including night, also during rough (stormy) seas and wind force " +
            "over 8B with necessity to take shifts behind the helm, with possibility to stay in anchorages and anchor " +
            "shifts during the night."
        },
        question7: {
          title: "What to take for a cruise?",
          content1: "For a weekly cruise it's best to pack yourself only in hand luggage. Many airlines offer for " +
            "additional fee, carriage of extra cabin luggage, however it is often not necessary. It is not advised " +
            "to take main, registered luggage due to costs and limited storage space on a yacht. " +
            "It's also not necessary to take body creams, shampoos, UV filter creams or toothpaste, (unless someone has to) " +
            "because we buy these things before departure and they are shared among the crew members.",
          content2: "Going out for a cruise it is most important to take your documents (valid EU ID or passport), " +
            "money (its worth to have cash also) and phone of course. It's also worth to take phone charger or " +
            "USB cable with plug for your smartphone, earphones for listening music or audiobooks, sunglasses, " +
            "head cover, personal hygiene items, powerbank and most importantly - earplugs " +
            "(someone may snore at night, and also yacht itself is making it's own noises - something is knocking, " +
            "other thing is rattling, or a line is squeaking and this is when earplugs are great).",
          content3: "Of course don't forget about underwear for each cruise day, T-shirts, swimming suit, " +
            "flip-flops, shorts, and for chilly evenings or worse weather - pair of long pants, at least one blouse " +
            "with hoodie and optionally - waterproof jacket. It is also important to have comfortable shoes with soft " +
            "sole. If someone likes he might consider also taking a book or small board game.\n \n" +
            "It's not worth to take hairdryer, laptops (unless you really need to), toasters, iron, excessive clothes, " +
            "or food (except sandwiches for your flight)."
        },
        question8: {
          title: "What to expect during the cruise?",
          content1: "In short - great fun! Living on a sailing yacht is similar to camping in premium RV " +
            "due to much bigger space available and convenience quantity. Despite that, living on a yacht is different than " +
            "in hotel and has it's own upsides and downsides. Even though \"Chacruna\" has 11m hull length and there is " +
            "more space than in a camper for example, it is still limited space. Sweet water tanks also have limited storage " +
            "quantity which is worth to keep in mind during the shower and not forgetting about other crew members.",
          content2: "Generally speaking, moving around a yacht requires working out some habits and imagination. When sailing " +
            "there is a rule, \"one hand for the boat, another one for me\" which means that one hand should always " +
            "be holding onto something - important when there are waves. In transit, all items should be secured " +
            "in such way that when boat is swaying, they should remain where they are. Keeping this in mind " +
            "will prevent your things from being damaged so they won't be hitting drawers or a floor. " +
            "It might occur to you as first impression that there is not a lot of space inside yacht. This happens when " +
            "everybody is unpacking their belongings during embarkation and there is general mess. In time we learn " +
            "how to move around in such way so we don't interfere with each other or we discover, that in order to pass " +
            "something to someone on the deck, we don't have to walk through mid ship, via stairs, through cockpit on the deck " +
            "but all it takes is to move your hand and pass the item through bow locker.",
          content3: "For our hardships we are rewarded with amazing views such as: beautiful bays, marinas, lagoons, towns, " +
            "sunsets and stars on the night sky. After few days the atmosphere is entering a sort of unique flow state " +
            "when everything starts to work like a clock and crew functions almost like one organism. Strong feeling " +
            "of individuality is tapered and we become closer to each other than even some old friends. This is the moment " +
            "when everybody knows who is doing breakfast or lunch, who's doing the dishes, what supplies are running low, " +
            "who drinks what, where we sail and when we get there."
        },
        question9: {
          title: "Can I drink alcohol or smoke during cruise?",
          content1: "It is not prohibited and what is not prohibited is allowed in reasonable boundaries. Skipper " +
            "assumes, he has to do with people that want to have fun and they are at least a little bit reasonable. " +
            "Skipper does not know how much and what quantity can be consumed by crew member and doesn't enter the role " +
            "of policeman. It's worth to remember however, when going out in the morning to buy a bread, that when you return, " +
            "there should be no such situations where, somebody returned drunk with three bottles of vodka and without " +
            "any bread. Just remember about the bread...",
          content2: "There are many different situations when excessive alcohol consumption does not matter at all and " +
            "situations when it plays massive role. For example - during night crossing in poor conditions " +
            "on hard cruise, it's better not to be drunk. Everybody knows more or less how much they can drink. " +
            "General rule of thumb is that on water we should try to stay rather on the sober side depending on the weather " +
            "conditions - the worse conditions, the more sober mind required.",
          content3: "Most dangerous drunken sailor situations happen when someone has problem with maintaining balance " +
            "which in best case can end up in a bruise and worst case - man overboard scenario. There is nothing " +
            "standing in our way however, when we stay in marina, to go tavern and get wasted there as a crew until " +
            "sunrise if that's what the crew wants."
        },
        question10: {
          title: "What will happen in case of storm?",
          content1: "Saying that storm can occur on water at any time is a myth. Storms don't happen suddenly and " +
            "forecasts of bad weather will reach us even days earlier. Local thunderstorms is another thing. " +
            "They can occur quite fast (in a matter of few hours) and disappear even faster (literally in 15min).",
          content2: "Except extreme cruises, generally we will not sail in storms and we will stay safely in well " +
            "protected port. This is the time when we can go to tavern and curse the weather, explore the port town, " +
            "play cards or board games. In rare cases it may happen that it would be impossible to get to target destination " +
            "on desired date, because of strong, several day-lasting storm. In such cases it's not worth taking the " +
            "risk and if you have to fly back, simply take the land route to your airport. So far such situation has never occurred.",
          content3: "When it comes down to local thunderstorms, contrary to common assumptions they are not storms. First of all, " +
            "local thunderstorm affects small area of water and because of that, it does not produce large waves. In other words - " +
            "it's windy, noisy but there are no waves. In most cases thunderstorm will pass on the side, will be windy for half hour, " +
            "will make some noise and go away. Sometimes however thunderstorm can pass directly over us. In that case " +
            "you can expect strong gusts and heavy rainfall which will last on average 30 minutes. It's safest then to reduce " +
            "or completely drop the sails due to wind gusts, fire up the engine and keep calmly going ahead on diesel. " +
            "Thunderstorms appear to be dangerous but in reality they are not, because they don't produce big waves " +
            "and they last only for short period of time."
        },
        question11: {
          title: "When the cruise video will be ready?",
          content1: "Videos from cruises are currently assembled by the Captain which of course is a process that takes time. " +
            "Good editing usually takes up to 3 work days so Captain needs to have at least free weekend to do it.",
          content2: "Because cruises are organised in larger chunks (one after another), you can except video to be " +
            "worked on after the whole chunk ends. The videos are done in chronological fashion so until " +
            "a movie from previous cruise is done, your cruise video is not be ready.",
          content3: "In other words, you can expect an influx of videos between chunks (if time allows) or " +
            "if chunks are tightly packed in the season, at the end of whole sailing season. Of course it's best interest " +
            "for all of us to have videos ready as soon as possible but we ask for your patience and understanding - video " +
            "will be done for sure and you will be the first person notified. :)"
        },
      }
    },
    admin: {
      cruises: {
        noCruises: "No cruises for this year",
        bookingsOrDiscounts: "Bookings / Discounts",
        cruiseForm: {
          createCruise: "Creating new cruise",
          editCruise: "Editing cruise",
          price: "Price",
          titlePl: "Title PL",
          titleEn: "Title EN",
          descriptionPl: "Description PL",
          descriptionEn: "Description EN",
          planPl: "Plan PL",
          planEn: "Plan EN",
          cruiseDifficulty: "Difficulty",
          startLocation: "Start location",
          finishLocation: "Finish location",
          startDay: "Start date",
          finishDay: "Finish date",
          splashImg: "Splash image",
          ytLink: "Youtube link",
          images: "Images",
        }
      },
      cruiseDetails: {
        tabs: {
          BOOKINGS: "BOOKINGS",
          DISCOUNTS: "CODES"
        },
        bookedSpaces: "Booked spaces:",
        noBookings: "No bookings for this cruise...",
        noDiscounts: "No discounts for this cruise...",
        code: "Code:",
        bookedBy: "Booked by:",
        timeCreate: "Booked at:",
        lastModified: "Last modified:",
        changeStatus: "Change Booking",
        showParticipants: "Participants",
        showCrewList: "Crew List",
        discount: {
          code: "Code",
          amount: "Discount",
        }
      }
    },
    emailVerify: {
      emailVerifyTitle: "Welcome on board!",
      emailVerifyDescription1: "We are happy to welcome new member of our \"tribe\". We wish you many successful cruises and excellent memories!",
      emailVerifyDescription2: "You can now ",
      emailVerifyLogin: "LOGIN"
    },
    expiredVerificationToken: "Email verification token is incorrect or has expired!",
    expiredResetToken: "Password reset token is incorrect or has expired!",
  },
  formErrors: {
    fieldRequired: "This field is required!",
    agreementRequired: "This agreement is required!",
    incorrectFormat: "Incorrect format!",
    onlyLetters: "Only letters are allowed!",
    onlyLettersAndDash: "Only letters and '-' are allowed!",
    badEmail: "Invalid e-mail address!",
    emailTaken: "This email is already taken!",
    maxAllowed: "Maximum ",
    maxChars: " chars are allowed!",
    maxLetters: " letters are allowed!",
    invalidLogin: "Invalid login credentials!",
    phoneFormat: "Phone format must be +YYXXXXXXXXX!",
    inadequatePassword: "Password is not strong enough!",
    noWhitespaces : "Cannot contain whitespace!",
    mismatchedPassword: "Passwords are not the same!",
    youMustChoose: "You must choose",
    rangeBetween: "Must be in range",
    bunks: "bunks!",
  }
}