import {Cacheable} from "../Cacheable";
import {Cruise} from "../../model/Cruise";


export function findInCache(objId: string, cache: Cacheable[]) {
  return cache.find(item => item.id === objId)
}

export function updateCache(cacheables: Cacheable[], cache: Cacheable[], updateOnly = false) {
  if (cacheables.length > 0) {
    cacheables.forEach(c => {
      const found = findInCache(c.id, cache);
      if (found) {
        const index = cache.indexOf(found);
        cache[index] = c;
      } else if (!updateOnly){
        cache.push(c);
      }
    });
  }
}

export function deleteFromCache(cacheable: Cacheable, cache: Cacheable[]) {
  const found = cache.find((obj) => obj.id === cacheable.id);
  if (found) {
    cache.splice(cache.indexOf(found), 1);
  }
}

export function sortCruisesByDateAndStatus(cruises: Cruise[]) {
  cruises.sort((cruiseA, cruiseB) => {
    const timeA = new Date(cruiseA.startDay).getTime();
    const timeB = new Date(cruiseB.startDay).getTime();
    //TODO IMPL status sort also
    return timeA < timeB ? -1 : 1;
  })
}