import {useContext, useState} from "react";
import {AppContext} from "../../../../appWrapper/AppWrapper";
import {Box, Link, Typography} from "@mui/material";
import BookingRequest from "../../../../../../service/booking/BookingRequest";
import {fonts, mainColors, mediumText} from "../../../../../../../app.style";
import SubStep from "../../subStep/SubStep";
import BookingDataStepValidator from "../BookingDataStepValidator";
import AppRequiredCheckbox from "../../../../appCheckbox/AppRequiredCheckbox";
import PolicyModal from "../../../../appModal/PolicyModal";

interface RequiredAgreementsProps {
  bookingFormData: BookingRequest;
  bookingDataStepValidator: BookingDataStepValidator;
  showErrorText: boolean;
}

const RequiredAgreements = ({bookingFormData, bookingDataStepValidator, showErrorText} : RequiredAgreementsProps) => {
  const {translation} = useContext(AppContext);
  const [isPolicyOpen, setIsPolicyOpen] = useState(false);

  return (
    <>
      <Box sx={{display: "flex"}}>
        <SubStep subStepNo={3} totalSteps={3}/>
        <Typography sx={[fonts.spartan16, mainColors.contrast, {display: "flex", justifyContent: "center", mb: 2}]}>
          {translation.form.newBooking.bookingDataStep.requiredAgreements.title}
        </Typography>
      </Box>
      <AppRequiredCheckbox
        label={
          <Box sx={{display: "flex"}}>
            <Typography sx={[fonts.spartan14, mediumText]}>
              *{translation.form.newBooking.bookingDataStep.requiredAgreements.reservationPolicyConfirm1}&nbsp;
              <Link
                sx={[fonts.spartan14, mediumText]}
                component="button"
                variant="body2"
                onClick={() => setIsPolicyOpen(true)}>
                {translation.form.newBooking.bookingDataStep.requiredAgreements.policy}&nbsp;
              </Link>
              {translation.form.newBooking.bookingDataStep.requiredAgreements.reservationPolicyConfirm2}
            </Typography>
          </Box>}
        setFormData={bookingFormData.setIsPolicyCheck}
        checked={bookingFormData.isPolicyCheck}
        showErrorText={showErrorText}
        validator={bookingDataStepValidator.reservationPolicyValidator}/>
      <Box sx={{mt: 2}}>
        <AppRequiredCheckbox
          label={
              <Typography sx={[fonts.spartan14, mediumText]}>
                *{translation.form.newBooking.bookingDataStep.requiredAgreements.reservationProcessingConfirm}
              </Typography>}
          setFormData={bookingFormData.setIsProcessingCheck}
          checked={bookingFormData.isProcessingCheck}
          showErrorText={showErrorText}
          validator={bookingDataStepValidator.reservationProcessingValidator}/>
      </Box>
      <PolicyModal isOpen={isPolicyOpen} setIsOpen={setIsPolicyOpen}/>
    </>
  )
}

export default RequiredAgreements;