import {VisibilityOutlined, VisibilityOffOutlined} from '@mui/icons-material';
import InputAdornment from '@mui/material/InputAdornment';
import {IconButton} from "@mui/material";
import PasswordAdornmentProps from "./PasswordAdornmentProps";


const PasswordAdornment = (props: PasswordAdornmentProps) => {

  const togglePasswordVisibility = () => {
    props.setShowPassword(!props.showPassword);
  }

  const isPasswordVisible = () => {
    return props.showPassword;
  }

  return (
    <InputAdornment position="end">
      <IconButton
        aria-label="toggle password visibility"
        onClick={togglePasswordVisibility}
        onMouseDown={isPasswordVisible}
      >
        {props.showPassword ? <VisibilityOutlined/> : <VisibilityOffOutlined/>}
      </IconButton>
    </InputAdornment>
  )
}

export default PasswordAdornment;