import {Box} from "@mui/material";
import PageSpinner from "../../common/appSpinner/PageSpinner";
import {useContext, useState} from "react";
import {AppContext} from "../../common/appWrapper/AppWrapper";
import {BookingInterval} from "../../../utils/appEnums/BookingInterval";
import Bookings from "./bookings/Bookings";
import BookingIntervalTabs from "./bookingIntervalTabs/BookingIntervalTabs";
import LoginInfoBtn from "../../common/loginInfoBtn/LoginInfoBtn";


const BookingsPage = () => {
  const {translation, appUser, appLoadersCache} = useContext(AppContext);
  const [selectedBookingInterval, setSelectedBookingInterval] = useState(BookingInterval.UPCOMING);

  return (
    <Box sx={{mt: 2}}>
      {appLoadersCache.getCruisesLoading || appLoadersCache.getMyBookingsLoading ?
        <PageSpinner/> :
        <>
          <BookingIntervalTabs setSelectedBookingInterval={setSelectedBookingInterval}/>
          {appUser ?
            <Bookings selectedBookingInterval={selectedBookingInterval}/> :
            !appUser &&
              <Box sx={{display: "flex", flexDirection: "column", justifyContent: "center", height: "25vh"}}>
                  <LoginInfoBtn descr={translation.page.bookings.loginToSeeBookings}/>
              </Box>}
        </>
      }
    </Box>
  )
}

export default BookingsPage;