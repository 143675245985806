import {Box, Button} from "@mui/material";
import {button, centeredBox, fonts, mainColors, semiBoldText} from "../../../../../app.style";
import {useContext} from "react";
import {AppContext} from "../../appWrapper/AppWrapper";
import SectionElement from "./SectionElement";
import SubSectionElement from "./SubSectionElement";

const PolicyModalContent = ({setIsOpen} : {setIsOpen: Function}) => {
  const {translation} = useContext(AppContext);

  return (
    <>
      <Box sx={{mb: 2, maxHeight: "63vh", overflowY: "auto"}}>
        <SectionElement number={"1"} text={translation.policyModal.text1}/>
        <SectionElement number={"2"} text={translation.policyModal.text2}/>
        <SectionElement number={"3"} text={translation.policyModal.text3}/>
        <SectionElement number={"4"} text={translation.policyModal.text4}/>
        <SectionElement number={"5"} text={translation.policyModal.text5}/>
        <SubSectionElement letter={"a)"} text={translation.policyModal.text5a}/>
        <SubSectionElement letter={"b)"} text={translation.policyModal.text5b}/>
        <SubSectionElement letter={"c)"} text={translation.policyModal.text5c}/>
        <SubSectionElement letter={"d)"} text={translation.policyModal.text5d}/>
        <SubSectionElement letter={" "} text={translation.policyModal.text5ex}/>
        <SectionElement number={"6"} text={translation.policyModal.text6}/>
        <SectionElement number={"7"} text={translation.policyModal.text7}/>
        <SectionElement number={"8"} text={translation.policyModal.text8}/>
        <SectionElement number={"9"} text={translation.policyModal.text9}/>
        <SectionElement number={"10"} text={translation.policyModal.text10}/>
        <SubSectionElement letter={"a)"} text={translation.policyModal.text10a}/>
        <SubSectionElement letter={"b)"} text={translation.policyModal.text10b}/>
        <SubSectionElement letter={"c)"} text={translation.policyModal.text10c}/>
        <SubSectionElement letter={"d)"} text={translation.policyModal.text10d}/>
        <SectionElement number={"11"} text={translation.policyModal.text11}/>
      </Box>
      <Box sx={centeredBox}>
      <Button
        variant="contained"
        disableElevation
        fullWidth
        onClick={() => setIsOpen(false)}
        sx={[fonts.spartan14, semiBoldText, button, mainColors.white, {maxWidth: "330px"}]}>
        {translation.close}
      </Button>
      </Box>
    </>
  )
}

export default PolicyModalContent;