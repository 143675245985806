import {Box, Typography} from "@mui/material";
import {useContext} from "react";
import {AppContext} from "../../../../../../common/appWrapper/AppWrapper";
import {fonts, mainColors, mediumText, semiBoldText} from "../../../../../../../../app.style";
import YourSpaces from "./yourSpaces/YourSpaces";
import {Booking} from "../../../../../../../model/Booking";
import {regularDiscountedPriceStyle} from "./bookingPrice.style";
import {AppCurrency} from "../../../../../../../model/modelEnums/AppCurrency";
import {ReactComponent as DiscountIcon} from "../../../../../../../../assets/icons/discount.svg";
import {BookingType} from "../../../../../../../model/modelEnums/BookingType";
import {getFormatPrice} from "../../../../../../../utils/misc/helpers";

interface BookingPriceProps {
  booking: Booking;
  isFinal?: boolean;
  justify: string;
  isAdmin?: boolean;
}

const BookingPrice = ({booking, justify, isFinal, isAdmin}: BookingPriceProps) => {
  const {translation} = useContext(AppContext);

  const getRegularPriceStyle = () => {
    return booking.discountPrice !== booking.regularPrice ? regularDiscountedPriceStyle : {}
  }

  return (
    <Box>
      <YourSpaces yourSpaces={[booking.bunks.length]} justify={justify} isAdmin={isAdmin}/>
      {isAdmin && booking.discountCode &&
          <Box sx={{display: "flex", justifyContent: justify}}>
              <Typography sx={[fonts.spartan16, mediumText, {mr: 1}]}>
                {translation.page.admin.cruiseDetails.code} {booking.discountCode}
              </Typography>
          </Box>}
      <Box sx={{display: "flex", justifyContent: justify}}>
        <Typography sx={[fonts.spartan16, mediumText, getRegularPriceStyle()]}>
          {getFormatPrice(booking.regularPrice)}{booking.currency === AppCurrency.PLN ? "zł" : "€"}
        </Typography>
        <Typography sx={[fonts.spartan16, getRegularPriceStyle()]}>
          &nbsp;{translation.page.cruises.cruiseDetails.perPerson}
        </Typography>
      </Box>
      {booking.discountPrice !== booking.regularPrice &&
      <Box sx={{display: "flex", justifyContent: justify}}>
        <DiscountIcon/>
        <Typography sx={[fonts.spartan16, mediumText]}>
          {getFormatPrice(booking.discountPrice)}{booking.currency === AppCurrency.PLN ? "zł" : "€"}
        </Typography>
        <Typography sx={[fonts.spartan16]}>
          &nbsp;{translation.page.cruises.cruiseDetails.perPerson}
        </Typography>
      </Box>}
      <Box sx={{display: "flex", justifyContent: justify, mt: 2}}>
        {booking.bookingType === BookingType.TWO_STAGE &&
        <Typography sx={[fonts.spartan12, mainColors.grey, {display: "flex", alignItems: "center"}]}>
          50%(
        </Typography>}
        <Typography sx={[fonts.spartan12, mainColors.grey, {display: "flex", alignItems: "center"}]}>
          {booking.bunks.length}x{getFormatPrice(booking.discountPrice)}{booking.currency === AppCurrency.PLN ? "zł" : "€"}
          {booking.bookingType === BookingType.TWO_STAGE && ")"}
          &nbsp;=&nbsp;
        </Typography>
        {isFinal ?
          <Typography sx={[fonts.spartan18, semiBoldText]}>
            {getFormatPrice((booking.discountPrice * booking.participants.length) - booking.bookingPrice)}
            {booking.currency === AppCurrency.PLN ? "zł" : "€"}
          </Typography> :
          <Typography sx={[fonts.spartan18, semiBoldText]}>
            {getFormatPrice(booking.bookingPrice)}
            {booking.currency === AppCurrency.PLN ? "zł" : "€"}
          </Typography>}
      </Box>
    </Box>
  )
}

export default BookingPrice;