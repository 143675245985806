import {useContext, useEffect, useState} from "react";
import ParticipantFormValidator from "./ParticipantFormValidator";
import {Grid, Typography} from "@mui/material";
import {AppContext} from "../../../../../appWrapper/AppWrapper";
import {AppUser} from "../../../../../../../model/AppUser";
import ParticipantRequest from "../../../../../../../service/booking/ParticipantRequest";
import useSimpleOpts from "../../../../../appSelectField/appSelectField/simpleOption/useSimpleOpts";
import AppSelectField from "../../../../../appSelectField/AppSelectField";
import AppTextField from "../../../../../appTextField/AppTextField";
import AppDatePicker from "../../../../../appTimePicker/AppDatePicker";
import {TextFieldType} from "../../../../../../../utils/appEnums/TextFieldType";
import {fonts} from "../../../../../../../../app.style";
import BookingRequest from "../../../../../../../service/booking/BookingRequest";

interface ParticipantDataProps {
  index: number;
  participantFormData: ParticipantRequest;
  bookingForm: BookingRequest;
  participantValidator: ParticipantFormValidator;
  showErrorText: boolean;
  appUser?: AppUser;
  forceBlur?: boolean;
}

const ParticipantData = ({index, participantFormData, participantValidator, showErrorText, forceBlur, appUser} : ParticipantDataProps) => {
  const {translation} = useContext(AppContext);
  const {simpleOpts: genderOpts} = useSimpleOpts(translation.form.registerForm.gender.options, false);
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (appUser) {
      setDisabled(true);
      participantFormData.setName(appUser!.name);
      participantFormData.setSurname(appUser!.surname);
      participantFormData.setBorn(appUser!.born);
      participantFormData.gender = appUser!.gender;
    } else {
      setDisabled(false);
    }
  }, [appUser, participantFormData]);

  return (
    <>
      <Typography sx={[fonts.spartan12, {mb: 1}]}>
        {translation.form.newBooking.bookingDataStep.passengerDetails.passenger}&nbsp;
        {index + 1}&nbsp;
        {translation.form.newBooking.bookingDataStep.passengerDetails.details}:
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={6} sm={3}>
          <AppSelectField
            label={translation.form.registerForm.gender.title}
            options={genderOpts}
            selectedValue={participantFormData.gender}
            required={true}
            disabled={disabled}
            setFormData={participantFormData.setGender}/>
        </Grid>
        <Grid item xs={6} sm={3}>
          <AppDatePicker
            formData={participantFormData.born}
            setFormData={participantFormData.setBorn}
            label={translation.form.registerForm.born}
            showErrorText={showErrorText}
            disabled={disabled}
            disableFuture={true}
            validator={participantValidator.bornValidator}/>
        </Grid>
        <Grid item xs={12} sm={3}>
          <AppTextField
            formData={participantFormData.name}
            setFormData={participantFormData.setName}
            required={true}
            disabled={disabled}
            type={TextFieldType.TEXT}
            label={translation.form.registerForm.name}
            showErrorText={showErrorText}
            validator={participantValidator.nameValidator}
            forceBlur={forceBlur}/>
        </Grid>
        <Grid item xs={12} sm={3}>
          <AppTextField
            formData={participantFormData.surname}
            setFormData={participantFormData.setSurname}
            required={true}
            disabled={disabled}
            type={TextFieldType.TEXT}
            label={translation.form.registerForm.surname}
            showErrorText={showErrorText}
            validator={participantValidator.surnameValidator}
            forceBlur={forceBlur}/>
        </Grid>
      </Grid>
    </>
  )
}

export default ParticipantData;