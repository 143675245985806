import {Box, Divider, Grid, Rating, Typography} from "@mui/material";
import {Review} from "../../../../../model/Review";
import {useContext} from "react";
import {AppContext, AppModalContext} from "../../../../common/appWrapper/AppWrapper";
import {centeredBox, fonts, mainColors, semiBoldText} from "../../../../../../app.style";
import {SM_BREAKPOINT} from "../../../../common/guiConstants";
import {AuthContext} from "../../../../../../App";
import {AppRole} from "../../../../../model/modelEnums/AppRole";
import {getLocaleDateTime} from "../../../../../utils/misc/helpers";
import DeleteEditBtns from "../../../../common/appButton/deleteEditBtns/DeleteEditBtns";
import ReviewForm from "../../../../common/appForm/reviewForm/ReviewForm";

const CruiseReview = ({review} : {review: Review}) => {
  const {role, jwt} = useContext(AuthContext);
  const {setModalHeaderText, setModalChildren, setConfirmModalHeaderText, setConfirmModalHandler} = useContext(AppModalContext);
  const {windowWidth, translation, appUser, appCacheApi} = useContext(AppContext);

  const editApiCallback = () => {
    setModalHeaderText(translation.modalHeader.editReview);
    setModalChildren(<ReviewForm cruiseId={review.cruiseId} review={review}/>);
  }

  const deleteApiCallback = () => {
    setConfirmModalHeaderText(translation.modalHeader.deleteReview);
    setConfirmModalHandler(() => () => {
      appCacheApi.deleteReviews([review.id], jwt)
    })
  }

  return (
    <>
      <Grid container spacing={3} sx={{mt: 1}}>
        {windowWidth < SM_BREAKPOINT &&
        <Grid item xs={12} sx={centeredBox}>
          <Rating value={review.score} readOnly />
        </Grid>}
        <Grid item xs={12} sm={9}>
          <Typography sx={[fonts.spartan14, mainColors.contrast, semiBoldText]}>
            {review.title}
          </Typography>
        </Grid>
        {windowWidth >= SM_BREAKPOINT &&
        <Grid item sm={3} sx={{display: "flex", justifyContent: "flex-end"}}>
          <Rating value={review.score} readOnly />
        </Grid>}
        <Grid item xs={12}>
          <Typography sx={[fonts.spartan12, mainColors.contrast, {fontStyle: "italic"}]}>
            &emsp;&emsp;{review.description}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{display: "flex", justifyContent: "space-between"}}>
          <Box>
            <Typography sx={[fonts.spartan12, mainColors.grey]}>
              {review.appUserNickname}
            </Typography>
            {role === AppRole.ADMIN &&
                <Typography sx={[fonts.spartan12, mainColors.grey]}>
                  ({review.appUserFullName})
                </Typography>}
            <Typography sx={[fonts.spartan10, mainColors.grey]}>
              {translation.page.cruises.cruiseDetails.reviews.createdAt}
              &nbsp;
              {getLocaleDateTime(review.createdTime)}
            </Typography>
            {review.modifiedTime &&
            <Typography sx={[fonts.spartan10, mainColors.grey]}>
              {translation.page.cruises.cruiseDetails.reviews.modifiedAt}
              &nbsp;
              {getLocaleDateTime(review.modifiedTime)}
            </Typography>}
          </Box>
          {appUser && review.createdById === appUser.id &&
              <Box sx={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
                <Box sx={{display: "flex", justifyContent: "flex-end"}}>
                  <DeleteEditBtns editApiCallback={editApiCallback} deleteApiCallback={deleteApiCallback}/>
                </Box>
              </Box>}
          </Box>
        </Grid>
        <Grid item xs={3}/>
        <Grid item xs={6}><Divider variant={"middle"} sx={{mt: 1}}/></Grid>
        <Grid item xs={3}/>
      </Grid>
    </>
  )
}

export default CruiseReview;