import {Cruise} from "../../../../model/Cruise";
import {Box} from "@mui/material";
import Image from "mui-image";
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry";
import {apiUrl} from "../../../../service/urls";

const CruiseImages = ({cruise} : {cruise: Cruise}) => {
  return (
    <Box sx={{mt: 5}}>
      <ResponsiveMasonry columnsCountBreakPoints={{0: 1, 550: 2, 900: 3}}>
        <Masonry gutter={"5px"}>
          {cruise.images.map((path, index) =>
          <Image src={`${apiUrl.cruises}/${path}`} key={index} duration={1000}/>)}
        </Masonry>
      </ResponsiveMasonry>
    </Box>
  )
}

export default CruiseImages;