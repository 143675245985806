import {useContext, useState} from "react";
import SimpleOption from "./SimpleOption";
import {UNDEFINED} from "../../../../../model/modelConstants/constants";
import {AppContext} from "../../../appWrapper/AppWrapper";


const useSimpleOpts = (list: any, hasUndefined = true) => {
  const {translation} = useContext(AppContext)
  const initialOpts = hasUndefined ? [<SimpleOption key={0} value={UNDEFINED} label={translation.undefined}/>] : []

  const [simpleOpts] = useState(initialOpts.concat(
    ...Object.keys(list).map((key, index) =>
      <SimpleOption key={hasUndefined ? index + 1 : 0} value={key} label={list[key]}/>)));

  return {simpleOpts}
}

export default useSimpleOpts;