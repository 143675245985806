import {useContext} from "react";
import {AppContext} from "../../../../../../appWrapper/AppWrapper";
import {Button} from "@mui/material";
import {button, fonts, semiBoldText} from "../../../../../../../../../app.style";

const RemoveParticipantBtn = ({handleRemoveParticipant}: {handleRemoveParticipant: Function}) => {
  const {translation} = useContext(AppContext);

  return (
    <Button variant="outlined"
            color="contrast"
            disableElevation fullWidth
            sx={[fonts.spartan14, semiBoldText, button, {maxWidth: "330px"}]}
            onClick={() => handleRemoveParticipant()}>
      {translation.form.newBooking.bookingDataStep.passengerDetails.removePassenger}
    </Button>
  )
}

export default RemoveParticipantBtn;