import {useContext} from "react";
import {AppContext} from "../../../../../../appWrapper/AppWrapper";
import {Button} from "@mui/material";
import {button, fonts, mainColors, semiBoldText} from "../../../../../../../../../app.style";

const AddParticipantBtn = ({handleAddParticipant, disabled}: {handleAddParticipant: Function, disabled: boolean}) => {
  const {translation} = useContext(AppContext);

  return (
    <Button variant="contained"
            disabled={disabled}
            disableElevation fullWidth
            sx={[fonts.spartan14, semiBoldText, button, {background: mainColors.primary.color, maxWidth: "330px"}]}
            onClick={() => handleAddParticipant()}>
      {disabled ?
        translation.form.newBooking.bookingDataStep.passengerDetails.noMoreSpaces :
        translation.form.newBooking.bookingDataStep.passengerDetails.addAnotherPassenger
      }
    </Button>
  )
}

export default AddParticipantBtn;