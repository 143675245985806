import {useContext} from "react";
import {AppContext} from "../../appWrapper/AppWrapper";
import {Typography} from "@mui/material";
import {fonts, semiBoldText} from "../../../../../app.style";
import {Language} from "../../../../../language/Language";

const CruiseTitle = ({titlePl, titleEn}: {titlePl: string, titleEn: string}) => {
  const {selectedLanguage} = useContext(AppContext);

  return (
    <Typography sx={[fonts.spartan16, semiBoldText, {textAlign: "left", display: "flex"}]}>
      {selectedLanguage === Language.PL ? titlePl : titleEn}
    </Typography>
  )
}

export default CruiseTitle;