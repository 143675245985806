import {Container} from "@mui/material";
import useSeasonCruises from "../../../common/yearTabs/useSeasonCruises";
import CruiseCard from "./cruiseCard/CruiseCard";
import NoCruises from "./noCruises/NoCruises";


interface CruisesProps {
  selectedYear: number;
}

const Cruises = ({selectedYear}: CruisesProps) => {
  const {seasonCruises} = useSeasonCruises(selectedYear);


  return (
    <Container sx={{mt: 3}}>
      {seasonCruises.length === 0 ?
        <NoCruises/> :
        seasonCruises.map((cruise, index) => <CruiseCard cruise={cruise} key={index}/>)}
    </Container>
  )
}

export default Cruises;