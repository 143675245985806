import AppCheckbox from "../../../../../appCheckbox/AppCheckbox";
import {useContext, useEffect, useState} from "react";
import {Typography} from "@mui/material";
import {fonts, mediumText} from "../../../../../../../../app.style";
import {AppContext} from "../../../../../appWrapper/AppWrapper";
import {addToArray, clone, removeFromArray} from "../../../../../../../utils/misc/helpers";
import {BunkStatus} from "../../../../../../../utils/appEnums/BunkStatus";

interface BunkCheckboxProps {
  bunkNo: number;
  passengerQty: number;
  getBunkStatus: Function
  selectedBunks: number[];
  setSelectedBunks: Function;
}

const BunkCheckbox = (props: BunkCheckboxProps) => {
  const {translation} = useContext(AppContext);
  const [disabled, setDisabled] = useState(false);

  const handleChange = (checked: boolean) => {
    if (checked) {
      props.setSelectedBunks(addToArray(props.bunkNo, clone(props.selectedBunks)));
    } else {
      props.setSelectedBunks(removeFromArray(props.bunkNo, clone(props.selectedBunks)))
    }
  }

  useEffect(() => {
    if (props.getBunkStatus(props.bunkNo) === BunkStatus.TAKEN) {
      setDisabled(true);
    } else if (props.getBunkStatus(props.bunkNo) === BunkStatus.SELECTED) {
      setDisabled(false);
    } else if (props.selectedBunks.length === props.passengerQty) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [props]);

  return (
    <AppCheckbox
      checked={props.selectedBunks.includes(props.bunkNo)}
      label={
        <Typography sx={[
          fonts.spartan14, mediumText,
          {textDecoration: props.getBunkStatus(props.bunkNo) === BunkStatus.TAKEN ? "line-through" : undefined}]
        }>
          {Object.values(translation.form.newBooking.bunksSelectionStep.bunkName)[props.bunkNo-1]}
        </Typography>}
      setFormData={handleChange} disabled={disabled}/>
  )
}

export default BunkCheckbox;