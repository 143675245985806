import {Box, Container, Tab, Tabs, Typography} from "@mui/material";
import {centeredBox, fonts, mainColors, semiBoldText} from "../../../../../app.style";
import useBookingIntervalOptions from "./useBookingIntervalOptions";
import {useContext, useEffect, useState} from "react";
import {AppContext} from "../../../common/appWrapper/AppWrapper";

interface BookingIntervalTabsProps {
  setSelectedBookingInterval: Function;
}

const BookingIntervalTabs = ({setSelectedBookingInterval}: BookingIntervalTabsProps) => {
  const [tab, setTab] = useState(0);
  const {translation} = useContext(AppContext);
  const {bookingIntervalOptions} = useBookingIntervalOptions();

  const handleChange = (newValue: number) => {
    setTab(newValue);
    setSelectedBookingInterval(bookingIntervalOptions[newValue]);
  };

  useEffect(() => {
    if (bookingIntervalOptions.length > 1) {
      setTab(1);
    }
  }, [bookingIntervalOptions.length]);

  return (
    <Container>
      <Box sx={centeredBox}>
        <Tabs value={tab} variant={"scrollable"} sx={{borderBottom: 1, borderColor: 'divider'}}>
          {
            bookingIntervalOptions.map((option, index) => (
              <Tab
                onClick={() => handleChange(index)}
                sx={[mainColors.contrast]} key={index} label={
                <Typography sx={[fonts.navRunic30, semiBoldText, {my: -2}]}>
                  {translation.page.bookings.bookingInterval[option]}
                </Typography>
              }/>
            ))
          }
        </Tabs>
      </Box>
    </Container>
  )
}

export default BookingIntervalTabs;