import useGet from "../axios/useGet";
import {apiUrl} from "../urls";
import {useEffect, useState} from "react";
import {Booking} from "../../model/Booking";

const useGetMyBookings = () => {
  const [myBookings, setMyBookings] = useState([] as Booking[]);
  const {data, setData, loading: getMyBookingsLoading, getData: getMyBookings} = useGet(apiUrl.bookings);

  useEffect(() => {
    if (data) {
      const bookings = data as unknown as Booking[]
      setMyBookings(bookings);
      setData(null);
    }
  }, [data, setData]);


  return {myBookings, setMyBookings, getMyBookings, getMyBookingsLoading}
}

export default useGetMyBookings;