import {Language} from "../../../language/Language";
import {Gender} from "../../model/modelEnums/Gender";
import {SimpleOptionProps} from "../../components/common/appSelectField/appSelectField/simpleOption/SimpleOption";

export default class RegisterRequest {
  name = "";
  surname = "";
  nickname = "";
  gender = Gender.MALE;
  born = "1990-01-01";
  email = "";
  phone = "";
  password = "";
  repeatPassword = "";
  lang: Language;

  constructor(language: Language) {
    this.lang = language;
  }

  setName = (input: string) => {
    this.name = input;
  }

  setSurname = (input: string) => {
    this.surname = input;
  }

  setNickname = (input: string) => {
    this.nickname = input;
  }

  setGender = (input: { props: SimpleOptionProps }) => {
    this.gender = input.props.value as Gender;
  }

  setBorn = (input: string) => {
    this.born = input;
  }

  setEmail = (input: string) => {
    this.email = input.toLowerCase().trim();
  }

  setPhone = (input: string) => {
    this.phone = input;
  }

  setPassword = (input: string) => {
    this.password = input;
  }

  setRepeatPassword = (input: string) => {
    this.repeatPassword = input;
  }
}