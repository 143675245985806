import useGet from "../axios/useGet";
import {apiUrl} from "../urls";
import {useEffect, useState} from "react";
import {Cruise} from "../../model/Cruise";
import {sortCruisesByDateAndStatus, updateCache} from "../helpers/cacheUtils";
import {clone} from "../../utils/misc/helpers";

const useGetCruises = () => {
  const [cruises, setCruises] = useState([] as Cruise[]);
  const {data, setData, loading: getCruisesLoading, getData: getCruises} = useGet(apiUrl.cruises);

  useEffect(() => {
    if (data) {
      const getResponse = data as unknown as Cruise[]
      const cacheClone = clone(cruises);
      updateCache(getResponse, cacheClone)
      sortCruisesByDateAndStatus(cacheClone);
      setCruises(cacheClone);
      setData(null);
    }
  }, [cruises, data, setData])

  return {cruises, setCruises, getCruises, getCruisesLoading}
}

export default useGetCruises;